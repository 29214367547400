import mock from '../mock'
import { paginateArray } from '../utils'

const data = {
  registrations: [
    {
      id: 1,
      firstname: 'Ramakrishna',
      lastname: 'Parimi',
      email: 'rk@gmail.com',
      dateofbirth: '1995-05-02',
      nameofthefather: 'Venkateswarlu',
      mobilenumber: '9494505228',
      gender: 'Male',
      address: 'abc',
      grade: '1st class',
      section: 'Section - A',
      medium: 'English',
      academicyear: '2021 - 2022',
      joinyear: '2021 - 2022',
      feecategory: 'Hostel',
      admissionno: '123456',
      dateofjoin: '2021-09-25',
      hostelfacility: 'Yes',
      busfacility: 'No'
    },
    {
      id: 2,
      firstname: 'Anil',
      lastname: 'Pavuluri',
      email: 'anil@gmail.com',
      dateofbirth: '1995-04-02',
      nameofthefather: 'Brahamaiah',
      mobilenumber: '9640401898',
      gender: 'Male',
      address: 'def',
      grade: '1st class',
      section: 'Section - A',
      medium: 'English',
      academicyear: '2021 - 2022',
      joinyear: '2021 - 2022',
      feecategory: 'Hostel',
      admissionno: '123456',
      dateofjoin: '2021-09-25',
      hostelfacility: 'Yes',
      busfacility: 'No'
    },
    {
      id: 3,
      firstname: 'Arun',
      lastname: 'Mareedu',
      email: 'arun@gmail.com',
      dateofbirth: '1992-05-01',
      nameofthefather: 'Venkateswarlu',
      mobilenumber: '9494505228',
      address: 'abc',
      gender: 'Male',
      grade: '1st class',
      section: 'Section - A',
      medium: 'English',
      academicyear: '2021 - 2022',
      joinyear: '2021 - 2022',
      feecategory: 'Hostel',
      admissionno: '123456',
      dateofjoin: '2021-09-25',
      hostelfacility: 'Yes',
      busfacility: 'No'
    },
    {
      id: 4,
      firstname: 'Ramakrishna',
      lastname: 'Parimi',
      email: 'rk@gmail.com',
      dateofbirth: '1995-05-02',
      nameofthefather: 'Venkateswarlu',
      mobilenumber: '9494505228',
      gender: 'Male',
      address: 'abc',
      grade: '1st class',
      section: 'Section - A',
      medium: 'English',
      academicyear: '2021 - 2022',
      joinyear: '2021 - 2022',
      feecategory: 'Hostel',
      admissionno: '123456',
      dateofjoin: '2021-09-25',
      hostelfacility: 'Yes',
      busfacility: 'No'
    },
    {
      id: 5,
      firstname: 'Ramakrishna',
      lastname: 'Parimi',
      email: 'rk@gmail.com',
      dateofbirth: '1995-05-02',
      nameofthefather: 'Venkateswarlu',
      mobilenumber: '9494505228',
      gender: 'Male',
      address: 'abc',
      grade: '1st class',
      section: 'Section - A',
      medium: 'English',
      academicyear: '2021 - 2022',
      joinyear: '2021 - 2022',
      feecategory: 'Hostel',
      admissionno: '123456',
      dateofjoin: '2021-09-25',
      hostelfacility: 'Yes',
      busfacility: 'No'
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/registrations/list/all-data').reply(200, data.registrations)

// POST: Add new registration
mock.onPost('/apps/registrations/add-registration').reply(config => {
  // Get event from post data
  const registration = JSON.parse(config.data)

  const { length } = data.registrations
  let lastIndex = 0
  if (length) {
    lastIndex = data.registrations[length - 1].id
  }
  registration.id = lastIndex + 1

  data.registrations.unshift(registration)

  return [201, { registration }]
})

// GET Updated DATA
mock.onGet('/api/registrations/list/data').reply(config => {
  const { q = '', perPage = 10, page = 1 } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const filteredData =
    data.registrations.filter(registration => (registration.firstname.toLowerCase().includes(queryLowered) || registration.lastname.toLowerCase().includes(queryLowered)))
  /* eslint-enable  */

  return [
    200,
    {
      registrations: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

// GET Bank
mock.onGet('/api/registrations/registration').reply(config => {
  const { id } = config
  const registration = data.registrations.find(i => i.id === id)
  return [200, { registration }]
})

// DELETE: Deletes Registration
mock.onDelete('/apps/registrations/delete').reply(config => {
  // Get registration id from URL
  let regId = config.id

  // Convert Id to number
  regId = Number(regId)

  const regIndex = data.registrations.findIndex(t => t.id === regId)
  data.registrations.splice(regIndex, 1)

  return [200]
})
