// ** Initial State
const initialState = {
  allData: [],
  ciList: [],
  data: [],
  total: 1,
  params: {},
  spinner: false,
  tokenExp: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const NaipunyaDashboard = (state = initialState, action) => {
  switch (action.type) {
    case "GET_NP_BATCHES_DATA":
      return {
        ...state,
        allData: action.allData,
        ciList: initialItem(action.courseInfoList),
        spinner: action.spinner,
      };
    case "GET_NP_BATCHES_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default NaipunyaDashboard;
