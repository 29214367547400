import { DefaultConfig } from "../../../../../../settings/config";
// ** Initial State
const initialState = {
  stuIOData: [],
  allData: [],
  data: [],
  total: 1,
  params: {},
  spinner: false,
  tokenExp: false,

  lallData: [],
  ldata: [],
  ltotal: 1,
  lparams: {},
};

const initialItem = (resData) => {
  // if (resData) {
  //   resData.forEach((resp, index) => {
  //     resp.serial = index + 1;
  //   });
  //   return resData;
  // } else {
  //   return [];
  // }
  const Classes = DefaultConfig().Classes;
  const Sections = DefaultConfig().Sections;
  const departmentsOptions = DefaultConfig().Departments
  if (resData) {
    resData.forEach((resp, index) => {
      resp.class = Classes.find((find) => find.value === resp.cls)
        ? Classes.find((find) => find.value === resp.cls).label
        : ""; 
      resp.section = Sections.find((find) => find.value === resp.sec)
        ? Sections.find((find) => find.value === resp.sec).label
        : "";
        resp.deptRef = departmentsOptions.find((find) => find.value === resp.deptRef)
        ? departmentsOptions.find((find) => find.value === resp.deptRef).label
        : "";
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const initialRowItem = (resData) => {
  // console.log(resData, "red");
  const Classes = DefaultConfig().Classes;
  const Sections = DefaultConfig().Sections;
  if (resData) {
    resData.forEach((resp, index) => {
      resp.class = Classes.find((find) => find.value === resp.cls)
        ? Classes.find((find) => find.value === resp.cls).label
        : "";
      resp.section = Sections.find((find) => find.value === resp.sec)
        ? Sections.find((find) => find.value === resp.sec).label
        : "";
    });
    return resData;
  } else {
    return [];
  }
};

const StuIOAttendance = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_STF_DATA":
      // console.log(action, "reducer");
      return {
        ...state,
        stuIOData: initialRowItem(action.data),
        spinner: action.spinner,
      };
    case "STF_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_STFIO_ATT_DATA":
      // console.log(action, "act");
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_STAFF_FIL_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
      };
    case "GET_STFLIO_ATT_DATA":
      return {
        ...state,
        lallData: initialItem(action.allData),
        ldata: action.data,
        ltotal: action.totalPages,
        lparams: action.params,
        spinner: action.spinner,
      };
    case "GET_STAFFL_FIL_DATA":
      return {
        ...state,
        ldata: action.data,
        ltotal: action.totalPages,
      };
    default:
      return { ...state };
  }
};
export default StuIOAttendance;
