import { DefaultConfig } from "../../../../../settings/config";
const catg = DefaultConfig().invCatgOpt;

// ** Initial State
const initialState = {
  allData: [],
  totData: [],
  data: [],
  acadYearList: [],
  total: 1,
  params: {},
  selectedInvoice: null,
  actionTitle: "",
  spinner: false,
  aiSpinner: false,
  tokenExp: false,
  invData: [],
  invSpinner: false,
  creditdata: [],
  aspinner: false,
  spinner: false,
  cspinner: false,
  tokenExp: false,
  erpdata: [],
  espinner: false,
  spData: '',
  spSpinner: false,
};

const initialItem = (resData) => {
  if (resData) {
    console.log(resData, "ResData");
    resData.forEach((resp, index) => {
      const price = resp.quantity * resp.unitPrice;
      resp["date"] = DefaultConfig().DateFormatMonth(resp.date);
      resp["bal"] = resp.invAmount - resp.paid - resp.discount + resp.gst;
      if (catg.find((find) => find.value === resp.catg)) {
        resp.catgname = catg.find((find) => find.value === resp.catg).label;
      } else {
        resp.catgname = "";
      }
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
    ``;
  }
};

const InvoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_INVOICE_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        totData: action.totData,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_INVOICE_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_INVOICE":
      return {
        ...state,
        selectedInvoice: action.selectedInvoice,
        actionTitle: action.actionTitle,
      };
    case "GET_ACAD_YEAR_LIST":
      return {
        ...state,
        acadYearList: action.data,
        aspinner: action.spinner,
      };
    case "ACAD_YEAR_LOADING_SPINNER":
      return {
        ...state,
        aspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "ADD_INVOICE":
      // console.log(action.allData, "ROW Adding");
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
        aiSpinner: action.spinner
      };
    case "UPDATE_INVOICE":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "DELETE_INVOICE":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "INVOICE_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_SINGLE_INVOICE_DATA":
      return {
        ...state,
        invData: action.allData,
        invSpinner: action.spinner,
      };
    case "GET_INVOICE_NO_LOADING_SPINNER":
      return {
        ...state,
        invSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "INV_ENTY_REMOVE":
      return {
        ...state,
        totData: null,
      };
    case "SAVE_PACKAGES_LOADING_SPINNER":
      return {
        ...state,
        spSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "SAVE_PACKAGES":
      return {
        ...state,
        spData: action.allData,
        spSpinner: action.spinner,
      };
    default:
      return { ...state };
  }
};
export default InvoiceReducer;
