// ** Initial State
const initialState = {
  spinner: false,
  tokenExp: false,
  htData: []
}

const HallTickets = (state = initialState, action) => {
  switch (action.type) {
    case "GET_HALL_TICKET_DATA":
      return {
        ...state,
        htData: action.data,
        spinner: action.spinner
      }
    case "HALL_TICKET_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp
      }
    default:
      return { ...state }
  }
}
export default HallTickets
