import { DefaultConfig } from "../../../../../../settings/config";
const Day = DefaultConfig().Day;

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      if (resp.stTime) {
        console.log(resp.stTime, "reduceram");
        const H = +resp.stTime.substr(0, 2);
        const h = H % 12 || 12;
        const ampm = H < 12 || H === 24 ? "AM" : "PM";
        resp.startTime = h + resp.stTime.substr(2, 3) + ampm;
      }
      if (Day.find((find) => find.value === resp.workingdaysId.day)) {
        resp.dayName = Day.find(
          (find) => find.value === resp.workingdaysId.day
        ).label;
      } else {
        resp.dayName = "";
      }
      if (resp.endTime) {
        const H = +resp.endTime.substr(0, 2);
        const h = H % 12 || 12;
        const ampm = H < 12 || H === 24 ? "AM" : "PM";
        resp.end = h + resp.endTime.substr(2, 3) + ampm;
      }
    });
    return resData;
  } else {
    return [];
  }
};

const InstituteTimeings = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_INSTIME_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_INSTIME_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_INSTIME":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };
    case "ADD_INSTIME":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "UPDATE_INSTIME":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "DELETE_INSTIME":
      return { ...state };
    case "INSTIME_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default InstituteTimeings;
