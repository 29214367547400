// ** Initial State
const initialState = {
  examData: [],
  questionPaperData: [],
  tokenExp: false,
  espinner: false,
  pspinner: false,
  qpspinner: false,
  stuData: [],
  keyDat: [],
  kspinner: false,
  result: [],
  rspinner: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const WriteExam = (state = initialState, action) => {
  switch (action.type) {
    case "GET_EXAM_DATA":
      console.log(action);
      return {
        ...state,
        examData: action.allData,
        espinner: action.spinner,
      };
    case "EXAM_LOADING_SPINNER":
      return {
        ...state,
        espinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_STU_DATA":
      console.log(action);
      return {
        ...state,
        stuData: action.data,
        pspinner: action.spinner,
      };
    case "STU_LOADING_SPINNER":
      return {
        ...state,
        pspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_QUESTION_PAPER_DATA":
      console.log(action);
      return {
        ...state,
        questionPaperData: action.allData,
        qpspinner: action.spinner,
      };
    case "QUESTION_PAPER_SPINNER":
      return {
        ...state,
        qpspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_KEY_PAPER_DATA":
      console.log(action);
      return {
        ...state,
        keyData: action.allData,
        kspinner: action.spinner,
      };
    case "KEY_PAPER_SPINNER":
      return {
        ...state,
        kspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "EXAM_SUBMIT":
      console.log(action);
      return {
        ...state,
        result: action.allData,
        rspinner: action.spinner,
      };
    case "EXAM_SUBMIT_SPINNER":
      return {
        ...state,
        rspinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    default:
      return { ...state };
  }
};
export default WriteExam;
