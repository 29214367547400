// ** Initial State
const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    selectedData: null,
    actionTitle: "",
    spinner: false,
    tokenExp: false,
    saveTeacherReview: '',
    strSpinner: false,
  };
  
  const initialItem = (resData) => {
    if (resData) {
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
      });
      return resData;
    } else {
      return [];
    }
  };
  
  const swCmpltdReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_SW_CMLTD_TOPICS_DATA":
        return {
          ...state,
          allData: initialItem(action.allData),
          data: action.data,
          total: action.totalPages,
          params: action.params,
          spinner: action.spinner,
        };
      case "SW_CMPLTD_TOPICS_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      case "GET_SW_CMPLTD_TOPICS_GETDATA":
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params,
        };
    //   case "SAVE_SW_TEACHER_REVIEW":
    //     return {
    //       ...state,
    //       saveTeacherReview: action.allData,
    //       spinner: action.spinner,
    //     };
    //   case "SW_TOPICS_LOADING_SPINNER":
    //     return {
    //       ...state,
    //       spinner: action.spinner,
    //       tokenExp: action.tokenExp,
    //     };
      default:
        return { ...state };
    }
  };
  export default swCmpltdReducer;  