import axios from "axios";
import { toast } from "react-toastify";
import ToastMessage from "../../../../../../@core/components/toast-messages";
import { paginateArray } from "../../../../../../@fake-db/utils";
import { DefaultConfig } from "../../../../../../settings/config";

export const getLeaveApplyDataTwo = (changeMonth, params, emp) => {
  return async (dispatch, getState) => {
    const authAxios = DefaultConfig().authAxios;
    const obj = {};
    obj["empId"] = emp ? emp : DefaultConfig().employeeId;
    obj["compId"] = DefaultConfig().companyId;
    obj["finGrp"] = DefaultConfig().finGrp;
    // obj["month"] = changeMonth.month;
    // obj["year"] = changeMonth.year;
    dispatch({
      type: "LEAVE_APPLY_LOADING_SPINNER",
      spinner: false,
      tokenExp: false,
    });
    await authAxios
      .post(`att/leavesInfo`, obj)
      .then((response) => {
        const resData = response.data;
        if (resData) {
          dispatch({
            type: "GET_LEAVE_APPLY_DATA_TWO",
            allData: resData,
            params,
            spinner: true,
          });
        }
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "LEAVE_APPLY_LOADING_SPINNER",
              spinner: true,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "LEAVE_APPLY_LOADING_SPINNER",
              spinner: true,
              tokenExp: false,
            });
            toast.error(
              <ToastMessage
                message={"att/leavesInfo" + "  " + error}
                status={"danger"}
              />,
              {
                hideProgressBar: true,
              }
            );
          }
        }
      });
  };
};

// ** Get data on page or row change
export const getData = (params) => {
  return (dispatch, getState) => {
    const {
      q = "",
      perPage = 10,
      page = 1,
      sortname = "",
      sortorder = "",
    } = params;
    const resData = getState().leaveApplyData.allData;
    if (resData) {
      let filteredData = [];
      const queryLowered = q.toLowerCase();
      filteredData = resData.filter(
        (att) =>
          att.leaveType && att.leaveType.toLowerCase().includes(queryLowered)
      );
      const sorting = DefaultConfig().sorter;
      const sortData = sortorder
        ? sorting(sortname, sortorder, filteredData)
        : filteredData;
      dispatch({
        type: "GET_LEAVE_TYPES_APPLY_DATA",
        data: paginateArray(sortData, perPage, page),
        totalPages: sortData.length,
        params,
      });
    }
  };
};

// ** Get data on page or row change
export const getBelowData = (params) => {
  return (dispatch, getState) => {
    const {
      q = "",
      perPage = 10,
      page = 1,
      sortname = "",
      sortorder = "",
    } = params;
    const resData = getState().leaveApplyData.dataTwo;
    if (resData) {
      let filteredData = [];
      const queryLowered = q.toLowerCase();
      filteredData = resData.filter(
        (att) =>
          (att.date && att.date.toLowerCase().includes(queryLowered)) ||
          (att.leaveType && att.leaveType.toLowerCase().includes(queryLowered))
      );
      const sorting = DefaultConfig().sorter;
      const sortData = sortorder
        ? sorting(sortname, sortorder, filteredData)
        : filteredData;
      dispatch({
        type: "GET_BELOW_LEAVE_TYPES_APPLY_DATA",
        dataBelow: paginateArray(sortData, perPage, page),
        totalPages: sortData.length,
        params,
      });
    }
  };
};

export const getLeaveApplyTypes = (id, availableLeaves, title) => {
  return (dispatch, getState) => {
    const allData = getState().leaveApplyData.allData;
    const findOne = allData.find((find) => find.leaveType === id);

    dispatch({
      type: "GET_LEAVE_APPLY_TYPES",
      selectedData: findOne,
      actionTitle: title,
    });
    if (id !== "With Loss Of Pay") {
      if (availableLeaves <= 0) {
        toast.error(
          <ToastMessage
            message={
              "You don't have leaves in this category..Please apply in another category.."
            }
            status={"error"}
          />,
          { hideProgressBar: true }
        );
      }
    }
  };
};

export const getSelectedEmp = (id) => {
  return (dispatch) => {
    dispatch({
      type: "GET_SEL_EMP",
      selectEmpId: id,
    });
  };
};

// apply leave
export const applyLeave = (data) => {
  return async (dispatch, getState) => {
    const obj = data;
    const authAxios = DefaultConfig().authAxios;
    dispatch({
      type: "APPLY_LEAVE_LOADING_SPINNER",
      spinner: false,
      tokenExp: false,
    });
    await authAxios
      .post(`att/leaves`, obj)
      .then((response) => {
        const resData = response.data;
        if (resData) {
          dispatch({
            type: "APPLY_LEAVE",
            data: resData,
            spinner: true,
            tokenExp: true,
          });
          if (!resData.value) {
            toast.success(
              <ToastMessage
                message={"Already Leave Applied"}
                status={"success"}
              />,
              { hideProgressBar: true }
            );
          }
          if (resData.value) {
            toast.success(
              <ToastMessage
                message={"Applied Successfully"}
                status={"success"}
              />,
              { hideProgressBar: true }
            );
          }
        }
      })
      .then(() => {
        // const chnageMonth = {};
        // chnageMonth["month"] = new Date().getMonth() + 1;
        // chnageMonth["year"] = new Date().getFullYear();
        dispatch(
          getLeaveApplyDataTwo(
            // chnageMonth,
            getState().leaveApplyData.params,
            getState().leaveApplyData.selectEmpId
          )
        );
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "APPLY_LEAVE_LOADING_SPINNER",
              spinner: true,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "APPLY_LEAVE_LOADING_SPINNER",
              spinner: true,
              tokenExp: false,
            });
            toast.error(
              <ToastMessage
                message={"att/leaves" + "  " + error}
                status={"danger"}
              />,
              {
                hideProgressBar: true,
              }
            );
          }
        }
      });
  };
};

// apply leave
export const leaveAdjstRecord = (data, emp) => {
  return async (dispatch, getState) => {
    const AcademicYear = DefaultConfig().AcademicYear;
    const curYear =
      AcademicYear &&
      AcademicYear.find((c) => c.curYear === true) &&
      AcademicYear.find((c) => c.curYear === true).value;
    const obj = {
      empId : emp,
      ltRef: data.ltRef,
      acadYear: curYear
    }
    const authAxios = DefaultConfig().authAxios;
    dispatch({
      type: "LEAVE_ADJUST_LOADING_SPINNER",
      spinner: false,
      tokenExp: false,
    });
    await authAxios
      .post(`att/leavesAdjustList`, obj)
      .then((response) => {
        const resData = response.data.value;
        if (resData) {
          dispatch({
            type: "LEAVE_ADJUSTMENT",
            data: resData,
            spinner: true,
            tokenExp: true,
          });
        }
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "LEAVE_ADJUST_LOADING_SPINNER",
              spinner: true,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "LEAVE_ADJUST_LOADING_SPINNER",
              spinner: true,
              tokenExp: false,
            });
            toast.error(
              <ToastMessage
                message={"att/leavesAdjustList" + "  " + error}
                status={"danger"}
              />,
              {
                hideProgressBar: true,
              }
            );
          }
        }
      });
  };
};
