import mock from "../mock"
import { paginateArray } from "../utils"

const data = {
  subjectmappings: [
    {
      id: 1,
      class: "All Classes",
      section: "All Sections",
      medium: "",
      subject: ""
    },
    {
      id: 2,
      class: "Nursery",
      section: "Army",
      medium: "English",
      subject: "computer"
    }
  ]
}

// GET ALL DATA
mock.onGet("/api/map/all-data").reply(200, data.subjectmappings)

//GET UPDATE DATA
mock.onGet("/api/map/data").reply((config) => {
  const { q = "", perPage = 10, page = 1 } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const filteredData = data.subjectmappings.filter(
    (subject) =>
      subject.class.toLowerCase().includes(queryLowered) ||
      subject.section.toLowerCase().includes(queryLowered)
  )
  /* eslint-enable  */

  return [
    200,
    {
      subjectmappings: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

// GET Subject
mock.onGet("/api/maps/map").reply((config) => {
  const { id } = config
  const subjectmapping = data.subjectmappings.find((i) => i.id === id)
  return [200, { subjectmapping }]
})
//Add subject
mock.onPost("/api/map/add-map").reply((config) => {
  // Get event from post data
  const subjectmapping = JSON.parse(config.data)

  const { length } = data.subjectmappings
  let lastIndex = 0
  if (length) {
    lastIndex = data.subjectmappings[length - 1].id
  }
  subjectmapping.id = lastIndex + 1

  data.subjectmappings.unshift(subjectmapping)

  return [201, { subjectmapping }]
})

// DELETE: Deletes Bank
mock.onDelete("/api/map/delete").reply((config) => {
  // Get bank id from URL
  let subjectmappingId = config.id

  // Convert Id to number
  subjectmappingId = Number(subjectmappingId)

  const subjectmappingIndex = data.subjectmappings.findIndex((t) => t.id === subjectmappingId)
  data.subjectmappings.splice(subjectmappingIndex, 1)

  return [200]
})
