// ** Initial State
const initialState = {
  bankData: [],
  trfrData: {},
  spinner: false,
  trfrSpinner: false,
  tokenExp: false,
  otpdata: {},
  otpspinner: false,
  deldata: {},
  delspinner: false,

  trfrGrpBillsData: [],
  trfrGrpBillsSpinner: false,
  trfrGrpBillsTokenExp: false,

  branchListData: [],
  branchListSpinner: false,
};

const FeeTransfers = (state = initialState, action) => {
  switch (action.type) {
    case "GET_FEE_TRNS_BANK_DATA":
      return {
        ...state,
        bankData: action.data,
        spinner: action.spinner,
      };
    case "ADD_FEE_TRNS":
      return {
        ...state,
        trfrData: action.data,
        trfrSpinner: action.spinner,
      };
    case "FEE_TRNS_LOADING_SPINNER":
      return {
        ...state,
        trfrSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "TRFR_ENTY_REMOVE":
      return {
        ...state,
        trfrData: null,
      };

    case "REPRINT_TRNS_VCHR_DATA":
      return {
        ...state,
        trfrData: action.allData,
        trfrSpinner: action.spinner
      }
    case "REPRINT_TRNS_VCHR_LOADING_SPINNER":
      return {
        ...state,
        trfrSpinner: action.spinner,
        tokenExp: action.tokenExp
      }
    
    case "GET_OTP_DATA":
      return {
        ...state,
        otpdata: action.data,
        otpspinner: action.spinner,
      }
    case "GET_OTP_LOADING_SPINNER":
      return {
        ...state,
        otpspinner: action.spinner,
      }
    case "DEL_OTP_DATA":
      return {
        ...state,
        deldata: action.data,
        delspinner: action.spinner,
      }
    case "DEL_OTP_LOADING_SPINNER":
      return {
        ...state,
        delspinner: action.spinner,
      }
    case "GET_PEND_GRP_BILLS_DATA":
      return {
        ...state,
        trfrGrpBillsData: action.data,
        trfrGrpBillsSpinner: action.spinner,
      };
    case "GET_PEND_GRP_BILLS_LOADING_SPINNER":
      return {
        ...state,
        trfrGrpBillsSpinner: action.spinner,
        trfrGrpBillsTokenExp: action.tokenExp,
      };
      case "GET_BRANCH_LIST_DATA":
      return {
        ...state,
        branchListData: action.data,
        branchListSpinner: action.spinner,
      };
    case "GET_BRANCH_LIST_DATA_LOADING_SPINNER":
      return {
        ...state,
        branchListSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default FeeTransfers;
