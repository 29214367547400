// ** Initial State
const initialState = {
  incspinner: false,
  tokenExp: false,
};

const StuIncomeExcelUpload = (state = initialState, action) => {
  switch (action.type) {
    case "STU_INCOME_EXCEL_LOADING_SPINNER":
      return {
        ...state,
        incspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default StuIncomeExcelUpload;
