// ** Initial State
const initialState = {
  allData: [],
  sentData: [],
  sspinner: false,
  tokenExp: false,
  noticeData: [],
  nspinner: false,
  temData: [],
  tspinner: false,

  annResData: {},
  annResSpinner: false,

  waTempData: {},
  waTempCount: '',
  waTempSize: '',
  waTempSpinner: false,
};


const initialItem = (resData) => {
  if (resData) {
    console.log(resData, "ResData");
    resData.forEach((resp, index) => {
      resp["senderId"] = resp.id.senderId;
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const Announcements = (state = initialState, action) => {
  switch (action.type) {
    case "ANN_RES_DATA_DATA":
      return {
        ...state,
        annResData: action.annResData,
        annResSpinner: action.spinner,
      };
    case "ANN_LOADING_SPINNER":
      return {
        ...state,
        nspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_ALL_NOTICE_DATA":
      return {
        ...state,
        noticeData: action.allData,
        nspinner: action.spinner,
      };
    case "NOTICE_LOADING_SPINNER":
      return {
        ...state,
        nspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_ALL_SENT_DATA":
      return {
        ...state,
        sentData: action.allData,
        sspinner: action.spinner,
      };
    case "SENTANN_LOADING_SPINNER":
      return {
        ...state,
        sspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "UPDATE_ANN_NOTIFICATIONS_DATA":
      return {
        ...state,
        noticeData: action.data,
      };
    case "UPDATE_NOTIFICATION_LOADING_SPINNER":
      return {
        ...state,
        nspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_TEMP_DATA":
      return {
        ...state,
        temData: action.allData,
        tspinner: action.spinner,
      };
    case "TEMP_LOADING_SPINNER":
      return {
        ...state,
        nspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_WHATSAPP_TEMP_LIST":
      return {
        ...state,
        waTempData: action.allData,
        waTempCount: action.waTempCount,
        waTempSize: action.waTempSize,
        waTempSpinner: action.spinner,
      };
    case "WHATSAPP_TEMP_LIST_LOADING_SPINNER":
      return {
        ...state,
        waTempSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    default:
      return { ...state };
  }
};
export default Announcements;
