//** Initial State
const initialState = {
  allData: [],
  resData: [],
  data: [],
  stuSno: "",
  total: 1,
  params: {},
  spinner: false,
  tokenExp: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });

    return resData;
  } else {
    return [];
  }
};

const StuLogDtlsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_STUDENT_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };

    case "GET_STUDENT_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "STUDENT_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_STUDENT_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "SEND_LOGIN_DETAILS_DATA":
      return {
        ...state,
        sentData: action.resData,
        spinner: action.spinner
      };
    case "SEND_LOGIN_DETAILS_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default StuLogDtlsReducer;
