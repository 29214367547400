import { DefaultConfig } from "../../../../../../settings/config";
const companyId = DefaultConfig().companyId;
const term = DefaultConfig().term;
const termList = DefaultConfig().termList;

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
  pspinner: false,
  progData: [],
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const CbsePattern = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_CBSC_DATA":
      console.log(action, "red");
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };

    case "GET_CBSC_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_CBSC":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };
    case "ADD_CBSC":
      console.log(action, "red");
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "GET_ALL_PROGRESS_DATA":
      return {
        ...state,
        progData: action.allData,
        pspinner: action.spinner,
      };

    // case "UPDATE_CBSC":
    //   return {
    //     ...state,
    //     allData: initialItem(action.allData),
    //     spinner: action.spinner
    //   }
    case "DELETE_CBSC":
      return { ...state };
    case "CBSC_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "PROGRESS_LOADING_SPINNER":
      return {
        ...state,
        pspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default CbsePattern;
