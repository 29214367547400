import { DefaultConfig } from "../../../../../../settings/config";
const accountType = DefaultConfig().accountType;

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  parent: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
};

const initialItem = (resData) => {
  if (resData) {
    //console.log(par);
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      if (accountType.find((find) => find.value === resp.incExp)) {
        resp.account = accountType.find(
          (find) => find.value === resp.incExp
        ).label;
      } else {
        resp.account = "";
      }
      // if (resData.find((find) => find.sno === resp.parent)) {
      //   resp.par = resData.find((find) => find.sno === resp.parent).descr;
      // } else {
      //   resp.par = "";
      // }
    });
    return resData;
  } else {
    return [];
  }
};

const AccountType = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_ACC_TYPE_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        parent: action.parent,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_ACC_TYPE_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_ACCOUNT":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };
    case "ADD_ACCOUNT":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "UPDATE_ACCOUNT":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "DELETE_ACCOUNT":
      return { ...state };
    case "ACC_TYPE_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default AccountType;
