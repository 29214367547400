
// ** Store & Actions
import { getFeeHeads, deleteFeeHeads } from "../store/action"
import { store } from "@store/storeConfig/store"

// ** Third Party Components
import { Button, UncontrolledTooltip } from "reactstrap"
import { Trash2, Eye, Edit } from "react-feather"

// ** config settings
import { DefaultConfig } from "../../../../../settings/config"
const amtSymbol = DefaultConfig().amtSymbol

export const columns = [
  {
    name: "S.NO",
    minWidth: "0px",
    maxWidth: "60px",
    selector: "serial",
    sortable: true,
    showhide: true,
    center: true,
    cell: (row) => row.serial
  },
  {
    name: "Category",
    minWidth: "0px",
    selector: "category",
    sortable: true,
    showhide: true,
    center: true,
    grow: 1.5,
    cell: (row) => row.category
  },
  {
    name: "Fee Header",
    minWidth: "0px",
    selector: "printHead",
    sortable: true,
    showhide: true,
    center: true,
    cell: (row) => row.printHead
  },
  {
    name: "Term(s)",
    minWidth: "0px",
    selector: "terms",
    sortable: true,
    showhide: true,
    center: true,
    cell: (row) => row.terms
  },
  {
    name: "Fee Type",
    minWidth: "0px",
    selector: "feeType",
    sortable: true,
    showhide: true,
    center: true,
    grow: 1.5,
    cell: (row) => row.feeType
  },
  {
    name: "Account Name",
    minWidth: "0px",
    selector: "accName",
    sortable: true,
    showhide: true,
    center: true,
    grow: 1.5,
    cell: (row) => row.accName
  },
  // {
  //   name: "Route",
  //   minWidth: "0px",
  //   selector: "routeName",
  //   sortable: true,
  //   showhide: true,
  //   cell: (row) => row.routeName
  // },
  {
    name: "New Admissions",
    minWidth: "0px",
    selector: "newadmName",
    sortable: true,
    showhide: true,
    center: true,
    grow: 1.5,
    cell: (row) => row.newadmName
  },
  // {
  //   name: "Hostel",
  //   minWidth: "0px",
  //   selector: "hslName",
  //   sortable: true,
  //   showhide: true,
  //   cell: (row) => row.hslName
  // },
  {
    name: "Special Course",
    minWidth: "0px",
    selector: "splCourseName",
    sortable: true,
    showhide: true,
    center: true,
    cell: (row) => row.splCourseName
  },
  {
    name: "Course Fee",
    minWidth: "0px",
    selector: "courseFee",
    sortable: true,
    showhide: true,
    center: true,
    cell: (row) => (row.courseFee ? (<div className='text-right w-100'> {amtSymbol} {row.courseFee}</div>) : '')
  },
  {
    name: "GST",
    minWidth: "0px",
    selector: "gst",
    sortable: true,
    showhide: true,
    center: true,
    cell: (row) => row.gst
  },
  {
    name: "Hide Option",
    minWidth: "0px",
    selector: "hideOptionName",
    sortable: true,
    showhide: true,
    center: true,
    cell: (row) => row.hideOptionName
  },
  {
    name: "Priority",
    minWidth: "0px",
    selector: "priority",
    sortable: true,
    showhide: true,
    center: true,
    cell: (row) => row.priority
  },
  {
    name: "Edit By Student",
    minWidth: "0px",
    selector: "editStudent",
    sortable: true,
    showhide: true,
    center: true,
    cell: (row) => row.editStudent
  },
  {
    name: "Gender",
    minWidth: "0px",
    selector: "genderName",
    sortable: true,
    showhide: true,
    center: true,
    cell: (row) => row.genderName
  },
  {
    name: "Actions",
    minWidth: "120px",
    maxWidth: "160px",
    showhide: true,
    center: true,
    allowOverflow: true,
    cell: (row) => (
      <div className='d-flex'>
        <Button.Ripple
          className="btn-icon btn_Action"
          outline
          color="primary"
          id={`id_${row.serial}_view`}
          onClick={() => store.dispatch(getFeeHeads(row.feeheadsId, "View"))}
        >
          <Eye size={15} />
        </Button.Ripple>
        <UncontrolledTooltip placement="top" target={`id_${row.serial}_view`}>
          View
        </UncontrolledTooltip>
        <Button.Ripple
          className="btn-icon btn_Action"
          outline
          color="primary"
          id={`id_${row.serial}_edit`}
          onClick={() => store.dispatch(getFeeHeads(row.feeheadsId, "Edit"))}
        >
          <Edit size={15} />
        </Button.Ripple>
        <UncontrolledTooltip placement="top" target={`id_${row.serial}_edit`}>
          Edit
        </UncontrolledTooltip>
        {/* <Button.Ripple
          className="btn-icon btn_Action"
          outline
          color="primary"
          id="deleteTip"
          onClick={() => store.dispatch(deleteFeeHeads(row.feeheadsId))}
        >
          <Trash2 size={15} />
        </Button.Ripple>
        <UncontrolledTooltip placement="top" target="deleteTip">
          Delete
        </UncontrolledTooltip> */}
      </div>
    )
  }
]
