// ** Initial State
const initialState = {
  eleSubjData: [],
  espinner: false,
  allData: [],
  data: [],
  total: 1,
  params: {},
  actionTitle: "",
  spinner: false,
  tokenExp: false,
};

  const initialItem = (resData) => {
    if (resData) {
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
      });
      return resData;
    } else {
      return [];
    }
  };

const ElectiveSubjReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_STU_ELESUBJ_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: initialItem(action.data),
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "STU_ELECSUBJ_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "UPD_STU_ELECTIVE":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "GET_ELESUB_GETDATA":
      return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params,
      };
    case "GET_ELECTIVE_SUBJ_DATA":
      return {
        ...state,
        eleSubjData: action.allData,
        espinner: action.spinner,
      };
    case "ELECTIVE_SUBJ_LOADING_SPINNER":
      return {
        ...state,
        espinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default ElectiveSubjReducer;
