import { DefaultConfig } from "../../../../../settings/config";
const DateFormatMonth = DefaultConfig().DateFormatMonth;
const warenty = DefaultConfig().warenty;

// ** Initial State
const initialState = {
  routeData: [],
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      resp.permit = resp.permitValid ? DateFormatMonth(resp.permitValid) : "";
      resp.insurance = resp.insurValid ? DateFormatMonth(resp.insurValid) : "";
      if (warenty.find((find) => find.value === resp.warranty)) {
        resp.warentyName = warenty.find(
          (find) => find.value === resp.warranty
        ).label;
      } else {
        resp.warentyName = "";
      }
    });
    return resData;
  } else {
    return [];
  }
};

const AddBuses = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ROUTE_DATA":
      return {
        ...state,
        routeData: action.data,
        spinner: action.spinner,
      };

    case "GET_ALL_BUS_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_BUS_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_BUS":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };
    case "ADD_BUS":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "UPDATE_BUS":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "DELETE_BUS":
      return { ...state };
    case "BUS_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default AddBuses;
