import mock from "../mock"
import { paginateArray } from "../utils"

const data = {
  mediums: [
    {
      id: 1,
      name: "English",
      status: "approved"
    },
    {
      id: 2,
      name: "Telugu",
      status: "approved"
    },
    {
      id: 3,
      name: "Kanada",
      status: "approved"
    }
  ]
}

// GET ALL DATA
mock.onGet("/api/med/all-data").reply(200, data.mediums)

//GET UPDATE DATA
mock.onGet("/api/med/data").reply((config) => {
  const { q = "", perPage = 10, page = 1 } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const filteredData = data.mediums.filter((med) =>
    med.name.toLowerCase().includes(queryLowered)
  )
  /* eslint-enable  */

  return [
    200,
    {
      mediums: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

// GET department
mock.onGet("/api/meds/med").reply((config) => {
  const { id } = config
  const medium = data.mediums.find((i) => i.id === id)
  return [200, { medium }]
})
//Add department
mock.onPost("/api/meds/add-med").reply((config) => {
  // Get event from post data
  const medium = JSON.parse(config.data)

  const { length } = data.mediums
  let lastIndex = 0
  if (length) {
    lastIndex = data.mediums[length - 1].id
  }
  medium.id = lastIndex + 1

  data.mediums.unshift(medium)

  return [201, { medium }]
})

// DELETE: Deletes Bank
mock.onDelete("/api/med/delete").reply((config) => {
  // Get bank id from URL
  let mediumId = config.id

  // Convert Id to number
  mediumId = Number(mediumId)

  const mediumIndex = data.mediums.findIndex((t) => t.id === mediumId)
  data.mediums.splice(mediumIndex, 1)

  return [200]
})
