// ** Initial State
const initialState = {
  studentData: [],
  vendorData: [],
  designationData: [],
  sspinner: false,
  vspinner: false,
  tokenExp: false,
  loader: false,
  ipAddress: "",
  uSpinner: true,

  ifcData: '',
  ifcSpinner: true,

  sendWhatsAppData: {},
  sendWhatsAppLoader: false,
  sentStatus: null,
  sentId: 0,
};

const CommonReducers = (state = initialState, action) => {
  switch (action.type) {
    case "GET_COMMON_IP_DATA":
      return {
        ...state,
        ipAddress: action.data,
      };
    case "GET_COMMON_STUDENT_DATA":
      return {
        ...state,
        studentData: action.data,
        sspinner: action.sspinner,
      };
    case "COMMON_LOADING_SPINNER":
      return {
        ...state,
        sspinner: action.sspinner,
        tokenExp: action.tokenExp,
      };
    case "GET_COMMON_VENDOR_DATA":
      return {
        ...state,
        vendorData: action.data,
        vspinner: action.vspinner,
      };
    case "VEND_COMMON_LOADING_SPINNER":
      return {
        ...state,
        vspinner: action.vspinner,
        tokenExp: action.tokenExp,
      };
    case "GET_DESIGNATIONS_DATA":
      return {
        ...state,
        designationData: action.data,
        loader: action.loader,
      };
    case "DESEGNATION_LOADING_SPINNER":
      return {
        ...state,
        tokenExp: action.tokenExp,
        loader: action.loader,
      };
    case "SEND_WHATSAPP":
      return {
        ...state,
        sendWhatsAppData: action.data,
        sentStatus: action.status,
        sentId: action.id,
        sendWhatsAppLoader: action.spinner,
      };
    case "SEND_WHATSAPP_LOADING_SPINNER":
      return {
        ...state,
        tokenExp: action.tokenExp,
        sendWhatsAppLoader: action.spinner,
      };
    case "RESET_SET_STATUS":
      return {
        ...state,
        sentStatus: action.status,
      };
    case "UPDATE_STAGE_REF":
      return {
        ...state,
        studentData: action.allData,
        uSpinner: action.spinner,
      };
    case "UPDATE_ROUTE_LOADING_SPINNER":
      return {
        ...state,
        uSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "INC_FEE_CATG_UPDATE":
      return {
        ...state,
        ifcData: action.allData,
        ifcSpinner: action.spinner,
      };
    case "INC_FEE_CATG_UPDATE_LOADING_SPINNER":
      return {
        ...state,
        ifcSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default CommonReducers;
