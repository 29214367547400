import { DefaultConfig } from "../../../../../settings/config";
const DateFormatMonth = DefaultConfig().DateFormatMonth

// ** Initial State
const initialState = {
  allData: [],

  vechicleRemindData: [],
  stallData: [],
  stdata: [],
  sttotal: 1,
  stparams: {},
  vrdSpinner: false,
  tokenExp: false,

  vechicleHistoryData: [],
  expiryCount: 0,
  shallData: [],
  shdata: [],
  shtotal: 1,
  shparams: {},
  vhSpinner: false,

  vchGpsData: [],
  gpsSpinner: false,

  vchGpsHistory: [],
  hsitorySpinner: false,

  selectedReprint: null,
  actionTitle: "",
  spinner: false,

  vmallData: [],
  vmdata: [],
  vmtotal: 1,
  vmparams: {},
  vmSpinner: false,

  vpallData: [],
  vpdata: [],
  vptotal: 1,
  vpparams: {},
  vpSpinner: false,

  vehBranchData: [],
  vbSpinner: false,

  vcSpinner: false,
  vehCountData: 0,

  expSpinner: false,
  expCountData: 0,

  bcallData: [],
  bcdata: [],
  bctotal: 1,
  bcparams: {},
  vbhSpinner: false,

  pbcallData: [],
  pbcdata: [],
  pbctotal: 1,
  pbcparams: {},
  pbcSpinner: false,

  insCompListSpinner: false,
  insCompCount: 0,
  insCompList: [],

  iclAllData: [],
  iclData: [],
  iclTotal: 1,
  iclParams: {},
  iclSpinner: false,

  trptExpSpinner: false,
  trptExpAmount: 0,

  bwteAllData: [],
  bwteData: [],
  bwteTotal: 1,
  bwteParams: {},
  bwteSpinner: false,

  vphAllData: [],
  vphData: [],
  vphTotal: 1,
  vphParams: {},
  vphSpinner: false
}

const initialItem = (resData) => {
  const branchOptions = DefaultConfig().Branches;
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      resp.companyName = branchOptions.find(find => find.value === resp.compId) ? branchOptions.find(find => find.value === resp.compId).branchName : ''
      resp.renewalDate = resp.renewalDate ? DateFormatMonth(resp.renewalDate) : ""
      resp.validityDate = resp.validityDate ? DateFormatMonth(resp.validityDate) : ""

      var fromDt = new Date();
      var toDt = new Date(resp.validityDate);
      var time_difference = toDt.getTime() - fromDt.getTime();
      const day_diff = Math.round(Math.abs(time_difference / (1000 * 60 * 60 * 24)));
      resp["dayDiff"] = day_diff + 1
    });
    return resData;
  } else {
    return [];
  }
};

const initialHisItem = (resData) => {
  let branchOptions = branchOptions = DefaultConfig().Branches;
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      resp.companyName = branchOptions.find(find => find.value === resp.compId) ? branchOptions.find(find => find.value === resp.compId).branchName : ''
    });
    return resData;
  } else {
    return [];
  }
};

const initialHisItems = (resData, insCompMap) => {
  let branchOptions = branchOptions = DefaultConfig().Branches;
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      if (DefaultConfig().companyType === 'insurance') {
      resp.companyName = insCompMap.find(find => find.compId === resp.compId) ? insCompMap.find(find => find.compId === resp.compId).branchName : ''
      } else {
        resp.companyName = branchOptions.find(find => find.value === resp.compId) ? branchOptions.find(find => find.value === resp.compId).branchName : ''
      }
    });
    return resData;
  } else {
    return [];
  }
};

const initialMilItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      resp.readingDate = resp.readingDate ? DateFormatMonth(resp.readingDate) : ""
    });
    return resData;
  } else {
    return [];
  }
};

const initialPaperHistoryItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.Sdate = (resp.date ? DateFormatMonth(resp.date) : '')
      resp.dateValues = (resp.dateVal ? DateFormatMonth(resp.dateVal) : '')

      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const getData = (resData) => {
  const branchOptions = DefaultConfig().Branches;
  if (resData) {
    const branchData = []
    resData.forEach((resp) => {
      const vehData = []
      if (resp.branchWiseResponse) {
        resp.branchWiseResponse.forEach((resps) => {
          vehData.push({ value: resps.deviceId, label: resps.regNo })
        });
      }
      const name = branchOptions.find(find => find.value === resp.compId) ? branchOptions.find(find => find.value === resp.compId).branchName : ''
      branchData.push({ value: resp.compId, label: name, vehData: vehData })
    });
    return branchData;
  } else {
    return [];
  }
}

const VehicleReminders = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_VEHIREMIND_DATA":
      return {
        ...state,
        stallData: initialItem(action.allData),
        stdata: action.data,
        sttotal: action.totalPages,
        stparams: action.params,
        vrdSpinner: action.spinner
      }
    case "GET_VEH_REM_DATA_FILTER":
      return {
        ...state,
        stdata: action.data,
        sttotal: action.totalPages,
        stparams: action.params,
      };
    case "VEHIREMIND_LOADING_SPINNER":
      return {
        ...state,
        vrdSpinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "GET_ALL_VEHIHIS_DATA":
      return {
        ...state,
        expiryCount: action.expiryCount,
        shallData: initialHisItem(action.allData),
        shdata: action.data,
        shtotal: action.totalPages,
        shparams: action.params,
        vhSpinner: action.spinner
      }
    case "GET_VEH_HIS_DATA_FILTER":
      return {
        ...state,
        shdata: action.data,
        shtotal: action.totalPages,
        shparams: action.params,
      };
    case "VEHIHIS_LOADING_SPINNER":
      return {
        ...state,
        vhSpinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "VEHBC_LOADING_SPINNER":
      return {
        ...state,
        vbcSpinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "GET_ALL_VEHBC_DATA":
      return {
        ...state,
        bcallData: initialHisItems(action.allData, action.insCompMap),
        bcdata: action.data,
        bctotal: action.totalPages,
        bcparams: action.params,
        vbcSpinner: action.spinner
      }
    case "GET_VEH_BRC_DATA_FILTER":
      return {
        ...state,
        bcdata: action.data,
        bctotal: action.totalPages,
        bcparams: action.params,
      };
    case "VEHBCP_LOADING_SPINNER":
      return {
        ...state,
        pbcSpinner: action.spinner,
        tokenExp: action.tokenExp
      }

      case "VEH_PAPER_HISTORY_LOADING_SPINNER":
      return {
        ...state,
        vphSpinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "VEH_PAPER_HISTORY_DATA":
      return {
        ...state,
        vphAllData: initialPaperHistoryItem(action.allData),
        vphData: action.data,
        vphTotal: action.totalPages,
        vphParams: action.params,
        vphSpinner: action.spinner
      }
      case "VEH_PAPER_HISTORY_FILTER_DATA":
      return {
        ...state,
        vphData: action.data,
        vphTotal: action.totalPages,
        vphParams: action.params,
      }
      case "BWTE_DATA":
        return {
          ...state,
          bwteAllData: initialHisItem(action.allData),
          bwteData: action.data,
          bwteTotal: action.totalPages,
          bwteParams: action.params,
          bwteSpinner: action.spinner
        }
      case "GET_VEH_BRC_DATA_FILTER":
        return {
          ...state,
          bwteData: action.data,
          bwteTotal: action.totalPages,
          bwteParams: action.params,
        };
      case "BWTE_DATA_LOADING_SPINNER":
        return {
          ...state,
          bwteSpinner: action.spinner,
          tokenExp: action.tokenExp
        }


    case "GET_ALL_VEHBCP_DATA":
      return {
        ...state,
        pbcallData: initialHisItem(action.allData),
        pbcdata: action.data,
        pbctotal: action.totalPages,
        pbcparams: action.params,
        pbcSpinner: action.spinner
      }
    case "GET_VEH_BRCP_DATA_FILTER":
      return {
        ...state,
        pbcdata: action.data,
        pbctotal: action.totalPages,
        pbcparams: action.params,
      };
    case "GET_ALL_GPS_DATA":
      return {
        ...state,
        vchGpsData: action.gpsData,
        gpsSpinner: action.spinner
      }
    case "GPS_LOADING_SPINNER":
      return {
        ...state,
        gpsspinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "GET_VEHICLE_HISTORY":
      return {
        ...state,
        vchGpsHistory: action.gpsHistoryData,
        hsitorySpinner: action.spinner
      }
    case "VEHICLE_HISTORY_LOADING_SPINNER":
      return {
        ...state,
        hsitorySpinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "GET_ALL_VEHIMIL_DATA":
      return {
        ...state,
        vmallData: initialMilItem(action.allData),
        vmdata: action.data,
        vmtotal: action.totalPages,
        vmparams: action.params,
        vmSpinner: action.spinner
      }
    case "GET_VEH_MIL_DATA_FILTER":
      return {
        ...state,
        vmdata: action.data,
        vmtotal: action.totalPages,
        vmparams: action.params,
      };
    case "VEHIMIL_LOADING_SPINNER":
      return {
        ...state,
        vmSpinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "GET_ALL_VEHIPAP_DATA":
      return {
        ...state,
        vpallData: initialItem(action.allData),
        vpdata: action.data,
        vptotal: action.totalPages,
        vpparams: action.params,
        vpSpinner: action.spinner
      }
    case "GET_VEH_PAP_DATA_FILTER":
      return {
        ...state,
        vpdata: action.data,
        vptotal: action.totalPages,
        vpparams: action.params,
      };
    case "VEHIPAP_LOADING_SPINNER":
      return {
        ...state,
        vpSpinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "VEHBR_LOADING_SPINNER":
      return {
        ...state,
        vbSpinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "GET_ALL_VEHBR_DATA":
      return {
        ...state,
        vbSpinner: action.spinner,
        vehBranchData: getData(action.allData)
      }
    case "GET_INS_COMP_LIST_LOADING_SPINNER":
      return {
        ...state,
        insCompListSpinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "GET_INS_COMP_LIST":
      return {
        ...state,
        insCompListSpinner: action.spinner,
        insCompCount: action.count,
        insCompList: action.allData,
      }
    case "VEHC_LOADING_SPINNER":
      return {
        ...state,
        vcSpinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "GET_ALL_VEHC_DATA":
      return {
        ...state,
        vcSpinner: action.spinner,
        vehCountData: action.allData
      }
    case "EXP_LOADING_SPINNER":
      return {
        ...state,
        expSpinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "GET_ALL_EXP_DATA":
      return {
        ...state,
        expSpinner: action.spinner,
        expCountData: action.allData
      }
    case "ICL_DATA":
      return {
        ...state,
        iclAllData: initialItem(action.allData),
        iclData: action.data,
        iclTotal: action.totalPages,
        iclParams: action.params,
        iclSpinner: action.spinner
      }
    case "ICL_DATA_FILTER":
      return {
        ...state,
        iclData: action.data,
        iclTotal: action.totalPages,
        iclParams: action.params,
      };
    case "GET_TRPT_EXP_AMOUNT_LOADING_SPINNER":
      return {
        ...state,
        trptExpSpinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "GET_TRPT_EXP_AMOUNT":
      return {
        ...state,
        trptExpSpinner: action.spinner,
        trptExpAmount: action.trptExpAmount
      }
    default:
      return { ...state }
  }
}
export default VehicleReminders
