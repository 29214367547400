// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false
}

// const initialItem = resData => {
//   if (resData) {
//     console.log(resData, "ResData")
//     resData.forEach((resp, index) => {
//       resp.serial = index + 1
//     })
//   }
// }

const initialItem = (resData) => {
  if (resData) {
    console.log(resData, "ResData")
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const StuExcelUpload = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_STUEXCEL_DATA":
      console.log(action, "ALL DATA")
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner
      }
    case "GET_STUEXCEL_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case "GET_STUEXCEL":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle
      }
   case "STUEXCEL_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp
      }
      case "GET_EXAM_ANSWR_DATA":
        console.log(action);
        return {
          ...state,
          allData: initialItem(action.allData),
          resData: action.allData,
          pspinner: action.spinner,
          total: action.totalPages,
          params: action.params,
        };
        case "EXAM_ANSWR_LOADING_SPINNER":
          return {
            ...state,
            pspinner: action.spinner,
            tokenExp: action.tokenExp,
          };

    default:
      return { ...state }
  }
}
export default StuExcelUpload
