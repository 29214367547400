import { DefaultConfig } from "../../../../../../settings/config";
const Classes = DefaultConfig().Classes;
const Sections = DefaultConfig().Sections;
const AcademicYear = DefaultConfig().AcademicYear;
// ** Initial State
const initialState = {
  allData: [],
  resData: [],
  data: [],
  columnData: [],
  smsData: [],
  subjects: [],
  total: 1,
  params: {},
  spinner: false,
  sspinner: false,
  tokenExp: false,

  btrAllData: [],
  btrSubjList: [],
  btrExamList: [],
  btrData: [],
  btrTotal: 1,
  btrSpinner: false,
};

const initialItem = (resData, subjects, incAssMarks) => {
  if (resData) {
    resData.sort((a, b) => b.total - a.total);
    resData.forEach((resp, index) => {
      resp["acadYearLable"] = AcademicYear.find(
        (item) => item.value === resp.acadYear
      )
        ? AcademicYear.find((item) => item.value === resp.acadYear).label
        : "";
      resp["clsName"] = Classes && Classes.find((item) => item.value === resp.cls)
        && Classes.find((item) => item.value === resp.cls).acr;
      resp["secName"] = Sections && Sections.find((item) => item.value === resp.sec)
        && Sections.find((item) => item.value === resp.sec).acr;
      resp.serial = index + 1;
      const totalMarks = resp.subWiseList.reduce((acc, subject) => {
        const findSubj = subjects.find(find => find.subRef === subject.subRef)
        let examMarks = 0;
        if (findSubj && findSubj.totInc === 'yes' && incAssMarks) {
          examMarks = Number(subject.examMarks) + Number(subject.assnMarks);
        } else {
          examMarks = Number(subject.examMarks);
        }
        return acc + (isNaN(examMarks) ? 0 : examMarks);
      }, 0);
      // const totalMax = resp.subWiseList.reduce(
      //   (acc, subject) => acc + subject.maxMarks,
      //   0
      // );
      const totalMax = resp.subWiseList.reduce((acc, subject) => {
        const findSubj = subjects.find(find => find.subRef === subject.subRef)
        let maxMarks = 0;
        if (findSubj && findSubj.totInc === 'yes') {
          maxMarks = Number(subject.maxMarks);
        }
        return acc + (isNaN(maxMarks) ? 0 : maxMarks);
      }, 0);
      resp.total = totalMarks;
      resp.percent = totalMarks ? ((totalMarks / totalMax) * 100).toFixed(2) : 0;
    });
    return resData;
  } else {
    return [];
  }
};

const getColumns = (data, subjects) => {

  var columns = [
    {
      name: "S.No",
      minWidth: "0px",
      selector: "serial",
      sortable: true,
      showhide: true,
      cell: (row) => row.serial,
    },
    {
      name: "Name",
      minWidth: "0px",
      selector: "name",
      sortable: true,
      showhide: true,
      cell: (row) => row.name,
    },
    {
      name: "Class",
      minWidth: "0px",
      selector: "clsName",
      sortable: true,
      showhide: true,
      cell: (row) => row.clsName,
    },
    {
      name: "Section",
      minWidth: "0px",
      selector: "secName",
      sortable: true,
      showhide: true,
      cell: (row) => row.secName,
    },
  ];
  const totalMax = subjects.filter(find => find.totInc === 'yes').reduce((acc, element) => acc + element.max, 0);
  if (Array.isArray(subjects)) {
    subjects.forEach((element) => {
      let item = `${element.subRef === -1 ? "Elective-1" : element.subRef === -2 ? "Elective-2" : element.subRef === -3 ? "Elective-3" : element.name} (${element.max})`;
      let itemAcr = `${element.subRef === -1 ? "El-1" : element.subRef === -2 ? "El-2" : element.subRef === -3 ? "El-3" : element.acr}`;
      columns.push({
        name: item,
        acr: itemAcr,
        totInc:element.totInc,
        minWidth: "0px",
        selector: "subRef",
        sortable: true,
        showhide: true,
        cell: (row) => row[element],
      });
    });
  }

  columns.push({
    name: `Total (${totalMax})`,
    minWidth: "0px",
    selector: "total",
    sortable: true,
    showhide: true,
    cell: (row) => row.total,
  });

  columns.push({
    name: `Percent`,
    minWidth: "0px",
    selector: "percent",
    sortable: true,
    showhide: true,
    cell: (row) => row.percent,
  });

  columns.push({
    name: `Grade`,
    minWidth: "0px",
    selector: "grade",
    sortable: true,
    showhide: true,
    cell: (row) => row.grade,
  });

  columns.push({
    name: `Rank`,
    minWidth: "0px",
    selector: "rank",
    sortable: true,
    showhide: true,
    cell: (row) => row.rank,
  });

  return columns;
};

const ResultViewByCls = (state = initialState, action) => {
  switch (action.type) {
    case "GET_RESVIEW_DATA":
      return {
        ...state,
        allData: initialItem(action.allData, action.subjects, action.incAssMarks),
        subjects: action.subjects,
        columnData: getColumns(action.allData, action.subjects),
        data: initialItem(action.data, action.subjects, action.incAssMarks),
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };

    case "GET_RVC_GET_DATA":
      return {
        ...state,
        columnData: getColumns(action.data, action.subjects),
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "RVCLASS_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };

      case "RESULT_BY_TERM_ALL_DATA":
      return {
        ...state,
        btrAllData: action.allData,
        btrSubjList: action.subjList,
        btrExamList: action.examList,
        btrData: action.data,
        btrTotal: action.totalPages,
        params: action.params,
        btrSpinner: action.spinner,
      };

    case "RESULT_BY_TERM_ALL_DATA":
      return {
        ...state,
        btrData: action.data,
        btrTotal: action.totalPages,
        params: action.params,
      };
    case "RESULT_BY_TERM_ALL_DATA_LOADING_SPINNER":
      return {
        ...state,
        btrSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default ResultViewByCls;
