import { Fragment } from "react";
import BreadCrumbs from "../../../../breadcrum";

// ** Styles
import "@styles/react/apps/app-users.scss";
import MarketsrcTable from "./Table";


const MarketingSources = () => {
  return (
    <Fragment>
      <BreadCrumbs
        breadCrumbParent="CRM"
        breadCrumbChild="Followups"
        breadCrumbTitle="Marketing Sources"
      />
      <div>
        <MarketsrcTable />
      </div>
    </Fragment>
  );
};

export default MarketingSources;