// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  actionTitle: "",
  spinner: false,
  tokenExp: false,

  dallData: [],
  ddata: [],
  dtotal: 1,
  dparams: {},
  dspinner: false,

  sallData: [],
  sdata: [],
  stotal: 1,
  sparams: {},
  sspinner: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const feeCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_FEECALLS_COUNT_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: initialItem(action.data),
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "FEECALL_COUNTS_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_FEECALLCOUNT_GETDATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_FEECALLS_COUNT_DATA_DTLS":
      return {
        ...state,
        dallData: initialItem(action.allData),
        ddata: initialItem(action.data),
        dtotal: action.totalPages,
        dparams: action.params,
        dspinner: action.spinner,
      };
    case "FEECALL_COUNTS_DTLS_LOADING_SPINNER":
      return {
        ...state,
        dspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_FEECALLCOUNT_GETDATA_DTLS":
      return {
        ...state,
        ddata: action.data,
        dtotal: action.totalPages,
        dparams: action.params,
      };
    case "GET_FEECALLS_COUNT_DATA_DTLSSTU":
      return {
        ...state,
        sallData: initialItem(action.allData),
        sdata: initialItem(action.data),
        stotal: action.totalPages,
        sparams: action.params,
        sspinner: action.spinner,
      };
    case "FEECALL_COUNTS_STUDTLS_LOADING_SPINNER":
      return {
        ...state,
        sspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_FEECALLCOUNT_GETDATA_DTLSSTU":
      return {
        ...state,
        sdata: action.data,
        stotal: action.totalPages,
        sparams: action.params,
      };
    case "ADD_DATE_REMIND":
      return {
        ...state,
        sallData: initialItem(action.allData),
        mspinner: action.spinner
      }
    case "REM_DATE_LOADING_SPINNER":
      return {
        ...state,
        mspinner: action.spinner,
        tokenExp: action.tokenExp
      }
    default:
      return { ...state };
  }
};
export default feeCountReducer;