import { DefaultConfig } from "../../../../../../settings/config";
// ** Initial State
const initialState = {
  allData: [],
  allinceData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  uspinner: false,
  tokenExp: false,
};

const initialItem = (resData) => {
  if (resData) {
    const AcademicYear = DefaultConfig().AcademicYear;
    resData.forEach((resp, index) => {
      resp["joinYear"] = AcademicYear.find(
        (item) => item.value === resp.joinYear
      )
        ? AcademicYear.find((item) => item.value === resp.joinYear).label
        : "";
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const CustomerView = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_COM_LIST_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_COMP_LIST":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "ADD_COMP":
      return {
        ...state,
        allData: action.resData,
        cspinner: action.spinner,
      };
    case "UPDATE_COMP_LIST":
      return {
        ...state,
        allData: action.updCompData,
        uspinner: action.spinner,
      };
    case "GET_COMPANY_UPDATE":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };

    case "COM_LIST_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default CustomerView;
