import { DefaultConfig } from "../../../../../settings/config";
const DateFormatMonth = DefaultConfig().DateFormatMonth;
const workInterval = DefaultConfig().WorkInterval;
const dayList = DefaultConfig().Day;
// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
};

const initialItem = (resData) => {
  if (resData) {
    const Branches = DefaultConfig().Branches;
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    
      const branchName = DefaultConfig().SubBranchList.find(
        (item) => item.compId === resp.branchId
      )
        ? DefaultConfig().SubBranchList.find(
            (item) => item.compId === resp.branchId
          ).branchName
        : "";
        resp["branchName"] = `${branchName}`;
        if (workInterval.find(find => find.value === resp.workInterval)) {
          resp.workIntervalname = workInterval.find(find => find.value === resp.workInterval).label
        } else {
          resp.workIntervalname = ''
        }
        // if (dayList.find(find => find.value === resp.dayList)) {
        //   resp.dayListname = dayList.find(find => find.value === resp.dayList).label
        // } else {
        //   resp.dayListname = ''
        // }
      
     });
    return resData;
  } else {
    return [];
  }
};

const WorkUpdate = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_WORK_UPDATE_DATA":
      //console.log(action.allData, "all")
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_WORK_UPDATE_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_WORK_UPDATE":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };
    case "ADD_WORK_UPDATE":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "UPDATE_WORK_UPDATE":
      //console.log(action.allData, "update")
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "DELETE_WORK_UPDATE":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "WORK_UPDATE_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default WorkUpdate;
