import { DefaultConfig } from "../../../../../../settings/config";
import FeeHeads from "../../../feeHeads/list";
const Classes = DefaultConfig().Classes;
import { getFeeHeads } from "../../../feeHeads/store/action";

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  feeHeadData: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
  recload: false,
};

const initialItem = (resData) => {
  if (resData) {
    const AcademicYear = DefaultConfig().AcademicYear;
    const Department = DefaultConfig().Departments;
    // console.log(AcademicYear, "yeee")
    //console.log(resData, "inin")
    resData.forEach((resp, index) => {
      //console.log(resData);
      resp["acadYear"] = AcademicYear.find(
        (item) => item.value === resp.recSeriesId.acadYear
      )
        ? AcademicYear.find((item) => item.value === resp.recSeriesId.acadYear)
            .label
        : "";
      resp["cls"] = Classes.find((item) => item.value === resp.recSeriesId.cls)
        ? Classes.find((item) => item.value === resp.recSeriesId.cls).label
        : "";
      resp["dept"] = resp.recSeriesId.dept === 0 ? (DefaultConfig().instType === 'college' ? "All Departments" : 'Schools upto X') : Department.find((item) => item.value === resp.recSeriesId.dept)
                    ? Department.find((item) => item.value === resp.recSeriesId.dept).label
                    : "";

      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const ReceiptSeries = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_RECEIPT_DATA":
      //console.log(action, "red")
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,

        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_RECEIPT_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_RECEIPT":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };
    case "FEE_HEAD_DATA":
      //console.log(action, "feee")
      return {
        ...state,
        feeHeadData: action.data,
        spinner: action.spinner,
        recload: action.recloader,
      };
    case "ADD_RECEIPT":
      return {
        ...state,
        allData: initialItem(action.allData, state),
        spinner: action.spinner,
      };

    case "RECEIPT_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default ReceiptSeries;
