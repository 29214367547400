import { DefaultConfig } from "../../../../../../settings/config";
// ** Initial State
const initialState = {
    allData: [],
    totData: [],
    data: [],
    total: 1,
    params: {},
    actionTitle: "",
    spinner: false,
    tokenExp: false,
    updImgData: '',
    updImgSpinner: false,
    spinnerShow: false,
};

const initialItem = (resData) => {
    if (resData) {
        resData.forEach((resp, index) => {
            resp.serial = index + 1;
        });
        return resData;
    } else {
        return [];
    }
};

const StudentImageUpload = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ALL_PRINT_SHEET_DATA":
            return {
                ...state,
                totalPrintData: action.allData,
                tpdSpinner: action.spinner
            };
        case "PRINT_SHEET_LOADING_SPINNER":
            return {
                ...state,
                tpdSpinner: action.spinner,
                tokenExp: action.tokenExp,
            };
        case "UPDATE_STU_IMG":
            return {
                ...state,
                updImgData: action.allData,
                updImgSpinner: action.spinner,
                spinnerShow: action.spinnerShow
            };
        case "UPDATE_STU_IMG_LOADING_SPINNER":
            return {
                ...state,
                updImgSpinner: action.spinner,
                spinnerShow: action.spinnerShow,
                tokenExp: action.tokenExp,
            };
        default:
            return { ...state };
    }
};
export default StudentImageUpload;
