

// ** Initial State
const initialState = {
  routeData: [],
  rspinner: false,
  tokenExp: false,

  allData: [],
  data: [],
  totalsObj: {},
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false
}

const initialItem = resData => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1
    })
    return resData
  } else {
    return []
  }
}

const FeeByRoute = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ROUTE_DATA":
      return {
        ...state,
        routeData: action.data,
        rspinner: action.spinner
      }
    case "ROUTE_LOADING_SPINNER":
      return {
        ...state,
        rspinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "GET_ALL_FBR_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        totalsObj: action.totalsObj,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
        actionTitle: action.actionTitle
      }
    case "GET_FBR_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case "GET_FBR":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle
      }
    case "ADD_FBR":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner
      }
    case "UPDATE_FBR":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner
      }
    case "FBR_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp
      }
    default:
      return { ...state }
  }
}
export default FeeByRoute
