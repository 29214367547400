// ** Initial State
const initialState = {
    allData: [],
    admnData: [],
    data: [],
    total: 1,
    params: {},
    selectedReprint: null,
    actionTitle: "",
    spinner: false,
    tokenExp: false,
    otpdata: {},
    otpspinner: false,
    deldata: {},
    delspinner: false
  }
  
  // const initialItem = resData => {
  //   if (resData) {
  //     console.log(resData, "ResData")
  //     resData.forEach((resp, index) => {
  //       resp.serial = index + 1
  //     })
  //   }
  // }

  const initialItem = (resData) => {
    if (resData) {
      //console.log(resData, "ResData")
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
      });
      return resData;
    } else {
      return [];
    }
  };
  
  const ReprintReceipt = (state = initialState, action) => {
    switch (action.type) {
      case "GET_ALL_REPRINT_DATA":
        return {
          ...state,
          allData: (action.allData),
         // data: action.data,
          //total: action.totalPages,
         // params: action.params,
          spinner: action.spinner
        }
        case "GET_ALL_READMPRINT_DATA":
        return {
          ...state,
          admnData: (action.admnData),
          spinner: action.spinner
        }
      case "GET_REPRINT_DATA":
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      case "GET_REPRINT":
        return {
          ...state,
          selectedReprint: action.selectedReprint,
          actionTitle: action.actionTitle
        }
      case "ADD_REPRINT":
        return {
          ...state,
          allData: initialItem(action.allData),
          spinner: action.spinner
        }
        
        case "GET_OTP_DATA":
          return { ...state,
            otpdata: action.data,
            otpspinner: action.spinner,
          }
          case "GET_OTP_LOADING_SPINNER":
          return {
            ...state,
            otpspinner: action.spinner,
          }
      case "REPRINT_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp
        }

        case "DEL_OTP_DATA":
          return { ...state,
            deldata: action.data,
            delspinner: action.spinner,
          }
          case "DEL_OTP_LOADING_SPINNER":
          return {
            ...state,
            delspinner: action.spinner,
          }
          case "EXP_ENTY_REMOVE":
            return {
              ...state,
              expData: null
            }
            case "REPRINT_ADMN_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp
        }
      default:
        return { ...state }
    }
    
  }
  export default ReprintReceipt
  