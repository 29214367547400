import mock from "../mock"
import { paginateArray } from "../utils"

const data = {
  classes: [
    {
      id: 1,
      class: "inter",
      acronym: "it",
      displayOrder: "1",
      previousClass: "10th Class",
      status: "approved"
    },
    {
      id: 2,
      class: "NURSERY",
      acronym: "Nursery",
      displayOrder: "1",
      previousClass: "10th Class",
      status: "approved"
    }
  ]
}

// GET ALL DATA
mock.onGet("/api/classes/all-data").reply(200, data.classes)

//GET UPDATE DATA
mock.onGet("/api/classes/data").reply((config) => {
  const { q = "", perPage = 10, page = 1 } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const filteredData = data.classes.filter(
    (deg) =>
      deg.class.toLowerCase().includes(queryLowered) ||
      deg.acronym.toLowerCase().includes(queryLowered)
  )
  /* eslint-enable  */

  return [
    200,
    {
      classes: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

// GET Classes
mock.onGet("/api/classes/class").reply((config) => {
  const { id } = config
  const cls = data.classes.find((i) => i.id === id)
  return [200, { cls }]
})
//Add Classes
mock.onPost("/api/classes/add-class").reply((config) => {
  // Get event from post data
  const cls = JSON.parse(config.data)

  const { length } = data.classes
  let lastIndex = 0
  if (length) {
    lastIndex = data.classes[length - 1].id
  }
  cls.id = lastIndex + 1

  data.classes.unshift(cls)

  return [201, { cls }]
})

// DELETE: Delete class
mock.onDelete("/api/classes/delete").reply((config) => {
  // Get bank id from URL
  let clsId = config.id

  // Convert Id to number
  clsId = Number(clsId)

  const clsIndex = data.classes.findIndex((t) => t.id === clsId)
  data.classes.splice(clsIndex, 1)

  return [200]
})
