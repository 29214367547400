import { DefaultConfig } from "../../../../../../settings/config";

// ** Initial State
const initialState = {
    tempIdList: [],
    data: [],
    total: 1,
    params: {},
    spinner: false,
    tokenExp: false,

    generationList: [],
    generatedList: [],
    gidSpinner: false,

    reGeneratedCard: {},
    regidSpinner: false,
    updStuSno: '',
    updStuIndex: '',

    rgSpinner: false,
    rspinner: false,
    stuRef: null,
    subRef: null,

    mtpData: '',
    mtpSpinner: false,

    uacsData: '',
    uacsSpinner: false,
};

const initialItem = (resData) => {
    if (resData) {
        resData.forEach((resp, index) => {
            resp.serial = index + 1;
        });
        return resData;
    } else {
        return [];
    }
};

const IdCardGeneration = (state = initialState, action) => {
    switch (action.type) {
        case "GET_TEMPLATE_ID_LIST":
            return {
                ...state,
                tempIdList: action.allData,
                spinner: action.spinner,
            };
        case "TEMPLATE_ID_LIST_LOADING_SPINNER":
            return {
                ...state,
                spinner: action.spinner,
                tokenExp: action.tokenExp,
            };

        case "GENERATE_ID_CARDS":
            return {
                ...state,
                generationList: action.allData,
                generatedList: action.generatedList,
                gidSpinner: action.spinner,
            };
        case "GENERATE_ID_CARDS_LOADING_SPINNER":
            return {
                ...state,
                gidSpinner: action.spinner,
                tokenExp: action.tokenExp,
            };

        case "REGENERATE_ID_CARDS":
            return {
                ...state,
                generatedList: action.generatedListData,
                gidSpinner: action.spinner,
                updStuSno: action.updStuSno,
                updStuIndex: action.updStuIndex
            };
        case "REGENERATE_ID_CARDS_LOADING_SPINNER":
            return {
                ...state,
                spinner: action.spinner,
                tokenExp: action.tokenExp,
            };

        case "MOVE_TO_PRINT_TRAY":
            return {
                ...state,
                mtpData: action.mtpData,
                mtpSpinner: action.spinner,
            };
        case "MOVE_TO_PRINT_TRAY_LOADING_SPINNER":
            return {
                ...state,
                mtpSpinner: action.spinner,
                tokenExp: action.tokenExp,
            };
        case "UPDATE_ALL_CARDS_STATUS":
            return {
                ...state,
                uacsData: action.mtpData,
                uacsSpinner: action.spinner,
            };
        case "UPDATE_ALL_CARD_STATUS_LOADING_SPINNER":
            return {
                ...state,
                uacsSpinner: action.spinner,
                tokenExp: action.tokenExp,
            };
        case "EMP_SAVE_LOADING_SPINNER":
            return {
                ...state,
                rspinner: action.rspinner,
                tokenExp: action.tokenExp,
                stuRef: action.stuRef,
                subRef: action.subRef,
            };
            case "GET_EMP_ENT_SAVE_DATA":
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params,
                rspinner: action.rspinner,
            };
        default:
            return { ...state };
    }
};
export default IdCardGeneration;