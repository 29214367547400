import { DefaultConfig } from "../../../../../settings/config";

// ** Initial State
const initialState = {
  stuColData: [],
  cspinner: false,

  resultData: [],
  columnData: [],
  dynamicCols: [],
  tokenExp: false,
  loading: false,
  sspinner: true,

  allData: [],
  data: [],
  total: 1,
  params: {},

  rspinner: true,
  stuRef: null,
  subRef: null,
  ltype: "",
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      if (resp) {
        resp.serial = index + 1;
      }
    });
    return resData;
  } else {
    return [];
  }
};
const getColumns = (data, stuColData) => {
  var columns = [];
  columns.push({
    name: "S.No",
    minWidth: "0px",
    selector: "serial",
    sortable: true,
    showhide: true,
    cell: (row) => row.serial,
  });
  const totalColumns = [];
  stuColData.forEach((resp) => {
    if (resp.fieldsByCatg) {
      resp.fieldsByCatg.forEach((cat) => {
        totalColumns.push(cat);
      });
    }
  });
  data.forEach((element) => {
    const findLabel = totalColumns.find((find) => find.value === element)
      ? totalColumns.find((find) => find.value === element).label
      : "";
    columns.push({
      name: findLabel,
      minWidth: "0px",
      selector: element,
      sortable: true,
      showhide: true,
      type: totalColumns.find((find) => find.value === element)
        ? totalColumns.find((find) => find.value === element).type
        : "",
      length: totalColumns.find((find) => find.value === element)
        ? totalColumns.find((find) => find.value === element).length
        : "",
      listModel: totalColumns.find((find) => find.value === element)
        ? totalColumns.find((find) => find.value === element).listModel
        : "",
      cell: (row) => row[element],
    });
  });
  return columns;
};

const empDynamicEdit = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ECOL_DATA":
      return {
        ...state,
        stuColData: action.data,
        cspinner: action.spinner,
      };
    case "EDIT_ECOL_LOADING_SPINNER":
      return {
        ...state,
        cspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "EMP_ENT_LOADING_SPINNER":
      return {
        ...state,
        sspinner: action.spinner,
        loading: action.loading,
        tokenExp: action.tokenExp,
      };
    case "GET_EMP_ENT_DATA":
      return {
        ...state,
        columnData: getColumns(action.columns, action.stuColData),
        dynamicCols: action.columns,
        resultData: action.data,
        sspinner: action.spinner,
      };
    case "GET_EMP_ENT_ALL_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_ERES_FIL_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "EMP_SAVE_LOADING_SPINNER":
      return {
        ...state,
        rspinner: action.rspinner,
        tokenExp: action.tokenExp,
        stuRef: action.stuRef,
        subRef: action.subRef,
      };
    case "GET_EMP_ENT_SAVE_DATA":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        rspinner: action.rspinner,
      };
    case "GET_ERES_ENT_UPDATE_DATA":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_INIT":
      return {
        ...state,
        loading: action.loading,
        sspinner: true,
      };
    default:
      return { ...state };
  }
};
export default empDynamicEdit;
