import { DefaultConfig } from "../../../../../../settings/config";
const Classes = DefaultConfig().Classes;
const compId = DefaultConfig().Branches;
// ** Initial State
const initialState = {
  totalPrintData: [],
  tpdSpinner: false,

  allData: [],
  totData: [],
  data: [],
  total: 1,
  params: {},
  actionTitle: "",
  spinner: false,
  tokenExp: false,

  staffData: [],
  sdata: [],
  stotal: 1,
  sparams: {},
  sspinner: false,

  printImages: [],
  piSpinner: false,

  updateImages: "",
  uiSpinner: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const PrintSheetGeneration = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_PRINT_SHEET_DATA":
    return {
      ...state,
      totalPrintData: action.allData,
      tpdSpinner: action.spinner 
    };
    case "PRINT_SHEET_LOADING_SPINNER":
      return {
        ...state,
        tpdSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };

      case "GET_STU_WISE_PRINT_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_STU_PRINTSHEET_FILTER_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };

      case "GET_STAFF_WISE_PRINT_DATA":
      return {
        ...state,
        staffData: initialItem(action.allData),
        sdata: action.data,
        stotal: action.totalPages,
        sparams: action.params,
        sspinner: action.spinner,
      };
    case "GET_STAFF_PRINTSHEET_FILTER_DATA":
      return {
        ...state,
        sdata: action.data,
        stotal: action.totalPages,
        sparams: action.params,
      };

    case "GET_ALL_PRINT_IMAGES_DATA":
      return {
        ...state,
        printImages: action.allData,
        piSpinner: action.spinner 
      };

    case "PRINT_IMAGES_LOADING_SPINNER":
      return {
        ...state,
        piSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };

      case "UPDATE_PRINT_SHEET_DATA":
      return {
        ...state,
        updateImages: action.allData,
        uiSpinner: action.spinner 
      };

    case "UPDATE_PRINT_SHEET_LOADING_SPINNER":
      return {
        ...state,
        uiSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    default:
      return { ...state };
  }
};
export default PrintSheetGeneration;
