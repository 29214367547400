import mock from '../mock'
import { paginateArray } from '../utils'

const data = {
  exams: [
    {
      id: 1,
      examname: 'Formative Assessment',
      acronym: 'FA1',
      examtype: 'Exam Only',
      groupname: 'FA',
      attendanceformat: 'Month Wise',
      posttoparentdate: 'Sep 25, 2021',
      prestartdate: 'Sep 17, 2021',
      resultdate: 'Sep 20, 2021',
      applicableto: 'Demo Account',
      examtimetable: 'Set For 0 Sub'
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/exams/list/all-data').reply(200, data.exams)

// POST: Add new exam
mock.onPost('/apps/exams/add-exam').reply(config => {
  // Get event from post data
  const exam = JSON.parse(config.data)

  const { length } = data.banks
  let lastIndex = 0
  if (length) {
    lastIndex = data.banks[length - 1].id
  }
  exam.id = lastIndex + 1

  data.exams.unshift(exam)

  return [201, { exam }]
})

// GET Updated DATA
mock.onGet('/api/exams/list/data').reply(config => {
  const { q = '', perPage = 10, page = 1 } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const filteredData =
    data.exams.filter(exam => (exam.examname.toLowerCase().includes(queryLowered) || exam.groupname.toLowerCase().includes(queryLowered)))
  /* eslint-enable  */

  return [
    200,
    {
      exams: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

// GET Exam
mock.onGet('/api/exams/exam').reply(config => {
  const { id } = config
  const exam = data.exams.find(i => i.id === id)
  return [200, { exam }]
})

// DELETE: Deletes exam
mock.onDelete('/apps/exams/delete').reply(config => {
  // Get exam id from URL
  let examId = config.id

  // Convert Id to number
  examId = Number(examId)

  const bankIndex = data.exams.findIndex(t => t.id === examId)
  data.exams.splice(bankIndex, 1)

  return [200]
})
