// ** Initial State
const initialState = {
  admitData: [],
  data: [], 
  resData: [],
  total: 1,
  params: {},
  spinner: false,
  tokenExp: false
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });

    return resData;
  } else {
    return [];
  }
};

const StuAdmitDropOutReport = (state = initialState, action) => {
  switch (action.type) {
    case "GET_STU_ADMIT_DATA":
      return {
        ...state,
        admitData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "ADMIT_REPORT_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_ADMIT_DATA":
    return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
    };
    default:
      return { ...state };
  }
};
export default StuAdmitDropOutReport;
