// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  actionTitle: "",
  selectedData: null,
  spinner: false,
  tokenExp: false,

  pendingAllData: [],
  pendingData: [],
  pendingTotal: 1,
  pendingParams: {},
  pendingSpinner: false,
  pendingSelectedData: null,
  invItems: [],
  ispinner: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      // resp["itemRef"] = invItems.find((c) => c.value === value);
      //   resp["remDate"] = DefaultConfig().DateFormatMonth(resp.removalDate);
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const InventoryIndent = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_INVENTORY_INDENT_DATA":
      return {
        ...state,
        allData: action.allData,
        data: initialItem(action.data),
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_INV_INDENT_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_INV_INDENT":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };
    case "ADD_INVENTORY_INDENT":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };

    case "INVENTORY_INDENT_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_ALL_INVENTORY_INDENT_PENDING_DATA":
      return {
        ...state,
        pendingAllData: action.allData,
        pendingData: initialItem(action.data),
        pendingTotal: action.totalPages,
        pendingParams: action.params,
        pendingSpinner: action.spinner,
      };
    case "GET_INV_PENDING_INDENT_DATA":
      return {
        ...state,
        pendingData: action.data,
        pendingTotal: action.totalPages,
        pendingParams: action.params,
      };
    case "GET_INV_INDENT_PENDING":
      return {
        ...state,
        pendingSelectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };
    case "UPDATE_INVENTORY_INDENT_PENDING":
      return {
        ...state,
        pendingAllData: action.allData,
        pendingSpinner: action.spinner,
      };
    case "INVENTORY_INDENT_PENDING_LOADING_SPINNER":
      return {
        ...state,
        pendingSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_ALL_INVENTORY_ITEM_DATA":
      //  console.log(action, "allData")
      return {
        ...state,
        invItems: action.allData,
        inv: action.data,
        params: action.params,
        ispinner: action.spinner,
      };
    case "INVENTORY_ITEM_LOADING_SPINNER":
      //console.log(action.spinner, "ree");
      return {
        ...state,
        ispinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    default:
      return { ...state };
  }
};
export default InventoryIndent;
