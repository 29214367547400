import { DefaultConfig } from "../../../../../../settings/config";
const smsType = DefaultConfig().SmsTypeOptions;

// ** Initial State
const initialState = {
  allData: [],
  totData: [],
  smsAllocData: [],
  data: [],
  allotDtls: [],
  total: 1,
  params: {},
  acadYearList: [],
  aspinner: false,
  actionTitle: "",
  smsAmount: "",
  spinner: false,
  sspinner: false,
  ownAllot: false,
  tokenExp: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp["quantity"] = resp.allotedSms;
      if (smsType.find((find) => find.value === resp.smsType)) {
        resp.smsTypename = smsType.find(
          (find) => find.value === resp.smsType
        ).label;
      } else {
        resp.smsTypename = "";
      }
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const SmsAllocation = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_SMS_ALLOCATION_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        allotDtls: action.allotDtls,
        totData: action.totData,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_SMS_ALLOCATION_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_SMS_ALLOCATION":
      return {
        ...state,
        selectedSmsAlloc: action.selectedSmsAlloc,
        actionTitle: action.actionTitle,
      };
    case "SMS_SAVE_ALLOCATION_LOADING_SPINNER":
      return {
        ...state,
        sspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "ADD_SMS_ALLOCATION":
      return {
        ...state,
        smsAllocData: action.allData,
        smsAmount: action.finalAmount,
        sspinner: action.spinner,
        ownAllot: action.ownAllot,
      };

    case "DELETE_SMS_ALLOCATION":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "SMS_ALLOCATION_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_ACADEMIC_YEAR_LIST":
      return {
        ...state,
        acadYearList: action.data,
        aspinner: action.spinner,
      };
    case "ACADEMIC_YEAR_LOADING_SPINNER":
      return {
        ...state,
        aspinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    default:
      return { ...state };
  }
};
export default SmsAllocation;