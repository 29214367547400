// ** Initial State
const initialState = {
  noticeListData: [],
    allData: [],
    shData: [],
    data: [],
    total: 1,
    params: {},
    shspinner: false,
    tokenExp: false,
    sdrAllData: [],
    dtlData: {},
    sdrData: [],
    sdrTotal: 1,
    sdrParams: {},
    sdrSpinner: false,
    allottedCount: '',
    scheduledCount: '',
    usageCount: '',
    saAllData: [],
    saData: [],
    saTotal: 1,
    saParams: {},
    saSpinner: false,

    ssAllData: [],
    ssData: [],
    ssTotal: 1,
    ssParams: {},
    ssSpinner: false
  };
  
  const initialItem = (resData) => {
    if (resData) {
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
      });
      return resData;
    } else {
      return [];
    }
  };
  
  const SmsHistory = (state = initialState, action) => {
    switch (action.type) {
      case "GET_SMS_HISTORY_REPORT_DATA":
        return {
          ...state,
          allottedCount: action.allottedCount,
          scheduledCount: action.scheduledCount,
          usageCount: action.usageCount,
          noticeListData: initialItem(action.allData),
          shspinner: action.spinner,
        };
      case "GET_NOTICE_LIST_DATA":
        return {
          ...state,
          allData: action.allData,
          shData: action.allData,
          data: initialItem(action.data),
          total: action.totalPages,
          params: action.params,
        };
      case "GET_SMS_HISTORY_FILTER_DATA":
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params,
        };
      case "SMS_HISTORY_REPORT_LOADING_SPINNER":
        return {
          ...state,
          shspinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      case "GET_SMS_DELIVERY_REPORT_DATA":
        return {
          ...state,
          sdrAllData: initialItem(action.allData),
          dtlData: action.dtlData,
          sdrData: initialItem(action.data),
          sdrTotal: action.totalPages,
          sdrParams: action.params,
          sdrSpinner: action.spinner,
        };
      case "GET_SMS_DELIVERY_FILTER_DATA":
        return {
          ...state,
          sdrData: action.data,
          total: action.totalPages,
          params: action.params,
        };
      case "SMS_DELIVERY_REPORT_LOADING_SPINNER":
        return {
          ...state,
          sdrSpinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      case "GET_SMS_ALLOTED_REPORT_DATA":
        return {
          ...state,
          saAllData: action.allData,
          saData: initialItem(action.data),
          saTotal: action.totalPages,
          saParams: action.params,
          saSpinner: action.spinner,
        };
      case "SMS_ALLOTED_REPORT_LOADING_SPINNER":
        return {
          ...state,
          saSpinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      case "GET_SMS_SCHEDULED_REPORT_DATA":
        return {
          ...state,
          ssAllData: action.allData,
          ssData: initialItem(action.data),
          ssTotal: action.totalPages,
          ssParams: action.params,
          ssSpinner: action.spinner,
        };
      case "GET_SMS_SCHEDULED_FILTER_DATA":
        return {
          ...state,
          ssData: action.data,
          ssTotal: action.totalPages,
          ssParams: action.params,
        };
      case "SMS_SCHEDULED_REPORT_LOADING_SPINNER":
        return {
          ...state,
          ssSpinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      default:
        return { ...state };
    }
  };
  export default SmsHistory;
  