//import { DefaultConfig } from "../../../../../settings/config";

import { DefaultConfig } from "../../../../../../settings/config";

//const DateFormatMonth = DefaultConfig().DateFormatMonth;
// const warenty = DefaultConfig().warenty;

// ** Initial State
const initialState = {
  allData: [],
  invItems: [],
  inv: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
  ispinner: false,
  qspinner: false,
  quantity: "",
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp["ddate"] = DefaultConfig().DateFormatMonth(resp.date);
      resp["dueDate"] = DefaultConfig().DateFormatMonth(resp.dueDate);
      resp["remindDate"] = DefaultConfig().DateFormatMonth(resp.remindDate);
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const PurchasePendList = (state = initialState, action) => {
  switch (action.type) {
    case "PURCHASE_ITEM_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_ALL_INVENTORY_ITEM_DATA":
      //  console.log(action, "allData")
      return {
        ...state,
        invItems: action.allData,
        inv: action.data,
        params: action.params,
        ispinner: action.spinner,
      };
    case "INVENTORY_ITEM_LOADING_SPINNER":
      //console.log(action.spinner, "ree");
      return {
        ...state,
        ispinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "PURCHASE_LOADING_SPINNER":
      //console.log(action.spinner, "ree");
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_INVENTORY_ITEM":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };
    case "INV_QUAN_LOADING_SPINNER":
      return {
        ...state,
        qspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_INV_QUAN_DATA":
      return {
        ...state,
        quantity: action.data,
        qspinner: action.spinner,
      };
    case "GET_INVENTORY_ITEM_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "UPDATE_INVENTORY_ITEM":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    default:
      return { ...state };
  }
};
export default PurchasePendList;
