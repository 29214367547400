import { DefaultConfig } from "../../../../../../settings/config";
const teachType = DefaultConfig().designationType;
// ** Initial State
const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    selectedData: null,
    actionTitle: "",
    spinner: false,
    tokenExp: false
  }
  
  const initialItem = resData => {
    if (resData) {
      resData.forEach((resp, index) => {
        if (teachType.find(find => find.value === resp.teachType)) {
            resp.teachTypeName = teachType.find(find => find.value === resp.teachType).label
          } else {
            resp.teachTypeName = ''
          }
          resp.serial = index + 1;
        });
       
     
      return resData
    } else {
      return []
    }
  }
  
  const EmployeeDesignations = (state = initialState, action) => {
    switch (action.type) {
      case "GET_ALL_EMPDESIGNATION_DATA":
        return {
          ...state,
          allData: initialItem(action.allData),
          data: action.data,
          total: action.totalPages,
          params: action.params,
          spinner: action.spinner
        }
      case "GET_EMPDESIGNATION_DATA":
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      case "GET_EMPDESIGNATION":
        return {
          ...state,
          selectedData: action.selectedData,
          actionTitle: action.actionTitle
        }
      case "ADD_EMPDESIGNATION":
        return {
          ...state,
          allData: initialItem(action.allData),
          spinner: action.spinner
        }
      case "UPDATE_EMPDESIGNATION":
        return {
          ...state,
          allData: initialItem(action.allData),
          spinner: action.spinner
        }
      case "DELETE_DESIGNATION":
        return { ...state }
      case "EMPDESIGNATION_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp
        }
      default:
        return { ...state }
    }
  }
  export default EmployeeDesignations;
  