import { DefaultConfig } from "../../../../../settings/config";
// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  spinner: false,
  tokenExp: false,

  espinner: false,
  empAreaListMap: [],
  mapData: null
};

const EmpOverview = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_EMP_OVER_DATA":
      return {
        ...state,
        mapData: action.allData,
        spinner: action.spinner,
      };
    case "GET_EMP_OVER_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "EMP_OVER_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_EMP_WORKAREA_DATA":
      return {
        ...state,
        empAreaListMap: action.data,
        espinner: action.spinner,
      };
    case "EMP_WORKAREA_LOADING_SPINNER":
      return {
        ...state,
        espinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default EmpOverview;
