import mock from "../mock"
import { paginateArray } from "../utils"

const data = {
  departments: [
    {
      id: 1,
      departmentName: "Maths",
      acronym: "Tel",
      departmentCode: "11",
      displayOrder: "3",
      degreeReference: "",
      status: "approved"
    },
    {
      id: 2,
      departmentName: "English",
      acronym: "Eng",
      departmentCode: "13",
      displayOrder: "4",
      degreeReference: "",
      status: "approved"
    },
    {
      id: 3,
      departmentName: "ECE",
      acronym: "ECE",
      departmentCode: "46",
      displayOrder: "1",
      degreeReference: "B.Tech",
      status: "approved"
    }
  ]
}

// GET ALL DATA
mock.onGet("/api/dep/all-data").reply(200, data.departments)

//GET UPDATE DATA
mock.onGet("/api/dep/data").reply((config) => {
  const { q = "", perPage = 10, page = 1 } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const filteredData = data.departments.filter(
    (dep) =>
      dep.departmentName.toLowerCase().includes(queryLowered) ||
      dep.acronym.toLowerCase().includes(queryLowered)
  )
  /* eslint-enable  */

  return [
    200,
    {
      departments: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

// GET department
mock.onGet("/api/deps/dep").reply((config) => {
  const { id } = config
  const department = data.departments.find((i) => i.id === id)
  return [200, { department }]
})
//Add department
mock.onPost("/api/dep/add-dep").reply((config) => {
  // Get event from post data
  const department = JSON.parse(config.data)

  const { length } = data.departments
  let lastIndex = 0
  if (length) {
    lastIndex = data.departments[length - 1].id
  }
  department.id = lastIndex + 1

  data.departments.unshift(department)

  return [201, { department }]
})

// DELETE: Deletes Bank
mock.onDelete("/api/dep/delete").reply((config) => {
  // Get bank id from URL
  let departmentId = config.id

  // Convert Id to number
  departmentId = Number(departmentId)

  const departmentIndex = data.departments.findIndex(
    (t) => t.id === departmentId
  )
  data.departments.splice(departmentIndex, 1)

  return [200]
})
