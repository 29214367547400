import { DefaultConfig } from "../../../../../../settings/config";

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
  espinner: false,
  empworkhoursdata: [],
};

const initialItem = (resData) => {
  if (resData) {
    //console.log(resData, "resucer");
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      if (resp.stTime) {
        if (resp.stTime) {
          //console.log(resp.stTime, "reduceram");
          const H = +resp.stTime.substr(0, 2);
          const h = H % 12 || 12;
          const ampm = H < 12 || H === 24 ? "AM" : "PM";
          resp.startTime = h + resp.stTime.substr(2, 3) + ampm;
        }
      }
      if (resp.endTime) {
        const H = +resp.endTime.substr(0, 2);
        const h = H % 12 || 12;
        const ampm = H < 12 || H === 24 ? "AM" : "PM";
        resp.end = h + resp.endTime.substr(2, 3) + ampm;
      }
    });
    return resData;
  } else {
    return [];
  }
};

const EmpWorkHours = (state = initialState, action) => {
  switch (action.type) {
    case "GET_EMP_WORK_HOURS_DATA":
      // console.log(action);
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "EMP_WORK_HOURS_LOADING_SPINNER":
      // console.log(action);
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_INSTIME_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "EMP_WORK_HOURS":
      return {
        ...state,
        empworkhoursdata: initialItem(action.data),
        espinner: action.espinner,
      };
    case "GET_INSTIME":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };
    case "ADD_INSTIME":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "UPDATE_EMP_WORKHOURS":
      console.log(action, "reduser update");
      return {
        ...state,
        empworkhoursdata: initialItem(action.allData),
        allData: initialItem(action.data),
        espinner: action.espinner,
      };
    case "DELETE_INSTIME":
      return { ...state };

    default:
      return { ...state };
  }
};
export default EmpWorkHours;
