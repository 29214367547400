
// ** Initial State
const initialState = {
  subData: [],
  allData: [],
  data: [],
  delData: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false
}

const initialItem = resData => {
  if (resData) {
    let total = 0
    resData.forEach((resp, index) => {
      resp.serial = index + 1
      if (index === 0) {
        total = Number(resp.period)
        resp.pno = `1 - ${total}`
      } else {
        const prvtotal = total
        total += Number(resp.period)
        resp.pno = `${prvtotal + 1} - ${total}`
      }
    })
    return resData
  } else {
    return []
  }
}

const SubjectsTopics = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SUB_DATA":
      return {
        ...state,
        subData: action.data,
        spinner: action.spinner
      }
    case "GET_ALL_SUB_TOPIC_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner
      }
    case "GET_SUB_TOPIC_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case "GET_SUB_TOPIC":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle
      }
    case "ADD_SUB_TOPIC":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner
      }
    case "UPDATE_SUB_TOPIC":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner
      }
    case "DELETE_SUB_TOPIC":
      return { ...state,
      delData: (action.allData),
      spinner: action.spinner,
      }
    case "SUB_TOPIC_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp
      }
    default:
      return { ...state }
  }
}
export default SubjectsTopics
