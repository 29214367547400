const initialState = {
  notifiCountData: [],
  data: [],
  designationData: [],
  updatedExeNotice: [],
  trfrData: [],
  updatedInvoiceNotice: [],
  updatedTrfrNotice: [],
  concData: [],
  leaveData: [],
  updatedLeaveNotice: [],
  billData: [],
  updatedBillNotice: [],
  expData: [],
  updatedExpNotice: [],
  noticeData: [],
  updatedAlertNotice: [],
  invoiceData: [],
  tokenData: [],
  empData: [],
  vendorData: [],
  empappData: [],
  tokenExp: false,
  loader: false,
  spinner: false,
  stuFeeData: [],
  stufdata: {},
  stuData: [],
  sloader: false,
  ltData: [],
  incomeData: [],
  updatedIncomeNotice: [],
  updatedTokenNotice: [],
  iloader: false,
  updatedEmpNotice: [],
  vspinner: false,
  stuBirthdayNotices: [],
  empBirthdayNotices: [],

  birspinner: false,

  tutorialData: [],
  ttSpinner: false,

  walletData: [],
  walSpinner: false,

  smsFind: "",
  bsSpinner: false,

  smsSendData: "",
  smsSpinner: false,
};
const getAttNotices = (resData) => {
  const leaveExemptions = resData;
  //console.log(leaveTypes);

  if (leaveExemptions) {
    leaveExemptions.forEach((item) => {
      if (item.exmType === "sp") {
        item["exmType"] = "SinglePunch";
      } else if (item.exmType === "np") {
        item["exmType"] = "OnDuty";
      } else if (item.exmType === "D") {
        item["exmType"] = "OnDuty";
      } else if (item.exmType === "lc") {
        item["exmType"] = "LateCome";
      } else if (item.exmType === "eg") {
        item["exmType"] = "EarlyGo";
      } else if (item.exmType === "lceg") {
        item["exmType"] = "LateComeEarlyGo";
      } else if (item.exmType === "CL") {
        item["exmType"] = "Compensatory Leave";
      }
    });
    return leaveExemptions;
  } else {
    return [];
  }
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_NOTIFICATIONS_COUNT_DATA":
      return {
        ...state,
        notifiCountData: action.data,
      };
    case "GET_NOTIFICATIONS_DATA":
      return {
        ...state,
        data: getAttNotices(action.data),
        loader: action.loader,
      };
    case "NOTIFICATION_LOADING_SPINNER":
      return {
        ...state,
        tokenExp: action.tokenExp,
        loader: action.loader,
      };
    case "GET_DESIGNATIONS_DATA":
      return {
        ...state,
        designationData: action.data,
        loader: action.loader,
      };
    case "DESEGNATION_LOADING_SPINNER":
      return {
        ...state,
        tokenExp: action.tokenExp,
        loader: action.loader,
      };
    case "UPDATE_EXEMP_NOTIFICATIONS_DATA":
      return {
        ...state,
        updatedExeNotice: action.data,
        loader: true,
        notifiCountData: action.ncountData,
      };

    case "GET_TRFR_NOTIFICATIONS_DATA":
      return {
        ...state,
        trfrData: action.data,
        loader: true,
      };
    case "TRANSFER_NOTIFICATION_LOADING_SPINNER":
      return {
        ...state,
        tokenExp: action.tokenExp,
        loader: action.loader,
      };

    case "UPDATE_TRANSFER_NOTIFICATIONS_DATA":
      return {
        ...state,
        updatedTrfrNotice: action.data,
        loader: true,
        notifiCountData: action.ncountData,
      };

    case "GET_CONC_NOTIFICATIONS_DATA":
      return {
        ...state,
        concData: action.data,
        loader: action.loader,
        stufdata: action.stufdata,
        stuData: action.stuData,
        sloader: action.sloader,
      };
    case "CONC_NOTIFICATION_LOADING_SPINNER":
      return {
        ...state,
        tokenExp: action.tokenExp,
        loader: action.loader,
      };
    case "GET_STU_FEE_DATA":
      return {
        ...state,
        stuFeeData: action.data,
        loader: action.loader,
        stufdata: action.stufdata,
      };
    case "GET_STU_FEE_LOADING_SPINNER":
      return {
        ...state,
        tokenExp: action.tokenExp,
        loader: action.loader,
      };
    case "UPDATE_STU_FEE_DATA":
      return {
        ...state,
        loader: action.loader,
        concData: action.cdata,
        stufdata: action.stufdata,
        stuData: action.stuData,
        notifiCountData: action.ncountData,
      };
    case "SKIP_STU_FEE_DATA":
      return {
        ...state,
        concData: action.cdata,
        stufdata: action.stufdata,
        stuData: action.stuData,
        notifiCountData: action.ncountData,
        loader: action.loader,
      };
    case "LSKIP_STU_FEE_DATA":
      return {
        ...state,
        concData: action.cdata,
        stufdata: action.stufdata,
        stuData: action.stuData,
        stuFeeData: [],
        notifiCountData: action.ncountData,
        loader: action.loader,
      };
    case "GET_LEAVE_NOTIFICATIONS_DATA":
      return {
        ...state,
        leaveData: action.data,
        loader: true,
      };
    case "LEAVE_NOTIFICATION_LOADING_SPINNER":
      return {
        ...state,
        tokenExp: action.tokenExp,
        loader: action.loader,
      };
    case "UPDATE_LEAVE_NOTIFICATIONS_DATA":
      return {
        ...state,
        updatedLeaveNotice: action.data,
        loader: true,
        notifiCountData: action.ncountData,
        leaveData: action.leaveData,
      };
    case "HIDE_LEAVE_NOTIFICATIONS_DATA":
      return {
        ...state,
        leaveData: action.leaveData,
      };
    case "GET_BILL_NOTIFICATIONS_DATA":
      return {
        ...state,
        billData: action.data,
        loader: true,
      };
    case "BILL_NOTIFICATION_LOADING_SPINNER":
      return {
        ...state,
        tokenExp: action.tokenExp,
        loader: action.loader,
      };
    case "UPDATE_BILL_NOTIFICATIONS_DATA":
      return {
        ...state,
        updatedBillNotice: action.data,
        loader: true,
        notifiCountData: action.ncountData,
      };
    case "GET_EMP_NOTIFICATIONS_DATA":
      return {
        ...state,
        empappData: action.data,
        loader: true,
      };
    case "EMP_NOTIFICATION_LOADING_SPINNER":
      return {
        ...state,
        tokenExp: action.tokenExp,
        loader: action.loader,
      };
    case "UPDATE_EMP_NOTIFICATIONS_DATA":
      return {
        ...state,
        updatedEmpNotice: action.data,
        loader: true,
        notifiCountData: action.ncountData,
      };
    case "GET_EXP_NOTIFICATIONS_DATA":
      return {
        ...state,
        expData: action.data,
        loader: true,
      };
    case "EXP_NOTIFICATION_LOADING_SPINNER":
      return {
        ...state,
        tokenExp: action.tokenExp,
        loader: action.loader,
      };
    case "UPDATE_EXP_NOTIFICATIONS_DATA":
      return {
        ...state,
        updatedExpNotice: action.data,
        loader: true,
        notifiCountData: action.ncountData,
      };

    case "GET_ALERT_NOTIFICATIONS_DATA":
      return {
        ...state,
        noticeData: action.data,
        loader: true,
      };
    case "ALERT_NOTIFICATION_LOADING_SPINNER":
      return {
        ...state,
        tokenExp: action.tokenExp,
        loader: action.loader,
      };
    case "UPDATE_ALERT_NOTIFICATIONS_DATA":
      return {
        ...state,
        updatedAlertNotice: action.data,
        loader: true,
        notifiCountData: action.ncountData,
      };

    case "GET_COMMON_VENDOR_DATA":
      return {
        ...state,
        vendorData: action.data,
        vspinner: action.vspinner,
      };
    case "EMP_LOADING_SPINNER":
      return {
        ...state,
        tokenExp: action.tokenExp,
        loader: action.loader,
      };

    case "LEAVE_TYPE":
      return {
        ...state,
        ltData: action.leaveTypes,
        spinner: action.spinner,
      };
    case "LEAVE_TYPE_LOADING_SPINNER":
      return {
        ...state,
        tokenExp: action.tokenExp,
        loader: action.loader,
      };
    case "GET_INVOICE_NOTIFICATIONS_DATA":
      return {
        ...state,
        invoiceData: action.data,
        loader: true,
      };
    case "INVOICE_NOTIFICATION_LOADING_SPINNER":
      return {
        ...state,
        tokenExp: action.tokenExp,
        loader: action.loader,
      };
    case "GET_TOKEN_NOTIFICATIONS_DATA":
      return {
        ...state,
        tokenData: action.data,
        loader: true,
      };
    case "TOKEN_NOTIFICATION_LOADING_SPINNER":
      return {
        ...state,
        tokenExp: action.tokenExp,
        loader: action.loader,
      };
    case "UPDATE_INVOICE_NOTIFICATIONS_DATA":
      return {
        ...state,
        updatedInvoiceNotice: action.data,
        loader: true,
        notifiCountData: action.ncountData,
      };
    case "GET_INCOME_NOTIFICATIONS_DATA":
      return {
        ...state,
        incomeData: action.data,
        iloader: true,
      };
    case "UPDATE_INVOICE_NOTIFICATIONS_DATA":
      return {
        ...state,
        updatedIncomeNotice: action.data,
        loader: true,
        notifiCountData: action.ncountData,
      };
    case "UPDATE_TOKEN_NOTIFICATIONS_DATA":
      return {
        ...state,
        updatedTokenNotice: action.data,
        loader: true,
        notifiCountData: action.ncountData,
      };
    case "INCOME_NOTIFICATION_LOADING_SPINNER":
      return {
        ...state,
        tokenExp: action.tokenExp,
        iloader: action.loader,
      };
    case "BIR_NOTIFICATIONS_DATA":
      return {
        ...state,
        stuBirthdayNotices: action.data,
        empBirthdayNotices: action.emp,
        birspinner: true,
      };
    case "BDAY_NOTIFICATION_LOADING_SPINNER":
      return {
        ...state,
        tokenExp: action.tokenExp,
        birspinner: action.loader,
      };
    case "GET_VIDEO_TUTORIALS_DATA":
      return {
        ...state,
        tutorialData: action.data,
        ttSpinner: action.spinner,
      };
    case "GET_VIDEO_TUTORIALS_LOADING_SPINNER":
      return {
        ...state,
        tokenExp: action.tokenExp,
        ttSpinner: action.loader,
      };
    case "GET_WALLET_AMT_DATA":
      return {
        ...state,
        walletData: action.data,
        walSpinner: action.loader,
      };
    case "GET_WALLET_AMT_LOADING_SPINNER":
      return {
        ...state,
        tokenExp: action.tokenExp,
        walSpinner: action.loader,
      };
    case "GET_SMS_VERIFY_DATA":
      return {
        ...state,
        smsFind: action.data,
        bsSpinner: action.loader,
      };
    case "GET_BDAY_SMS_VERIFY_LOADING_SPINNER":
      return {
        ...state,
        tokenExp: action.tokenExp,
        bsSpinner: action.loader,
      };
    case "SEND_BDAY_SMS_DATA":
      return {
        ...state,
        smsSendData: action.data,
        smsSpinner: action.loader,
      };
    case "SEND_BDAY_SMS_LOADING_SPINNER":
      return {
        ...state,
        tokenExp: action.tokenExp,
        smsSpinner: action.loader,
      };
    case "UPDATE_EXP_BANK_DETAILS":
      return {
        ...state,
        expData: action.data,
      };
    case "UPDATE_EXP_BANK_DETAILS_LOADING_SPINNER":
      return {
        ...state,
        tokenExp: action.tokenExp,
      };
    default:
      return state;
  }
};

export default notificationsReducer;
