import { DefaultConfig } from "../../../../../../settings/config";
const Classes = DefaultConfig().Classes;
const Sections = DefaultConfig().Sections;
const Mediums = DefaultConfig().Mediums;
const subjectsArr = DefaultConfig().subjectsArr;

// ** Initial State
const initialState = {
  groupData: [],
  subListData: [],
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  dspinner: false,
  tokenExp: false,
};

// const initialItem = (resData, groupData, subListData) => {
//   if (resData) {
//     resData.forEach((resp, index) => {
//       resp.serial = index + 1
//       let loadData = {}
//       loadData = groupData.find((d) => (d.subjId.cls === resp.cls) && (d.subjId.sec === resp.sec) && (d.subjId.med === resp.med))
//       const dataRows = []
//       if (loadData) {
//         subjectsArr.forEach((sub, ind) => {
//           const objData = { id: sub.id, subjectName: "", subjectId: "", subData: "", msgLength: "" }
//           if (loadData[`sub${ind + 1}`]) {
//             if (subListData) {
//               if (subListData.find(find => find.value === loadData[`sub${ind + 1}`])) {
//                 objData.subjectName = subListData.find(find => find.value === loadData[`sub${ind + 1}`]).label
//                 objData.subjectId = subListData.find(find => find.value === loadData[`sub${ind + 1}`]).value
//                 dataRows.push(objData)
//               }
//             }
//           }
//         })
//       }
//       dataRows.forEach((sub, ind) => {
//         if (resp[`sub${ind + 1}`]) {
//           sub.subData = resp[`sub${ind + 1}`]
//           sub.msgLength = resp[`sub${ind + 1}`].length
//         }
//       })
//       resp.subjects = dataRows
//       if (Classes.find(find => find.value === resp.cls)) {
//         resp.clsName = Classes.find(find => find.value === resp.cls).label
//       } else {
//         resp.clsName = ''
//       }
//       if (Sections.find(find => find.value === resp.sec)) {
//         resp.sectionName = Sections.find(find => find.value === resp.sec).label
//       } else {
//         resp.sectionName = ''
//       }
//       if (Mediums.find(find => find.value === resp.med)) {
//         resp.medName = Mediums.find(find => find.value === resp.med).label
//       } else {
//         resp.medName = ''
//       }
//     })
//     return resData
//   } else {
//     return []
//   }
// }

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });

    return resData;
  } else {
    return [];
  }
};

const Diary = (state = initialState, action) => {
  switch (action.type) {
    // case "GET_SUB_DATA":
    //   return {
    //     ...state,
    //     groupData: action.data,
    //     spinner: action.spinner
    //   }
    // case "GET_SUB_LIST":
    //   return {
    //     ...state,
    //     subListData: action.data,
    //     spinner: action.spinner
    //   }
    case "GET_ALL_DIARY_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
        actionTitle: action.actionTitle,
      };
    case "GET_DIARY_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_DIARY":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };
    case "ADD_DIARY":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    // case "UPDATE_DIARY":
    //   return {
    //     ...state,
    //     allData: initialItem(action.allData, state.groupData, state.subListData),
    //     spinner: action.spinner
    //   }
    // case "DELETE_DIARY":
    //   return { ...state }
    case "GET_DIARY_LOADING_SPINNER":
      return {
        ...state,
        dspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "DIARY_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default Diary;
