import { DefaultConfig } from "../../../../../../settings/config"
const DateFormatMonth = DefaultConfig().DateFormatMonth
const stdType = DefaultConfig().stdType

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false
}

const initialItem = resData => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1
      resp.Sdate = (resp.date ? DateFormatMonth(resp.date) : '')
      // resp.degRef = resp.degRef ? resp.degRef : ''
      // resp.empStatus = resp.empStatus ? resp.empStatus : ''
      if (stdType.find(find => find.value === resp.type)) {
        resp.typeName = stdType.find(find => find.value === resp.type).label
      } else {
        resp.typeName = ''
      }
    })
    return resData
  } else {
    return []
  }
}

const StudentActivities = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_STDACT_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner
      }
    case "GET_STDACT_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case "GET_STDACT":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle
      }
    case "ADD_STDACT":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner
      }
    case "UPDATE_STDACT":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner
      }
    case "DELETE_STDACT":
      return { ...state }
    case "STDACT_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp
      }
    default:
      return { ...state }
  }
}
export default StudentActivities
