import { DefaultConfig } from "../../../../../../settings/config";

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedClass: null,
  actionTitle: " ",
  spinner: false,
  sspinner: false,
  tokenExp: false,
};

const initialItem = (resData) => {
  console.log(DefaultConfig().Classes);
  console.log(DefaultConfig().AcademicYear);
  console.log(DefaultConfig().Sections);
  if (resData) {
    resData.forEach((resp, index) => {
      resp["class"] = DefaultConfig().Classes.find(
        (item) => item.value === resp.cls
      )
        ? DefaultConfig().Classes.find((item) => item.value === resp.cls).label
        : "";
      resp["section"] = DefaultConfig().Sections.find(
        (item) => item.value === resp.sec
      )
        ? DefaultConfig().Sections.find((item) => item.value === resp.sec).label
        : "";
      resp["year"] = DefaultConfig().AcademicYear.find(
        (item) => item.value === resp.acadYear
      )
        ? DefaultConfig().AcademicYear.find(
            (item) => item.value === resp.acadYear
          ).label
        : "";

      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const delItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const SchOnlineClass = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_CLS_DATA":
      console.log(action, "red");
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_CLASS_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_CLASS":
      return {
        ...state,
        selectedClass: action.selectedClass,
        actionTitle: action.actionTitle,
      };
    case "ADD_CLASS":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    // case "UPDATE_PERIOD":
    //   return {
    //     ...state,
    //     allData: initialItem(action.allData),
    //     spinner: action.spinner
    //   }
    // case "DELETE_MEDIUM":
    //   return { ...state }
    case "GET_SUB_DATA":
      return {
        ...state,
        subData: action.data,
      };
    case "GET_EMP_DATA":
      return {
        ...state,
        empData: action.data,
      };
    case "DELETE_PERIOD":
      return {
        ...state,
        allData: delItem(action.allData),
        spinner: action.spinner,
      };
    case "SCH_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "SUB_MAP_LOADING_SPINNER":
      return {
        ...state,
        sspinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    default:
      return { ...state };
  }
};
export default SchOnlineClass;
