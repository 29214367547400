import { DefaultConfig } from "../../../../../../settings/config";
import { paginateArray } from "../../../../../../@fake-db/utils";
// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  spinner: false,
  tokenExp: false,

  eallData: [],
  edata: [],
  etotal: 1,
  ecolumns: [],
  resultData: null,

  ballData: [],
  bdata: [],
  btotal: 1,

  cballData: [],
  cbdata: [],
  cbtotal: 1,
  cbColumns: [],

  crallData: [],
  crdata: [],
  crtotal: 1,
  crColumns: [],

  feeHeadData: [],
  fhSpinner: false,
  feeHeadMap: [],
  dayWiseList: [],
  catgWiseList: [],
  bankWiseMap: [],
  payModeWiseList: [],
  feeHeadWiseTotMap: {},

  dynamicParams: [],

  empFhAllData: [],
  empFhData: [],
  empFhTotal: 1,
  params: {},
  empFhSpinner: false,

  acData: [],
  acSpinner: false,

  bwacData: {},
  bwacSpinner: false,
};

const initialItem = (reqData) => {
  const branchOptions = DefaultConfig().Branches;
  const branchList = reqData
  if (branchList) {
    const newArr = []
    branchList.forEach((element, index) => {
      const newObj = {}
      newObj.serial = index + 1
      newObj.companyName = branchOptions.find(find => find.value === element) ? branchOptions.find(find => find.value === element).branchName : ''
      newObj.compId = element
      newArr.push(newObj)
    });
    return newArr;
  } else {
    return [];
  }
};

const initcbColumns = (resData, reqData) => {
  if (resData) {
    const monthlyIncExpDtlsList = resData.monthlyIncExpDtlsList
    const arrayOfObj = []
    if (reqData.type === 'branchWise') {
      arrayOfObj.push({
        name: "Branch Name",
        minWidth: "0px",
        maxWidth: "100px",
        selector: 'companyName',
        sortable: true,
        showhide: true,
        cell: (row) => row['companyName'],
      })
    } else {
      arrayOfObj.push({
        name: "Account Type",
        minWidth: "0px",
        maxWidth: "100px",
        selector: 'catg',
        sortable: true,
        showhide: true,
        cell: (row) => row['catg'],
      })
    }
    monthlyIncExpDtlsList.forEach((element) => {
      arrayOfObj.push({
        name: element.monthName,
        minWidth: "0px",
        maxWidth: "50px",
        selector: element.monthName,
        sortable: true,
        showhide: true,
        cell: (row) => row[element.monthName],
      })
    })
    arrayOfObj.push({
      name: "Total",
      minWidth: "0px",
      maxWidth: "100px",
      selector: 'total',
      sortable: true,
      showhide: true,
      cell: (row) => row['total'],
    })
    return arrayOfObj
  } else {
    return []
  }
}

const AccountWiseAnalysis = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_ACC_REPORT_DATA":
      return {
        ...state,
        resultData: action.allData,
        eallData: (action.eallData),
        ecolumns: initcbColumns(action.allData, action.reqData),
        edata: action.edata,
        etotal: action.etotalPages,

        params: action.params,
        spinner: action.spinner,
      };
    case "GET_PENDING_ACC_DATA":
      return {
        ...state,
        edata: action.data,
        etotal: action.totalPages,
        totalPenSumData: action.totalPenSumData,
        params: action.params,
      };
    case "ACCOUNT_WISE_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_ACC_REPORT_DATA":
      return {
        ...state,
        acData: action.allData,
        acSpinner: action.spinner,
      };
    case "ACCOUNT_WISE_DATA_LOADING_SPINNER":
      return {
        ...state,
        acSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_BRANCH_WISE_ACC_DATA":
      return {
        ...state,
        bwacData: action.allData,
        bwacSpinner: action.spinner,
      };
    case "BRANCH_WISE_ACC_DATA_LOADING_SPINNER":
      return {
        ...state,
        bwacSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default AccountWiseAnalysis;
