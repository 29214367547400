// ** Initial State
const initialState = {
  natureOfIssueData: [],
  marketResourceData: [],
  empAreaListMap: [],
  token: [],
  tokenViewData: {},
  spinner: false,
  mspinner: false,
  tokenExp: false,
  data: [],
  total: 1,
  params: {},
  allData: [],

  pendCountData: [],
  pcspinner: false,

  teallData: [],
  tedata: [],
  tetotal: 1,
  teparams: {},

  tlbeData: [],
  tlbespinner: false,

  tlbeAllData: [],
  tlbeData: [],
  tlbeTotal: 1,
  tlbeParams: {},

  tlbempAllData: [],
  tlbempData: [],
  tlbempTotal: 1,
  tlbempParams: {},

  empData: [],
  tempspinner: false,

  tsData: [],
  tsspinner: false,

  compData: [],
  compspinner: [],

  myAllData: [],
  myData: [],
  myspinner: false,
  myTotal: 1,
  myparams: {},

  teacherListData: [],
  tlspinner: false,

  workHoursData: {},
  whspinner : false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const TokenEntry = (state = initialState, action) => {
  switch (action.type) {
    case "GET_NATURE_OF_ISSUE_DATA":
      return {
        ...state,
        natureOfIssueData: action.data,
        spinner: action.spinner,
      };
    case "NATURE_OF_ISSUE_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };

      case "GET_TEACHER_LIST_DATA":
      return {
        ...state,
        teacherListData: action.data,
        tlspinner: action.spinner,
      };
    case "TEACHER_LIST_LOADING_SPINNER":
      return {
        ...state,
        tlspinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    case "GET_MARKET_RESOURCE_DATA":
      return {
        ...state,
        marketResourceData: action.data,
        mspinner: action.spinner,
      };
    case "MARKET_RESOURCE_LOADING_SPINNER":
      return {
        ...state,
        mspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_EMP_WORKAREA_DATA":
      return {
        ...state,
        empAreaListMap: action.data,
        spinner: action.spinner,
      };
    case "EMP_WORKAREA_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "ADD_TOKEN":
      return {
        ...state,
        token: action.data,
        spinner: action.spinner,
      };
    case "UPDATE_TOKEN":
      return {
        ...state,
        spinner: action.spinner,
      };
    case "ADD_TOKEN_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_TOKEN_VIEW_DATA":
      return {
        ...state,
        tokenViewData: action.data,
        spinner: action.spinner,
      };
    case "TOKEN_VIEW_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case 'GET_DATA':
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_PENDING_TOKENS_COUNT_DATA":
      return {
        ...state,
        pendCountData: action.data,
        pcspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "PENDING_TOKEN_LOADING_SPINNER":
      return {
        ...state,
        pcspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_TOKEN_COUNT_BY_EMP":
      return {
        ...state,
        teallData: initialItem(action.allData),
        tedata: action.data,
        tetotal: action.totalPages,
        teparams: action.params,
      };
    case "GET_EMP_TOKEN_COUNT":
      return {
        ...state,
        tedata: action.data,
        tetotal: action.totalPages,
        teparams: action.params,
      };

    case "GET_TOKEN_LIST_BY_EMP":
      return {
        ...state,
        tlbempAllData: initialItem(action.allData),
        tlbempData: action.data,
        tlbempTotal: action.totalPages,
        tlbempParams: action.params,
        tlbempSpinner: action.spinner
      };
    case "GET_TOKEN_LIST_BY_EMP_FILTER":
      return {
        ...state,
        tlbempData: action.data,
        tlbempTotal: action.totalPages,
        tlbempParams: action.params,
      };
    case "TOKEN_LIST_BY_EMP_SPINNER":
      return {
        ...state,
        tlbempspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
      
      case "GET_TOKEN_LIST_BY_ID":
      return {
        ...state,
        tlbeAllData: initialItem(action.allData),
        tlbeData: action.data,
        tlbeTotal: action.totalPages,
        tlbeParams: action.params,
        tlbeSpinner: action.spinner
      };
    case "GET_TOKEN_LIST_BY_ID_FILTER":
      return {
        ...state,
        tlbeData: action.data,
        tlbeTotal: action.totalPages,
        tlbeParams: action.params,
      };
    case "TOKEN_LIST_BY_ID_SPINNER":
      return {
        ...state,
        tlbespinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    case "GET_TOKEN_EMP_DATA":
      return {
        ...state,
        empData: action.data,
        tempspinner: action.spinner,
      };
      case "TOKEN_EMP_DATA_SPINNER":
      return {
        ...state,
        tempspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
      case "GET_TOKEN_STATUS_COUNTS":
      return {
        ...state,
        tsData: action.allData,
        tsspinner: action.spinner,
      };
      case "TOKEN_STATUS_COUNTS_SPINNER":
      return {
        ...state,
        tsspinner: action.spinner,
        tokenExp: action.tokenExp,
      };

      case "GET_COMPANY_LIST":
      return {
        ...state,
        compData: action.allData,
        spinner: action.spinner,
      };
      case "COMPANY_LIST_SPINNER":
      return {
        ...state,
        compspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
      case 'MY_TOKEN_LIST_DATA':
        return {
          ...state,
          myAllData: initialItem(action.allData),
          myData: action.data,
          myTotal: action.totalPages,
          myparams: action.params,
        };
      case "MY_TOKEN_DATA_SPINNER":
        return {
          ...state,
          myspinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      case 'GET_MY_TOKEN_FILTER':
        return {
          ...state,
          myData: action.data,
          myTotal: action.totalPages,
          myparams: action.params,
        };
      case "GET_USED_WORKHRS_DATA":
        return {
          ...state,
          workHoursData: action.resData,
          whspinner: action.spinner,
        };
      case "WORK_HOURS_USED_LOADING_SPINNER":
        return {
          ...state,
          whspinner: action.spinner,
          tokenExp: action.tokenExp,
        };
    default:
      return { ...state };
  }
};
export default TokenEntry;