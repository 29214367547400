import SiblingMapping from "../../list";

// ** Initial State
const initialState = {
  allData: [],
  stuData: [],
  data: [],
  total: 1,
  params: {},
  selectedSibling: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
};

// const initialItem = resData => {
//   if (resData) {
//     console.log(resData, "ResData")
//     resData.forEach((resp, index) => {
//       resp.serial = index + 1
//     })
//   }
// }

const initialItem = (resData) => {
  if (resData) {
    //console.log(resData, "ResData");
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const SiblingMap = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_SIBLING_MAP_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        stuData: action.stuInfoData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_SIBLING_MAP_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_SIBLING_MAP":
      return {
        ...state,
        selectedSibling: action.selectedSibling,
        actionTitle: action.actionTitle,
      };
    case "ADD_SIBLING_MAP":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "UPDATE_SIBLING_MAP":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "DELETE_SIBLING_MAP":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "SIBLING_MAP_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default SiblingMap;
