// ** React Imports
import { Fragment, useState, useEffect, useRef, createRef } from "react";

// ** Spinner
import SpinnerComponent from "../../../../../@core/components/spinner/Loading-spinner";
// ** Invoice List Sidebar

import ExportPDF from "./ExportPDF";
import ExportExcel from "./ExportExcel";

// ** Columns
import { columns } from "./columns";
import Sidebar from "./Sidebar";

// ** Store & Actions
import { getAllData, getData, getSibling, addSibling } from "../store/action";
//import { getAllData, getData, getSibling, addSms  } from "./store/action";
import { useDispatch, useSelector } from "react-redux";
import { store } from "@store/storeConfig/store";

// ** Third Party Components
import ReactPaginate from "react-paginate";
import DataTable from "react-data-table-component";
import {
  Card,
  Input,
  Row,
  Col,
  Label,
  CustomInput,
  Button,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledTooltip,
  CardBody,
  FormGroup,
  CardHeader,
} from "reactstrap";
import {
  ChevronDown,
  Columns,
  Plus,
  FileText,
  File,
  Search,
} from "react-feather";

// ** default settings
import DefaultSettings from "../../../../../settings";

// ** auth settings
import Authverify from "../../../../../router/Authverify";

// ** Styles
import "@styles/pages/_react-select.scss";
import "@styles/pages/react-dataTable-component.scss";
import axios from "axios";
import { DefaultConfig } from "../../../../../settings/config";

// import XLSX from "xlsx";

// ** Table Header
const CustomHeader = ({
  toggleSidebar,
  handleFilter,
  searchTerm,
  columns,
  changeShowHideColumn,
  tableColumns,
  tableRows,
  excelExport,
}) => {
  return (
    <Fragment>
      <div className="mt-75">
        <Row className="mr-50 ml-50">
          <Col
            xs="12"
            sm="8"
            md="7"
            lg="7"
            xl="7"
            className="d-flex align-items-sm-center justify-content-start flex-lg-nowrap flex-wrap flex-sm-row flex-column p-0"
          >
            <Button.Ripple
              color="primary"
              onClick={() => excelExport()}
              className="mr-xl-50 mr-lg-50 mr-md-50 mr-sm-50 mb-50"
              id="excelexport"
            >
              <File size={14} className="mr-25" />
              <span className="align-middle d-xs-block d-sm-none d-md-none d-lg-inline-none d-xl-inline-block">
                Excel Export
              </span>
            </Button.Ripple>
            <UncontrolledTooltip placement="top" target="excelexport">
              Excel Export
            </UncontrolledTooltip>
            <Button.Ripple
              color="primary"
              onClick={() => ExportPDF(tableColumns, tableRows, "SmsTemplate")}
              className="mr-xl-50 mr-lg-50 mr-md-50 mr-sm-50 mb-50"
              id="pdfexport"
            >
              <FileText size={14} className="mr-25" />
              <span className="align-middle d-xs-block d-sm-none d-md-none d-lg-inline-none d-xl-inline-block">
                PDF Export
              </span>
            </Button.Ripple>
            <UncontrolledTooltip placement="top" target="pdfexport">
              PDF Export
            </UncontrolledTooltip>
            <div
              className="d-flex align-items-center form-group breadcrum-right dropdown mb-50"
              id="showhide"
            >
              <UncontrolledButtonDropdown className="w-100">
                <DropdownToggle
                  color="primary"
                  className="btn-round dropdown-toggle"
                >
                  <Columns size={14} className="mr-25" />
                  <span className="align-middle d-xs-block d-sm-none d-md-none d-lg-inline-none d-xl-inline-block">
                    Show / Hide Columns
                  </span>
                </DropdownToggle>
                <DropdownMenu tag="ul" right>
                  {columns &&
                    columns.map((column, index) => {
                      return (
                        <DropdownItem
                          key={index}
                          className="w-100 column-select-filter"
                          toggle={false}
                        >
                          <Input
                            type="checkbox"
                            defaultChecked={column.showhide}
                            id="basic-cb-checked"
                            className="ml-0 column-select-filter-check"
                            name={column.selector}
                            onClick={(e) => changeShowHideColumn(e, index)}
                          />
                          <span className="align-middle ml-2">
                            {column.name}
                          </span>
                        </DropdownItem>
                      );
                    })}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
            <UncontrolledTooltip placement="top" target="showhide">
              Show / Hide Columns
            </UncontrolledTooltip>
            <Button.Ripple
              color="primary"
              onClick={() => toggleSidebar("Add")}
              className="d-md-none d-lg-none d-xl-none d-block ml-sm-50 mb-50"
              id="addmethod"
            >
              <Plus size={14} />
              <span className="align-middle ml-75 d-xs-block d-sm-none d-md-none d-lg-inline-none d-xl-inline-block">
                Add Sibling
              </span>
            </Button.Ripple>
            <UncontrolledTooltip placement="top" target="addmethod">
              Add Sibling
            </UncontrolledTooltip>
          </Col>
          <Col
            xs="12"
            sm="4"
            md="5"
            lg="5"
            xl="5"
            className="d-flex align-items-sm-center justify-content-sm-end justify-content-md-end justify-content-lg-end justify-content-start flex-lg-nowrap flex-wrap flex-sm-row flex-column p-0"
          >
            <div className="d-flex align-items-center mb-50 mb-xl-0 mb-lg-0 mb-md-0 mb-sm-0 mr-xl-1 mr-lg-1 mr-md-1">
              <InputGroup className="input-group-merge flex-nowrap">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <Search size={14} />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  id="search-invoice"
                  className="w-100"
                  type="text"
                  value={searchTerm}
                  onChange={(e) => handleFilter(e.target.value)}
                  placeholder="Search Sibling"
                />
              </InputGroup>
            </div>
            <Button.Ripple
              color="primary"
              onClick={() => toggleSidebar("Add")}
              className="d-md-block d-lg-block d-xl-block d-none"
              id="addmethod"
            >
              <Plus size={14} />
              <span className="align-middle ml-75 d-xs-block d-sm-none d-md-none d-lg-inline-none d-xl-inline-block">
                Add Sibling
              </span>
            </Button.Ripple>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

// ** no data in table list ot hide the columns
const NoDataList = ({ showhidecolumns }) => {
  return (
    <Fragment>
      {showhidecolumns.length > 0 ? (
        <div className="py-2">There are no records to display</div>
      ) : (
        <div className="py-2">There are no columns to display</div>
      )}
    </Fragment>
  );
};

const SiblingTable = ({ getId }) => {
  // ** Store Vars
  // console.log(getId, "All Data");
  const dispatch = useDispatch();
  const stores = useSelector((state) => state.siblingMapping);
  //console.log(stores, "sibling Reducer");
  const authAxios = DefaultConfig().authAxios;
  // ** Export
  const _export = useRef(null);
  const _exporter = createRef();

  // ** States
  const [tableColumns, setPDFColumns] = useState([]);
  const [tableRows, setPDFRowData] = useState([]);
  const [allcolumns, setColumns] = useState(columns);
  const [showhidecolumns, setShowHideColumns] = useState(
    columns.filter((find) => find.showhide)
  );
  const [sortname, setSortName] = useState("");
  const [sortorder, setSortOrder] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(DefaultSettings.perPage);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [countFrom, setCountFrom] = useState("");
  const [countTo, setCountTo] = useState("");
  const [countTotal, setCountTotal] = useState("");
  const [file, setFile] = useState(null);

  // ** Function to toggle sidebar
  const toggleSidebar = (title) => {
    store.dispatch(getSibling(0, title));
    setSidebarOpen(!sidebarOpen);
  };

  // ** updates
  useEffect(() => {
    if (stores.actionTitle === "Edit" || stores.actionTitle === "View") {
      setSidebarOpen(true);
    }
  }, [stores.actionTitle]);

  // ** set pdf generate data
  useEffect(() => {
    const tblColumns = [];
    allcolumns.forEach((col) => {
      if (col.name !== "Actions") {
        tblColumns.push(col.name);
      }
    });
    setPDFColumns(tblColumns);
    const tblRows = [];
    stores.allData.forEach((row) => {
      tblRows.push([
        row.serial,
        row.compId,
        row.child1,
        row.child2,
        row.child3,
        row.child4,
      ]);
    });
    setPDFRowData(tblRows);
  }, [stores.allData.length]);

  // Export const function
  const save = (component) => {
    const options = component.current.workbookOptions();
    options.sheets[0].title = "SiblingMapping";
    component.current.save(options);
  };

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
    save(_exporter);
  };

  // ** showing pagination count
  useEffect(() => {
    const fromCount = (currentPage - 1) * rowsPerPage + 1;
    const toCount = fromCount + (stores.data.length - 1);
    const totalCount = stores.total;
    setCountFrom(fromCount);
    setCountTo(toCount);
    setCountTotal(totalCount);
  }, [stores.data.length, currentPage, rowsPerPage, stores.total]);

  // ** Get data on mount
  useEffect(() => {
    dispatch(
      getAllData(
        {
          page: currentPage,
          perPage: rowsPerPage,
          q: searchTerm,
        },
        getId
      )
    );
  }, [dispatch]);

  // ** Function in get data on page change
  const handlePagination = (page) => {
    dispatch(
      getData({
        page: page.selected + 1,
        perPage: rowsPerPage,
        q: searchTerm,
        sortname,
        sortorder,
      })
    );
    setCurrentPage(page.selected + 1);
  };

  // ** Function in get data on rows per page
  const handlePerPage = (e) => {
    const value = parseInt(e.currentTarget.value);
    const currentPage = 1;
    setCurrentPage(currentPage);
    dispatch(
      getData({
        page: currentPage,
        perPage: value,
        q: searchTerm,
        sortname,
        sortorder,
      })
    );
    setRowsPerPage(value);
  };

  // ** Function in get data on search query change
  const handleFilter = (val) => {
    setSearchTerm(val);
    const currentPage = 1;
    setCurrentPage(currentPage);
    dispatch(
      getData({
        page: currentPage,
        perPage: rowsPerPage,
        q: val,
        sortname,
        sortorder,
      })
    );
  };

  // ** Table data to render
  const dataToRender = () => {
    const filters = { q: searchTerm };

    const isFiltered = Object.keys(filters).some(function (k) {
      return filters[k].length > 0;
    });

    if (stores.data.length > 0) {
      return stores.data;
    } else if (stores.data.length === 0 && isFiltered) {
      return [];
    } else {
      return stores.allData.slice(0, rowsPerPage);
    }
  };

  // ** Table data sorting to render
  const handleSort = (column, sortDirection) => {
    const currentPage = 1;
    setCurrentPage(currentPage);
    setSortName(column.selector);
    setSortOrder(sortDirection);
    dispatch(
      getData({
        page: currentPage,
        perPage: rowsPerPage,
        q: searchTerm,
        sortname: column.selector,
        sortorder: sortDirection,
      })
    );
  };

  // ** Show / Hide columns change function
  const changeShowHideColumn = (e, index) => {
    e.stopPropagation();
    const columnsdata = allcolumns;
    columnsdata[index].showhide = e.target.checked;
    setColumns(columnsdata);
    setShowHideColumns(columnsdata.filter((find) => find.showhide));
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   //if await is removed, console log will be called before the uploadFile() is executed completely.
  //   //since the await is added, this will pause here then console log will be called
  //   let res = await uploadFile(file);
  //   // console.log(res.data);
  // };

  // const uploadFile = async (file) => {
  //   console.log(file);
  //   const formData = new FormData();
  //   formData.append("file", file);

  //   return await authAxios.post("academic/upload", formData, {
  //     headers: {
  //       "content-type": "multipart/form-data",
  //     },
  //   });
  // };

  const handleOnChange = (e) => {
    console.log(e.target.files[0]);
    setFile(e.target.files[0]);
    // var files = event.target.files;
    // var i, f;
    // for (i = 0, f = files[i]; i != files.length; ++i) {
    //   var name = f.name;
    //   var fields = name.split('.');
    //   var filepath = fields[1];
    //   if (filepath === 'xlsx' || filepath === 'xls') {
    //     const reader = new FileReader();
    //     reader.onload = (evt) => {
    //       const bstr = evt.target.result;
    //       const wb = XLSX.read(bstr, { type: 'binary' });
    //       var first_sheet_name = wb.SheetNames;
    //       var excelData = XLSX.utils.sheet_to_json(wb.Sheets[first_sheet_name[0]]);
    //       console.log(excelData)
    //       setFile(excelData);
    //     };
    //     reader.readAsBinaryString(f);
    //   }
    // }
  };

  return (
    <Fragment>
      {stores.spinner ? (
        <Fragment>
          <Card>
            <CustomHeader
              toggleSidebar={toggleSidebar}
              handlePerPage={handlePerPage}
              rowsPerPage={rowsPerPage}
              searchTerm={searchTerm}
              handleFilter={handleFilter}
              columns={allcolumns}
              changeShowHideColumn={changeShowHideColumn}
              tableColumns={tableColumns}
              tableRows={tableRows}
              excelExport={excelExport}
            />

            <DataTable
              noHeader
              responsive
              paginationServer
              columns={showhidecolumns}
              sortIcon={<ChevronDown />}
              className="react-dataTable"
              noDataComponent={<NoDataList showhidecolumns={showhidecolumns} />}
              data={showhidecolumns.length > 0 ? dataToRender() : []}
              sortServer
              onSort={handleSort}
            />
            {/* Custom footer section */}
            <Row className="mr-50 ml-50">
              <Col
                sm="12"
                md="4"
                lg="4"
                xl="4"
                className="d-flex align-items-center p-0"
              >
                <Label className="my-1 font-weight-bold page-show">
                  Showing {countFrom} to {countTo} of {countTotal} entries
                </Label>
              </Col>
              <Col
                sm="12"
                md="8"
                lg="8"
                xl="8"
                className="d-flex align-items-sm-center justify-content-lg-end justify-content-md-end justify-content-start flex-lg-nowrap flex-wrap flex-sm-row flex-column p-0"
              >
                <div className="d-flex align-items-center">
                  <Label for="rows-per-page font-weight-bold">
                    Rows per page:{" "}
                  </Label>
                  <CustomInput
                    className="form-control mx-50"
                    type="select"
                    id="rows-per-page"
                    value={rowsPerPage}
                    onChange={handlePerPage}
                    style={{
                      width: "5rem",
                      padding: "0 0.8rem",
                      backgroundPosition:
                        "calc(100% - 3px) 11px, calc(100% - 20px) 13px, 100% 0",
                    }}
                  >
                    {DefaultSettings &&
                      DefaultSettings.pagination.map((count, index) => {
                        return (
                          <option value={count.value} key={index.toString()}>
                            {count.label}
                          </option>
                        );
                      })}
                  </CustomInput>
                </div>
                <ReactPaginate
                  previousLabel={""}
                  nextLabel={""}
                  pageCount={Number(Math.ceil(stores.total / rowsPerPage)) || 1}
                  activeClassName="active"
                  forcePage={currentPage !== 0 ? currentPage - 1 : 0}
                  onPageChange={(page) => handlePagination(page)}
                  pageClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  nextClassName={"page-item next"}
                  previousClassName={"page-item prev"}
                  previousLinkClassName={"page-link"}
                  pageLinkClassName={"page-link"}
                  containerClassName={
                    "pagination react-paginate d-flex align-items-center my-1"
                  }
                />
              </Col>
            </Row>
          </Card>
          {stores && stores.actionTitle === "Add" && (
            <Sidebar
              open={sidebarOpen}
              toggleSidebar={toggleSidebar}
              title={"Add"}
              selectedSibling={""}
            />
          )}
          {stores &&
            (stores.actionTitle === "Edit" ||
              stores.actionTitle === "View") && (
              <Sidebar
                open={sidebarOpen}
                toggleSidebar={toggleSidebar}
                title={stores.actionTitle}
                selectedSibling={stores.selectedSibling}
                // previousClass={
                //   stores.allData
                //     ? stores.allData.map((da) => ({
                //         value: da.sno,
                //         label: da.cls,
                //       }))
                //     : []
                // }
              />
            )}
        </Fragment>
      ) : (
        <SpinnerComponent />
      )}
      {/* when token expire redirect to login page */}
      {stores.tokenExp && <Authverify />}
      {/* excel export */}
      {_export && (
        <ExportExcel
          data={stores.allData}
          columns={allcolumns}
          _export={_export}
          _exporter={_exporter}
          name="SiblingMapping"
        />
      )}
    </Fragment>
  );
};

export default SiblingTable;
