// ** Initial State
const initialState = {
  data: [],
  spinner: false,
};
  
  const DamagedCardsReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_DAMAGED_DATA":
        return {
          ...state,
          data: action.data,
          spinner: action.spinner,
        };
      case "DAMAGE_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp,
        };
        case "UPDATE_DAMAGED_DATA":
          return {
            ...state,
            data: action.data,
            spinner: action.spinner,
          };
        case "UPDATE_LOADING_SPINNER":
          return {
            ...state,
            spinner: action.spinner,
            tokenExp: action.tokenExp,
          };
      
      default:
        return { ...state };
    }
  };
  export default DamagedCardsReducer;
  