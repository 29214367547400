
// ** Initial State
const initialState = {
  groupData: [],
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false
}

const initialItem = (resData, groupData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1
      if (groupData.find(find => find.value === resp.state)) {
        resp.stateName = groupData.find(find => find.value === resp.state).label
      } else {
        resp.stateName = ''
      }
    })
    return resData
  } else {
    return []
  }
}

const Vendors = (state = initialState, action) => {
  switch (action.type) {
    case "GET_STATELIST_DATA":
      return {
        ...state,
        groupData: action.data,
        spinner: action.spinner
      }
    case "GET_ALL_VENDOR_DATA":
      return {
        ...state,
        allData: initialItem(action.allData, state.groupData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner
      }
    case "GET_VENDOR_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case "GET_VENDOR":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle
      }
    case "ADD_VENDOR":
      return {
        ...state,
        allData: initialItem(action.allData, state.groupData),
        spinner: action.spinner
      }
    case "UPDATE_VENDOR":
      return {
        ...state,
        allData: initialItem(action.allData, state.groupData),
        spinner: action.spinner
      }
    case "DELETE_VENDOR":
      return { ...state }
    case "VENDOR_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp
      }
    default:
      return { ...state }
  }
}
export default Vendors
