import { DefaultConfig } from "../../../../../../settings/config";

// ** Initial State
const initialState = {
  allData: [],
  totData: [],
  data: [],
  total: 1,
  params: {},
  selectedInvoice: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
};

const initialItem = (resData) => {
  if (resData) {
    console.log(resData, "ResData");
    resData.forEach((resp, index) => {
      resp["date"] = DefaultConfig().DateFormatMonth(resp.date);
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const InvoiceEdit = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_INVOICE_DATA":
      console.log(action, "ALL DATA");
      return {
        ...state,
        allData: initialItem(action.allData),

        totData: action.totData,
        spinner: action.spinner,
      };
    case "GET_INVOICE_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_INVOICE":
      return {
        ...state,
        selectedInvoice: action.selectedInvoice,
        actionTitle: action.actionTitle,
      };
    case "ADD_INVOICE":
      console.log(action.allData, "ROW Adding");
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "UPDATE_INVOICE":
      return {
        ...state,
        //allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "DELETE_INVOICE":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "INVOICE_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default InvoiceEdit;
