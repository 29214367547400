import MarketingSources from "../../list";
import { DefaultConfig } from "../../../../../../settings/config";
const BoardTypes = DefaultConfig().BoardTypes;
const empName = DefaultConfig().empName;
const Branches = DefaultConfig().Branches;

// ** Initial State
const initialState = {
    groupData: [],
    allData: [],
    data: [],
    total: 1,
    params: {},
    selectedMarketsrc: null,
    actionTitle: "",
    spinner: false,
    tokenExp: false
  }
  
  // const initialItem = resData => {
  //   if (resData) {
  //     console.log(resData, "ResData")
  //     resData.forEach((resp, index) => {
  //       resp.serial = index + 1
  //     })
  //   }
  // }

  const initialItem = (resData, groupData) => {
    if (resData) {
      console.log(resData, "ResData")
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
        if (groupData.find(find => find.value === resp.states)) {
        resp.stateName = groupData.find(find => find.value === resp.states).label
      } else {
        resp.stateName = ''
      }
      
      });
      return resData;
    } else {
      return [];
    }
  };
  
  const MarketingSrc = (state = initialState, action) => {
    switch (action.type) {
      case "GET_ALL_MARKET_SRC_DATA":
        console.log(action, "ALL DATA")
        return {
          ...state,
          allData: initialItem(action.allData, state.groupData),
          data: action.data,
          total: action.totalPages,
          params: action.params,
          spinner: action.spinner
        }
      case "GET_MARKET_SRC_DATA":
        return {
          ...state,

          data: action.data,
          total: action.totalPages,
          params: action.params
        }
        case "GET_STATELIST_DATA":
          return {
            ...state,
            groupData: action.data,
            spinner: action.spinner
          }
      case "GET_MARKET_SRC":
        return {
          ...state,

          selectedMarketsrc: action.selectedMarketsrc,
          actionTitle: action.actionTitle
        }
      case "ADD_MARKET_SRC":
        console.log(action.allData, 'ROW Adding')
        return {
          ...state,
          allData: initialItem(action.allData, state.groupData),
          spinner: action.spinner
        }
      case "UPDATE_MARKET_SRC":
        return {
          ...state,
          allData: initialItem(action.allData, state.groupData),
          spinner: action.spinner
        }
        case "DELETE_MARKET_SRC":
          return { ...state,
            allData: initialItem(action.allData),
            spinner: action.spinner,
          }
      case "MARKET_SRC_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp
        }
      default:
        return { ...state }
    }
  }
  export default MarketingSrc
  