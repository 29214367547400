// ** Initial State
const initialState = {
  groupData: [],
  allinceData: [],
  districtData: [],
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
  cspinner: false,
};

const initialItem = (resData) => {
  if (resData) {
    // console.log(resData, "reducer")
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const CompanyAdd = (state = initialState, action) => {
  switch (action.type) {
    case "GET_STATELIST_DATA":
      return {
        ...state,
        groupData: action.data,
        spinner: action.spinner,
      };
    case "GET_DISTRICTS_DATA":
      return {
        ...state,
        districtData: action.data,
        spinner: action.spinner,
      };
    case "GET_ALL_ALLIANCE_DATA":
      //  console.log(action, "allData")
      return {
        ...state,
        allinceData: action.data,
        spinner: action.spinner,
      };

    case "ADD_COMP":
      return {
        ...state,
        allData: action.resData,
        cspinner: action.spinner,
      };

    case "COMP_LOADING_SPINNER":
      // console.log(action.spinner, "ree")
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "COMP_ADD_LOADING_SPINNER":
      // console.log(action.spinner, "ree")
      return {
        ...state,
        cspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default CompanyAdd;
