import { DefaultConfig } from "../../../../../../settings/config";
const Classes = DefaultConfig().Classes;
const AcademicYear = DefaultConfig().AcademicYear;
// ** Initial State
const initialState = {
  allData: [],
  resData: [],
  data: [],
  secTotalList: [],
  columnData: [],
  smsData: [],
  subjects: [],
  total: 1,
  params: {},
  spinner: false,
  sspinner: false,
  tokenExp: false,
};

const initialItem = (resData) => {
  if (resData) {
    const sections = DefaultConfig().Sections;
    resData.forEach((resp, index) => {
      resp.serial = index + 1;

      resp["acadYearLable"] = AcademicYear.find(
        (item) => item.value === resp.acadYear
      )
        ? AcademicYear.find((item) => item.value === resp.acadYear).label
        : "";
      resp["clsName"] = Classes.find((item) => item.value === resp.cls)
        ? Classes.find((item) => item.value === resp.cls).label
        : resp.cls;
      resp["secName"] = sections.find((item) => item.value === resp.sec)
        ? sections.find((item) => item.value === resp.sec).label
        : "";
    });

    return resData;
  } else {
    return [];
  }
};
const getColumns = (data, subjects, ranksList) => {
  var columns = [
    {
      name: " ",
      minWidth: "0px",
      selector: "checkbox",
      sortable: true,
      showhide: true,
      cell: (row) => row.checkbox,
    },
  ];
  columns.push(
    {
      name: "S.No",
      minWidth: "0px",
      selector: "serial",
      sortable: true,
      showhide: true,
      cell: (row) => row.serial,
    },
    {
      name: "Name",
      minWidth: "0px",
      selector: "name",
      sortable: true,
      showhide: true,
      cell: (row) => row.name,
    }
  );

  if (Array.isArray(subjects)) {
    subjects.forEach((element) => {
      let item = DefaultConfig().Subjects.find((cc) => cc.value === element)
        ? DefaultConfig().Subjects.find((cc) => cc.value === element).label
        : element === -1 ? "Elective-1" : element === -2 ? "Elective-2" : element === -3 ? "Elective-3" : "";

      columns.push({
        name: item,
        minWidth: "0px",
        selector: "subRef",
        sortable: true,
        showhide: true,
        cell: (row) => row[element],
      });
    });
  }

  columns.push({
    name: "Total",
    minWidth: "0px",
    selector: "total",
    sortable: true,
    showhide: true,
    cell: (row) => row.total,
  });

  columns.push({
    name: "Rank",
    minWidth: "0px",
    selector: "stuRank",
    sortable: true,
    showhide: true,
    cell: (row) => row.total > 0 ? ranksList.findIndex((ff) => ff == row.total)+1 : "F"
  });

  return columns;
};

const ResultSMS = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SMS_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        smsData: action.allData,
        subjects: action.subjects,
        columnData: getColumns(action.allData, action.subjects, action.secTotalList),
        data: initialItem(action.data),
        secTotalList: action.secTotalList,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };

    case "GET_ATT_SMS_DATA":
      return {
        ...state,
        columnData: getColumns(action.data, action.subjects),
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "SMS_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_ATT_SEND_SMS_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "SAVE_ATT_SMS_DATA":
      return {
        ...state,
        saveData: action.allData,
        sspinner: action.spinner,
      };
    case "SAVE_ATT_SMS_LOADING_SPINNER":
      return {
        ...state,
        sspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default ResultSMS;
