import mock from '../mock'
import { paginateArray } from '../utils'

const data = {
  banks: [
    {
      id: 1,
      accountname: 'Ramakrishna',
      bankName: 'State Bank Of India',
      branchName: 'Vinukonda',
      accountno: '20156737843',
      ifsccode: 'SBIN0001234',
      status: 'Active',
      priority: 'High',
      approvedby: 'As Per Approval Settings',
      banktype: 'Real Bank',
      applicableto: 'All Branches'
    },
    {
      id: 2,
      accountname: 'Anil',
      bankName: 'State Bank Of India',
      branchName: 'Vinukonda',
      accountno: '20156737843',
      ifsccode: 'SBIN0001234',
      status: 'Active',
      priority: 'High',
      approvedby: 'As Per Approval Settings',
      banktype: 'Real Bank',
      applicableto: 'All Branches'
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/banks/list/all-data').reply(200, data.banks)

// POST: Add new bank
mock.onPost('/apps/banks/add-bank').reply(config => {
  // Get event from post data
  const bank = JSON.parse(config.data)

  const { length } = data.banks
  let lastIndex = 0
  if (length) {
    lastIndex = data.banks[length - 1].id
  }
  bank.id = lastIndex + 1

  data.banks.unshift(bank)

  return [201, { bank }]
})

// GET Updated DATA
mock.onGet('/api/banks/list/data').reply(config => {
  const { q = '', perPage = 10, page = 1 } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const filteredData =
    data.banks.filter(bank => (bank.accountname.toLowerCase().includes(queryLowered) || bank.bankName.toLowerCase().includes(queryLowered)))
  /* eslint-enable  */

  return [
    200,
    {
      banks: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

// GET Bank
mock.onGet('/api/banks/bank').reply(config => {
  const { id } = config
  const bank = data.banks.find(i => i.id === id)
  return [200, { bank }]
})

// DELETE: Deletes Bank
mock.onDelete('/apps/banks/delete').reply(config => {
  // Get bank id from URL
  let bankId = config.id

  // Convert Id to number
  bankId = Number(bankId)

  const bankIndex = data.banks.findIndex(t => t.id === bankId)
  data.banks.splice(bankIndex, 1)

  return [200]
})
