import { DefaultConfig } from "../../../../../settings/config";
// ** Initial State
const DateFormatMonth = DefaultConfig().DateFormatMonth
const initialState = {

  allData: [],
  vehData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  vspinner: false,
  tokenExp: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.Sdate = (resp.date ? DateFormatMonth(resp.date) : '')
      resp.serial = index + 1;

    });
    return resData;
  } else {
    return [];
  }
};

const VehiclesFuel = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_VEHICLE_LIST_DATA":
     
      return {
        ...state,
        vehData: action.allData,
        vspinner: action.spinner,
      };
    case "VEHICLE_LIST_LOADING_SPINNER":
      return {
        ...state,
        vspinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    case "GET_ALL_FUEL_LIST_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_FUEL_LIST_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
      case "GET_FUEL_LIST":
        return {
          ...state,
          selectedData: action.selectedData,
          actionTitle: action.actionTitle,
        };
    
    case "ADD_FUEL_LIST":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      }; 
      case "UPDATE_FUEL_LIST":
        return {
          ...state,
          allData: initialItem(action.allData),
          spinner: action.spinner,
        };
    case "FUEL_LIST_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default VehiclesFuel;
