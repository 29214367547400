import mock from "../mock"
import { paginateArray } from "../utils"

const data = {
  degrees: [
    {
      id: 1,
      name: "B.Tech",
      acronym: "B.tech"
    },
    {
      id: 2,
      name: "Intermediate",
      acronym: "inter"
    },
    {
      id: 3,
      name: "B.COM",
      acronym: "B.com"
    },
    {
      id: 4,
      name: "Chemistry",
      acronym: "Chm"
    }
  ]
}

// GET ALL DATA
mock.onGet("/api/degree/all-data").reply(200, data.degrees)

//GET UPDATE DATA
mock.onGet("/api/degrees/data").reply((config) => {
  const { q = "", perPage = 10, page = 1 } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const filteredData = data.degrees.filter(
    (deg) =>
      deg.name.toLowerCase().includes(queryLowered) ||
      deg.acronym.toLowerCase().includes(queryLowered)
  )
  /* eslint-enable  */

  return [
    200,
    {
      degrees: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

// GET Degree
mock.onGet("/api/degrees/degree").reply((config) => {
  const { id } = config
  const degree = data.degrees.find((i) => i.id === id)
  return [200, { degree }]
})

//Add Degree
mock.onPost("/api/degree/add-degree").reply((config) => {
  // Get event from post data
  const degree = JSON.parse(config.data)

  const { length } = data.degrees
  let lastIndex = 0
  if (length) {
    lastIndex = data.degrees[length - 1].id
  }
  degree.id = lastIndex + 1

  data.degrees.unshift(degree)

  return [201, { degree }]
})

// DELETE: Deletes Bank
mock.onDelete("/api/degrees/delete").reply((config) => {
  // Get bank id from URL
  let degreeId = config.id

  // Convert Id to number
  degreeId = Number(degreeId)

  const degreeIndex = data.degrees.findIndex((t) => t.id === degreeId)
  data.degrees.splice(degreeIndex, 1)

  return [200]
})
