import jsPDF from "jspdf"
import "jspdf-autotable"
import { DefaultConfig } from "../../../../../settings/config"

// define a generatePDF function that accepts a custom argument
const exportPDF = (tableColumn, tableRows, title) => {
  // initialize jsPDF
  const doc = new jsPDF("landscape", "px", "a3")

  const date = DefaultConfig().date
  const CurrentTime = DefaultConfig().CurrentTime

  // header margin-left + margin-top 
  doc.setFontSize(14)
  doc.text(title, 30, 15)
  doc.text(date, 752, 15)
  doc.text(CurrentTime, 815, 15)
  // startY is basically margin-top
  doc.autoTable(tableColumn, tableRows, { startY: 20 })

  // footer
  const pageCount = doc.internal.getNumberOfPages()
  // doc.setFont('helvetica', 'italic')
  doc.setFontSize(10)
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i)
    doc.text(`Page ${String(i)} of ${String(pageCount)}`, 30, doc.internal.pageSize.height - 10, {
      align: 'left'
    })
  }

  // date conversion
  // const date = Date().split(" ")
  // we use a date string to generate our filename.
  // const dateStr = date[0] + date[1] + date[2] + date[3] + date[4]
  // we define the name of our PDF file.
  doc.save(`${title}.pdf`)
}

export default exportPDF