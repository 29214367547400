// ** Initial State
const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    selectedTimeTable: null,
    actionTitle: "",
    spinner: false,
    tokenExp: false,
    deldata: {},
    delspinner: false
  }
  
  // const initialItem = resData => {
  //   if (resData) {
  //     console.log(resData, "ResData")
  //     resData.forEach((resp, index) => {
  //       resp.serial = index + 1
  //     })
  //   }
  // }

  const initialItem = (resData) => {
    if (resData) {
      // console.log(resData, "ResData")
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
      });
      return resData;
    } else {
      return [];
    }
  };
  
  const TimeTableAddReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_ALL_TIME_TABLE_DATA":
        // console.log(action, "ALL DATA")
        return {
          ...state,
          allData: initialItem(action.allData),
          data: action.data,
          total: action.totalPages,
          params: action.params,
          spinner: action.spinner
        }
      case "GET_TIME_TABLE_DATA":
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      case "GET_TIME_TABLE":
        return {
          ...state,
          selectedTimeTable: action.selectedTimeTable,
          actionTitle: action.actionTitle
        }
      case "ADD_TIME_TABLE":
        console.log(action.allData, 'ROW Adding')
        return {
          ...state,
          allData: initialItem(action.allData),
          spinner: action.spinner
        }
        case "GET_TIME_TABLE":
          return {
            ...state,
  
            selectedTimeTable: action.selectedTimeTable,
            actionTitle: action.actionTitle
          }
          case "ADD_TIME_TABLE":
        // console.log(action.allData, 'ROW Adding')
        return {
          ...state,
          allData: initialItem(action.allData),
          spinner: action.spinner
        }
      case "TIME_TABLE_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp
        }

        case "DEL_TIME_TABLE_DATA":
          return { ...state,
            deldata: action.data,
            delspinner: action.spinner,
          }
          case "DEL_TIME_TABLE_LOADING_SPINNER":
          return {
            ...state,
            delspinner: action.spinner,
          }
      default:
        return { ...state }
    }
  }
  export default TimeTableAddReducer
  