import { paginateArray } from "../../../../../../@fake-db/utils";
import { Button, UncontrolledTooltip } from "reactstrap";
import { store } from "@store/storeConfig/store";
import { getLeaveApplyTypes } from "../action";
import { DefaultConfig } from "../../../../../../settings/config";
const DateFormat = DefaultConfig().DateFormat;
const initialState = {
  columns: [],
  data: [],
  allData: [],
  params: {},
  total: 1,
  totalTwo: 1,
  spinner: false,
  tokenExp: false,
  dataTwo: [],
  actionTitle: "",
  selectedData: null,
  applLeaves: [],
  empdata: [],
  leaveTypes: [],
  empLoader: true,
  dataBelow: [],
  bspinner: false,
  selectEmpId: "",
  leaveAdjust: [],
  laspinner: false,
};

const resData = (data) => {
  const leaveWiseDetailes = data.leaveWiseDetailes;
  var leaveApply = [];
  leaveWiseDetailes.forEach((element) => {
    const leaveObj = {};
    if (element.accuredByPolicy === "N/A") {
      element.accuredByPolicy = "0.0";
    }
    // console.log(element);
    leaveObj["ltRef"] = element.ltRef;
    leaveObj["priorNotice"] = element.priorNotice;
    leaveObj["leaveType"] = element.leaveType;
    leaveObj["leavesAdded"] = element.leavesAdded;
    leaveObj["accuredByPolicy"] = element.accuredByPolicy;
    leaveObj["availableLeaves"] = parseFloat(element.availableLeaves);
    // parseFloat(
    //   parseFloat(element.accuredByPolicy) +
    //     (element.leavesAdded === "N/A"
    //       ? 0.0
    //       : parseFloat(element.leavesAdded)) -
    //     parseFloat(element.totalLeavesUtilized)
    // );
    leaveObj["totalLeavesUtilized"] = element.totalLeavesUtilized;
    const monthLeaves = element.monthLeaves;
    monthLeaves.forEach((ele) => {
      if (ele.month == 1) {
        leaveObj["Jan"] = ele.leaveCount;
      } else if (ele.month == 2) {
        leaveObj["Feb"] = ele.leaveCount;
      } else if (ele.month == 3) {
        leaveObj["Mar"] = ele.leaveCount;
      } else if (ele.month == 4) {
        leaveObj["Apr"] = ele.leaveCount;
      } else if (ele.month == 5) {
        leaveObj["May"] = ele.leaveCount;
      } else if (ele.month == 6) {
        leaveObj["Jun"] = ele.leaveCount;
      } else if (ele.month == 7) {
        leaveObj["Jul"] = ele.leaveCount;
      } else if (ele.month == 8) {
        leaveObj["Aug"] = ele.leaveCount;
      } else if (ele.month == 9) {
        leaveObj["Sep"] = ele.leaveCount;
      } else if (ele.month == 10) {
        leaveObj["Oct"] = ele.leaveCount;
      } else if (ele.month == 11) {
        leaveObj["Nov"] = ele.leaveCount;
      } else if (ele.month == 12) {
        leaveObj["Dec"] = ele.leaveCount;
      }
    });
    leaveApply.push(leaveObj);
  });
  return leaveApply;
};

const columData = (data) => {
  const leaveWiseDetailes = data.leaveWiseDetailes;
  var leaveApply = [
    {
      name: "Leave Type",
      minWidth: "150px",
      maxWidth: "150px",
      selector: "leaveType",
      sortable: true,
      showhide: true,
      center: true,
      cell: (row) => row.leaveType,
    },
    {
      name: "Accured By Policy",
      minWidth: "100px",
      maxWidth: "100px",
      center: true,
      selector: "accuredByPolicy",
      sortable: true,
      showhide: true,
      cell: (row) => row.accuredByPolicy,
    },
    {
      name: "Leaves Adjustment",
      minWidth: "100px",
      maxWidth: "100px",
      center: true,
      selector: "leavesAdded",
      sortable: true,
      showhide: true,
      cell: (row) => row.leavesAdded,
    },
  ];
  if (leaveWiseDetailes.length > 0) {
    const monthLeaves = leaveWiseDetailes[0].monthLeaves;
    monthLeaves.forEach((ele) => {
      if (ele.month == 1) {
        leaveApply.push({
          name: "JAN",
          minWidth: "40px",
          maxWidth: "40px",
          center: true,
          selector: "Jan",
          sortable: true,
          showhide: true,
          cell: (row) => row.Jan,
        });
      } else if (ele.month == 2) {
        leaveApply.push({
          name: "FEB",
          minWidth: "40px",
          maxWidth: "40px",
          center: true,
          selector: "Feb",
          sortable: true,
          showhide: true,
          cell: (row) => row.Feb,
        });
      } else if (ele.month == 3) {
        leaveApply.push({
          name: "MAR",
          minWidth: "40px",
          maxWidth: "40px",
          center: true,
          selector: "Mar",
          sortable: true,
          showhide: true,
          cell: (row) => row.Mar,
        });
      } else if (ele.month == 4) {
        leaveApply.push({
          name: "APR",
          minWidth: "40px",
          maxWidth: "40px",
          center: true,
          selector: "Apr",
          sortable: true,
          showhide: true,
          cell: (row) => row.Apr,
        });
      } else if (ele.month == 5) {
        leaveApply.push({
          name: "MAY",
          minWidth: "40px",
          maxWidth: "40px",
          center: true,
          selector: "May",
          sortable: true,
          showhide: true,
          cell: (row) => row.May,
        });
      } else if (ele.month == 6) {
        leaveApply.push({
          name: "JUN",
          minWidth: "40px",
          maxWidth: "40px",
          center: true,
          selector: "Jun",
          sortable: true,
          showhide: true,
          cell: (row) => row.Jun,
        });
      } else if (ele.month == 7) {
        leaveApply.push({
          name: "JUL",
          minWidth: "40px",
          maxWidth: "40px",
          center: true,
          selector: "Jul",
          sortable: true,
          showhide: true,
          cell: (row) => row.Jul,
        });
      } else if (ele.month == 8) {
        leaveApply.push({
          name: "AUG",
          minWidth: "40px",
          maxWidth: "40px",
          center: true,
          selector: "Aug",
          sortable: true,
          showhide: true,
          cell: (row) => row.Aug,
        });
      } else if (ele.month == 9) {
        leaveApply.push({
          name: "SEP",
          minWidth: "40px",
          maxWidth: "40px",
          center: true,
          selector: "Sep",
          sortable: true,
          showhide: true,
          cell: (row) => row.Sep,
        });
      } else if (ele.month == 10) {
        leaveApply.push({
          name: "OCT",
          minWidth: "40px",
          maxWidth: "40px",
          center: true,
          selector: "Oct",
          sortable: true,
          showhide: true,
          cell: (row) => row.Oct,
        });
      } else if (ele.month == 11) {
        leaveApply.push({
          name: "NOV",
          minWidth: "40px",
          maxWidth: "40px",
          center: true,
          selector: "Nov",
          sortable: true,
          showhide: true,
          cell: (row) => row.Nov,
        });
      } else if (ele.month == 12) {
        leaveApply.push({
          name: "DEC",
          minWidth: "40px",
          maxWidth: "40px",
          center: true,
          selector: "Dec",
          sortable: true,
          showhide: true,
          cell: (row) => row.Dec,
        });
      }
    });
  }

  leaveApply.push(
    {
      name: "Total Leaves Utilized",
      minWidth: "0px",
      center: true,
      selector: "totalLeavesUtilized",
      sortable: true,
      showhide: true,
      cell: (row) => row.totalLeavesUtilized,
    },
    {
      name: "Available Leaves",
      minWidth: "0px",
      center: true,
      selector: "availableLeaves",
      sortable: true,
      showhide: true,
      cell: (row) => row.availableLeaves,
    },
    {
      name: "Actions",
      minWidth: "0px",
      selector: "actions",
      // maxWidth: "120px",
      center: true,
      showhide: true,
      allowOverflow: true,
      cell: (row) => (
        <div className="d-flex">
          <Button.Ripple
            className="btn-icon btn_Action"
            outline
            color="primary"
            id="viewTip"
            onClick={() =>
              store.dispatch(
                getLeaveApplyTypes(row.leaveType, row.availableLeaves, "Apply")
              )
            }
          >
            Apply
          </Button.Ripple>
          <UncontrolledTooltip placement="top" target="viewTip">
            Apply
          </UncontrolledTooltip>
        </div>
      ),
    }
  );
  return leaveApply;
};

const applyLeaveData = (data) => {
  const appliedLeaves = data.appliedLeaves;
  const groupEmpBasicInfo = JSON.parse(
    localStorage.getItem("groupEmpBasicInfo")
  );
  appliedLeaves.forEach((ele) => {
    ele.date = ele.date ? DateFormat(ele.date) : "";
    if (ele.approvedBy) {
      const findEmp = groupEmpBasicInfo.find(
        (find) => find.empId === ele.approvedBy
      );
      if (findEmp) {
        ele.approvedBy = findEmp.name;
      }
    }
  });
  return appliedLeaves;
};

const leaveApplyData = (state = initialState, action) => {
  switch (action.type) {
    case "GET_LEAVE_APPLY_DATA_TWO":
      const { perPage = 10, page = 1 } = action.params;
      return {
        ...state,
        columns: columData(action.allData),

        allData: resData(action.allData),
        data: paginateArray(resData(action.allData), perPage, page),
        total: resData(action.allData).length,
        params: action.params,
        spinner: action.spinner,

        dataTwo: applyLeaveData(action.allData),
        dataBelow: paginateArray(applyLeaveData(action.allData), perPage, page),
        totalTwo: applyLeaveData(action.allData).length,
        bspinner: action.spinner,
      };
    case "LEAVE_APPLY_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_LEAVE_TYPES_APPLY_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_BELOW_LEAVE_TYPES_APPLY_DATA":
      return {
        ...state,
        dataBelow: action.dataBelow,
        totalTwo: action.totalPages,
        params: action.params,
      };
    case "GET_LEAVE_APPLY_TYPES":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };
    case "APPLY_LEAVE":
      return {
        ...state,
        applLeaves: action.data,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "APPLY_LEAVE_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "LEAVE_ADJUSTMENT":
    return {
      ...state,
      leaveAdjust: action.data,
      laspinner: action.spinner,
      tokenExp: action.tokenExp,
    };
    case "LEAVE_ADJUST_LOADING_SPINNER":
      return {
        ...state,
        laspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_SEL_EMP":
      return {
        ...state,
        selectEmpId: action.selectEmpId,
      };
    default:
      return { ...state };
  }
};
export default leaveApplyData;
