//import { DefaultConfig } from "../../../../../settings/config";

import { DefaultConfig } from "../../../../../../settings/config";

// ** Initial State
const initialState = {
  allData: {},
  data: [],
  itemData: [],
  comboData: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  refName: "",
  spinner: false,

  itemList: [],
  itemData: [],
  itemTotalPages: 1,
  itemParams: {},
  itemSpinner: false,

  comboList: [],
  comboData: [],
  comboTotalPages: 1,
  comboParams: {},
  comboSpinner: false,
  
  historyData: [],
  hSpinner: false
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const SalePrice = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_SALE_DATA":
      return {
        ...state,
        allData: action.allData,
        spinner: action.spinner,
      };
      case "SALE_PRICE_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };

      case "GET_ITEM_LIST_DATA":
      return {
        ...state,
        itemList: initialItem(action.itemList),
        itemData: initialItem(action.data),
        itemTotalPages: action.totalPages,
        itemParams: action.params,
        itemSpinner: action.spinner
      };
      case "GET_ITEM_LIST_FILTER_DATA":
      return {
        ...state,
        itemData: initialItem(action.data),
        itemTotalPages: action.totalPages,
        itemParams: action.params,
      };
      

      case "GET_COMBO_LIST_DATA":
      return {
        ...state,
        comboList: initialItem(action.comboList),
        comboData: initialItem(action.data),
        comboTotalPages: action.totalPages,
        comboParams: action.params,
        comboSpinner: action.spinner
      };
      case "GET_COMBO_LIST_FILTER_DATA":
      return {
        ...state,
        comboData: initialItem(action.data),
        comboTotalPages: action.totalPages,
        comboParams: action.params,
      };

      case "GET_SALE_PRICE":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
        refName: action.refName
      };
      case "GET_SALE_DATA":
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
        case "GET_SALE_PRICE_HISTORY":
          return {
            ...state,
            historyData: initialItem(action.data),
            hSpinner: action.spinner,
        }
        case "GET_SALE_PRICE_HISTORY_SPINNER":
        return {
          ...state,
          hSpinner: action.spinner,
          tokenExp: action.tokenExp,
        };       
    
    default:
      return { ...state };
  }
};
export default SalePrice;
