// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  spinner: false,
  tokenExp: false,
};

const Captured = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_CAPTURED_IMAGES_DATA":
      console.log(action.allData, "reducer");
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        spinner: action.spinner,
      };

    case "GET_CAPTURED_IMAGES_DATA":
      return {
        ...state,
        data: action.data,
      };
    case "CAPTURED_IMAGES_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default Captured;
