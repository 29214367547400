// ** Initial State
const initialState = {
    allData: [],
    data: [],
    bedsList: [],
    total: 1,
    params: {},
    selectedData: null,
    actionTitle: "",
    spinner: false,
    vspinner: false,
    hraSpinner: false,
    tokenExp: false,
  };
  
  const initialItem = (resData) => {
    if (resData) {
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
      });
      return resData;
    } else {
      return [];
    }
  };
  
  const HstlRoomAllotReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_HSTL_ROOMS_ALLOT_DATA":
        return {
          ...state,
          allData: initialItem(action.allData),
          data: action.data,
          total: action.totalPages,
          bedsList: action.bedsList,
          params: action.params,
          spinner: action.spinner,
        };
      case "GET_HSTL_ROOMS_ALLOT_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      case "GET_HSTL_ROOM_ALLOT_FILTER_DATA":
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params,
        };
      case "GET_HSTL_ROOM_UPDATE":
        return {
          ...state,
          selectedData: action.selectedData,
          actionTitle: action.actionTitle,
        };
      case "ADD_HSTL_ROOM_ALLOT_DATA":
        return {
          ...state,
          allData: initialItem(action.allData),
          spinner: action.spinner,
        };
    case "HSTL_ROOMS_ALLOT_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp,
        };
    case "UPDATE_HSTL_ROOM_DATA":
        return {
          ...state,
          allData: initialItem(action.allData),
          spinner: action.spinner,
        };
    case "GET_SEL_HSTL_ROOM_ALLOT_UPDATE":
        return {
            ...state,
            selectedData: action.selectedData,
            actionTitle: action.actionTitle,
        };
    case "UPD_VACATESTU_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        vspinner: action.spinner,
      };
    case "VACATE_LOADING_SPINNER":
      return {
        ...state,
        vspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
      default:
        return { ...state };
    }
  };
  export default HstlRoomAllotReducer;
  