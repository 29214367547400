// ** Initial State
const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    selectedIp: null,
    actionTitle: "",
    spinner: false,
    tokenExp: false
  }
  
  const initialItem = resData => {
    if (resData) {
      resData.forEach((resp, index) => {
        resp.serial = index + 1
        resp.empStatus = resp.empStatus ? resp.empStatus : ''
        if (resData.find(find => find.sno === resp.prevCls)) {
          resp.preClsName = resData.find(find => find.sno === resp.prevCls).cls
        } else {
          resp.preClsName = ''
        }
      })
      return resData
    } else {
      return []
    }
  }
  
  const IpPermitsReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_ALL_IP_DATA":
        return {
          ...state,
          allData: initialItem(action.allData),
          data: action.data,
          total: action.totalPages,
          params: action.params,
          spinner: action.spinner
        }
      case "GET_IP_DATA":
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      case "GET_IP":
        return {
          ...state,
          selectedIp: action.selectedIp,
          actionTitle: action.actionTitle
        }
      case "ADD_IP":
        return {
          ...state,
          allData: initialItem(action.allData),
          spinner: action.spinner
        }
      case "UPDATE_IP":
        return {
          ...state,
          allData: initialItem(action.allData),
          spinner: action.spinner
        }
      case "IP_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp
        }
      default:
        return { ...state }
    }
  }
  export default IpPermitsReducer
  