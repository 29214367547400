import { DefaultConfig } from "../../../../../../settings/config"
const grade = DefaultConfig().grade
const Classes = DefaultConfig().Classes

// ** Initial State
const initialState = {
  cbscData: [],
  subDepData: [],
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false
}

const initialItem = (resData, subDepData, cbscData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1
      if (subDepData.find(find => find.value === resp.subjDepend)) {
        resp.subdepName = subDepData.find(find => find.value === resp.subjDepend).label
      } else {
        resp.subdepName = ''
      }
      if (grade.find(find => find.value === resp.grade)) {
        resp.gradeName = grade.find(find => find.value === resp.grade).label
      } else {
        resp.gradeName = ''
      }

      if (cbscData.find(find => find.value === resp.cbseGrpRef)) {
        resp.cbName = cbscData.find(find => find.value === resp.cbseGrpRef).label
      } else {
        resp.cbName = ''
      }
      if (Classes.find(find => find.value === resp.cls)) {
        resp.clsName = Classes.find(find => find.value === resp.cls).label
      } else {
        resp.clsName = ''
      }

    })
    return resData
  } else {
    return []
  }
}

const CbscSubject = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SUB_DEP_DATA":
      return {
        ...state,
        subDepData: action.data,
        spinner: action.spinner
      }
    case "GET_GRP_DATA":
      return {
        ...state,
        cbscData: action.data,
        spinner: action.spinner
      }
    case "GET_ALL_CBSC_SUBJ_DATA":
      return {
        ...state,
        allData: initialItem(action.allData, state.subDepData, state.cbscData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner
      }
    case "GET_CBSC_SUBJ_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case "GET_CBSC_SUBJ":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle
      }
    case "ADD_CBSC_SUBJ":
      return {
        ...state,
        allData: initialItem(action.allData, state.subDepData, state.cbscData),
        spinner: action.spinner
      }
    case "UPDATE_CBSC_SUBJ":
      return {
        ...state,
        allData: initialItem(action.allData, state.subDepData, state.cbscData),
        spinner: action.spinner
      }
    case "DELETE_CBSC_SUBJ":
      return { ...state }
    case "CBSC_SUBJ_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp
      }
    default:
      return { ...state }
  }
}
export default CbscSubject
