
// ** Initial State
const initialState = {
    
  spinner: false,
  data:[],
  tokenExp: false,
  data: ''

  
}

const ChangePassword = (state = initialState, action) => {
  switch (action.type) {
  
    case "CHANGE_PASSWORD":
     // console.log(action.data, "red")
      return {
        ...state,  
        data: action.data,
        spinner: action.spinner
      }
      case "CHANGE_STU_PASSWORD":
     // console.log(action.data, "red")
      return {
        ...state,  
        data: action.data,
        spinner: action.spinner
      }
    case "CHANGE_PASSWORD_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp
      }
    default:
      return { ...state }
  }
}
export default ChangePassword;
