import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export'

const exportExcel = ({ data, columns, _export, _exporter, name }) => {
  return <div>
    <ExcelExport
      data={data}
      ref={_exporter}
      name={name}
      fileName={`${name}.xlsx`}
    >
      {columns.map((column, index) => (
        <ExcelExportColumn field={column.selector} title={column.name} key={index} />
      ))}
    </ExcelExport>
  </div>
}

export default exportExcel