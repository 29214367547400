// ** Initial State
const initialState = {
  subData: [],
  data: [],
  quesData: [],
  tokenExp: false,
  subjspinner: false,

  subDtlsData: [],
  sdspinner: false,

  rvqueCount: [],
  rvspinner: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const SelfAssmtRvsnReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_SUBJ_TOPICS_DATA":
      return {
        ...state,
        subData: action.allData,
        subjspinner: action.spinner,
      };
    case "SUBJ_TOPICS_LOADING_SPINNER":
      return {
        ...state,
        tokenExp: action.tokenExp,
        subjspinner: action.spinner,
      };
    case "GET_ALL_SUBJ_TOPICS_DTLS":
    return {
        ...state,
        subDtlsData: action.allData,
        sdspinner: action.spinner,
    };
    case "SUBJ_TOPICS_DTLS_LOADING_SPINNER":
    return {
        ...state,
        tokenExp: action.tokenExp,
        sdspinner: action.spinner,
    };
    case "GET_ALL_RVSN_QUESTIONS_DATA":
    return {
        ...state,
        rvqueCount: action.allData,
        rvspinner: action.spinner,
    };
    case "SUBJ_QUESTIONS_LOADING_SPINNER":
    return {
        ...state,
        tokenExp: action.tokenExp,
        rvspinner: action.spinner,
    };
    default:
      return { ...state };
  }
};
export default SelfAssmtRvsnReducer;
