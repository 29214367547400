import { DefaultConfig } from "../../../../../../settings/config";

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
  espinner: false,
  empsaldata: [],
};

const initialItem = (resData) => {
  if (resData) {
    //console.log(resData, "resucer");
    resData.forEach((resp, index) => {
      // resp["fromDate"] = DefaultConfig().DateFormatMonth(resp.fromDate);
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const SalarySettings = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SALARY_SETTINGS_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "SALARY_SETTINGS_LOADING_SPINNER":
      console.log(action);
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_INSTIME_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "EMP_SAL_DET":
      return {
        ...state,
        empsaldata: initialItem(action.data),
        espinner: action.espinner,
      };
    case "EMP_SAL_LOADING_SPINNER":
      return {
        ...state,
        espinner: action.espinner,
        tokenExp: action.tokenExp,
      };

    case "GET_SALSET":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };
    case "ADD_INSTIME":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "UPDATE_SAL":
      console.log(action, "reduser update");
      return {
        ...state,
        empsaldata: initialItem(action.allData),
        allData: action.data,
        spinner: action.spinner,
        espinner: action.espinner,
      };

    default:
      return { ...state };
  }
};
export default SalarySettings;
