import axios from "axios";
import { toast } from "react-toastify";
import ToastMessage from "../../../../../../@core/components/toast-messages";
import { DefaultConfig } from "../../../../../../settings/config";
import { paginateArray } from "../../../../../../@fake-db/utils";
import DefaultSettings from "../../../../../../settings";

export const removeAllData = () => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_EMP_ATTENDANCE_ISSUE",
      spinner: false,
      tokenExp: false,
    });
  };
};

// ** Get attendance Data
export const getAttendanceData = (changeMonth, bioCode, empCompId) => {
  return async (dispatch, getState) => {
    const authAxios = DefaultConfig().authAxios;
    const companyId = DefaultConfig().companyId;
    const finGrp = DefaultConfig().finGrp;
    //const bioCode = DefaultConfig().bioCode
    const errorMsg = DefaultConfig().errorresponse;

    const objectData = {};
    objectData["compId"] = empCompId ? empCompId : companyId;
    objectData["finGrp"] = empCompId == "GVCOMP0000" ? empCompId : finGrp;
    objectData["bioCode"] = bioCode;
    objectData["month"] = changeMonth.month;
    objectData["year"] = changeMonth.year;
    objectData["refType"] = "stf";

    dispatch({
      type: "FETCH_EMP_ATTENDANCE_ISSUE",
      spinner: false,
      tokenExp: false,
    });
    await authAxios
      .post(`att/monthly`, objectData)
      .then((response) => {
        const respData = response.data;
        if (respData) {
          if (respData.error) {
            const error = errorMsg;
            dispatch({
              type: "FETCH_EMP_ATTENDANCE_ISSUE",
              spinner: true,
              tokenExp: false,
            });
            toast.error(
              <ToastMessage
                message={"att/monthly" + "  " + error}
                status={"danger"}
              />,
              {
                hideProgressBar: true,
              }
            );
          } else {
            const resData = respData.value;

            const leaveExemptions = resData.recentExemptions;

            const params = getState().myattendance.params;

            const leaveTypes = getState().myattendance.leaveTypes;

            const { perPage = DefaultSettings.perPage, page = 1 } = params;
            if (leaveTypes) {
              leaveExemptions.forEach((item, index) => {
                item["halfDayLeave"] = leaveTypes.find(
                  (cc) => cc.ltRef === item.halfDayLeave
                )
                  ? leaveTypes.find((cc) => cc.ltRef === item.halfDayLeave)
                      .leaveType
                  : "";
                item.serial = index + 1;
              });
            }
            dispatch({
              type: "FETCH_EMP_ATTENDANCE",
              data: resData,
              allData: leaveExemptions,
              datas: paginateArray(leaveExemptions, perPage, page),
              totalPages: leaveExemptions.length,
              params,
              spinner: true,
            });
          }
        }
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "EMP_ATT_LOADING_SPINNER",
              spinner: true,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "EMP_ATT_LOADING_SPINNER",
              spinner: true,
              tokenExp: false,
            });
            toast.error(<ToastMessage message={error} status={"danger"} />, {
              hideProgressBar: true,
            });
          }
        }
      });
  };
};

// ** Get data on page or row change
export const getData = (params) => {
  return (dispatch, getState) => {
    const {
      q = "",
      perPage = DefaultSettings.perPage,
      page = 1,
      sortname = "",
      sortorder = "",
    } = params;
    const resData = getState().myattendance.allData;
    if (resData) {
      let filteredData = [];
      const queryLowered = q.toLowerCase();
      filteredData = resData.filter(
        (common) =>
          common.date && common.date.toLowerCase().includes(queryLowered)
      );
      const sorting = DefaultConfig().sorter;
      const sortData = sortorder
        ? sorting(sortname, sortorder, filteredData)
        : filteredData;
      dispatch({
        type: "GET_EMP_ATT_DATA",
        data: paginateArray(sortData, perPage, page),
        totalPages: sortData.length,
        params,
      });
    }
  };
};

// ** Get Leavetypes
export const getLeaveTypesData = () => {
  return async (dispatch) => {
    const finGrp = DefaultConfig().finGrp;
    const authAxios = DefaultConfig().authAxios;
    dispatch({
      type: "EMP_ATT_LOADING_SPINNER",
      spinner: false,
      tokenExp: false,
    });
    await authAxios
      .get(`att/leaveTypesList/${finGrp}`)
      .then((response) => {
        const resData = response.data.value;
        if (resData) {
          dispatch({
            type: "LEAVE_TYPE",
            leaveTypes: resData,
          });
        }
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "EMP_ATT_LOADING_SPINNER",
              spinner: true,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "EMP_ATT_LOADING_SPINNER",
              spinner: true,
              tokenExp: false,
            });
            toast.error(
              <ToastMessage
                message={"att/leaveTypesList" + "  " + error}
                status={"danger"}
              />,
              {
                hideProgressBar: true,
              }
            );
          }
        }
      });
  };
};

export const getAttExemptionData = (empId) => {
  return async (dispatch, getState) => {
    const authAxios = DefaultConfig().authAxios;
    const params = getState().myattendance.params;
    dispatch({
      type: "EMP_ATT_LOADING_SPINNER",
      spinner: false,
      tokenExp: false,
    });
    await authAxios
      .get(`att/leaveExeList/${empId}`)
      .then((response) => {
        const resData = response.data
          .slice(response.data.length - 60, response.data.length)
          .reverse();
        if (resData) {
          const leaveTypes = getState().myattendance.leaveTypes;
          const emp = getState().navbar.groupEmpBasicInfo;
          if (leaveTypes) {
            resData.forEach((item, index) => {
              item["halfDayLeave"] = leaveTypes.find(
                (cc) => cc.ltRef === item.halfDayLeave
              )
                ? leaveTypes.find((cc) => cc.ltRef === item.halfDayLeave)
                    .leaveType
                : "";
              item["approvedBy"] = emp.find(
                (cc) => cc.empId === item.approvedBy
              )
                ? emp.find((cc) => cc.empId === item.approvedBy).name
                : "";
              item.serial = index + 1;
            });
          }
          const { perPage = DefaultSettings.perPage, page = 1 } = params;
          dispatch({
            type: "FETCH_ATTENDANCE_EXMP",
            allData: resData,
            data: paginateArray(resData, perPage, page),
            totalPages: resData.length,
            params,
            spinner: true,
          });
        }
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({ type: "ATT_LOADING_SPINNER", tokenExp: true });
          } else {
            const error = errorresponse.data.error;
            dispatch({ type: "ATT_LOADING_SPINNER", tokenExp: false });
            toast.error(
              <ToastMessage
                message={"att/leaveExeList" + "  " + error}
                status={"danger"}
              />,
              {
                hideProgressBar: true,
              }
            );
          }
        }
      });
  };
};

//employee attendance exemption data

export const getAttEmpData = (date, employeeId, biocode) => {
  return async (dispatch) => {
    const authAxios = DefaultConfig().authAxios;
    const finGrp = DefaultConfig().finGrp;
    const obj = {};
    obj["finGrp"] = finGrp;
    obj["empId"] = employeeId;
    obj["biocode"] = biocode;
    obj["date"] = date;

    dispatch({
      type: "EMP_ATT_LOADING_SPINNER",
      spinner: false,
      tokenExp: false,
    });
    await authAxios
      .post(`att/attExemp`, obj)
      .then((response) => {
        const resData = response.data;
        if (resData) {
          dispatch({
            type: "FETCH_EMP_ATT_DATA",
            data: resData,
            spinner: true,
          });
        }
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "EMP_ATT_LOADING_SPINNER",
              spinner: false,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "EMP_ATT_LOADING_SPINNER",
              spinner: false,
              tokenExp: false,
            });
            toast.error(
              <ToastMessage
                message={"att/attExemp" + "  " + error}
                status={"danger"}
              />,
              {
                hideProgressBar: true,
              }
            );
          }
        }
      });
  };
};

export const attExp = (data) => {
  return async (dispatch, getState) => {
    const authAxios = DefaultConfig().authAxios;
    dispatch({ type: "ATT_LOADING_SPINNER", tokenExp: false });
    await authAxios
      .post("att/leaveExemptionApproval", data)
      .then((response) => {
        const resData = response.data.value
          ? response.data.value
          : response.data;
        if (resData) {
          const allData = getState().myattendance.allData;
          const leaveTypes = getState().myattendance.leaveTypes;
          resData["halfDayLeave"] = leaveTypes.find(
            (item) => item.ltRef === resData.halfDayLeave
          )
            ? leaveTypes.find((item) => item.ltRef === resData.halfDayLeave)
                .leaveType
            : "";
          dispatch({
            type: "ADD_EXMP",
            //allData,
          });
          toast.success(
            <ToastMessage message={"Added Successfully"} status={"success"} />,
            { hideProgressBar: true }
          );
        }
      })
      .then(() => {
        dispatch(getData(getState().myattendance.params));
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({ type: "ATT_LOADING_SPINNER", tokenExp: true });
          } else {
            const error = errorresponse.data.error;
            dispatch({ type: "ATT_LOADING_SPINNER", tokenExp: false });
            toast.error(
              <ToastMessage
                message={"att/leaveExemptionApproval" + "  " + error}
                status={"danger"}
              />,
              {
                hideProgressBar: true,
              }
            );
          }
        }
      });
  };
};

// ** Get Leavetypes
export const getLeaveTypes = () => {
  return async (dispatch) => {
    const finGrp = DefaultConfig().finGrp;
    const authAxios = DefaultConfig().authAxios;
    dispatch({
      type: "EMP_ATT_LOADING_SPINNER",
      spinner: false,
      tokenExp: false,
    });
    await authAxios
      .get(`att/leaveTypesList/${finGrp}`)
      .then((response) => {
        const resData = response.data.value;
        // console.log(resData, "llllll");
        if (resData) {
          dispatch({
            type: "LEAVE_TYPE",
            leaveTypes: resData,
          });
        }
      })
      .then(() => {
        const empId = DefaultConfig().employeeId;
        dispatch(getAttExemptionData(empId));
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "EMP_ATT_LOADING_SPINNER",
              spinner: true,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "EMP_ATT_LOADING_SPINNER",
              spinner: true,
              tokenExp: false,
            });
            toast.error(
              <ToastMessage
                message={"att/leaveTypesList" + "  " + error}
                status={"danger"}
              />,
              {
                hideProgressBar: true,
              }
            );
          }
        }
      });
  };
};

// ** Fetch Events
export const fetchEvents = (calendars) => {
  return (dispatch) => {
    axios.get("/apps/calendar/events", { calendars }).then((response) => {
      dispatch({
        type: "FETCH_EVENTS",
        events: response.data,
      });
    });
  };
};

// ** Add Event
export const addEvent = (event) => {
  return (dispatch, getState) => {
    axios.post("/apps/calendar/add-event", { event }).then(() => {
      dispatch({
        type: "ADD_EVENT",
      });
      dispatch(fetchEvents(getState().calendar.selectedCalendars));
    });
  };
};

// ** Update Event
export const updateEvent = (event) => {
  return (dispatch) => {
    axios.post("/apps/calendar/update-event", { event }).then(() => {
      dispatch({
        type: "UPDATE_EVENT",
      });
    });
  };
};

// ** Filter Events
export const updateFilter = (filter) => {
  return (dispatch, getState) => {
    dispatch({
      type: "UPDATE_FILTERS",
      filter,
    });
  };
};

// ** Add/Remove All Filters
export const updateAllFilters = (value) => {
  return (dispatch, getState) => {
    dispatch({
      type: "UPDATE_ALL_FILTERS",
      value,
    });
  };
};

// ** remove Event
export const removeEvent = (id) => {
  return (dispatch) => {
    axios.delete("/apps/calendar/remove-event", { id }).then(() => {
      dispatch({
        type: "REMOVE_EVENT",
      });
    });
  };
};

// ** Select Event (get event data on click)
export const selectEvent = (event) => {
  return (dispatch) => {
    dispatch({
      type: "SELECT_EVENT",
      event,
    });
  };
};

export const getEmpWorkHours = () => {
  return async (dispatch) => {
    const authAxios = DefaultConfig().authAxios;
    const compId = DefaultConfig().companyId;
    dispatch({
      type: "EMP_WORK_ HOURS_LOADING_SPINNER",
      spinner: false,
      tokenExp: false,
    });
    await authAxios
      .get(`att/empWrkHrs/${compId}`)
      .then((response) => {
        const resData = response.data;
        if (resData) {
          dispatch({
            type: "EMP_WORK_HOURS_DATA",
            data: resData,
            spinner: false,
          });
        }
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "EMP_WORK_ HOURS_LOADING_SPINNER",
              spinner: false,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "EMP_WORK_ HOURS_LOADING_SPINNER",
              spinner: false,
              tokenExp: false,
            });
            toast.error(
              <ToastMessage
                message={"att/empWrkHrs" + "  " + error}
                status={"danger"}
              />,
              {
                hideProgressBar: true,
              }
            );
          }
        }
      });
  };
};

//get Dynamic Dates
export const getDynamicDates = () => {
  return async (dispatch) => {
    const finGrp = DefaultConfig().finGrp;
    console.log(finGrp, "Get finGrp");
    const authAxios = DefaultConfig().authAxios;
    dispatch({ type: "DYNAMIC_DATES_LOADING_SPINNER", spinner: false, tokenExp: false });
    await authAxios
      .get(`att/dynamicDates/${finGrp}`)
      .then((response) => {
        const resData = response.data.value;
        //const { perPage = 10, page = 1 } = params;
        if (resData) {
          console.log(resData, "Res Data")
            resData["stMonth"] = DefaultConfig().monthOptions.find(
              (cc) => cc.value === resData.stMonth
            )
              ? DefaultConfig().monthOptions.find(
                  (cc) => cc.value === resData.stMonth
                ).label
              : "";
              resData["endMonth"] = DefaultConfig().monthOptions.find(
                (cc) => cc.value === resData.endMonth
              )
                ? DefaultConfig().monthOptions.find(
                    (cc) => cc.value === resData.endMonth
                  ).label
                : "";
          dispatch({
            type: "GET_ALL_DYNAMIC_DATES_DATA",
            allData: resData,
            spinner: true,
          });
        }
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "DYNAMIC_DATES_LOADING_SPINNER",
              spinner: true,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "DYNAMIC_DATES_LOADING_SPINNER",
              spinner: true,
              tokenExp: false,
            });
            toast.error(
              <ToastMessage
                message={"att/dynamicDates" + "  " + error}
                status={"danger"}
              />,
              {
                hideProgressBar: true,
              }
            );
          }
        }
      });
  };
};


// ** Get Employee Attendance Exemption List
export const getEmpAttExemLimit = (empId, empDept, month, year) => {
  return async (dispatch) => {
    const authAxios = DefaultConfig().authAxios;
    const object = {
      empId: empId,
      empDept: empDept ? Number(empDept) : null,
      month: Number(month),
      year: Number(year)
    }
    dispatch({
      type: "GET_EMP_ATT_EXEM_LIMIT_LOADING_SPINNER",
      spinner: false,
      tokenExp: false,
    });
    await authAxios
      .post(`/att/attExmptionsUsed`, object)
      .then((response) => {
        const resData = response.data.value;
        if (resData) {
          const exemLimit = resData.key;
          const exemUsed = resData.value;
          dispatch({
            type: "GET_EMP_ATT_EXEM_LIMIT",
            exemLimit: exemLimit,
            exemUsed: exemUsed,
            spinner: true,
          });
        }
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "GET_EMP_ATT_EXEM_LIMIT_LOADING_SPINNER",
              spinner: true,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "GET_EMP_ATT_EXEM_LIMIT_LOADING_SPINNER",
              spinner: true,
              tokenExp: false,
            });
            toast.error(
              <ToastMessage
                message={"/att/attExmptionsUsed/" + "  " + error}
                status={"danger"}
              />,
              {
                hideProgressBar: true,
              }
            );
          }
        }
      });
  };
};