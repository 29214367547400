// ** Initial State
const initialState = {
    allData: [],
    data: [],
    spinner: false,
    tokenExp: false,
    total: 1,
    params: {},
  };
  
  const initialItem = (resData) => {
    if (resData) {
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
      });
      return resData;
    } else {
      return [];
    }
  };
  
  const FHExpReportReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_FINHEADWISEEXP_DATA":
        return {
          ...state,
          allData: initialItem(action.allData),
          data: action.data,
          total: action.totalPages,
          params: action.params,
          spinner: action.spinner,
        };
      case "EXP_FINHEADWISE_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      case "GET_FHWISEEXP_DATA":
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params,
        };
      default:
        return { ...state };
    }
  };
  export default FHExpReportReducer;