import { DefaultConfig } from "../../../../../../settings/config";

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
};

const initialItem = (resData) => {
  const Classes = DefaultConfig().Classes;
  const Departments = DefaultConfig().Departments;
  const MifareUHFOptions = DefaultConfig().MifareUHFOptions;
  const autoAbsents = DefaultConfig().autoAbsents;
  if (resData) {
    resData.forEach((item, index) => {
      item["clsName"] = Classes.find((cc) => cc.value === item.cls)
        ? Classes.find((cc) => cc.value === item.cls).label
        : "";
      item["deptName"] = Departments.find((cc) => cc.value === item.deptRef)
        ? Departments.find((cc) => cc.value === item.deptRef).label
        : "N/A";
      item["mifareName"] = MifareUHFOptions.find(
        (cc) => cc.value === item.mifare
      )
        ? MifareUHFOptions.find((cc) => cc.value === item.mifare).label
        : "";
      item["rfIdStatusName"] = autoAbsents.find(
        (cc) => cc.value === item.rfIdStatus
      )
        ? autoAbsents.find((cc) => cc.value === item.rfIdStatus).label
        : "";
      item.serial = index + 1;
    });

    return resData;
  } else {
    return [];
  }
};

const SmartCardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_SMART_CARDS_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_SMART_CARDS_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_SMART_CARDS":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };
    case "UPDATE_SMART_CARDS":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "SMART_CARDS_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default SmartCardsReducer;
