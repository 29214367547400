// ** Initial State
const initialState = {
    moveData: [],
    reJoinData: [],
    spinner: false,
    rspinner: false,
    tokenExp: false,
    deldata: {},
    delspinner: false
  };
  
  const StudentRemove = (state = initialState, action) => {
    switch (action.type) {
        case "STUDENT_MOVETOOLD":
          return {
            ...state,
            moveData: action.resData,
            tokenExp: action.tokenExp,
            spinner: action.spinner,
          };
      case "MOVE_TO_OLD_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp,
        };
        case "STUDENT_REJOIN":
          return {
            ...state,
            reJoinData: action.resData,
            tokenExp: action.tokenExp,
            rspinner: action.spinner,
          };
      case "REJOIN_LOADING_SPINNER":
        return {
          ...state,
          rspinner: action.spinner,
          tokenExp: action.tokenExp,
        };
        case "DEL_OTP_DATA":
          return { ...state,
            deldata: action.data,
            delspinner: action.spinner,
          }
          case "DEL_OTP_LOADING_SPINNER":
          return {
            ...state,
            delspinner: action.spinner,
          }
      default:
        return { ...state };
    }
  };
  export default StudentRemove;  