import { array } from "prop-types";
import { DefaultConfig } from "../../../../../settings/config";

// ** Initial State
const initialState = {
  allData: [],
  spinner: false,
  tokenExp: false,
  alSubjData: [],
  alSpinner: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};


const getColumns = (resData) => {
  var columns = []
  columns.push({
    name: "S.NO",
    minWidth: "0px",
    selector: "serial",
    sortable: true,
    showhide: true,
    cell: (row) => row.serial,
  }, {
    name: "Name",
    minWidth: "0px",
    selector: "name",
    sortable: true,
    showhide: true,
    cell: (row) => row.name,
  })
  if (resData.length > 0) {
    for (const key in resData[0].attMap) {
      columns.push({
        name: Number(key),
        minWidth: "0px",
        selector: 'att' + key,
        sortable: true,
        showhide: true,
        cell: (row) => row[resData[0]['attData']['att' + key]],
      })
    }
  }
  columns.push({
    name: "Present Count",
    minWidth: "0px",
    selector: "count",
    sortable: true,
    showhide: true,
    cell: (row) => row.count,
  })
  return columns
}

const HourlyAttendance = (state = initialState, action) => {
  switch (action.type) {
    case "GET_HOURLY_ATT_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "GET_HOURLY_ATT_DATA_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_ALLOTED_SUBJECTS":
      return {
        ...state,
        alSubjData: initialItem(action.resData),
        alSpinner: action.spinner,
      };
    case "GET_ALLOTED_SUBJECTS_LOADING_SPINNER":
      return {
        ...state,
        alSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default HourlyAttendance;
