import { DefaultConfig } from "../../../../../../settings/config";
const bankTypeOptions = DefaultConfig().bankTypeOptions;

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedBank: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      if (bankTypeOptions.find((find) => find.value === resp.cardType)) {
        resp.cardTypeName = bankTypeOptions.find(
          (find) => find.value === resp.cardType
        ).label;
      } else {
        resp.cardTypeName = "";
      }
    });
    return resData;
  } else {
    return [];
  }
};

const banks = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_BANK_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_BANK_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_BANK":
      return {
        ...state,
        selectedBank: action.selectedBank,
        actionTitle: action.actionTitle,
      };
    case "ADD_BANK":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "UPDATE_BANK":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "DELETE_BANK":
      return { ...state };
    case "BANK_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default banks;
