import { Fragment } from "react"
import Breadcrumbs from "../../../../breadcrum"
// ** FeeHeads List Component
import Table from "./Table"

// ** Styles
import "@styles/react/apps/app-users.scss"

const FeeHeads = () => {
  return (
    <Fragment>
      <Breadcrumbs
        breadCrumbParent="Finance"
        breadCrumbChild="Finance Settings"
        breadCrumbTitle="Fee Heads"
      />
      <div className="app-user-list">
        <Table />
      </div>
    </Fragment>
  )
}

export default FeeHeads
