import { toast } from "react-toastify";
import ToastMessage from "../../../../../../@core/components/toast-messages";
import { DefaultConfig } from "../../../../../../settings/config";
import { paginateArray } from "../../../../../../@fake-db/utils";

// ** Get exams data
export const getExamData = (fdata) => {
  return async (dispatch) => {
    const authAxios = DefaultConfig().authAxios;
    const companyId = DefaultConfig().companyId;
    const acadGrp = DefaultConfig().acadGrp;
    dispatch({
      type: "RES_ENT_LOADING_SPINNER",
      espinner: true,
      sspinner: false,
      loading: false,
      tokenExp: false,
    });
    const objData = {};
    objData["branchId"] = fdata.branch ? fdata.branch : DefaultConfig().companyId;
    objData["cls"] = fdata.class ? fdata.class : 0;
    // objData["sec"] = fdata.section ? [fdata.section] : ["0"];
    // Process fdata.sec
    if (Array.isArray(fdata.section)) {
      // If fdata.sec is an array, flatten it and ensure it's a list of integers
      objData["sec"] = fdata.section;
    } else {
      // If fdata.sec is not an array, wrap it in a list
      objData["sec"] = [fdata.section];
    }
    // objData["syllabusRef"] = fdata.syllabus ? fdata.syllabus : 0;
    objData["dept"] = fdata.department ? fdata.department : 0;
    objData["acadYear"] = fdata.academic ? fdata.academic : 0;
    objData["acadGrp"] = acadGrp;
    const finGrp = DefaultConfig().finGrp;
    objData["finGrp"] = finGrp;
    await authAxios
      .post(`academic/examList`, objData)
      .then((response) => {
        const resData = response.data;
        if (resData) {
          const value = resData.value;
          const mapData = value.map((exam) => ({
            value: exam.examRef,
            label: exam.exam,
            acr: exam.acr
          }));
          // mapData.unshift({ value: 0, label: 'All' })
          dispatch({
            type: "GET_EXAM_DATA",
            data: mapData,
            spinner: false,
          });
        }
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "RES_ENT_LOADING_SPINNER",
              espinner: false,
              sspinner: false,
              loading: false,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "RES_ENT_LOADING_SPINNER",
              espinner: false,
              sspinner: false,
              loading: false,
              tokenExp: false,
            });
            toast.error(
              <ToastMessage
                message={"academic/examList" + "  " + error}
                status={"danger"}
              />,
              { hideProgressBar: true }
            );
          }
        }
      });
  };
};

// ** Get Progress Data
export const getResultData = (fdata) => {
  return async (dispatch) => {
    const authAxios = DefaultConfig().authAxios;
    const companyId = DefaultConfig().companyId;
    const finGrp = DefaultConfig().finGrp;
    const branchIdList = fdata.branchId;
    const empId = DefaultConfig().employeeId;
    dispatch({
      type: "RES_ENT_LOADING_SPINNER",
      espinner: false,
      sspinner: true,
      loading: true,
      tokenExp: false,
    });
    const objData = {};
    objData["cls"] = fdata.class ? fdata.class : 0;
    objData["sec"] = fdata.section ? fdata.section.map(Number) : [0];
    objData["year"] = fdata.academic ? fdata.academic : 0;
    objData["syllabusRef"] = fdata.syllabus ? fdata.syllabus : 0;
    objData["yearCode"] = fdata.yearCode ? fdata.yearCode : "";
    objData["exam"] = fdata.examRef ? fdata.examRef : 0;
    objData["dept"] = fdata.department ? fdata.department : 0;
    objData["branchList"] = branchIdList;
    objData["finGrp"] = finGrp;
    objData["isAllSubject"] = fdata.subjType;
    objData["orderBy"] = fdata.orderBy === "perc" ? "name" : fdata.orderBy ? fdata.orderBy : 'name';
    objData["empId"] = empId;

    // objData["cls"] = 6398
    // objData["sec"] = 3073
    // objData["year"] = 404
    // objData["yearCode"] = '2022-2023'
    // objData["exam"] = 99557
    // objData["dept"] = 0
    // objData["branchId"] = "GVTEMS0709"
    // objData["compId"] = "GVTEMS0709"
    // objData["finGrp"] = "GAITVIEWGR"
    // objData["isAllSubject"] = true

    await authAxios
      .post(`academic/resultEntry`, objData)
      .then((response) => {
        const resData = response.data;
        if (resData) {
          const value = resData.value;
          dispatch({
            type: "GET_RES_ENT_DATA",
            data: value,
            percSort: fdata.orderBy,
            spinner: false,
          });
        }
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "RES_ENT_LOADING_SPINNER",
              espinner: false,
              sspinner: false,
              loading: false,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "RES_ENT_LOADING_SPINNER",
              espinner: false,
              sspinner: false,
              loading: false,
              tokenExp: false,
            });
            toast.error(
              <ToastMessage
                message={"academic/resultEntry" + "  " + error}
                status={"danger"}
              />,
              { hideProgressBar: true }
            );
          }
        }
      });
  };
};

export const getAllResultEntData = (params) => {
  return async (dispatch, getState) => {
    const { perPage = 10, page = 1 } = params;
    const resData = getState().resultentry.resultData;
    const resultEntries = resData.resultEntries;
    resultEntries.forEach((element, index) => {
      element.conCheck = false
    })
    dispatch({
      type: "GET_RES_ENT_ALL_DATA",
      allData: resultEntries,
      data: paginateArray(resultEntries, perPage, page),
      totalPages: resultEntries.length,
      params,
      spinner: true,
    });
  };
};

export const getInitialState = (params) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_INIT",
      loading: false,
    });
  };
};

export const getData = (params) => {
  return (dispatch, getState) => {
    const {
      q = "",
      perPage = 10,
      page = 1,
      sortname = "",
      sortorder = "",
    } = params;
    const resData = getState().resultentry.allData;
    if (resData) {
      let filteredData = [];
      const queryLowered = q.toLowerCase();
      filteredData = resData.filter(
        (item) =>
          (item.regNo && item.regNo.toLowerCase().includes(queryLowered)) ||
          (item.name && item.name.toLowerCase().includes(queryLowered)) ||
          (item.village && item.village.toLowerCase().includes(queryLowered)) ||
          (item.rollNo && item.rollNo.toLowerCase().includes(queryLowered)) ||
          (item.branchName && item.branchName.toLowerCase().includes(queryLowered))
      );
      const sorting = DefaultConfig().sorter;
      const sortData = []
      if(sortorder){
        if(sortname === "percent"){
          if(sortorder === 'asc'){
            const sData = filteredData.sort((a, b) => b.percent - a.percent);
            sortData.push(...sData)
          } else {
            const sData = filteredData.sort((a, b) => a.percent - b.percent);
            sortData.push(...sData)
          }
        } else{
          sortData.push(...sorting(sortname, sortorder, filteredData))
        }
      } else {
        sortData.push(...filteredData)
      }
      // const sortData = sortorder
      //   ? sorting(sortname, sortorder, filteredData)
      //   : filteredData;
      dispatch({
        type: "GET_RES_FIL_DATA",
        data: paginateArray(sortData, perPage, page),
        totalPages: sortData.length,
        params,
      });
    }
  };
};

export const getSortData = (params) => {
  return (dispatch, getState) => {
    const {
      q = "",
      perPage = 10,
      page = 1,
      sortname = "",
      sortorder = "",
    } = params;
    const resData = getState().resultentry.allData;
    if (resData) {
      let filteredData = [];
      const queryLowered = q.toLowerCase();
      filteredData = resData.filter(
        (item) =>
          (item.regNo && item.regNo.toLowerCase().includes(queryLowered)) ||
          (item.name && item.name.toLowerCase().includes(queryLowered)) ||
          (item.village && item.village.toLowerCase().includes(queryLowered)) ||
          (item.rollNo && item.rollNo.toLowerCase().includes(queryLowered))
      );
      const sorting = DefaultConfig().sorter;
      const sortData = []
      if(sortorder){
        if(sortname === "percent"){
          if(sortorder === 'asc'){
            const sData = filteredData.sort((a, b) => b.percent - a.percent);
            sortData.push(...sData)
          } else {
            const sData = filteredData.sort((a, b) => a.percent - b.percent);
            sortData.push(...sData)
          }
        } else{
          console.log(sortname, sortorder)
          sortData.push(...sorting(sortname, sortorder, filteredData))
        }
      } else {
        sortData.push(...filteredData)
      }
      dispatch({
        type: "GET_SORT_RES_FIL_DATA",
        allData: sortData,
        data: paginateArray(sortData, perPage, page),
        totalPages: sortData.length,
        params,
      });
    }
  };
};

export const resultExcelSave = (obj) => {
  return async (dispatch) => {
    const authAxios = DefaultConfig().authAxios;
    const finGrp = DefaultConfig().finGrp;
    await authAxios
      .post(`academic/resultSave/${finGrp}`, obj)
      .then((response) => {
        const resltData = response.data;
        if (resltData) {
          if (resltData.error) {
            toast.error(
              <ToastMessage
                message={"academic/resultSave"}
                status={"danger"}
              />,
              { hideProgressBar: true }
            );
          } else {
            const value = resltData.value;
            if (value) {
              toast.success(
                <ToastMessage
                  message={"Updated Successfully"}
                  status={"success"}
                />,
                { hideProgressBar: true }
              );
            }
          }
        }
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "RES_SAVE_LOADING_SPINNER",
              rspinner: false,
              tokenExp: true,
              stuRef: "",
              subRef: "",
              ltype: "",
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "RES_SAVE_LOADING_SPINNER",
              rspinner: false,
              tokenExp: false,
              stuRef: "",
              subRef: "",
              ltype: "",
            });
            toast.error(
              <ToastMessage
                message={"academic/resultSave" + "  " + error}
                status={"danger"}
              />,
              { hideProgressBar: true }
            );
          }
        }
      });
  };
};

export const getResultSave = (obj, params, type) => {
  return async (dispatch, getState) => {
    const authAxios = DefaultConfig().authAxios;
    const finGrp = DefaultConfig().finGrp;
    dispatch({
      type: "RES_SAVE_LOADING_SPINNER",
      rspinner: false,
      tokenExp: false,
      stuRef: obj.stuRef,
      subRef: obj.subRef,
      indexId: obj.indexId,
      cosId: obj.cosId,
      group: obj.group,
      ltype: type,
    });
    await authAxios
      .post(`academic/resultSave/${finGrp}`, [obj])
      .then((response) => {
        const resltData = response.data;
        if (resltData) {
          const value = resltData.value ? resltData.value[0] : null;
          if (value) {
            const { q = "", perPage = 10, page = 1 } = params;
            const resultsId = value.resultsId;
            const eMarks = value.examMarks;
            const resData = getState().resultentry.allData;
            const findIndex = resData.findIndex(
              (find) => find.sno === resultsId.stuRef
            );
            if (findIndex >= 0) {
              if (resData[findIndex]["examMarks"]) {
                resData[findIndex]["examMarks"][resultsId.subRef] =
                  value.examMarks;
                  resData[findIndex]["examMarks"][resultsId.subRef + 'c'] = resData[findIndex]["examMarks"][resultsId.subRef] ? (((resData[findIndex]["examMarks"][resultsId.subRef] >= 0) || (resData[findIndex]["examMarks"][resultsId.subRef] === "AB" || "Ab" || "Absent")) ? true : false) : ""
                  resData[findIndex]["conCheck"] = true
              }
              if (resData[findIndex]["assMarks"]) {
                resData[findIndex]["assMarks"][resultsId.subRef] =
                  value.assnMarks;
                  resData[findIndex]["assMarks"][resultsId.subRef + 'd'] = resData[findIndex]["assMarks"][resultsId.subRef] ? (((resData[findIndex]["assMarks"][resultsId.subRef] >= 0) || (resData[findIndex]["assMarks"][resultsId.subRef] === "AB" || "Ab" || "Absent")) ? true : false) : ""
                  resData[findIndex]["conCheck"] = true
              }
            }

            let filteredData = [];
            const queryLowered = q.toLowerCase();
            filteredData = resData.filter(
              (item) =>
                (item.regNo &&
                  item.regNo.toLowerCase().includes(queryLowered)) ||
                (item.name && item.name.toLowerCase().includes(queryLowered))
            );
            dispatch({
              type: "GET_RES_ENT_SAVE_DATA",
              allData: resData,
              data: paginateArray(filteredData, perPage, page),
              totalPages: filteredData.length,
              eMarks: eMarks,
              params,
              csoData: value,
              rspinner: true,
            });
            // toast.success(
            //   <ToastMessage
            //     message={"Updated Successfully"}
            //     status={"success"}
            //   />,
            //   { hideProgressBar: true }
            // );
          }
        }
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "RES_SAVE_LOADING_SPINNER",
              rspinner: false,
              tokenExp: true,
              stuRef: "",
              subRef: "",
              ltype: "",
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "RES_SAVE_LOADING_SPINNER",
              rspinner: false,
              tokenExp: false,
              stuRef: "",
              subRef: "",
              ltype: "",
            });
            toast.error(
              <ToastMessage
                message={"academic/resultSave" + "  " + error}
                status={"danger"}
              />,
              { hideProgressBar: true }
            );
          }
        }
      });
  };
};

export const getResultUpdate = (resData, params) => {
  return async (dispatch) => {
    const { q = "", perPage = 10, page = 1 } = params;
    let filteredData = [];
    const queryLowered = q.toLowerCase();
    filteredData = resData.filter(
      (item) =>
        (item.regNo && item.regNo.toLowerCase().includes(queryLowered)) ||
        (item.name && item.name.toLowerCase().includes(queryLowered))
    );
    dispatch({
      type: "GET_RES_ENT_UPDATE_DATA",
      allData: resData,
      data: paginateArray(filteredData, perPage, page),
      totalPages: filteredData.length,
      params,
    });
  };
};
