// ** Initial State
const initialState = {
  allData: [],
  spinner: false,
  tokenExp: false,
  updData: [],
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const CompProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_COMPANY_DATA":
      return {
        ...state,
        allData: action.allData,
        spinner: action.spinner,
      };
    case "COMP_DETAILS_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "UPDATE_COMPANY_DATA":
      return {
        ...state,
        allData: action.updData,
        spinner: action.spinner,
      };
    default:
      return { ...state };
  }
};
export default CompProfileReducer;
