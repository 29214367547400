import { DefaultConfig } from "../../../../../../settings/config";
// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
  updData: "",
  smsUsageData: "",
  uspinner: false,
};

const initialItem = (resData) => {
  if (resData) {
    // console.log(resData, "reducer")
    const AcademicYear = DefaultConfig().AcademicYear;
    resData.forEach((resp, index) => {
      resp["joinYear"] = AcademicYear.find(
        (item) => item.value === resp.joinYear
      )
        ? AcademicYear.find((item) => item.value === resp.joinYear).label
        : "";
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const CompanyList = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_COM_LIST_DATA":
      //  console.log(action, "allData")
      return {
        ...state,
        allData: initialItem(action.allData),
        //data: action.mapData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_COM_LIST_DATA":
      // console.log(action.data, "data")
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "COM_LIST_LOADING_SPINNER":
      // console.log(action.spinner, "ree")
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    // case "CLCTMODE_LOADING_SPINNER":
    //   return {
    //     ...state,
    //     uspinner: action.spinner,
    //     tokenExp: action.tokenExp,
    //   };
    case "UPDATE_COLLECT_MODE":
      return {
        ...state,
        updData: action.updData,
        spinner: action.spinner,
      };
    case "SMS_USAGE_REPORT_LOADING_SPINNER":
      return {
        ...state,
        uspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_SMS_USAGE_REPORT_DATA":
      return {
        ...state,
        smsUsageData: action.smsUsageData,
        uspinner: action.spinner,
      }
    default:
      return { ...state };
  }
};
export default CompanyList;
