// ** Initial State
const initialState = {
  allData: [],
  EmpListData: [],
  data: [],
  total: 1,
  params: {},
  selectedSms: null,
  //actionTitle: "",
  spinner: false,
  tokenExp: false,

  cspinner: false,
  activeComData: [],

  selectedEvent: null,
  actionTitle: '',

  eventListData: [],
  espinner: false,

  formData: null,

  accountTypes: [],
  aspinner: false,
  subaccountTypes: [],
  sspinner: false,
  innersubaccountTypes: [],
  ispinner: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const EmpList = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_EMP_LIST_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner
      };
    case "GET_EMP_LIST_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    //   case "GET_SMS":
    //     return {
    //       ...state,
    //       selectedSms: action.selectedSms,
    //       actionTitle: action.actionTitle
    //     }
    case "EMP_LIST_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
      case "COM_LOADING_SPINNER":
      return {
        ...state,
        cspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
      case "COM_LOADING_SPINNER":
        return {
          ...state,
          espinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      case "GET_EVENT_DATA":
      return {
        ...state,
        selectedEvent: action.selectedEvent,
        actionTitle: action.actionTitle
      }
      case "EVENT_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp
        }
      case "ADD_EVENT":
        return {
          ...state,
          spinner: action.spinner
        }
        case "UPDATE_EVENT":
        return {
          ...state,
          allData: initialItem(action.allData),
          spinner: action.spinner
        }
        case "GET_BILL_ENTY_ACCOUNT_DATA":
      return {
        ...state,
        accountTypes: action.data,
        aspinner: action.spinner,
      };
    case "GET_BILL_ENTY_SUB_ACCOUNT_DATA":
      return {
        ...state,
        subaccountTypes: action.data,
        sspinner: action.spinner,
      };
    case "GET_BILL_ENTY_INN_SUB_ACCOUNT_DATA":
      return {
        ...state,
        innersubaccountTypes: action.data,
        ispinner: action.spinner,
      };
        case "BILL_ENTY_SUB_LOADING_SPINNER":
          return {
            ...state,
            sspinner: action.spinner,
            tokenExp: action.tokenExp,
          };
        case "BILL_ENTY_INN_SUB_LOADING_SPINNER":
          return {
            ...state,
            ispinner: action.spinner,
            tokenExp: action.tokenExp,
          };
        case "BILL_ENTY_LOADING_SPINNER":
          return {
            ...state,
            aspinner: action.spinner,
            tokenExp: action.tokenExp,
          };
    default:
      return { ...state };
  }
};
export default EmpList;
