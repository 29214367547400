import { Fragment } from "react";
import { User } from "react-feather";
import { getSyllabus } from '../action'
import { store } from '@store/storeConfig/store'
import { Badge, Button, UncontrolledTooltip } from "reactstrap";
import { paginateArray } from "../../../../../@fake-db/utils";
import { DefaultConfig } from "../../../../../settings/config";
import TooltipItem from "../../TooltipItem";
import DiaryTooltipItem from "../../DiaryTooltip";
import handIcon from '@styles/birdsong/images/icons/handIcon.svg'
const DateFormat = DefaultConfig().DateFormat;
const TimeFormat = DefaultConfig().TimeFormat;

// ** Initial State
const initialState = {
  spinner: false,
  tokenExp: false,
  recData: [],
  sspinner: false,
  subStsData: [],

  allData: [],
  data: [],
  total: 1,
  params: {},
  sylStsData: [],
  columns: [],
  allcolumns: [],
  loader: false,

  dwsallData: [],
  dwsdata: [],
  dwstotal: 1,
  dwsparams: {},

  dwsSpinner: false,

  complData: [],
  complSpinner: false,

  sylObj: {},
  model: false
};

const columnsData = (data) => {
  const DateFormatMonth = DefaultConfig().DateFormatMonth;
  const columns = [
    {
      name: "S.NO",
      minWidth: "0px",
      maxWidth: "50px",
      selector: "serial",
      center: true,
      sortable: true,
      showhide: true,
      cell: (row) => (
        <div className="" style={{ padding: "0.5rem" }}>
          <div className="text-center">{row.serial}</div>
        </div>
      ),
    },
    {
      name: "Branch",
      minWidth: "0px",
      width: "100px",
      selector: "compId",
      center: true,
      sortable: true,
      showhide: true,
      cell: (row) => (
        <div className="" style={{ padding: "0.5rem" }}>
          <div className="text-center">{row.compId} <br />
            {row.exam == true ? (
              <Badge color='primary'>
                Holiday
              </Badge>
            ) : (
              "-"
            )}</div>
        </div>
      ),
    },
    {
      name: "Class",
      minWidth: "0px",
      width: "100px",
      selector: "cls",
      center: true,
      sortable: true,
      showhide: true,
      cell: (row) => (
        <div className="" style={{ padding: "0.5rem" }}>
          <div className="text-center">{row.cls} <br />
            {row.exam == true ? (
              <Badge color='primary'>
                Exam
              </Badge>
            ) : (
              "-"
            )}
          </div>
        </div>
      ),
    },
  ];
  if (data.subjectsResponseList) {
    const subjectsResponseList = data.subjectsResponseList;
    subjectsResponseList.forEach((element, index) => {
      columns.push({
        name: element.subjectName,
        minWidth: "0px",
        // width: "100px",
        selector: element.subjectName,
        center: true,
        sortable: true,
        showhide: true,
        cell: (row) => (

          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              background: row[element.subjectName + "DT"].colorCode ? row[element.subjectName + "DT"].colorCode : "",
              color: row[element.subjectName + "DT"].colorCode ? "#ffffff" : "",
              padding: "0.5rem",
            }}
          >
            <div className="text-center">
              {/* <span className="font-weight-bold float-left">{row[element.subjectName + 'DT'].delay}</span> */}
              {row[element.subjectName] && (

                <Fragment>
                  <div className="mt-1">
                    {/* <h6 className="mb-25 font-small-2 text-white">H/W : 9th Sep</h6> */}
                    <DiaryTooltipItem diaryDate={`${row[element.subjectName + "DT"].diaryDate ? DateFormatMonth(row[element.subjectName + "DT"].diaryDate) : "--"}`} diaryNotes={`${row[element.subjectName + "DT"].diaryNotes ? row[element.subjectName + "DT"].diaryNotes : "Not Updated"}`} subjRef={element.subjRef} indexValue={index} />
                    <hr className="my-0" />
                    <span onClick={() => handleSubj(row, row[element.subjectName + "DT"])} style={{ cursor: "pointer" }}>
                    <span className="font-weight-bold">
                      {row[element.subjectName]} <img src={handIcon} style={{width: "15px"}}/>
                    </span>
                    <br />
                    <span
                      className="font-weight-bold"
                      style={{ fontSize: "0.8rem" }}
                    >
                      {row[element.subjectName + "DT"].updTime ? DateFormatMonth(row[element.subjectName + "DT"].implDate).slice(0, 6) : ""}
                      &nbsp;-&nbsp;
                      {row[element.subjectName + "DT"].endDate ? DateFormatMonth(row[element.subjectName + "DT"].endDate).slice(0, 6) : ""}
                    </span>
                    <br />
                    <span
                      className="font-weight-bold"
                      style={{ fontSize: "0.8rem" }}
                    >
                      Upd. @ {row[element.subjectName + "DT"].updTime ? DateFormatMonth(row[element.subjectName + "DT"].updTime).slice(0, 6) : ""} <br />
                      {row[element.subjectName + "DT"].delay ? (row[element.subjectName + "DT"].delay > 0 ? "Delay : " : "Advance : ") + (row[element.subjectName + "DT"].delay === 9999 ? "-" : row[element.subjectName + "DT"].delay) : "0 Days"}
                    </span>
                    </span>
                  </div>
                  <TooltipItem subjTeacher={`${row[element.subjectName + "DT"].subjTeacherName} @ ${row[element.subjectName + "DT"].mobNo}`} subjRef={element.subjRef} indexValue={index} />
                </Fragment>
              )}
            </div>
          </div>
        ),
      });
    });
  }
  return columns;
};

const handleSubj = (item, subjData) => {
  // console.log(item, subjData, "Subject Schedule")
  const obj = {};
  obj["branch"] = item.compnyId;
  obj["academicYear"] = subjData.acadYear;
  obj["cls"] = item.clsId;
  obj["dept"] = subjData.dept ? subjData.dept : 0;
  obj["sec"] = subjData.sec ? subjData.sec : 0;
  obj["subject"] = subjData.subjRef;
  obj["syllabusRef"] = subjData.syllabusRef;
  obj["acadGrp"] = DefaultConfig().acadGrp;
  obj["finGrp"] = DefaultConfig().finGrp;
  
  store.dispatch(getSyllabus(obj, true))
}

const totalData = (data) => {
  const totalList = [];
  if (data.subjectsResponseList && data.syllabusStatus) {
    const subjectsResponseList = data.subjectsResponseList;
    const syllabusStatus = data.syllabusStatus;
    const Classes = DefaultConfig().Classes;
    const Branches = DefaultConfig().Branches;
    syllabusStatus.forEach((element, index) => {
      const totalObj = {};
      totalObj["serial"] = index + 1;
      totalObj["compnyId"] = element.compId;
      totalObj["clsId"] = element.cls;
      totalObj["compId"] = Branches.find((find) => find.value == element.compId)
        ? Branches.find((find) => find.value == element.compId).branchName
        : "";
      totalObj["cls"] = Classes.find((find) => find.value == element.cls)
        ? Classes.find((find) => find.value == element.cls).label
        : "";
      const statusBySubj = element.statusBySubj;
      subjectsResponseList.forEach((ele) => {
        const findSubj = statusBySubj.find((find) => find.subjRef === ele.subjRef);
        if (findSubj) {
          totalObj[ele.subjectName] = findSubj.topicCompleted + " / " + findSubj.topicsCount;
          totalObj[ele.subjectName + "DT"] = {};
          totalObj[ele.subjectName + "DT"]["implDate"] = findSubj.implDate;
          totalObj[ele.subjectName + "DT"]["updTime"] = findSubj.updTime;
          totalObj[ele.subjectName + "DT"]["endDate"] = findSubj.endDate;
          totalObj[ele.subjectName + "DT"]["subjTeacherName"] = findSubj.subjTeacherName;
          totalObj[ele.subjectName + "DT"]["mobNo"] = findSubj.mobNo;
          totalObj[ele.subjectName + "DT"]["delay"] = findSubj.delay;
          totalObj[ele.subjectName + "DT"]["subjRef"] = findSubj.subjRef;
          totalObj[ele.subjectName + "DT"]["syllabusRef"] = findSubj.syllabusRef;
          totalObj[ele.subjectName + "DT"]["diaryDate"] = findSubj.diaryDate;
          totalObj[ele.subjectName + "DT"]["diaryNotes"] = findSubj.diaryNotes;

          totalObj[ele.subjectName + "DT"]["acadYear"] = findSubj.acadYear;
          totalObj[ele.subjectName + "DT"]["subjRef"] = findSubj.subjRef;
          totalObj[ele.subjectName + "DT"]["sec"] = findSubj.sec;
          totalObj[ele.subjectName + "DT"]["dept"] = findSubj.dept;

          if (findSubj.delay || findSubj.delay === 0) {
            if (findSubj.delay < -5 && (findSubj.topicCompleted != findSubj.topicsCount)) {
              totalObj[ele.subjectName + "DT"]["colorCode"] = "#000bed";
            } else if (findSubj.delay < 1 || (findSubj.topicCompleted == findSubj.topicsCount)) {
              totalObj[ele.subjectName + "DT"]["colorCode"] = "#03821b";
            } else if (findSubj.delay >= 1 && findSubj.delay < 5) {
              totalObj[ele.subjectName + "DT"]["colorCode"] = "#cf7606";
            } else if (findSubj.delay >= 5 && findSubj.delay < 10) {
              totalObj[ele.subjectName + "DT"]["colorCode"] = "#bea91e";
            } else if (findSubj.delay >= 10) {
              totalObj[ele.subjectName + "DT"]["colorCode"] = "#ff0808";
            } 
          }
        } else {
          totalObj[ele.subjectName] = "";
          totalObj[ele.subjectName + "DT"] = {};
          totalObj[ele.subjectName + "DT"]["implDate"] = "";
          totalObj[ele.subjectName + "DT"]["updTime"] = "";
          totalObj[ele.subjectName + "DT"]["endDate"] = "";
          totalObj[ele.subjectName + "DT"]["delay"] = "";
          totalObj[ele.subjectName + "DT"]["subjTeacherName"] = "";
          totalObj[ele.subjectName + "DT"]["mobNo"] = "";
          totalObj[ele.subjectName + "DT"]["colorCode"] = "";
          totalObj[ele.subjectName + "DT"]["subjRef"] = "";
          totalObj[ele.subjectName + "DT"]["syllabusRef"] = "";
          totalObj[ele.subjectName + "DT"]["diaryDate"] = "";
          totalObj[ele.subjectName + "DT"]["diaryNotes"] = "";

          totalObj[ele.subjectName + "DT"]["acadYear"] = "";
          totalObj[ele.subjectName + "DT"]["subjRef"] = "";
          totalObj[ele.subjectName + "DT"]["sec"] = "";
          totalObj[ele.subjectName + "DT"]["dept"] = "";
        }
      });
      totalList.push(totalObj);
    });
  }
  return totalList;
};

const AcademicDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SYLLABUS_STATUS_DATA":
      const { perPage = 10, page = 1 } = action.params;
      return {
        ...state,
        columns: columnsData(action.data),
        allcolumns: columnsData(action.data),
        sylStsData: totalData(action.data),
        allData: totalData(action.data),
        data: paginateArray(totalData(action.data), perPage, page),
        total: totalData(action.data).length,
        loader: action.loader,
        params: action.params,
      };
    case "GET_SYLLABUS_STATUS_LOADING_SPINNER":
      return {
        ...state,
        tispinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_SYLL_DATA":
      return {
        ...state,
        sylObj: action.data,
        model: action.loader
      };
    case "GET_BR_REP_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_FIL_REP_DATA":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        columns: action.columns,
      };
    case 'DAY_WISE_SCHEDULE_DATA':
      return {
        ...state,
        dwsallData: action.allData,
        dwsdata: action.allData,
      };
    case "DAY_WISE_SCHEDULE_LOADING_SPINNER":
      return {
        ...state,
        dwsSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "DAY_WISE_SCHEDULE_COMPLETE":
      return {
        ...state,
        dwsallData: action.allData,
        dwsdata: action.allData,
        dwsspinner: action.spinner
      };
    case "DAY_WISE_SCHEDULE_COMPLETE_SPINNER":
      return {
        ...state,
        complSpinner: action.spinner,
        complTokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default AcademicDashboardReducer;
