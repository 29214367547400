import { DefaultConfig } from "../../../../../settings/config";
const AcademicYear = DefaultConfig().AcademicYear;
const Subjects = DefaultConfig().Subjects;
const NaipunyaBatches = DefaultConfig().NaipunyaBatches;
const SubBranchList = DefaultConfig().SubBranchList

// ** Initial State
const initialState = {
    allData: [],
    stuData: [],
    data: [],
    total: 1,
    params: {},
    selectedNaipunya: null,
    actionTitle: "",
    spinner: false,
    tokenExp: false
  }
  

  const initialItem = (resData) => {
    if (resData) {
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
        resp["branchName"] = SubBranchList.find((item) => item.compId === resp.branchId) ? SubBranchList.find((item) => item.compId === resp.branchId).branchName : "";
        resp["subjName"] = Subjects.find((item) => item.value === resp.subj) ? Subjects.find((item) => item.value === resp.subj).label : "";
        resp["acadYearLable"] = AcademicYear.find((item) => item.value === resp.acadYear) ? AcademicYear.find((item) => item.value === resp.acadYear).label : "";
        resp["batchName"] = NaipunyaBatches.find((item) => item.value === resp.batch) ? NaipunyaBatches.find((item) => item.value === resp.batch).label : "";
      });
      return resData;
    } else {
      return [];
    }
  };
  
  const NaipunyaAlloc = (state = initialState, action) => {
    switch (action.type) {
      case "GET_ALL_NAIPUNYA_DATA":
        return {
          ...state,
          allData: initialItem(action.allData),
          data: action.data,
          total: action.totalPages,
          params: action.params,
          spinner: action.spinner
        }
      case "GET_NAIPUNYA_DATA":
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      case "GET_NAIPUNYA":
        return {
          ...state,
          selectedNaipunya: action.selectedNaipunya,
          actionTitle: action.actionTitle
        }
      case "ADD_NAIPUNYA":
        return {
          ...state,
          allData: initialItem(action.allData),
          spinner: action.spinner
        }
      case "UPDATE_NAIPUNYA":
        return {
          ...state,
          allData: initialItem(action.allData),
          spinner: action.spinner
        }
        case "DELETE_NAIPUNYA":
          return { ...state,
            allData: initialItem(action.allData),
            spinner: action.spinner,
          }
      case "NAIPUNYA_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp
        }
      default:
        return { ...state }
    }
  }
  export default NaipunyaAlloc
  