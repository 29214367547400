// ** Initial State
const initialState = {
    allData: [],
    vehData: [],
    data: [],
    total: 1,
    params: {},
    selectedData: null,
    actionTitle: "",
    spinner: false,
    vspinner: false,
    tokenExp: false,
  };
  
  const initialItem = (resData) => {
    if (resData) {
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
      });
      return resData;
    } else {
      return [];
    }
  };
  
  const VehicleExpences = (state = initialState, action) => {
    switch (action.type) {
      case "GET_ALL_VEHICLE_LIST_DATA":
        //console.log(action);
        return {
          ...state,
          vehData: action.allData,
          vspinner: action.spinner,
        };
      case "VEHICLE_LIST_LOADING_SPINNER":
        return {
          ...state,
          vspinner: action.spinner,
          tokenExp: action.tokenExp,
        };
  
      case "GET_ALL_VECH_EXPNS_LIST_DATA":
        return {
          ...state,
          allData: initialItem(action.allData),
          data: action.data,
          total: action.totalPages,
          params: action.params,
          spinner: action.spinner,
        };
      case "GET_VECH_EXPNS_LIST_DATA":
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params,
        };
      case "GET_VECH_EXPNS_LIST":
        return {
          ...state,
          selectedData: action.selectedData,
          actionTitle: action.actionTitle,
        };
      // case "ADD_READING_LIST":
      //   return {
      //     ...state,
      //     allData: initialItem(action.allData),
      //     spinner: action.spinner,
      //   };
      // case "UPDATE_READING_LIST":
      //   return {
      //     ...state,
      //     allData: initialItem(action.allData),
      //     spinner: action.spinner,
      //   };
      // case "DELETE_READING_LIST":
      //   return {
      //     ...state,
      //     allData: initialItem(action.allData),
      //     spinner: action.spinner,
      //   };
      case "VECH_EXPNS_LIST_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      default:
        return { ...state };
    }
  };
  export default VehicleExpences;
  