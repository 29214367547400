import { toast } from "react-toastify";
import ToastMessage from "../../../../../@core/components/toast-messages";
import { paginateArray } from "../../../../../@fake-db/utils";
import { DefaultConfig } from "../../../../../settings/config";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

export const getSyllabusStatusData = (params) => {
  return async (dispatch, getState) => {
    const authAxios = DefaultConfig().authAxios;
    const branchIdList = DefaultConfig().BranchIdList;
    const AcademicYear = DefaultConfig().AcademicYear;
    const acadGrp = DefaultConfig().acadGrp;
    const object = {
      branchList: branchIdList,
      acadYear: AcademicYear &&
      AcademicYear.find((c) => c.curYear === true) &&
      AcademicYear.find((c) => c.curYear === true).value,
      acadGrp: acadGrp,
    };
    // const object = {
    //   "branchList": ["GVTEMS0709", "GVTEMS0911"],
    //   "acadYear": 404
    // }
    dispatch({
      type: "GET_SYLLABUS_STATUS_LOADING_SPINNER",
      loader: false,
      tokenExp: false,
    });
    await authAxios
      .post(`academic/syllabusStatus`, object)
      .then((response) => {
        const errData = response.data.error
        if (errData == true) {
          MySwal.fire({
            title: "ERROR !",
            html:
              `<h4 className="mb-50">${response.status} - ERROR, Please Contact your R.M </h4>`,
            icon: 'error',
            allowOutsideClick: true,
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          }).then(function (result) {
              if (result.value) {
                window.location.reload();
              }
          })
          }
        const resData = response.data.value;
        if (resData) {
          const sbsData = resData.syllabusStatus;
          const sbData = sbsData.map((arr) => arr.statusBySubj);
          const emp = getState().navbar.groupEmpBasicInfo;
          const employeeData = emp.map((sub) => ({
            value: sub.empId,
            label: sub.name,
            mobNo: sub.mob,
          }));
          if (sbData) {
            sbData.forEach((items, index) => {
              items.forEach((item) => {
                item["mobNo"] =
                  employeeData &&
                    employeeData.find((mm) => mm.value === item.subjTeacher)
                    ? employeeData &&
                    employeeData.find((mm) => mm.value === item.subjTeacher)
                      .mobNo
                    : "";

                item["subjTeacherName"] =
                  employeeData &&
                    employeeData.find((cc) => cc.value === item.subjTeacher)
                    ? employeeData &&
                    employeeData.find((cc) => cc.value === item.subjTeacher)
                      .label
                    : "";
              });
            });
          }
          dispatch({
            type: "GET_SYLLABUS_STATUS_DATA",
            data: resData,
            params,
            loader: true,
            tokenExp: false,
          });
        }
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "GET_SYLLABUS_STATUS_LOADING_SPINNER",
              loader: true,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "GET_SYLLABUS_STATUS_LOADING_SPINNER",
              loader: true,
              tokenExp: false,
            });
            toast.error(
              <ToastMessage
                message={"academic/syllabusStatus" + " " + error}
                status={"danger"}
              />,
              {
                hideProgressBar: true,
              }
            );
          }
        }
      });
  };
};

// ** Get data on page or row change
export const getData = (params) => {
  return (dispatch, getState) => {
    const {
      q = "",
      perPage = 10,
      page = 1,
      sortname = "",
      sortorder = "",
    } = params;
    const resData = getState().acaddashboardreducer.allData;
    if (resData) {
      let filteredData = [];
      const queryLowered = q.toLowerCase();
      filteredData = resData.filter(
        (academic) =>
          (academic.cls && academic.cls.toLowerCase().includes(queryLowered)) ||
          (academic.branch &&
            academic.branch.toLowerCase().includes(queryLowered))
      );
      const sorting = DefaultConfig().sorter;
      const sortData = sortorder
        ? sorting(sortname, sortorder, filteredData)
        : filteredData;
      dispatch({
        type: "GET_BR_REP_DATA",
        data: paginateArray(sortData, perPage, page),
        totalPages: sortData.length,
        params,
      });
    }
  };
};

export const getFilterData = (params) => {
  return (dispatch, getState) => {
    const {
      q = "",
      perPage = 10,
      page = 1,
      branchvalue = "",
      classvalue = "",
    } = params;
    const resData = getState().acaddashboardreducer.sylStsData;
    const columnData = getState().acaddashboardreducer.allcolumns;

    var findResults = [];
    if (branchvalue == 0 && classvalue == 0) {
      findResults = resData;
    } else if (branchvalue != 0 && classvalue == 0) {
      findResults = resData.filter((find) => find.compnyId == branchvalue);
    } else if (branchvalue == 0 && classvalue != 0) {
      findResults = resData.filter((find) => find.clsId == classvalue);
    } else {
      findResults = resData.filter(
        (find) => find.clsId == classvalue && find.compnyId == branchvalue
      );
    }
    var newColumnData = [];
    columnData.forEach((element) => {
      element["showhide"] = true;
      if (
        element.selector === "serial" ||
        element.selector === "compId" ||
        element.selector === "cls"
      ) {
        newColumnData.push(element);
      } else {
        const findSubj = findResults.find((find) => find[element.selector]);
        if (findSubj) {
          newColumnData.push(element);
        } else {
          element["showhide"] = false;
          newColumnData.push(element);
        }
      }
    });
    // console.log(findResults, columnData)
    if (findResults) {
      dispatch({
        type: "GET_FIL_REP_DATA",
        data: paginateArray(findResults, perPage, page),
        totalPages: findResults.length,
        allData: findResults,
        columns: newColumnData,
        params,
      });
    }
  };
};


// Get call for marketing Resources
export const getDayWiseScheduleData = () => {
  return async (dispatch) => {
    const authAxios = DefaultConfig().authAxios;
    const empId = DefaultConfig().employeeId;
    const compId = DefaultConfig().companyId;
    const finGrp = DefaultConfig().finGrp;
    const acadYear = DefaultConfig().defaultYear;
    const AcademicYear = DefaultConfig().AcademicYear.find(obj => {
      return obj.value === acadYear;
    });

    const objData = {};
    objData["empId"] = empId;
    objData["compId"] = compId;
    objData["finGrp"] = finGrp;
    objData["acadYear"] = acadYear;
    objData["yearCode"] = AcademicYear.label;
    objData["logType"] = "emp";


    dispatch({
      type: "DAY_WISE_SCHEDULE_LOADING_SPINNER",
      spinner: false,
      tokenExp: false,
    });
    await authAxios
      .post(`academic/dayWiseScheduleLessonPlan/`, objData)
      .then((response) => {
        const resData = response.data.value;
        if (resData) {
          dispatch({
            type: "DAY_WISE_SCHEDULE_DATA",
            allData: resData,
            spinner: true
          });
        }
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "DAY_WISE_SCHEDULE_LOADING_SPINNER",
              spinner: true,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "DAY_WISE_SCHEDULE_LOADING_SPINNER",
              spinner: true,
              tokenExp: false,
            });
            toast.error(
              <ToastMessage
                message={"/lesson-planning/day-wise-schedule" + "  " + error}
                status={"danger"}
              />,
              {
                hideProgressBar: true,
              }
            );
          }
        }
      });
  };
};

//Complete Schedule
export const completeSchedule = (data, params) => {
  return async (dispatch, getState) => {
    const authAxios = DefaultConfig().authAxios;
    const compId = DefaultConfig().companyId;
    const empId = DefaultConfig().employeeId;
    const ip = DefaultConfig().ip;

    dispatch({
      type: "DAY_WISE_SCHEDULE_COMPLETE_SPINNER",
      spinner: false,
      tokenExp: false,
    });
    await authAxios
      .post(`/academic/saveLessonPlanTracker/`, data)
      .then((response) => {
        const resData = response.data.value;
        const { perPage = 10, page = 1 } = params;
        var allData = getState().lessonPlanningDWSReducer.allData;
        var resultData = []
        if (data.nextTopic) {
          const findIndex = allData.findIndex(el => el.topicRef == data.lessonPlanTracker.topicRef)
          if (findIndex >= 0) {
            allData[findIndex] = resData
            resultData = allData
          }
        } else {
          resultData = allData.filter(el => el.topicRef != data.lessonPlanTracker.topicRef)
        }


        dispatch({
          type: "DAY_WISE_SCHEDULE_COMPLETE",
          allData: resultData,
          data: paginateArray(resultData, perPage, page),
          totalPages: resultData.length,
          spinner: true,
          params,
        });
        toast.success(
          <ToastMessage
            message={`Updated Successfully`}
            status={"success"}
          />,
          { autoClose: 8000, position: toast.POSITION.TOP_CENTER }
        );

      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "DAY_WISE_SCHEDULE_COMPLETE_SPINNER",
              spinner: true,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "DAY_WISE_SCHEDULE_COMPLETE_SPINNER",
              spinner: true,
              tokenExp: false,
            });
            toast.error(
              <ToastMessage
                message={"/academic/saveLessonPlanTracker" + "  " + error}
                status={"danger"}
              />,
              {
                hideProgressBar: true,
              }
            );
          }
        }
      });
  };
};

export const getSyllabus = (obj, loader) => {
  return (dispatch) => {
    dispatch({
      type: "GET_SYLL_DATA",
      data: obj,
      loader
    });
  };
};