
// ** Initial State
const initialState = {
  accountTypes: [],
  subaccountTypes: [],
  innersubaccountTypes: [],
  empData: [],
  bankData: [],
  spinner: false,
  sspinner: false,
  ispinner: false,
  tokenExp: false
}

const EmployeeAdvance = (state = initialState, action) => {
  switch (action.type) {
    case "GET_EMP_ADVANCE_ACCOUNT_DATA":
      return {
        ...state,
        accountTypes: action.data,
        spinner: action.spinner
      }
    case "GET_EMP_ADVANCE_SUB_ACCOUNT_DATA":
      return {
        ...state,
        subaccountTypes: action.data,
        sspinner: action.spinner
      }
    case "GET_EMP_ADVANCE_INN_SUB_ACCOUNT_DATA":
      return {
        ...state,
        innersubaccountTypes: action.data,
        ispinner: action.spinner
      }
    case "GET_EMP_ADVANCE_EMP_DATA":
      return {
        ...state,
        empData: action.data,
        spinner: action.spinner
      }
    case "ADD_EMP_ADVANCE":
      return {
        ...state,
        spinner: action.spinner
      }
    case "EMP_ADVANCE_SUB_LOADING_SPINNER":
      return {
        ...state,
        sspinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "EMP_ADVANCE_INN_SUB_LOADING_SPINNER":
      return {
        ...state,
        ispinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "EMP_ADVANCE_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp
      }
    default:
      return { ...state }
  }
}
export default EmployeeAdvance
