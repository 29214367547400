import { DefaultConfig } from "../../../../../../settings/config";
import { deleteTeantativeExam } from "../action";
import { store } from "@store/storeConfig/store";

// ** Third Party Components
import { Button, UncontrolledTooltip } from "reactstrap";
import { Trash2, Eye, Edit, ArrowDown } from "react-feather";

const Classes = DefaultConfig().Classes;
const Academic = DefaultConfig().AcademicYear;
const Departments = DefaultConfig().Departments;
// ** Initial State
const initialState = {
  columns: [],
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      if (Classes.find((find) => find.value === resp.cls)) {
        resp.cls = Classes.find((find) => find.value === resp.cls).label;
      } else {
        resp.cls = "";
      }
      if (Academic.find((find) => find.value === resp.year)) {
        resp.year = Academic.find((find) => find.value === resp.year).label;
      } else {
        resp.year = "";
      }
      if (Departments.find((find) => find.value === resp.dept)) {
        resp.dept = Departments.find((find) => find.value === resp.dept).label;
      } else {
        resp.dept = "";
      }
    });
    return resData;
  } else {
    return [];
  }
};

const initialColumns = (data) => {
  const columnData = [];
  data.forEach((resp) => {
    if (resp != "cls") {
      columnData.push({
        name: resp,
        minWidth: "0px",
        selector: resp,
        sortable: true,
        center: true,
        showhide: true,
        cell: (row) => (
          <div className="d-flex">
           <span className="text-primary text-center"> {row[`${resp}~start`]} <br />  {row[`${resp}~start`] && <ArrowDown size={10} className="text-secondary" /> } <br/> {row[`${resp}~end`]} </span>
            {row[`${resp}~start`] && (
              <Button.Ripple
                className="btn_Action absolute-icon"
                color="danger"
                outline
                id="deleteTip"
                onClick={() => store.dispatch(deleteTeantativeExam(row[`${resp}~REF`]))}
              >
                <Trash2 size={12} />
              </Button.Ripple>
            )}
            {/* <UncontrolledTooltip placement="top" target="deleteTip">
              Delete
            </UncontrolledTooltip> */}
          </div>
        ),
      });
    } else {
      columnData.push({
        name: resp,
        minWidth: "0px",
        selector: resp,
        sortable: true,
        showhide: true,
        cell: (row) => row[resp],
      });
    }
  });
  // columnData.push({
  //   name: "Actions",
  //   minWidth: "120px",
  //   maxWidth: "120px",
  //   showhide: true,
  //   allowOverflow: true,
  //   cell: (row) => (
  //     <div className='d-flex'>
  //       <Button.Ripple
  //         className="btn-icon btn_Action"
  //         outline
  //         color="primary"
  //         id="viewTip"
  //         onClick={() => store.dispatch(getTentativeExam(row.sno, "View"))}
  //       >
  //         <Eye size={15} />
  //       </Button.Ripple>
  //       <UncontrolledTooltip placement="top" target="viewTip">
  //         View
  //       </UncontrolledTooltip>
  //       <Button.Ripple
  //         className="btn-icon btn_Action"
  //         outline
  //         color="primary"
  //         id="editTip"
  //         onClick={() => store.dispatch(getTentativeExam(row.sno, "Edit"))}
  //       >
  //         <Edit size={15} />
  //       </Button.Ripple>
  //       <UncontrolledTooltip placement="top" target="editTip">
  //         Edit
  //       </UncontrolledTooltip>
  //       <Button.Ripple
  //         className="btn-icon btn_Action"
  //         outline
  //         color="primary"
  //         id="deleteTip"
  //         onClick={() => store.dispatch(getTentativeExam(row.sno))}
  //       >
  //         <Trash2 size={15} />
  //       </Button.Ripple>
  //       <UncontrolledTooltip placement="top" target="deleteTip">
  //         Delete
  //       </UncontrolledTooltip>
  //     </div>
  //   )
  // })
  return columnData;
};

const TentativeExam = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_TEN_DATA":
      return {
        ...state,
        columns: initialColumns(action.columnIds),
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_TEN_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_TEN":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };
    case "ADD_TEN":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "UPDATE_TEN":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "DELETE_TEN":
      return { ...state };
    case "TEN_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default TentativeExam;
