// ** Initial State
const initialState = {
  visitorData: [],
  spinner: false,
  tokenExp: false,
};

const VisitorpassReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_STU_VISITOR":
      return {
        ...state,
        visitorData: action.visitorData,
        spinner: action.spinner,
      };
    case "STU_VISITOR_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    default:
      return { ...state };
  }
};
export default VisitorpassReducer;
