// ** Initial State
const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    selectedSms: null,
    actionTitle: "",
    smsUsageList: "",
    spinner: false,
    tokenExp: false
  }
  
  // const initialItem = resData => {
  //   if (resData) {
  //     console.log(resData, "ResData")
  //     resData.forEach((resp, index) => {
  //       resp.serial = index + 1
  //     })
  //   }
  // }

  const initialItem = (resData) => {
    if (resData) {
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
      });
      return resData;
    } else {
      return [];
    }
  };
  
  const SmsTrnsfrReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_ALL_SMS_TRNSFR_DATA":
        return {
          ...state,
          allData: initialItem(action.allData),
          data: action.data,
          total: action.totalPages,
          params: action.params,
          spinner: action.spinner
        }
      case "GET_SMS_TRNSFR_DATA":
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      case "GET_SMS_TRNSFR":
        return {
          ...state,
          selectedSms: action.selectedSms,
          actionTitle: action.actionTitle
        }
    //   case "ADD_SMS":
    //     console.log(action.allData, 'ROW Adding')
    //     return {
    //       ...state,
    //       allData: initialItem(action.allData),
    //       spinner: action.spinner
    //     }
    //   case "UPDATE_SMS":
    //     return {
    //       ...state,
    //       allData: initialItem(action.allData),
    //       spinner: action.spinner
    //     }
    //     case "DELETE_SMS":
    //       return { ...state,
    //         allData: initialItem(action.allData),
    //         spinner: action.spinner,
    //       }
      case "SMS_TRNSFR_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp
        }
      case "SMS_USAGE_LIST_LOADING_SPINNER":
      return {
        ...state,
        uspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
      case "GET_SMS_USAGE_LIST_DATA":
      return {
        ...state,
        smsUsageList: action.smsUsageList,
        uspinner: action.spinner,
      }
      default:
        return { ...state }
    }
  }
  export default SmsTrnsfrReducer
  