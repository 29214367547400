import { DefaultConfig } from "../../../../../../settings/config";
const AcademicYear = DefaultConfig().AcademicYear;
const DateFormatMonth = DefaultConfig().DateFormatMonth;
const AmountFormat = DefaultConfig().AmountFormat;

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  prevBal: '',
  spinner: false,
  tokenExp: false,

  billDtls: [],
  bspinner: false,
};

const initialItem = (ledgerData) => {
  if (ledgerData) {
    // console.log(ledgerData);
    ledgerData.forEach((resp, index) => {
      resp["date"] = DefaultConfig().DateFormatMonth(resp.date);
      resp.serial = index + 1;
    });
    return ledgerData;
  } else {
    return [];
  }
};

const EmpVendReport = (state = initialState, action) => {
  switch (action.type) {
    case "GETALL_EMPVEND_DATA":
      return {
        ...state,
        allData: action.allData,
        data: initialItem(action.data),
        prevBal: action.prevBalance,
        total: action.totalPages,
        spinner: action.spinner,
      };

    case "EMP_VENDREPORTS_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_EMPVEND_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
      case "BILL_DETAILS_LOADING_SPINNER":
        return {
          ...state,
          bspinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      case "GET_BILLDTLS_DATA":
        return {
          ...state,
          billDtls: action.billDtls,
          bspinner: action.spinner,
        };

    default:
      return { ...state };
  }
};
export default EmpVendReport;
