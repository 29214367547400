import { DefaultConfig } from "../../../../../settings/config"

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,

  gvAcadYearList: [],
  gvAylSpinner: false,
}

const initialItem = resData => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1
      resp["dateFrom"] = DefaultConfig().DateFormatMonth(resp.dateFrom);
      resp["dateTo"] = DefaultConfig().DateFormatMonth(resp.dateTo);

    })
    return resData
  } else {
    return []
  }
}

const Event = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_EVENT_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner
      }
    case "GET_EVENT_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case "GET_EVENT":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle
      }
    case "ADD_EVENT":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner
      }
    case "UPDATE_EVENT":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner
      }
    case "DELETE_EVENT":
      return { ...state }
    case "EVENT_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "GET_GV_ACAD_YEAR_LIST_LOADING_SPINNER":
      return {
        ...state,
        gvAylSpinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "GET_GV_ACAD_YEAR_LIST":
      return {
        ...state,
        gvAcadYearList: action.resData,
        gvAylSpinner: action.spinner,
      }
    default:
      return { ...state }
  }
}
export default Event
