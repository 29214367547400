const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  filData: [],
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
  creditData: [],
  creditSpinner: false,
  aspinner: false,
  spinner: false,
  cspinner: false,
  tokenExp: false,
  erpdata: [],
  espinner: false,
  amtspinner: false,
  amtData: {},
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
    ``;
  }
};

const CreditReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_CREDITS_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        filterData: action.filData,
        data: initialItem(action.data),
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_CREDIT_DATA":
      return {
        ...state,
        data: action.data,
        filterData: action.filtData,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_COMPFIL_DATA":
      return {
        ...state,
        filData: action.data,
      };
    case "GET_CREDIT":
      return {
        ...state,
        actionTitle: action.actionTitle,
      };
    case "ADD_CREDIT":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };

    case "CREDIT_AMOUNT_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };

      case "EMP_CREDIT_DATA":
        return {
          ...state,
          amtData: action.allData,
          amtspinner: action.spinner,
        };

      case "EMP_CREDIT_LOADING_SPINNER":
      return {
        ...state,
        amtspinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    default:
      return { ...state };
  }
};
export default CreditReducer;
