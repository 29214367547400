// ** Initial State
const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    actionTitle: "",
    spinner: false,
    tokenExp: false,
  };
  
  const initialItem = (resData) => {
    if (resData) {
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
      });
      return resData;
    } else {
      return [];
    }
  };
  
  const tdsPendReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_PENDING_TDS_DATA":
        return {
          ...state,
          allData: initialItem(action.allData),
          filData: initialItem(action.allData),
          data: initialItem(action.data),
          totalSumData: initialItem(action.totalSumData),
          total: action.totalPages,
          params: action.params,
          spinner: action.spinner,
        };
      case "PEND_TDS_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp,
        };
    case "GET_PEND_TDS_GETDATA":
        return {
            ...state,
            data: action.data,
            filData: action.filData,
            totalSumData: action.totalSumData,
            total: action.totalPages,
            params: action.params,
        };
      default:
        return { ...state };
    }
  };
  export default tdsPendReducer;