// ** Initial State
const initialState = {
    data: [],
    spinner: false,
    tokenExp: false,

    savedData: '',
    spLoader: true,
    spSpinner: false,
};

const initialItem = (resData) => {
    if (resData) {
        resData.forEach((resp, index) => {
            resp.serial = index + 1;
        });
        return resData;
    } else {
        return [];
    }
};

const SalaryPostingReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_SALARY_POSTING_DATA":
            return {
                ...state,
                data: action.data,
                spinner: action.spinner,
            };
        case "GET_SALARY_POSTING_DATA_LOADING_SPINNER":
            return {
                ...state,
                spinner: action.spinner,
                tokenExp: action.tokenExp,
            };
        case "SAVE_SALARY_POSTING_DATA":
            return {
                ...state,
                savedData: action.data,
                spLoader: action.loader,
                spSpinner: action.spinner,
            };
        case "SAVE_SALARY_POSTING_DATA_LOADING_SPINNER":
            return {
                ...state,
                spSpinner: action.spinner,
                spLoader: action.loader,
                tokenExp: action.tokenExp,
            };
        default:
            return { ...state };
    }
};
export default SalaryPostingReducer;
