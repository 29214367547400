// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedMedium: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false
}

const initialItem = resData => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1
      resp.empStatus = resp.empStatus ? resp.empStatus : ''
    })
    return resData
  } else {
    return []
  }
}

const Mediums = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_MED_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner
      }
    case "GET_MED_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case "GET_MEDIUM":
      return {
        ...state,
        selectedMedium: action.selectedMedium,
        actionTitle: action.actionTitle
      }
    case "ADD_MEDIUM":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner
      }
    case "UPDATE_MEDIUM":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner
      }
    case "DELETE_MEDIUM":
      return { ...state }
    case "SEC_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp
      }
    default:
      return { ...state }
  }
}
export default Mediums
