// ** Initial State
const initialState = {
    generateOtpData: {},
    spinner: false,
    tokenExp: false,

    packageSaveData: {},
    psSpinner: false,

    ggicData: [],
    ggicSpinner: false,

    ptData: [],
    ptSpinner: false,

    exSpinner: false,
    expDate: "",
  };
  
  const initialItem = (resData) => {
    if (resData) {
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
      });
      return resData;
    } else {
      return [];
    }
  };
  
  const adbReducer = (state = initialState, action) => {
    switch (action.type) {
      case "INVOICE_GENERATE_OTP":
        return {
          ...state,
          generateOtpData: action.resData,
          spinner: action.spinner,
        };
      case "INVOICE_GENERATE_OTP_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      case "PACKAGE_SAVE":
          return {
            ...state,
            packageSaveData: action.resData,
            psSpinner: action.spinner,
          };
      case "PACKAGE_SAVE_LOADING_SPINNER":
          return {
            ...state,
            psSpinner: action.spinner,
            tokenExp: action.tokenExp,
          };
      case "GET_GENERATED_INVOICE_COUNT":
          return {
            ...state,
            ggicData: action.resData,
            ggicSpinner: action.spinner,
          };
      case "GET_GENERATED_INVOICE_COUNT_LOADING_SPINNER":
          return {
            ...state,
            ggicSpinner: action.spinner,
            tokenExp: action.tokenExp,
          };
      case "PACKAGE_TABLE":
          return {
            ...state,
            ptData: action.PackagesList,
            ptSpinner: action.spinner,
          };
      case "PACKAGE_TABLE_LOADING_SPINNER":
          return {
            ...state,
            ptSpinner: action.spinner,
            tokenExp: action.tokenExp,
          };
      case "UPD_EXPDATE":
        return {
          ...state,
          expDate: action.resData,
          exSpinner: action.spinner,
        };
      case "EXP_DATE_UPD_LOADING_SPINNER":
        return {
          ...state,
          exSpinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      default:
        return { ...state };
    }
  };
  export default adbReducer;
  