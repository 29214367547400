import { DefaultConfig } from "../../../../../settings/config";

// ** Initial State
const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    spinner: false,
    tokenExp: false,

    answrdCount: "",
    qstnsCount: "",
    score: "",
    sapData: [],
    sapSpinner: false,

    ssaData: {},
    ssaSpinner: false,

    stuAnnData: [],
    stuAnnSpinner: false,

    stuDiaryData: [],
    stuDiarySpinner: false,

    newAnnData: '',
    newAnnSpinner: false,

    stuDynamicData: {},
    curSelYear: "",
    curSelYearCode: "",
    stuDynamicSpinner: false,

    stuFeeAttData: {},
    stuFeeAttSpinner: false,

    stuAttData: [],
    stuFeeDtls: {},

    stuSyllabusData: [],
    stuSyllabusSpinner: false,

    stuCLPData: [],
    stuCLPSpinner: false,

    stuReaData: [],
    stuReaSpinner: false,

    encData: "",
    encSpinner: false,

    examData: [],
    exspinner: false,
};

const initialStuAttItem = (resData) => {
    if (resData) {
        resData.forEach((resp, index) => {
            resp.serial = index + 1;
        });
        return resData;
    } else {
        return [];
    }
};

const StuDashboard = (state = initialState, action) => {
    switch (action.type) {
        case "GET_STU_SELF_ASSESMENT_LOADING_SPINNER":
            return {
                ...state,
                sapSpinner: action.spinner,
                tokenExp: action.tokenExp,
            };
        case "GET_STU_SELF_ASSESMENT_DATA":
            return {
                ...state,
                answrdCount: action.answrdCount,
                qstnsCount: action.qstnsCount,
                score: action.score,
                sapData: action.allData,
                sapSpinner: action.spinner,
            };
        case "GET_SAVE_SELF_ASSESMENT_LOADING_SPINNER":
            return {
                ...state,
                ssaSpinner: action.spinner,
                tokenExp: action.tokenExp,
            };
        case "GET_SAVE_SELF_ASSESMENT_DATA":
            return {
                ...state,
                ssaData: action.allData,
                ssaSpinner: action.spinner,
            };
        case "NOTICE_LOADING_SPINNER":
            return {
                ...state,
                stuAnnSpinner: action.spinner,
                tokenExp: action.tokenExp,
            };
        case "GET_ALL_NOTICE_DATA":
            return {
                ...state,
                stuAnnData: action.allData,
                stuAnnSpinner: action.spinner,
            };
        case "STU_DYNAMIC_DATA":
            return {
                ...state,
                stuDynamicData: action.allData,
                curSelYear: action.curSelYear,
                curSelYearCode: action.curSelYearCode,
                stuDynamicSpinner: action.spinner
            }
        case "STU_DYNAMIC_DATA_LOADING_SPINNER":
            return {
                ...state,
                stuDynamicSpinner: action.spinner,
                tokenExp: action.tokenExp
            }
        case "GET_DIARY_DATA_LOADING_SPINNER":
            return {
                ...state,
                stuDiarySpinner: action.spinner,
                tokenExp: action.tokenExp,
            };
        case "GET_DIARY_DATA":
            return {
                ...state,
                stuDiaryData: action.allData,
                stuDiarySpinner: action.spinner,
            };
        case "GET_NEW_ANN_DATA_LOADING_SPINNER":
            return {
                ...state,
                newAnnSpinner: action.spinner,
                tokenExp: action.tokenExp,
            };
        case "GET_NEW_ANN_DATA":
            return {
                ...state,
                newAnnData: action.allData,
                newAnnSpinner: action.spinner,
            };
        case "GET_STU_ATT_FEE_DATA_LOADING_SPINNER":
            return {
                ...state,
                stuFeeAttSpinner: action.spinner,
                tokenExp: action.tokenExp,
            };
        case "GET_STU_ATT_FEE_DATA":
            return {
                ...state,
                stuFeeAttData: action.allData,
                stuFeeAttSpinner: action.spinner,
            };
        case "GET_STU_ATT_COUNT_DATA":
            return {
                ...state,
                stuAttData: initialStuAttItem(action.allData),
            };
        case "GET_STU_FEE_DTLS_DATA":
            return {
                ...state,
                stuFeeDtls: action.feeDtls,
            };
        case "GET_SYLLABUS_DATA_LOADING_SPINNER":
            return {
                ...state,
                stuSyllabusSpinner: action.spinner,
                tokenExp: action.tokenExp,
            };
        case "GET_SYLLABUS_DATA":
            return {
                ...state,
                stuSyllabusData: action.data,
                stuSyllabusSpinner: action.spinner,
            };
        case "GET_CLS_WISE_LESSON_PLAN_LOADING_SPINNER":
            return {
                ...state,
                stuCLPSpinner: action.spinner,
                tokenExp: action.tokenExp,
            };
        case "GET_CLS_WISE_LESSON_PLAN_DATA":
            return {
                ...state,
                stuCLPData: action.allData,
                stuCLPSpinner: action.spinner,
            };
        case "GET_STU_REA_DATA_LOADING_SPINNER":
            return {
                ...state,
                stuReaSpinner: action.spinner,
                tokenExp: action.tokenExp,
            };
        case "GET_STU_REA_DATA":
            return {
                ...state,
                stuReaData: action.allData,
                stuReaSpinner: action.spinner,
            };
        case "GET_ENCRYPTED_DATA_LOADING_SPINNER":
            return {
                ...state,
                encSpinner: action.spinner,
                tokenExp: action.tokenExp,
            };
        case "GET_ENCRYPTED_DATA":
            return {
                ...state,
                encData: action.data,
                encSpinner: action.spinner,
            };
        case "GET_EXAM_DATA":
            return {
                ...state,
                examData: action.allData,
                exspinner: action.spinner,
            };
        case "GET_EXAM_TIME_TABLE_DATA_LOADING_SPINNER":
        return {
            ...state,
            exspinner: action.spinner,
            tokenExp: action.tokenExp,
        };
        default:
            return { ...state };
    }
};
export default StuDashboard;