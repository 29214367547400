import { DefaultConfig } from "../../../../../../settings/config";

// ** Initial State
const initialState = {
  swTopicData: {},
  swTopicSpinner: false,

  swtvData: '',
  swtvSpinner: false,

  swTrackData: [],
  swTrackSpinner: false,
};


const initialStuAttItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const SpeakWell = (state = initialState, action) => {
  switch (action.type) {
    case "SW_TOPIC_LOADING_SPINNER":
        return {
          ...state,
          swTopicSpinner: action.spinner,
          tokenExp: action.tokenExp,
        };
    case "GET_SW_TOPIC_DATA":
        return {
            ...state,
            swTopicData: action.topicData,
            swTopicSpinner: action.spinner,
        };
    case "SAVE_SW_TOPIC_VIDEO_LOADING_SPINNER":
        return {
          ...state,
          swtvSpinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      case "SAVE_SW_TOPIC_VIDEO":
        return {
          ...state,
          swtvData: action.allData,
          swtvSpinner: action.spinner,
        };
      case "SW_TRACKER_LOADING_SPINNER":
        return {
          ...state,
          swTrackSpinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      case "GET_SW_TRACKER_DATA":
        return {
          ...state,
          swTrackData: action.allData,
          swTrackSpinner: action.spinner,
        };
        
    default:
      return { ...state };
  }
};
export default SpeakWell;
