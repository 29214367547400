
// ** Initial State
const initialState = {
  groupData: [],
  subListData: [],
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  dspinner: false,
  udspinner: false,
  tokenExp: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });

    return resData;
  } else {
    return [];
  }
};

const AllDiaries = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_DIARY_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        dspinner: action.spinner,
        actionTitle: action.actionTitle,
      };
    case "GET_DIARY_LOADING_SPINNER":
      return {
        ...state,
        dspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
      case "UPDATE_DIARY":
      return {
        ...state,
        allData: initialItem(action.allData),
        udspinner: action.spinner,
        actionTitle: action.actionTitle,
      };
    case "UPDATE_DIARY_LOADING_SPINNER":
      return {
        ...state,
        udspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default AllDiaries;