import axios from "axios";
import { toast } from "react-toastify";
import ToastMessage from "../../../../../../@core/components/toast-messages";
import { paginateArray } from "../../../../../../@fake-db/utils";
import { DefaultConfig } from "../../../../../../settings/config";
import DefaultSettings from "../../../../../../settings";
const perPageCount = DefaultSettings.perPage
// ** Get all Data
export const getAllData = (params) => {
  return async (dispatch) => {
    const companyId = DefaultConfig().companyId;
    const authAxios = DefaultConfig().authAxios;
    dispatch({
      type: "LOADING_SPINNER_FEE_HEAD",
      spinner: false,
      tokenExp: false,
    });
    await authAxios
      .get(`finance/feeheadsList/${companyId}/`)
      .then((response) => {
        const resData = response.data;
        const { perPage = perPageCount, page = 1 } = params;
        if (resData) {
          const filterData = resData.filter((find) => find !== null);
          dispatch({
            type: "GET_ALL_FEE_HEAD_DATA",
            allData: filterData,
            data: paginateArray(filterData, perPage, page),
            totalPages: filterData.length,
            params,
            spinner: true,
          });
        }
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status; 
          if (status === 403) {
            dispatch({
              type: "LOADING_SPINNER_FEE_HEAD",
              spinner: true,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;                                   
            dispatch({
              type: "LOADING_SPINNER_FEE_HEAD",
              spinner: true,
              tokenExp: false,
            });
            toast.error(
              <ToastMessage
                message={"finance/feeheadsList" + "  " + error}
                status={"danger"}
              />,
              { hideProgressBar: true }
            );
          }
        }
      });
  };
};

// ** Get Account list Data
export const getAccountData = () => {
  return async (dispatch, getState) => {
    const companyId = DefaultConfig().companyId;
    const authAxios = DefaultConfig().authAxios;
    dispatch({
      type: "LOADING_SPINNER_FEE_HEAD",
      spinner: false,
      tokenExp: false,
    });
    await authAxios
      .get(`finance/finheadsList/${companyId}`)
      .then((response) => {
        const resData = response.data;
        if (resData) {
          
          const mapData = resData.map((cus) => ({
            value: cus.sno,
            label: cus.descr,
          }));
          dispatch({
            type: "GET_ACC_DATA",
            data: mapData,
          });
        }
      })
      .then(() => {
        dispatch(getAllData(getState().feeheads.params));
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "LOADING_SPINNER_FEE_HEAD",
              spinner: true,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "LOADING_SPINNER_FEE_HEAD",
              spinner: true,
              tokenExp: false,
            });
            toast.error(
              <ToastMessage
                message={"finance/finheadsList" + "  " + error}
                status={"danger"}
              />,
              { hideProgressBar: true }
            );
          }
        }
      });
  };
};

// ** Get data on page or row change
export const getData = (params) => {
  return (dispatch, getState) => {
    const {
      q = "",
      perPage = perPageCount,
      page = 1,
      sortname = "",
      sortorder = "",
    } = params;
    const resData = getState().feeheads.allData;
    if (resData) {
      let filteredData = [];
      const queryLowered = q.toLowerCase();
      filteredData = resData.filter(
        (finance) =>
          finance.printHead &&
          finance.printHead.toLowerCase().includes(queryLowered)
      );
      const sorting = DefaultConfig().sorter;
      const sortData = sortorder
        ? sorting(sortname, sortorder, filteredData)
        : filteredData;
      dispatch({
        type: "GET_FEE_HEAD_DATA",
        data: paginateArray(sortData, perPage, page),
        totalPages: sortData.length,
        params,
      });
    }
  };
};

// ** Add new FeeHeads
export const addFeeHeads = (dataObj) => {
  return async (dispatch, getState) => {
    const companyId = DefaultConfig().companyId;
    const authAxios = DefaultConfig().authAxios;
    const branch = DefaultConfig().companyId === DefaultConfig().finGrp
    // console.log(branch, "Branch")
    dispatch({
      type: "LOADING_SPINNER_FEE_HEAD",
      spinner: false,
      tokenExp: false,
    });
    const objectData = {};
    // console.log(objectData, "11")
    objectData["compIds"] = [companyId];
    objectData["feeheadsId"] = {compId : companyId}
    objectData["printHead"] = dataObj.feeheader;
    objectData["terms"] = dataObj.term;
    objectData["hideOption"] = dataObj.hideoption;                   
    objectData["branchId"] = branch ? "0000000000" : companyId;
    objectData["newAdm"] = dataObj.newadmin;
    objectData["feeType"] = dataObj.feeType;
    objectData["gender"] = dataObj.gender;
    // if (dataObj.feetype === 1) {
    //   objectData["route"] = true;
    //   objectData["hstl"] = false;
    // } else if (dataObj.feetype === 2) {
    //   objectData["hstl"] = true;
    //   objectData["route"] = false;
    // } else if (dataObj.feetype === 3) {
    //   objectData["route"] = false;
    //   objectData["hstl"] = false;
    // }
    objectData["ip"] = DefaultConfig().ip;
    objectData["finHead"] = dataObj.actname;
    objectData["priority"] = dataObj.priority;
    objectData["gst"] = dataObj.gst;
    objectData["editByStu"] = dataObj.editstd;
    objectData["splCourse"] = dataObj.special;
    objectData["courseFee"] = dataObj.course;
    objectData["logId"] = DefaultConfig().employeeId;
    // console.log(objectData, "obj");
    //objectData['logId']=dataObj.logId
    await authAxios
      .post("finance/feeheads", objectData)
      .then((response) => {
        const resData = response.data.value;
        if (resData) {
          const allData = getState().feeheads.allData;
          if (resData.length > 0) {
            allData.unshift(resData[0]);
          }
          dispatch({
            type: "ADD_FEEHEAD",
            resData,
            spinner: true,
          });
          toast.success(
            <ToastMessage message={"Added Successfully"} status={"success"} />,
            { hideProgressBar: true }
          );
        }
      })
      .then(() => {
        dispatch(getAllData(getState().feeheads.params));
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "LOADING_SPINNER_FEE_HEAD",
              spinner: true,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "LOADING_SPINNER_FEE_HEAD",
              spinner: true,
              tokenExp: false,
            });
            toast.error(
              <ToastMessage
                message={"finance/feeheads" + "  " + error}
                status={"danger"}
              />,
              { hideProgressBar: true }
            );
          }
        }
      });
  };
};

// ** Get FeeHeads
export const getFeeHeads = (id, title) => {
  return (dispatch, getState) => {
    const allData = getState().feeheads.allData;
    const findOne = allData.find((find) => find.feeheadsId === id);
    dispatch({
      type: "GET_FEEHEAD",
      selectedData: findOne,
      actionTitle: title,
    });
  };
};

// ** Update Feeheads
export const updateFeeHeads = (dataObj, selectedData) => {
  return async (dispatch, getState) => {
    const companyId = DefaultConfig().companyId;
    const authAxios = DefaultConfig().authAxios;
    dispatch({
      type: "LOADING_SPINNER_FEE_HEAD",
      spinner: false,
      tokenExp: false,
    });
    const objectData = {};
    objectData["compIds"] = [companyId];
    objectData["feeheadsId"] = {compId : companyId, catg: selectedData.category}
    objectData["printHead"] = dataObj.feeheader;
    //objectData["feeheadsId"] = selectedData.feeheadsId;
    objectData["terms"] = dataObj.term;
    objectData["hideOption"] = dataObj.hideoption;
    objectData["branchId"] = companyId;
    objectData["newAdm"] = dataObj.newadmin;
    objectData["feeType"] = dataObj.feeType;
    objectData["gender"] = dataObj.gender;
    // if (dataObj.feetype === 1) {
    //   objectData["route"] = true;
    //   objectData["hstl"] = false;
    // } else if (dataObj.feetype === 2) {
    //   objectData["hstl"] = true;
    //   objectData["route"] = false;
    // } else if (dataObj.feetype === 3) {
    //   objectData["route"] = false;
    //   objectData["hstl"] = false;
    // }
    objectData["ip"] = DefaultConfig().ip;
    objectData["finHead"] = dataObj.actname;
    objectData["priority"] = dataObj.priority;
    objectData["gst"] = dataObj.gst;
    objectData["editByStu"] = dataObj.editstd;
    objectData["splCourse"] = dataObj.special;
    objectData["courseFee"] = dataObj.course;
    await authAxios
      .post(`finance/feeheads`, objectData)
      .then((response) => {
        const resData = response.data.value;
        if (resData) {
          // console.log(resData, "Fee Head Response Data")
          // const allData = getState().feeheads.allData;
          // console.log(allData, "All Data After");
          // const findIndex = allData.findIndex((find) => find.feeheadsId.catg === selectedData.feeheadsId.catg);
          // console.log(findIndex, "findIndex");
          // if (findIndex === -1) {
          //   if (resData.length > 0) {
          //     allData.push(resData[0]);
          //   }
          // } else {
          //   if (resData.length > 0) {
          //     console.log(allData[findIndex], "Find Index All Data")
          //     allData[findIndex] = resData[0];
          //   }
          // }
          // allData[findIndex] = resData;
          dispatch({
            type: "UPDATE_FEEHEAD",
            // allData: allData.filter((find) => find !== null),
            allData: resData,
            spinner: true,
          });
          toast.success(
            <ToastMessage
              message={"Updated Successfully"}
              status={"success"}
            />,
            { hideProgressBar: true }
          );
        }
      })
      .then(() => {
        dispatch(getAllData(getState().feeheads.params));
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "LOADING_SPINNER_FEE_HEAD",
              spinner: true,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "LOADING_SPINNER_FEE_HEAD",
              spinner: true,
              tokenExp: false,
            });
            toast.error(
              <ToastMessage
                message={"finance/feeheads" + "  " + error}
                status={"danger"}
              />,
              { hideProgressBar: true }
            );
          }
        }
      });
  };
};

// ** Delete FeeHeads
export const deleteFeeHeads = (id) => {
  return (dispatch, getState) => {
    axios
      .delete("/api/dep/delete", { id })
      .then((response) => {
        dispatch({
          type: "DELETE_FEEHEAD",
        });
      })
      .then(() => {
        dispatch(getData(getState().departments.params));
        dispatch(getAllData());
      });
  };
};
