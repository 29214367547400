// ** Initial State
const initialState = {
  allData: [],
  data: [],
  totalSumData: [],
  total: 1,
  params: {},
  actionTitle: "",
  spinner: false,

  stuPckMnyAllData: [],
  stuPckMnyData: [],
  stuPckMnytotal: 1,
  stuPckMnyparams: {},
  totalStuSumData: [],
  actionTitle: "",
  stuPckMnySpinner: false,
  tokenExp: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const PckMnyBtwDatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PCKT_MONEY_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: initialItem(action.data),
        totalSumData: initialItem(action.totalSumData),
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "PCK_MONEY_REPORT_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_PCKT_MONEY_GETDATA":
      return {
        ...state,
        data: action.data,
        totalSumData: action.totalSumData,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_STU_PCKT_MONEY_DATA":
      return {
        ...state,
        stuPckMnyAllData: initialItem(action.allData),
        stuPckMnyData: initialItem(action.data),
        totalStuSumData: initialItem(action.totalStuSumData),
        stuPckMnyTotal: action.totalPages,
        stuPckMnyparams: action.params,
        stuPckMnySpinner: action.spinner,
      };
    case "STU_PCK_MONEY_REPORT_LOADING_SPINNER":
      return {
        ...state,
        stuPckMnySpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_STU_PCKT_MONEY_GETDATA":
      return {
        ...state,
        stuPckMnyData: action.data,
        totalStuSumData: action.totalStuSumData,
        stuPckMnyTotal: action.totalPages,
        stuPckMnyparams: action.params,
      };
    default:
      return { ...state };
  }
};
export default PckMnyBtwDatesReducer;
