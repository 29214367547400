// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  spinner: false,
  tokenExp: false,

  saData: [],
  saspinner: false,

  espinner: false,
  examData: []
};
const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      resp.subjAnalytics.forEach((sub) => {
        sub.avg = sub.avg ? Number(Math.round(sub.avg)) : 0
        sub.min = sub.min ? Number(Math.round(sub.min)) : 0
        sub.max = sub.max ? Number(Math.round(sub.max)) : 0
        sub.failCount = sub.failCount ? sub.failCount : 0
      })
    });
    return resData;
  } else {
    return [];
  }
};

const ResultAnalysis = (state = initialState, action) => {
  switch (action.type) {
    case "GET_REA_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      }
    case "GET_REA_DATA_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_SUBJ_ANLT_DATA":
      return {
        ...state,
        saData: action.allData,
        saspinner: action.spinner,
      }
    case "REXAM_LOADING_SPINNER":
      return {
        ...state,
        espinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_ALL_REXAM_DATA":
      return {
        ...state,
        espinner: action.spinner,
        examData: action.data
      };
    default:
      return { ...state };
  }
};
export default ResultAnalysis;
