import axios from 'axios'
import GlobalVariable from '../path/global'
const baseApiUrl = GlobalVariable.BASE_API_URL

let userDetails = {}
let companyId = ''
let employeeId = ''
let Classes = []
let Sections = []
let Mediums = []
let Degrees = []
let Departments = []
let AcademicYear = []
let Branches = []
let authAxios
if (localStorage.getItem("userData")) {
  userDetails = JSON.parse(localStorage.getItem("userData"))
  // company id
  companyId = userDetails['CompanyBean'] && userDetails['CompanyBean']['compId']
  // employee id
  employeeId = userDetails['EmpBean'] && userDetails['EmpBean']['empId']
  // jwt token set to axios header 
  authAxios = axios.create({
    baseURL: baseApiUrl,
    headers: { Authorization: `Bearer ${userDetails.jwtToken}` }
  })
  // classes
  Classes = userDetails['Classes'] && userDetails['Classes'].map(cls => ({ value: cls.id, label: cls.name }))
  // Sections
  Sections = userDetails['Sections'] && userDetails['Sections'].map(sec => ({ value: sec.id, label: sec.name }))
  // Mediums
  Mediums = userDetails['Mediums'] && userDetails['Mediums'].map(med => ({ value: med.id, label: med.name }))
  // Degrees
  Degrees = userDetails['Degrees'] && userDetails['Degrees'].map(deg => ({ value: deg.id, label: deg.name }))
  // Departments
  Departments = userDetails['Departments'] && userDetails['Departments'].map(dep => ({ value: dep.id, label: dep.name }))
  // AcademicYear
  AcademicYear = userDetails['AcademicYear'] && userDetails['AcademicYear'].map(academic => ({ value: academic.id, label: academic.yearCode }))


  // Branches
  const CompanyBranch = userDetails['CompanySubBranch'] && userDetails['CompanySubBranch'].find(find => find.compId === companyId)
  if (CompanyBranch) {
    if (CompanyBranch.subBranchCompList) {
      Branches = CompanyBranch.subBranchCompList.map(subbranch => ({ value: subbranch.compId, label: subbranch.branchName }))
    } else {
      Branches.push({ value: CompanyBranch.compId, label: CompanyBranch.branchName })
    }
  }
}

const monthnames = ["Jan", "Feb", "March", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
// date format conversion for month
const DateFormatMonth = value => {
  if (value) {
    const monthname = monthnames[new Date(value).getMonth()]
    const datevalue = `0${new Date(value).getDate()}`.slice(-2)
    const yearvalue = new Date(value).getFullYear()
    return `${monthname} ${datevalue}, ${yearvalue}`
  } else {
    return ''
  }
}

// date format conversion for month
const DateFormat = value => {
  if (value) {
    return `${new Date(value).getFullYear()}-${`0${new Date(value).getMonth() + 1}`.slice(-2)}-${`0${new Date(value).getDate()}`.slice(-2)}`
  } else {
    return ''
  }
}

// rupee symbols
const amtSymbol = '₹'

// subjects crud
const Electives = [{ value: -1, label: "Elective 1" }, { value: -2, label: "Elective 2" }, { value: -3, label: "Elective 3" }, { value: -4, label: "Elective 4" }, { value: -5, label: "Elective 5" }, { value: -6, label: "Elective 6" }, { value: -7, label: "Elective 7" }, { value: -8, label: "Elective 8" }, { value: -9, label: "Elective 9" }, { value: -10, label: "Elective 10" }]

// subject mappings
const subjectsArr = [
  { id: 1, subjectOrder: "Subject - 01", subjectName: "", employeeName: "", subjectId: "", employeeId: "" },
  { id: 2, subjectOrder: "Subject - 02", subjectName: "", employeeName: "", subjectId: "", employeeId: "" },
  { id: 3, subjectOrder: "Subject - 03", subjectName: "", employeeName: "", subjectId: "", employeeId: "" },
  { id: 4, subjectOrder: "Subject - 04", subjectName: "", employeeName: "", subjectId: "", employeeId: "" },
  { id: 5, subjectOrder: "Subject - 05", subjectName: "", employeeName: "", subjectId: "", employeeId: "" },
  { id: 6, subjectOrder: "Subject - 06", subjectName: "", employeeName: "", subjectId: "", employeeId: "" },
  { id: 7, subjectOrder: "Subject - 07", subjectName: "", employeeName: "", subjectId: "", employeeId: "" },
  { id: 8, subjectOrder: "Subject - 08", subjectName: "", employeeName: "", subjectId: "", employeeId: "" },
  { id: 9, subjectOrder: "Subject - 09", subjectName: "", employeeName: "", subjectId: "", employeeId: "" },
  { id: 10, subjectOrder: "Subject - 10", subjectName: "", employeeName: "", subjectId: "", employeeId: "" },
  { id: 11, subjectOrder: "Subject - 11", subjectName: "", employeeName: "", subjectId: "", employeeId: "" },
  { id: 12, subjectOrder: "Subject - 12", subjectName: "", employeeName: "", subjectId: "", employeeId: "" },
  { id: 13, subjectOrder: "Subject - 13", subjectName: "", employeeName: "", subjectId: "", employeeId: "" },
  { id: 14, subjectOrder: "Subject - 14", subjectName: "", employeeName: "", subjectId: "", employeeId: "" },
  { id: 15, subjectOrder: "Subject - 15", subjectName: "", employeeName: "", subjectId: "", employeeId: "" },
  { id: 16, subjectOrder: "Subject - 16", subjectName: "", employeeName: "", subjectId: "", employeeId: "" },
  { id: 17, subjectOrder: "Subject - 17", subjectName: "", employeeName: "", subjectId: "", employeeId: "" },
  { id: 18, subjectOrder: "Subject - 18", subjectName: "", employeeName: "", subjectId: "", employeeId: "" },
  { id: 19, subjectOrder: "Subject - 19", subjectName: "", employeeName: "", subjectId: "", employeeId: "" },
  { id: 20, subjectOrder: "Subject - 20", subjectName: "", employeeName: "", subjectId: "", employeeId: "" }
]

// exams crud
const ExamTypes = [{ value: 1, label: "Exam Only" }, { value: 2, label: "Assignment and Exam" }]
const GroupTypes = [{ value: "FA", label: "FA" }, { value: "SA", label: "SA" }, { value: "PA", label: "PA" }, { value: "OTHERS", label: "OTHERS" }]
const AttformatTypes = [{ value: 1, label: "Month Wise" }, { value: 2, label: "Overall" }, { value: 3, label: "None" }]

// academic crud
const currentyear = [{ value: 1, label: "Yes" }, { value: 2, label: "No" }]

// banks crud
const statusOptions = [{ value: 'active', label: 'Active' }, { value: 'inactive', label: 'Inactive' }]
const bankTypeOptions = [{ value: 1, label: 'Real Bank' }, { value: 2, label: 'Virtual Bank' }, { value: 3, label: 'Personal Bank' }]

// approval seetings crud
const approvalTypes = [
  { value: "exp", label: "Expenses" },
  { value: "conc", label: "Concession" },
  { value: "budget", label: "Budget" },
  { value: "bill", label: "Bills" },
  { value: "trfr", label: "Finance Transfers" },
  { value: "leave", label: "Leaves" },
  { value: "exem", label: "Attendance Exemptions" },
  { value: "own", label: "Own Consumption" },
  { value: "emp", label: "Employee Approval" }
]
const approvalLevels = [
  { value: 0, label: "0" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" }
]

// account type crud
const accountType = [
  { value: 1, label: "INCOME" },
  { value: 2, label: "EXPENSE" },
  { value: 3, label: "ASSETS" },
  { value: 4, label: "LIABILITIES" },
  { value: 5, label: "INCOME+EXPENSE" },
  { value: 6, label: "NON-RECURING EXPENSE" }
]

// Fee Category crud
const FeeCategory = [{ value: 1, label: "Yes" }, { value: 2, label: "No" }]

// Fee Head crud
const Terms = [{ value: 1, label: "1 Term" }, { value: 2, label: "2 Terms" }, { value: 3, label: "3 Terms" }, { value: 4, label: "4 Terms" }, { value: 5, label: "5 Terms" }, { value: 6, label: "6 Terms" }, { value: 7, label: "7 Terms" }, { value: 8, label: "8 Terms" }, { value: 9, label: "9 Terms" }, { value: 10, label: "10 Terms" }, { value: 11, label: "11 Terms" }, { value: 12, label: "12 Terms" }]

const HideOption = [{ value: true, label: "true" }, { value: false, label: "false" }]

const ApplicableTo = [{ value: true, label: "New Admissions only" }, { value: false, label: "All Students" }]

const FeeType = [{ value: 1, label: "Transport" }, { value: 2, label: "Hostel" }, { value: 3, label: "None" }]

const EditStd = [{ value: true, label: "Yes" }, { value: false, label: "NO" }]
const SpecialCourse = [{ value: 1, label: "Yes" }, { value: 2, label: "NO" }]

const LoginConfig = Object.freeze({
  companyId,
  employeeId,
  authAxios,
  DateFormatMonth,
  DateFormat,
  amtSymbol,
  Classes,
  Sections,
  Mediums,
  Degrees,
  Departments,
  AcademicYear,
  Branches,
  Electives,
  ExamTypes,
  GroupTypes,
  AttformatTypes,
  currentyear,
  statusOptions,
  bankTypeOptions,
  approvalTypes,
  approvalLevels,
  accountType,
  subjectsArr,
  FeeCategory,
  Terms,
  HideOption,
  ApplicableTo,
  FeeType,
  EditStd,
  SpecialCourse
})

export default LoginConfig