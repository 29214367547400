// ** Initial State
const initialState = {
  depData: [],
  empData: [],
  degiData: [],
  accountTypes: [],
  subaccountTypes: [],
  innersubaccountTypes: [],
  espinner: false,
  tokenExp: false,
  spinner: false,
  sspinner: false,
  ispinner: false,
  redirect: false,
  employeeData: {},
  empspinner: true,
  certificateList: [],
  employeeInformation: null,
}
const EmpDepAdd = (state = initialState, action) => {
  switch (action.type) {
    case "GET_EMPLOYEE_INFO_DATA":
      return {
        ...state,
        employeeInformation: action.data,
        certificateList: action.certificatesInfoList,
        espinner: action.spinner,
      };
    case "EMP_UPDATE_LOADING_SPINNER":
      return {
        ...state,
        espinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_EMP_DEP_ADD_ACCOUNT_DATA":
      return {
        ...state,
        accountTypes: action.data,
        spinner: action.spinner
      }
    case "GET_EMP_ADD_SUB_ACCOUNT_DATA":
      return {
        ...state,
        subaccountTypes: action.data,
        sspinner: action.spinner
      }
    case "GET_EMP_ADD_INN_SUB_ACCOUNT_DATA":
      return {
        ...state,
        innersubaccountTypes: action.data,
        ispinner: action.spinner
      }
    case "GET_DEP_DATA":
      return {
        ...state,
        depData: action.data,
        spinner: action.spinner
      }
    case "GET_DEG_DATA":
      return {
        ...state,
        degiData: action.data,
        spinner: action.spinner
      }
    case "GET_EMP_DATA":
      return {
        ...state,
        empData: action.data,
        spinner: action.spinner
      }
    case "ADD_EMPLOYEE":
      return {
        ...state,
        empspinner: action.spinner,
        redirect: action.redirect,
        employeeData: action.data
      }
    case "EMP_DEP_ADD_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "EMP_ADD_LOADING_SPINNER":
      return {
        ...state,
        empspinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "EMP_GET_LOADING_SPINNER":
      return {
        ...state,
        espinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "UPDATE_EMPLOYEE":
      return {
        ...state,
        // employeeInformation: action.data,
        espinner: action.spinner,
      };
    default:
      return { ...state }
  }
}
export default EmpDepAdd
