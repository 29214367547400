// ** Initial State
const initialState = {
  examData: [],
  grpData: [],
  progressData: [],
  spinner: false,
  tokenExp: false,

  grpExamData: [],
  geSpinner: false,

  grpExamList: [],
  gelSpinner: false
}

const ProgressCardByExam = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PROG_CARD_DATA":
      return {
        ...state,
        progressData: action.data,
        spinner: action.spinner
      }
    case "PROG_CARD_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "GET_GROUP_EXAM_DATA":
      return {
        ...state,
        grpExamData: action.data,
        geSpinner: action.spinner
      }
    case "GROUP_EXAM_NAMES_LOADING_SPINNER":
      return {
        ...state,
        geSpinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "GET_GROUP_EXAM_LIST_DATA":
      return {
        ...state,
        grpExamList: action.data,
        gelSpinner: action.spinner
      }
    case "GROUP_EXAM_LIST_NAMES_LOADING_SPINNER":
      return {
        ...state,
        gelSpinner: action.spinner,
        tokenExp: action.tokenExp
      }
    default:
      return { ...state }
  }
}
export default ProgressCardByExam
