import React from 'react'
import { Button } from 'reactstrap';
import { User } from "react-feather";
import ReactTooltip from "react-tooltip";

export default function DiaryTooltipItem({ diaryDate, diaryNotes }) {
  return (
    <div>
      <ReactTooltip place="top" effect="solid" style={{ background: '#323232' }} />
        <h6 data-tip={diaryNotes} className="mb-0 font-small-2 text-white" style={{cursor: "pointer"}}>H/W : {diaryDate.slice(0, 6)}</h6>
    </div>
  );
}