// ** Initial State
const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    actionTitle: "",
    spinner: false,
    tokenExp: false,
  };
  
  const initialItem = (resData) => {
    if (resData) {
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
      });
      return resData;
    } else {
      return [];
    }
  };
  
  const SelfAssmtQueReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_QUE_COUNT_DATA":
        return {
          ...state,
          allData: initialItem(action.allData),
          data: initialItem(action.data),
          total: action.totalPages,
          params: action.params,
          spinner: action.spinner,
        };
      case "SFASMT_QUECOUNT_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp,
        };
    case "GET_QUECOUNT_GETDATA":
        return {
            ...state,
            data: action.data,
            total: action.totalPages,
            params: action.params,
        };
      default:
        return { ...state };
    }
  };
  export default SelfAssmtQueReducer;