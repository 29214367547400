import { withRouter } from "react-router-dom"
import jwtDecode from "jwt-decode"

const Authverify = ({ history }) => {
  if (localStorage.getItem("userData")) {
    const userData = JSON.parse(localStorage.getItem("userData"))
    const jwt_Token_decoded = jwtDecode(userData.jwtToken)
    if (jwt_Token_decoded.exp * 1000 < Date.now()) {
      if (localStorage.getItem("appType") === "erp") {
        try{
          AndroidInterface.logoutUser()
        }catch(Err)
        { 
          console.log(Err.stack);
        }
        history.push('/login')
      } else {
        history.push('/chess-login')
      }
      localStorage.clear()
    }
  }
  return <div></div>
}

export default withRouter(Authverify)