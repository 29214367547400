// ** Initial State
const initialState = {
  data: [],
  spinner: false,
  tokenExp: false,
};
const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      // if (AcademicYear.find(find => find.value === resp.prevYear)) {
      //   resp.prevYearName = AcademicYear.find(find => find.value === resp.prevYear).label
      // } else {
      //   resp.prevYearName = ''
      // }
    });
    return resData;
  } else {
    return [];
  }
};

const otherincome = (state = initialState, action) => {
  switch (action.type) {
    case "GET_OTHER_INCOME_DATA":
      return {
        ...state,
        data: action.data,
        spinner: action.spinner,
      };
    case "OTHER_INCOME_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "INC_ENTY_REMOVE":
      return {
        ...state,
        data: null,
      };
    default:
      return { ...state };
  }
};
export default otherincome;
