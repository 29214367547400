// ** Initial State
const initialState = {
    saData: [],
    saWeekly: [],
    saMonthly: [],
    saYearly: [],
    saClsList: [],
    saSpinner: false,
    satokenExp: false,

    swData: [],
    swWeekly: [],
    swYearly: [],
    swClsList: [],
    swSpinner: false,
    swtokenExp: false,
  };
  
  const AttDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_SELFASSMNT_DATA":
        return {
          ...state,
          saData: action.allData,
          saClsList: action.clsList,
          saWeekly: action.saWeekly,
          saMonthly: action.saMonthly,
          saYearly: action.saYearly,
          saSpinner: action.spinner,
        };
      case "SELF_ASSMNT_LOADING_SPINNER":
        return {
          ...state,
          saSpinner: action.spinner,
          satokenExp: action.tokenExp,
        };
      case "GET_SPEAKWELL_DATA":
        return {
          ...state,
          swData: action.allData,
          swClsList: action.clsList,
          swWeekly: action.saWeekly,
          swYearly: action.saYearly,
          swSpinner: action.spinner,
        };
      case "SPEAKWELL_LOADING_SPINNER":
        return {
          ...state,
          swSpinner: action.spinner,
          swtokenExp: action.tokenExp,
        };
      default:
        return { ...state };
    }
  };
  export default AttDashboardReducer;