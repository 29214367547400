// ** Initial State
const initialState = {
  resData: [],
  evaData: [],
  examData: [],
  allData: [],
  params: {},
  tokenExp: false,
  spinner: false,
  espinner: false,
  pspinner: false,
  evspinner: false,
};
const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};
const AnswerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_EXAM_DATA":
      // console.log(action);
      return {
        ...state,
        examData: action.allData,
        espinner: action.spinner,
      };
    case "EXAM_LOADING_SPINNER":
      return {
        ...state,
        espinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_EVALUATION_DATA":
      console.log(action);
      return {
        ...state,
        evaData: initialItem(action.allData),
        eresData: action.allData,
        evspinner: action.spinner,
        etotal: action.totalPages,
        params: action.params,
      };
    case "EVALUATION_LOADING_SPINNER":
      return {
        ...state,
        espinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    case "GET_EXAM_ANSWR_DATA":
      // console.log(action);
      return {
        ...state,
        allData: initialItem(action.allData),
        resData: action.allData,
        pspinner: action.spinner,
        total: action.totalPages,
        params: action.params,
      };
    case "EXAM_ANSWR_LOADING_SPINNER":
      return {
        ...state,
        pspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_ALL_EXAM_ANSWR_DATA":
    default:
      return { ...state };
  }
};
export default AnswerReducer;
