import { DefaultConfig } from "../../../../../../settings/config";
const Classes = DefaultConfig().Classes;
const Sections = DefaultConfig().Sections;
const Mediums = DefaultConfig().Mediums;
const Subjects = DefaultConfig().subjectsArr;
// ** Initial State
const initialState = {
  subData: [],

  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedSubjectMap: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
};

const initialItem = (resData, state) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      const SubjectData = [];
      Subjects.forEach((sub, ind) => {
        const objData = {
          id: sub.id,
          subjectOrder: sub.subjectOrder,
          subjectName: "",
          employeeName: "",
          subjectId: "",
          employeeId: "",
        };
        if (resp[`sub${ind + 1}`]) {
          if (state.subData) {
            if (
              state.subData.find((find) => find.value === resp[`sub${ind + 1}`])
            ) {
              objData.subjectName = state.subData.find(
                (find) => find.value === resp[`sub${ind + 1}`]
              ).label;
              objData.subjectId = state.subData.find(
                (find) => find.value === resp[`sub${ind + 1}`]
              ).value;
            }
          }
        }
        if (resp[`emp${ind + 1}`]) {
          if (state.empData) {
            if (
              state.empData.find((find) => find.value === resp[`emp${ind + 1}`])
            ) {
              objData.employeeName = state.empData.find(
                (find) => find.value === resp[`emp${ind + 1}`]
              ).label;
              objData.employeeId = state.empData.find(
                (find) => find.value === resp[`emp${ind + 1}`]
              ).value;
            }
          }
        }
        SubjectData.push(objData);
      });
      resp.subjects = SubjectData;

      if (resp.subjId) {
        if (Classes.find((find) => find.value === resp.subjId.cls)) {
          resp.clsName = Classes.find(
            (find) => find.value === resp.subjId.cls
          ).label;
        } else {
          resp.clsName = "";
        }
        if (Sections.find((find) => find.value === resp.subjId.sec)) {
          resp.secName = Sections.find(
            (find) => find.value === resp.subjId.sec
          ).label;
        } else {
          resp.secName = "";
        }
        if (Mediums.find((find) => find.value === resp.subjId.med)) {
          resp.medName = Mediums.find(
            (find) => find.value === resp.subjId.med
          ).label;
        } else {
          resp.medName = "";
        }
      } else {
        resp.clsName = "";
        resp.secName = "";
        resp.medName = "";
      }
    });
    return resData;
  } else {
    return [];
  }
};

const subjectmapping = (state = initialState, action) => {
  switch (action.type) {
    case "GET_LU_SUB_SUB_MAP":
      return {
        ...state,
        subData: action.data,
        spinner: action.spinner,
      };
    case "GET_ALL_SUB_MAP_DATA":
      return {
        ...state,
        allData: initialItem(action.allData, state),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_SUB_MAP_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_SUBJECTMAP":
      return {
        ...state,
        selectedSubjectMap: action.selectedSubjectMap,
        actionTitle: action.actionTitle,
      };
    case "ADD_SUBJECTMAP":
      return {
        ...state,
        allData: initialItem(action.allData, state),
        spinner: action.spinner,
      };
    case "UPDATE_SUBJECTMAP":
      return {
        ...state,
        allData: initialItem(action.allData, state),
        spinner: action.spinner,
      };
    case "DELETE_SUBJECTMAP":
      return {
        ...state,
        allData: initialItem(action.allData, state),
        spinner: action.spinner,
      };
    case "SUB_MAP_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default subjectmapping;
