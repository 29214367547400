import { DefaultConfig } from "../../../../../../settings/config";
const AcademicYear = DefaultConfig().AcademicYear;
const DateFormatMonth = DefaultConfig().DateFormatMonth;
const AmountFormat = DefaultConfig().AmtFormat;
const Banks = DefaultConfig().Banks;

// ** Initial State
const initialState = {
  bankData: [],
  allData: [],
  ledgerData: [],
  filteredData: [],
  bankBalances: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
  prevBankBal: "",
  prevCashBal: "",
};

const initialItem = (ledgerData) => {
  if (ledgerData) {
    ledgerData.forEach((resp, index) => {
      //resp["compId"] = SubBranchList.find((item) => item.compId === resp.compId) ? SubBranchList.find((item) => item.compId === resp.compId).branchName : "";
      resp.source = resp.source === "trfr" ? "Transfer" : resp.source;
      resp["gdate"] = DateFormatMonth(resp.date);
      const refNo = resp.refNo === null ? "" : resp.refNo;
      //const name = resp.name === null ? "" : resp.name;
      resp["refNo"] = `${refNo}`;
      resp["source"] = `${resp.source}`;
      //resp["narration"] = `${name}`;
      resp["cDebit"] = `${
        resp.cashDebit === null ? "" : AmountFormat(resp.cashDebit)
      } `;
      resp["cDebitStatus"] = resp.cashDebit === null ? "" : resp.status;
      resp["cCredit"] = resp.cashCredit === null ? "" : AmountFormat(resp.cashCredit);
      resp["bDebit"] = resp.bankDebit === null ? "" : AmountFormat(resp.bankDebit);
      resp["bDebitBankStatus"] = resp.bankDebit === null ? "" : resp.status;
      resp["bCredit"] =
        resp.bankCredit === null ? "" : AmountFormat(resp.bankCredit);
      resp["bCreditBankStatus"] = resp.bankCredit === null ? "" : resp.status;
      resp["cBalance"] =
        resp.cashBalance === null ? "" : AmountFormat(resp.cashBalance);
      resp["bBalance"] =
        resp.bankBalance === null ? "" : AmountFormat(resp.bankBalance);
      resp.serial = index + 1;
    });
    return ledgerData;
  } else {
    return [];
  }
};

function filterNullValues(obj) {
  const filteredObj = [];
  for (const [key, value] of Object.entries(obj)) {
    if (value !== null) {
      if (typeof value === "object" && !Array.isArray(value)) {
        filteredObj[key] = filterNullValues(value);
      } else if (Array.isArray(value)) {
        filteredObj[key] = value.filter((item) => item !== null);
      } else {
        filteredObj[key] = value;
      }
    }
  }
  return filteredObj;
}

const cashORBankBooks = (state = initialState, action) => {
  switch (action.type) {
    case "GET_BANK_DATA":
      return {
        ...state,
        bankData: action.data,
        spinner: action.spinner,
      };

    case "CASH_OR_BANK_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_ALL_LEDGER_DATA":
      const filteredData = filterNullValues(action.allData);
      return {
        ...state,
        allData: initialItem(action.allData),
        ledgerData: initialItem(action.allData),
        filteredData: filteredData,
        bankBalances: action.bankBalances,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
        prevBankBal: action.prevBankBal,
        prevCashBal: action.prevCashBal,
      };
    case "GET_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        ledgerData: action.ledgerData,
        params: action.params,
      };
    case "LEDGER_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default cashORBankBooks;
