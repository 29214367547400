import { DefaultConfig } from "../../../../../../settings/config";
// ** Initial State
const  year = DefaultConfig().AcademicYear
const initialState = {
    allData: [],
    total: 1,
    params: {},
    selectedData: null,
    actionTitle: "",
    spinner: false,
    tokenExp: false,
    sspinner: false,
    rspinner: true,
    stuRef: null,
    subRef: null,
  };

  export const getInitialState = (params) => {
    return async (dispatch) => {
      dispatch({
        type: "GET_INIT",
        loading: false,
      });
    };
  };
  
  const initialItem = (resData) => {
    if (resData) {
      console.log(resData, "Data");
      resData.forEach((resp, index) => {
      
        resp.serial = index + 1;
      });
      return resData;
    } else {
      return [];
    }
  };
  
  const InvoiceTerms = (state = initialState, action) => {
    switch (action.type) {
      case "GET_ALL_INV_DATA":
        //  console.log(action.allData, "ALL DATA");
        return {
          ...state,
          allData: action.allData,
          // data: action.data,
          total: action.totalPages,
          params: action.params,
          spinner: action.spinner,
        };
      case "GET_INV_DATA":
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params,
        };
      case "GET_INV":
        return {
          ...state,
          selectedData: action.selectedData,
          actionTitle: action.actionTitle,
        };
        case "ADD_INV":
          return {
            ...state,
            allData: initialItem(action.allData),
            spinner: action.spinner,
          };
      case "UPDATE_INV":
        return {
          ...state,
          allData: action.allData,
          spinner: action.spinner,
        };
    
      case "INV_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp,
        };
        case "ACAD_YEAR_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp,
        };
        case "GET_ACAD_YEAR_LIST":
      return {
        ...state,
        acadYearList: action.data,
        aspinner: action.spinner,
      };
      default:
        return { ...state };
    }
  };
  export default InvoiceTerms;
  