// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedSms: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
  sspinner: false,
  rspinner: true,
  stuRef: null,
  subRef: null,
};

// const initialItem = resData => {
//   if (resData) {
//     console.log(resData, "ResData")
//     resData.forEach((resp, index) => {
//       resp.serial = index + 1
//     })
//   }
// }

const initialItem = (resData) => {
  if (resData) {
    console.log(resData, "ResData");
    resData.forEach((resp, index) => {
      resp["senderId"] = resp.id.senderId;
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const SmsTemplateTracker = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_SMS_DATA":
      // console.log(action, "ALL DATA");
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_SMS_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_SMS":
      return {
        ...state,
        selectedSms: action.selectedSms,
        actionTitle: action.actionTitle,
      };
    case "ADD_SMS":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "UPDATE_SMS":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "DELETE_SMS":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "SMS_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "ADD_SMS_LOADING_SPINNER":
      return {
        ...state,
        rspinner: action.rspinner,
        tokenExp: action.tokenExp,
        stuRef: action.stuRef,
        subRef: action.subRef,
      };
    case "GET_INIT":
      return {
        ...state,
        loading: false,
        sspinner: true,
      };
    case "GET_RES_ENT_UPDATE_DATA":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    default:
      return { ...state };
  }
};
export default SmsTemplateTracker;
