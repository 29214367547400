// ** Initial State
const initialState = {
  allData: [],
  data: [],
  // blockData: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const HstlBlockReducder = (state = initialState, action) => {
  switch (action.type) {
    case "GET_HSTL_BLOCKS_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        blockData: action.blockData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "HSTL_BLOCKS_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_HSTL_BLOCK_GETDATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_HSTL_BLOCK_UPDATE":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };
    case "ADD_HSTL_BLOCKS_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "UPDATE_HSTL_BLOCK_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    // case "DELETE_TUTORIAL_LINK":
    //   return {
    //     ...state,
    //     allData: initialItem(action.allData),
    //     spinner: action.spinner,
    //   };
    default:
      return { ...state };
  }
};
export default HstlBlockReducder;
