//import { DefaultConfig } from "../../../../../settings/config";

import { DefaultConfig } from "../../../../../../settings/config";

//const DateFormatMonth = DefaultConfig().DateFormatMonth;
// const warenty = DefaultConfig().warenty;

// ** Initial State
const initialState = {
  allData: [],
  vehData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
  recload: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp["typeName"] = DefaultConfig().typeOpt.find(
        (item) => item.value === resp.type
      )
        ? DefaultConfig().typeOpt.find((item) => item.value === resp.type).label
        : "";
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const InventoryItems = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_INVENTORY_ITEM_DATA":
      //  console.log(action, "allData")
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_ALL_INV_CATG_DATA":
      return {
        ...state,
        vehData: action.allData,
        spinner: action.spinner,
        recload: action.recloader,
      };
    case "GET_INVENTORY_ITEM_DATA":
      // console.log(action.data, "data")
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_INVENTORY_ITEM":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };
    case "ADD_INVENTORY_ITEM":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "UPDATE_INVENTORY_ITEM":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "DELETE_INVENTORY_ITEM":
      return { ...state };
    case "INVENTORY_ITEM_LOADING_SPINNER":
      //console.log(action.spinner, "ree");
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default InventoryItems;
