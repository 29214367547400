import { DefaultConfig } from "../../../../../../settings/config"
const part = DefaultConfig().part

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false
}

const initialItem = resData => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1
      if (part.find(find => find.value === resp.part)) {
        resp.partName = part.find(find => find.value === resp.part).label
      } else {
        resp.partName = ''
      }
    })
    return resData
  } else {
    return []
  }
}

const CbscGroups = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_CBSCGRP_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner
      }
    case "GET_CBSCGRP_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case "GET_CBSCGRP":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle
      }
    case "ADD_CBSCGRP":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner
      }
    case "UPDATE_CBSCGRP":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner
      }
    case "DELETE_CBSCGRP":
      return { ...state }
    case "CBSCGRP_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp
      }
    default:
      return { ...state }
  }
}
export default CbscGroups
