import React from 'react'
import { Button } from 'reactstrap';
import { User } from "react-feather";
import ReactTooltip from "react-tooltip";

export default function TooltipItem({ subjTeacher }) {
  return (
    <div>
      <ReactTooltip place="top" effect="solid" style={{ background: '#323232' }} />
      <Button.Ripple
        className="btn_Action absolute-top-right-icon"
        data-tip={subjTeacher}
      >
        <User size={15} />
      </Button.Ripple>
    </div>
  );
}