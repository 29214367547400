// ** Initial State
const initialState = {
  galleryData: [],
  spinner: false,
  tokenExp: false,
};

const GalleryEntry = (state = initialState, action) => {
  switch (action.type) {
    case "GET_GALLERY_DATA":
      return {
        ...state,
        galleryData: action.data,
        spinner: action.spinner,
      };
    case "GALLERY_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default GalleryEntry;
