// ** Initial State
const initialState = {
    selectedData: null,
    allData: [],
    spinner: false,
    tokenExp: false,
  };
  
  const AdmRegFormData = (state = initialState, action) => {
       switch (action.type) {
          case "GET_DATA":
            return {
            ...state,
            allData: action.allData,
            spinner: action.spinner,
            };
         
          case "GET_ADM_REG_FORM":
            return {
               ...state,
              selectedData: action.selectedData,
              actionTitle: action.actionTitle,
            };
          case "ADM_REG_FORM_LOADING_SPINNER":
          return {
            ...state,
            spinner: action.spinner,
            tokenExp: action.tokenExp,
          };
          
      default:
        return { ...state };
    }
  };
  export default AdmRegFormData;
  