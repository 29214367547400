// ** Initial State
const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    selectedData: null,
    actionTitle: "",
    spinner: false,
    tokenExp: false
  }
  
  const initialItem = resData => {
    if (resData) {
      resData.forEach((resp, index) => {
        resp.serial = index + 1
        resp.empStatus = resp.empStatus ? resp.empStatus : ''
      })
      return resData
    } else {
      return []
    }
  }
  
  const ProgressCards = (state = initialState, action) => {
    switch (action.type) {
      case "GET_ALL_PROGRESS_DATA":
        return {
          ...state,
          allData: initialItem(action.allData),
          data: action.data,
          total: action.totalPages,
          params: action.params,
          spinner: action.spinner
        }
      case "GET_PROGRESS_DATA":
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      case "GET_PROGRESSCARDS":
        return {
          ...state,
          selectedData: action.selectedData,
          actionTitle: action.actionTitle
        }
      case "ADD_PROGRESSCARDS":
        return {
          ...state,
          allData: initialItem(action.allData),
          spinner: action.spinner
        }
      case "UPDATE_PROGRESSCARDS":
        return {
          ...state,
          allData: initialItem(action.allData),
          spinner: action.spinner
        }
      case "DELETE_PROGRESSCARDS":
        return { ...state }
      case "PROGRESS_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp
        }
      default:
        return { ...state }
    }
  }
  export default ProgressCards
  