// ** Initial State
const initialState = {
    amount: 0,
    moneyData: {},
    spinner: false,
    fspinner: false,
    tokenExp: false,
    pckMoney: {},
    hspinner: false,
  };
  
  const DepWithReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_POCKET_MONEY_DATA":
        return {
          ...state,
          amount: action.allData,
          spinner: action.spinner,
        };
      case "PKTMONEY_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp,
        };
    case "SAVE_POCKET_MONEY_DATA":
      return {
        ...state,
        moneyData: action.allData,
        fspinner: action.spinner,
      };
    case "GET_POCKET_MONEY_HISTORY":
      return {
        ...state,
        pckMoney: action.allData,
        hspinner: action.spinner,
      };
    case "PKTMONEY_HISTORY_LOADING_SPINNER":
      return {
        ...state,
        hspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
      default:
        return { ...state };
    }
  };
  export default DepWithReducer;  