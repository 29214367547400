import LoginConfig from "../../../../../../settings/login"
const DateFormatMonth = LoginConfig.DateFormatMonth
// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",

  examName: "",
  examRef: null,
  examType: null,
  spinner: false,
  aspinner:false,
  tokenExp: false,
  tspinner: false,
  ettList: [],
  subjData: [],

  aallData: [],
  adata: [],
  atotal: 1,
  aparams: {},
  selectedExam: null,
  actionTitleExam: '',
  selExamData: {},

  coSpinner: false,
  copyExam: [],
}

const initialItem = resData => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1
      resp.button = false
      resp['dateFormat'] = DateFormatMonth(resp.date)
    })
    const findnotdate = resData.findIndex((find => !find.date))
    if (findnotdate >= 0) {
      resData[findnotdate].button = true
    }
    return resData
  } else {
    return []
  }
}

const Classes = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_DATE_DATA":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
        actionTitle: action.actionTitle
      }
    case "GET_DATE_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
      case "GET_DATE":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle
      }
    case "ADD_DATE":
      return {
        ...state,
        allData: initialItem(action.allData),
        aspinner: action.spinner
      }
    case "DATE_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp
      }
      case "ADD_DATE_LOADING_SPINNER":
      return {
        ...state,
        aspinner: action.spinner,
        tokenExp: action.tokenExp
      }
    default:
      return { ...state }
  }
}
export default Classes