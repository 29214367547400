import { DefaultConfig } from "../../../../../settings/config";

// ** Initial State
const initialState = {
  repWiseData: [],
  stuData: [],
  totalEnqStuCount: '',
  repType: "",
  defCompId: "",
  fromDate: {},
  toDate: {},

  allData: [],
  data: [],
  totalEQdata: [],
  total: 1,
  params: {},

  updateEnquiry: [],
  stuFilterData: [],
  data: [],
  enqData: [],
  proData: [],
  oldStdData: [],
  
  actionTitle: "",
  spinner: false,
  ispinner: false,
  tokenExp: false,

  stuFiltData: [],
  sfSpinner: false,
  imSpinner: false,

  stuAllData: [],
  stuFilData: [],
  stuTotal: 1,

  updData: "",
  uspinner: false,

  trackData: [],
  ftspinner: false,
};

const initialItem = (resData) => {
  if (resData) {
    const SubBranchList = DefaultConfig().SubBranchList

    resData.forEach((resp, index) => {
      if(resp.branchId){
        resp.branchName = SubBranchList.find((ff) => ff.compId === resp.branchId) && SubBranchList.find((ff) => ff.compId === resp.branchId).branchName 
      }
      // if (Branches.find((find) => find.value === resp.compId)) {
      //   resp.branchName = Branches.find(
      //     (find) => find.value === resp.compId
      //   ).branchName;
      // } else {
      //   resp.branchName = "";
      // }
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const EnquiriesReports = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_ENQUIRIES_DATA":
      return {
        ...state,
        repWiseData: initialItem(action.repWiseData),
        stuData: action.stuData,
        totalEnqStuCount: action.totEnqCount,
        repType: action.repType,
        defCompId: action.defCompId,
        fromDate: action.dateFrom,
        toDate: action.dateTo,
        spinner: action.spinner
      };
    case "GET_REP_WISE_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        totalEQdata: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_REP_WISE_FIL_DATA":
      return {
        ...state,
        data: action.data,
        totalEQdata: action.totalEQdata,
        total: action.totalPages,
        params: action.params,
      };

      case "GET_STU_WISE_DATA":
      return {
        ...state,
        stuAllData: initialItem(action.allData),
        stuFilData: action.data,
        stuTotal: action.totalPages,
        params: action.params,
      };
    case "GET_STU_WISE_FIL_DATA":
      return {
        ...state,
        stuFilData: action.data,
        stuTotal: action.totalPages,
        params: action.params,
      };

    case "GET_ENQUIRIES_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_STU_FILTER_DATA":
      return {
        ...state,
        stuFilterData: initialItem(action.filterData),
      };
    case "GET_ENQUIRIES":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };
    case "GET_ENQUIRIES_REPORT_DATA":
      return {
        ...state,
        enquiriesAllData: initialItem(action.allData),
        enquiriesData: action.data,
        totalPages: action.totalPages,
        params: action.params,
        spinner: action.spinner
      }
    case "UPDATE_STU_ENQUIRY":
      return {
        ...state,
        enqData: action.enqData,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      }
    case "GET_STU_ENQ_OLDLIST_DATA":
      return {
        ...state,
        oldStdData: action.data,
        spinner: action.spinner,
      };
    case "ENQUIRIES_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_STU_ENQ_PROLIST_DATA":
      return {
        ...state,
        proData: action.data,
        espinner: action.spinner,
      };
    case "GET_STU_ENQ_PROLIST_DATA":
      return {
        ...state,
        proData: action.data,
        espinner: action.spinner,
      };

      case "GET_ENQ_STU_FILTER_DATA":
      return {
        ...state,
        stuFiltData: action.data,
        sfSpinner: action.spinner,
      };
    case "GET_ENQ_STU_FILTER_LOADING_SPINNER":
      return {
        ...state,
        stuFiltData: action.data,
        sfSpinner: action.spinner,
      };
    case "INCEN_LOADING_SPINNER":
      return {
        ...state,
        ispinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "ADD_EMPBILL":
      return {
        ...state,
        ispinner: action.spinner,
        tokenExp: action.tokenExp,
      };
      case "ADD_IMAGE":
        return {
          ...state,
          imSpinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      case "IMAGE_LOADING_SPINNER":
      return {
        ...state,
        imSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
      
      case "UPDATE_MRKTSRC_ENQUIRY":
        return {
          ...state,
          enqData: action.enqData,
          mspinner: action.spinner,
          tokenExp: action.tokenExp,
        }
      case "UPDATE_MRKTSRC_LOADING_SPINNER":
      return {
        ...state,
        mspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
      case "UPDATE_VILLAGE_NAME":
        return {
          ...state,
          updData: action.updData,
          uspinner: action.spinner,
          tokenExp: action.tokenExp,
        }
      case "UPD_VILLAGE_NAME_LOADING_SPINNER":
      return {
        ...state,
        uspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
      case "GET_FOLLOWUP_TRACK":
        return {
          ...state,
          trackData: action.trackData,
          ftspinner: action.spinner,
          tokenExp: action.tokenExp,
        }
      case "FOLLOWUP_TRACK_LOADING_SPINNER":
      return {
        ...state,
        ftspinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    default:
      return { ...state };
  }
};
export default EnquiriesReports;