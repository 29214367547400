// ** Initial State
const initialState = {
  proData: [],
  feecateData: [],
  feeheads: [],
  oldschlData: [],
  departData: [],
  degData: [],
  espinner: false,
  tokenExp: false,
  spinner: false,
  lspinner: false,
  certSpinner: false,
  redirect: false,
  studentData: {},
  // areaList: [],
  empData: [],
  rspinner: false,
  routeData: [],
  bspinner: false,
  busData: [],
  stageData: [],
  sspinner: false,
};

const StudentAdmisions = (state = initialState, action) => {
  switch (action.type) {
    case "GET_STUDENT_AR_DEPT_DATA":
      return {
        ...state,
        departData: action.data,
      };
    case "GET_STUDENT_AR_DEG_DATA":
      return {
        ...state,
        degData: action.data,
      };
    case "GET_STUDENT_AR_FEECATE_DATA":
      return {
        ...state,
        feecateData: action.data,
        spinner: action.spinner,
      };
    case "GET_STUDENT_AR_FEEHEAD_DATA":
      return {
        ...state,
        feeheads: action.data,
        spinner: action.spinner,
      };

    case "GET_STUDENT_AR_PRO_DATA":
      return {
        ...state,
        proData: action.data,
        espinner: action.spinner,
      };
    case "GET_STUDENT_AR_OLDSCH_DATA":
      return {
        ...state,
        oldschlData: action.data,
        spinner: action.spinner,
      };
    case "ADD_STUDENT_AR":
      return {
        ...state,
        lspinner: action.lspinner,
        certSpinner: action.certSpinner,
        studentData: action.data,
      };
    case "STUDENT_AR_LOADING_SPINNER":
      return {
        ...state,
        espinner: action.espinner,
        sspinner: action.sspinner,
        tokenExp: action.tokenExp,
      };
    case "STUDENT_AR_GP_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "STUDENT_AR_POST_LOADING_SPINNER":
      return {
        ...state,
        lspinner: action.lspinner,
        tokenExp: action.tokenExp,
      };
    case "ROUTE_LOADING_SPINNER":
      return {
        ...state,
        rspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_ROUTE_DATA":
      return {
        ...state,
        routeData: action.data,
        rspinner: action.spinner,
      };
    case "STAGE_LOADING_SPINNER":
      return {
        ...state,
        sspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_STAGE_DATA":
      console.log(action, "red");
      return {
        ...state,
        stageData: action.data,
        sspinner: action.spinner,
      };

    case "BUS_LOADING_SPINNER":
      return {
        ...state,
        bspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_BUS_DATA":
      return {
        ...state,
        busData: action.data,
        bspinner: action.spinner,
      };
    default:
      return { ...state };
  }
};
export default StudentAdmisions;
