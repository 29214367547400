import { Fragment } from 'react'
import { Check } from 'react-feather'
import Avatar from '@components/avatar'

const ToastMessage = ({ message, status, body }) => {
  return (
    <Fragment>
      <div className='toastify-header'>
        <div className='title-wrapper'>
          <Avatar size='sm' color={status} icon={<Check size={12} />} />
          <h6 className='toast-title'>{message}</h6>
        </div>
      </div>
      <div className='toastify-body text-center font-weight-bold' color={status}>
        <span className='h4'>{body}</span>
      </div>
    </Fragment>
  )
}

export default ToastMessage
