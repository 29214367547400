// ** Store & Actions
import { getSibling } from "../store/action";
import { store } from "@store/storeConfig/store";

// ** Third Party Components
import { Button, UncontrolledTooltip } from "reactstrap";
import { Trash2, Eye, Edit } from "react-feather";

export const columns = [
  {
    name: "S.NO",
    minWidth: "0px",
    maxWidth: "50px",
    selector: "serial",
    sortable: true,
    showhide: true,
    center: true,
    cell: (row) => <div className="text-center w-100">{row.serial}</div>,
  },
  {
    name: "Branch",
    minWidth: "0px",
    maxWidth: "180px",
    selector: "branch",
    sortable: true,
    showhide: true,
    center: true,
    cell: (row) => <div className="text-center w-100">{row.branch}</div>,
  },
  {
    name: "Child 1",
    minWidth: "0px",
    maxWidth: "200px",
    selector: "child1name",
    sortable: true,
    showhide: true,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">
        {row.child1name} <br /> <small className="font-small-1 font-weight-bold text-primary">{row.cls1name}</small> <br /> 
        <small className="font-small-1 font-weight-bold text-primary">{row.sec1name}</small> <br />{" "}
        <small className="font-small-1 font-weight-bold text-primary">{row.village1}</small>
      </div>
    ),
  },
  {
    name: "Child 1 Class/Sec",
    minWidth: "0px",
    maxWidth: "200px",
    selector: "cls1name",
    sortable: true,
    showhide: false,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">
        `{row.cls1name}, {row.sec1name}`
      </div>
    ),
  },
  {
    name: "Child 1 Place",
    minWidth: "0px",
    maxWidth: "200px",
    selector: "village1",
    sortable: true,
    showhide: false,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">
        {row.village1}
      </div>
    ),
  },
  {
    name: "Child 2",
    minWidth: "0px",
    maxWidth: "200px",
    selector: "child2name",
    sortable: true,
    showhide: true,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">
        {row.child2name} <br /> <small className="font-small-1 font-weight-bold text-primary">{row.cls2name}</small> <br /> 
        <small className="font-small-1 font-weight-bold text-primary">{row.sec2name}</small> <br />{" "}
        <small className="font-small-1 font-weight-bold text-primary">{row.village2}</small>
      </div>
    ),
  },
  {
    name: "Child 2 Class/Sec",
    minWidth: "0px",
    maxWidth: "200px",
    selector: "cls2name",
    sortable: true,
    showhide: false,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">
        `{row.cls2name}, {row.sec2name}`
      </div>
    ),
  },
  {
    name: "Child 2 Place",
    minWidth: "0px",
    maxWidth: "200px",
    selector: "village2",
    sortable: true,
    showhide: false,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">
        {row.village2}
      </div>
    ),
  },
  {
    name: "Child 3",
    minWidth: "0px",
    maxWidth: "200px",
    selector: "child3name",
    sortable: true,
    showhide: true,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">
        {row.child3name} <br /> <small className="font-small-1 font-weight-bold text-primary">{row.cls3name}</small> <br /> 
        <small className="font-small-1 font-weight-bold text-primary">{row.sec3name}</small> <br />{" "}
        <small className="font-small-1 font-weight-bold text-primary">{row.village4}</small>
      </div>
    ),
  },
  {
    name: "Child 3 Class/Sec",
    minWidth: "0px",
    maxWidth: "200px",
    selector: "cls3name",
    sortable: true,
    showhide: false,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">
        `{row.cls3name}, {row.sec3name}`
      </div>
    ),
  },
  {
    name: "Child 3 Place",
    minWidth: "0px",
    maxWidth: "200px",
    selector: "village3",
    sortable: true,
    showhide: false,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">
        {row.village3}
      </div>
    ),
  },
  {
    name: "Child 4",
    minWidth: "0px",
    maxWidth: "200px",
    selector: "child4name",
    sortable: true,
    showhide: true,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">
        {row.child4name} <br /> <small className="font-small-1 font-weight-bold text-primary">{row.cls4name}</small> <br /> 
        <small className="font-small-1 font-weight-bold text-primary">{row.sec4name}</small> <br />{" "}
        <small className="font-small-1 font-weight-bold text-primary">{row.village4}</small>
      </div>
    ),
  },
  {
    name: "Child 4 Class/Sec",
    minWidth: "0px",
    maxWidth: "200px",
    selector: "cls4name",
    sortable: true,
    showhide: false,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">
        `{row.cls4name}, {row.sec4name}`
      </div>
    ),
  },
  {
    name: "Child 4 Place",
    minWidth: "0px",
    maxWidth: "200px",
    selector: "village4",
    sortable: true,
    showhide: false,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">
        {row.village4}
      </div>
    ),
  },
  {
    name: "Actions",
    minWidth: "0",
    maxWidth: "140px",
    showhide: true,
    center: true,
    allowOverflow: true,
    cell: (row) => (
      <div className="d-flex">
        <Button.Ripple
          className="btn-icon btn_Action"
          outline
          color="primary"
          id="viewTip"
          onClick={() => store.dispatch(getSibling(row.serial, "View"))}
        >
          <Eye size={15} />
        </Button.Ripple>
        <UncontrolledTooltip placement="top" target="viewTip">
          View
        </UncontrolledTooltip>
        <Button.Ripple
          className="btn-icon btn_Action"
          outline
          color="primary"
          id="editTip"
          onClick={() => store.dispatch(getSibling(row.serial, "Edit"))}
        >
          <Edit size={15} />
        </Button.Ripple>
        <UncontrolledTooltip placement="top" target="editTip">
          Edit
        </UncontrolledTooltip>
        {/* <Button.Ripple
          className="btn-icon btn_Action"
          outline
          color="primary"
          id="deleteTip"
          onClick={() => store.dispatch(deleteSms(row.serial))}
        >
          <Trash2 size={15} />
        </Button.Ripple>
        <UncontrolledTooltip placement="top" target="deleteTip">
          Delete
        </UncontrolledTooltip> */}
      </div>
    ),
  },
];
