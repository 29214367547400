// ** Initial State
const initialState = {
  allData: [],
  data: [],
  feecateData: [],
  total: 1,
  params: {},
  spinner: false,
  tokenExp: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const FeeCard = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_FEE_DATA":
      console.log(action.allData, "reducer");
      return {
        ...state,
        allData: action.allData,
        feeData:action.allData,
        data: initialItem(action.data),
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_FEE_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "FEE_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "STUDENT_AR_GP_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
      case "GET_STUDENT_AR_FEECATE_DATA":
        return {
          ...state,
          feecateData: action.data,
          spinner: action.spinner,
        };
    default:
      return { ...state };
  }
};
export default FeeCard;
