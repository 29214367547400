import { array } from "prop-types";
import { DefaultConfig } from "../../../../../settings/config";

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  monthData: [],
  mondata: [],
  montotal: 1,
  total: 1,
  params: {},
  spinner: false,
  tokenExp: false,
  mspinner: false,
  columnData: [],
  holidays: [],
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      for (const key in resp.attMap) {
        resp["attendance"] = resp.attMap[key]
          ? resp.attMap[key]
          : DefaultConfig().stuAtt;
        resp["day"] = key;
        if (resp["attendance"] == "P") {
          resp["color"] = "#4B8641";
        } else if (resp["attendance"] == "A") {
          resp["color"] = "#DF0101";
        } else if (resp["attendance"] == "L") {
          resp["color"] = "#B18904";
        } else if (resp["attendance"] == "H") {
          resp["color"] = "#9178ED";
        } else if (resp["attendance"] == "M") {
          resp["color"] = "#FE642E";
        } else if (resp["attendance"] == "N") {
          resp["color"] = "#FA8258";
        } else if (resp["attendance"] == "W") {
          resp["color"] = "#848484";
        } else if (resp["attendance"] == "-") {
          resp["color"] = "#2E9AFE";
        }
      }
    });
    return resData;
  } else {
    return [];
  }
};

const getItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      let presentCount = 0;
      let attData = {};
      for (const key in resp.attMap) {
        attData["att" + key] = resp.attMap[key]
          ? resp.attMap[key]
          : DefaultConfig().stuAtt;
        if (attData["att" + key] == "P") {
          attData["color" + key] = "#4B8641";
        } else if (attData["att" + key] == "A") {
          attData["color" + key] = "#DF0101";
        } else if (attData["att" + key] == "L") {
          attData["color" + key] = "#B18904";
        } else if (attData["att" + key] == "H") {
          attData["color" + key] = "#9178ED";
        } else if (attData["att" + key] == "M") {
          attData["color" + key] = "#FE642E";
        } else if (attData["att" + key] == "N") {
          attData["color" + key] = "#FA8258";
        } else if (attData["att" + key] == "W") {
          attData["color" + key] = "#848484";
        } else if (attData["att" + key] == "-") {
          attData["color" + key] = "#2E9AFE";
        }
        if (attData["att" + key] === "P") {
          presentCount += 1;
        } else if (attData["att" + key] === "M" || attData["att" + key] === "N") {
          presentCount += 0.5;
        }
      }
      resp["attData"] = attData;
      resp["count"] = presentCount;
      resp["name"] = `${resp.name}`;
      resp["regNo"] = `${resp.regNo}`;
      resp["cls"] = DefaultConfig().Classes.find((cc) => cc.value === resp.cls)
        ? DefaultConfig().Classes.find((cc) => cc.value === resp.cls).label
        : "";
      resp["sec"] = DefaultConfig().Sections.find((cc) => cc.value === resp.sec)
        ? DefaultConfig().Sections.find((cc) => cc.value === resp.sec).label
        : "";
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const getColumns = (resData) => {
  var columns = [];
  columns.push(
    {
      name: "S.NO",
      minWidth: "0px",
      selector: "serial",
      sortable: true,
      showhide: true,
      cell: (row) => row.serial,
    },
    {
      name: "Name",
      minWidth: "0px",
      selector: "name",
      sortable: true,
      showhide: true,
      cell: (row) => row.name,
    },
    {
      name: "Roll No",
      minWidth: "0px",
      selector: "regNo",
      sortable: true,
      showhide: true,
      cell: (row) => row.regNo,
    },

    {
      name: "Class",
      minWidth: "0px",
      selector: "cls",
      sortable: true,
      showhide: true,
      cell: (row) => row.cls,
    },
    {
      name: "Section",
      minWidth: "0px",
      selector: "sec",
      sortable: true,
      showhide: true,
      cell: (row) => row.sec,
    }
  );
  if (resData.length > 0) {
    for (const key in resData[0].attMap) {
      columns.push({
        name: Number(key),
        minWidth: "0px",
        selector: "att" + key,
        sortable: true,
        showhide: true,
        cell: (row) => row[resData[0]["attData"]["att" + key]],
      });
    }
  }
  columns.push({
    name: "Present Count",
    minWidth: "0px",
    maxWidth: "80px",
    selector: "count",
    sortable: true,
    showhide: true,
    cell: (row) => row.count,
  });
  return columns;
};

const StuAttendance = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_EXAM_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
        actionTitle: action.actionTitle,
      };
    case "GET_STUDENT_ATT_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
        holidays: action.holidays,
      };
    case "STUDENT_ATT_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
      case "SSTUDENT_ATT_LOADING_SPINNER":
      return {
        ...state,
        sspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_STUDENT_ATT_MONTH_DATA":
      return {
        ...state,
        monthData: getItem(action.allData),
        columnData: getColumns(action.allData),
        mondata: action.data,
        montotal: action.totalPages,
        params: action.params,
        mspinner: action.spinner,
        holidays: action.holidays,
      };
    case "STUDENT_ATT_MONTH_LOADING_SPINNER":
      return {
        ...state,
        mspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_STUDENT_UPDATE_MONTH_DATA":
      return {
        ...state,
        monthData: getItem(action.allData),
        mspinner: action.spinner,
      };
    case "GET_STUDENT_UPDATE_DAY_DATA":
      return {
        ...state,
        allData: action.allData,
        spinner: action.spinner,
      };
    case "GET_STU_ATT_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
      };
    case "GET_STU_ATT_MDATA":
      return {
        ...state,
        mondata: action.data,
        montotal: action.totalPages,
      };
    default:
      return { ...state };
  }
};
export default StuAttendance;
