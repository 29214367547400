// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false
}

const initialItem = resData => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1
      resp.empStatus = resp.empStatus ? resp.empStatus : ''
      if (resData.find(find => find.sno === resp.prevCls)) {
        resp.preClsName = resData.find(find => find.sno === resp.prevCls).cls
      } else {
        resp.preClsName = ''
      }
    })
    return resData
  } else {
    return []
  }
}

const BranchList = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_BRL_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
        selectedData: null
      }
    case "GET_BRL_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case "GET_BRL":
      return {
        ...state,
        selectedData: action.allData,
        spinner: action.spinner
      }
    case "BRL_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp
      }
    default:
      return { ...state }
  }
}
export default BranchList
