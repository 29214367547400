// ** Initial State
const initialState = {
  subData: [],
  data: [],
  quesData: [],
  spinner: false,
  loader: false,
  tokenExp: false,
  qspinner: false,
  subjspinner: true,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const QuestionEntry = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_SUB_TOPIC_DATA":
      return {
        ...state,
        subData: action.allData,
        spinner: action.spinner,
        loader: action.loader,
        subjspinner: action.spinner,
      };
    case "SUB_TOPIC_LOADING_SPINNER":
      return {
        ...state,
        loader: action.loader,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
        subjspinner: action.spinner,
      };
    case "GET_ALL_QUESTIONS":
      return {
        ...state,
        quesData: action.allData,
        qspinner: action.spinner,
      };
    case "GET_QUEST_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_QUEST":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };
    case "ADD_QUESTION":
      return {
        ...state,
        quesData: initialItem(action.allData),
        qspinner: action.spinner,
      };
      case "DELETE_QUEST":
        return {
          ...state,
          quesData: initialItem(action.allData),
          qspinner: action.spinner,
        };

    case "QUEST_LOADING_SPINNER":
      return {
        ...state,
        qspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default QuestionEntry;
