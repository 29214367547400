// ** Initial State
const initialState = {
    natureOfIssueData: [],
    token: [],
    spinner: false,
    mspinner: false,
    tokenExp: false,
    data: [],
    total: 1,
    params: {},
    allData: [],
    
    tlbcData: [],
  tlbcspinner: false,

  tlbcAllData: [],
  tlbcData: [],
  tlbcTotal: 1,
  tlbcParams: {},
  };
  
  const initialItem = (resData) => {
    if (resData) {
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
      });
      return resData;
    } else {
      return [];
    }
  };
  
  const TokenErp = (state = initialState, action) => {
    switch (action.type) {
      case "GET_NATURE_OF_ISSUE_DATA":
        return {
          ...state,
          natureOfIssueData: action.data,
          spinner: action.spinner,
        };
      case "NATURE_OF_ISSUE_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      
      case "ADD_TOKEN":
        return {
          ...state,
          token: action.data,
          spinner: action.spinner,
        };
      
      case "ADD_TOKEN_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp,
        };
        case "GET_TOKEN_LIST_BY_COMP":
      return {
        ...state,
        tlbcAllData: initialItem(action.allData),
        tlbcData: action.data,
        tlbcTotal: action.totalPages,
        tlbcParams: action.params,
        tlbcSpinner: action.spinner
      };
    case "GET_TOKEN_LIST_BY_COMP_FILTER":
      return {
        ...state,
        tlbcData: action.data,
        tlbcTotal: action.totalPages,
        tlbcParams: action.params,
      };
    case "TOKEN_LIST_BY_COMP_SPINNER":
      return {
        ...state,
        tlbcspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
      default:
        return { ...state };
    }
  };
  export default TokenErp;
  