import { DefaultConfig } from "../../../../../../settings/config";
const TimeFormat = DefaultConfig().TimeFormatNotSec;
const DateFormatMonth = DefaultConfig().DateFormatMonth;
const Branches = DefaultConfig().Branches;
import { getLeaveTypes } from "../actions";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  events: [],
  selectedEvent: {},
  selectedCalendars: [
    "Present",
    "On Duty",
    "Absent",
    "Leave",
    "Holiday",
    "Half Day",
    "Week Off",
    "Exempted",
    "Permitted",
    "NA"
  ],
  empWorkHoursData: [],
  empWorkHoursList: [],
  leaveExemptions: [],
  attDayDetailsList: [],
  cellColors: [],
  spinner: false,
  tokenExp: false,

  leaveTypes: [],
  empExeData: [],
  dynamicdata: [],
  dynamicspinner: [],

  exemLimit: null,
  exemUsed: null,
  elSpinner: false,
};

const initialItem = (resData) => {
  if (resData) {
    const empWorkHoursList = resData.empWorkHours;

    if (empWorkHoursList) {
      return empWorkHoursList;
    } else {
      return [];
    }
  } else {
    return [];
  }
};
const getExemptions = (resData) => {
  const leaveExemptions = resData;

  if (leaveExemptions) {
    leaveExemptions.forEach((item) => {
      if (item.exmType === "sp") {
        item["exmType"] = "SinglePunch";
      } else if (item.exmType === "np") {
        item["exmType"] = "On Duty";
      } else if (item.exmType === "D") {
        item["exmType"] = "OnDuty";
      } else if (item.exmType === "lc") {
        item["exmType"] = "LateCome";
      } else if (item.exmType === "eg") {
        item["exmType"] = "EarlyGo";
      } else if (item.exmType === "lceg") {
        item["exmType"] = "LateComeEarlyGo";
      } else if (item.exmType === "CL") {
        item["exmType"] = "CompensatoryLeave";
      }
    });
    return leaveExemptions;
  } else {
    return [];
  }
};

const leaveList = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.date = DateFormatMonth(resp.date);
      if (Branches.find((find) => find.value === resp.compId)) {
        resp.compId = Branches.find((find) => find.value === resp.compId).label;
      }
      resp.serial = index + 1;

      if (resp.exmType === "sp") {
        resp["exmType"] = "SinglePunch";
      } else if (resp.exmType === "np") {
        resp["exmType"] = "OnDuty";
      } else if (resp.exmType === "D") {
        resp["exmType"] = "OnDuty";
      } else if (resp.exmType === "lc") {
        resp["exmType"] = "LateCome";
      } else if (resp.exmType === "eg") {
        resp["exmType"] = "EarlyGo";
      } else if (resp.exmType === "lceg") {
        resp["exmType"] = "LateComeEarlyGo";
      } else if (resp.exmType === "CL") {
        resp["exmType"] = "Compensatory Leave";
      }
    });
    return resData;
  } else {
    return [];
  }
};
const addExemption = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.date = DateFormatMonth(resp.date);

      resp.serial = index + 1;

      if (resp.exmType === "sp") {
        resp["exmType"] = "SinglePunch";
      } else if (resp.exmType === "np") {
        resp["exmType"] = "OnDuty";
      } else if (resp.exmType === "D") {
        resp["exmType"] = "OnDuty";
      } else if (resp.exmType === "lc") {
        resp["exmType"] = "LateCome";
      } else if (resp.exmType === "eg") {
        resp["exmType"] = "EarlyGo";
      } else if (resp.exmType === "lceg") {
        resp["exmType"] = "LateComeEarlyGo";
      } else if (resp.exmType === "CL") {
        resp["exmType"] = "Compensatory Leave";
      }
    });
    return resData;
  } else {
    return [];
  }
};

const colorList = (resData) => {
  if (resData) {
    const attDayDetailsList = resData.attResponse;
    const month = resData.month
    let curMonth = new Date().getMonth() + 1
    if (attDayDetailsList) {
      const cellColors = [];
      attDayDetailsList.forEach((att) => {
        if (att.att) {
          const attMapData = {};
          if (att.att === "P") {
            attMapData["colorCode"] = "present";
            attMapData["code"] = "Present";
          } else if (att.att === "D") {
            attMapData["colorCode"] = "onduty";
            attMapData["code"] = "On Duty";
          } else if (att.att === "A") {
            if ((month != curMonth) && (att.day <= new Date().getDate() || att.day >= new Date().getDate())) {
              attMapData["colorCode"] = "absent";
              attMapData["code"] = "Absent";
            } else if ((month === curMonth) && (att.day <= new Date().getDate())) {
              attMapData["colorCode"] = "absent";
              attMapData["code"] = "Absent";
            } else {
              attMapData["colorCode"] = "n-a";
              attMapData["code"] = "NA";
            }
            // attMapData["colorCode"] = "absent";
            // attMapData["code"] = "Absent";
          } else if (att.att === "L") {
            attMapData["colorCode"] = "leave";
            attMapData["code"] = "Leave";
          } else if (att.att === "H") {
            attMapData["colorCode"] = "holiday";
            attMapData["code"] = "Holiday";
          } else if (att.att === "W") {
            attMapData["colorCode"] = "weekoff";
            attMapData["code"] = "Week Off";
          } else if (att.att === "LC" || att.att === "EG" || att.att === "SP") {
            if (att.halfDayLeave != 0) {
              attMapData["colorCode"] = "halfday";
              attMapData["code"] = "Half Day";
            } else if (att.exemStatus === "approved") {
              attMapData["colorCode"] = "permitted";
              attMapData["code"] = "Permitted";
            } else {
              attMapData["colorCode"] = "exempted";
              attMapData["code"] = "Exempted";
            }
          }
          cellColors.push({
            date: `${resData.year}-${resData.month}-${att.day}`,
            color: attMapData["colorCode"],
            code: attMapData["code"],
          });
        }
      });
      return cellColors;
    } else {
      return [];
    }
  } else {
    return [];
  }
};

const attList = (resData) => {
  if (resData) {
    const attDayDetailsList = resData.attResponse;
    const leaveTypes = resData.leaveTypes;

    if (leaveTypes) {
      attDayDetailsList.forEach((item, index) => {
        if (item.att === "L") {
          item["remark"] = leaveTypes.find((cc) => cc.day === item.day)
            ? leaveTypes.find((cc) => cc.day === item.day).leaveType
            : "";
          index = index + 1;
        }
        return attDayDetailsList;
      });
    }

    if (attDayDetailsList) {
      // console.log(attDayDetailsList, "leave");
      const attListData = [];
      // const trueBool = true
      const month = resData.month
      let curMonth = new Date().getMonth() + 1
      attDayDetailsList.forEach((att) => {
        const attMapData = {};
        attMapData["id"] = Math.random();
        attMapData["url"] = "";
        attMapData["start"] = new Date(
          `${resData.year}-${resData.month}-${att.day}`
        );
        attMapData["end"] = new Date(
          `${resData.year}-${resData.month}-${att.day}`
        );
        // attMapData['allDay'] = ((att.att === 'M' || att.att === 'N') ? false : trueBool)
        attMapData["allDay"] = true;
        attMapData["extendedProps"] = { calendar: "", time: "", remark: "" };
        if (att.att === "P") {
          attMapData["extendedProps"]["calendar"] = "Present";
          attMapData["title"] = "Present";
          attMapData["colorCode"] = "present";
        } else if (att.att === "D") {
          attMapData["extendedProps"]["calendar"] = "On Duty";
          attMapData["title"] = "On Duty";
          attMapData["colorCode"] = "onduty";
        } else if (att.att === "A") {
          if ((month != curMonth) && (att.day <= new Date().getDate() || att.day >= new Date().getDate())) {
            attMapData["extendedProps"]["calendar"] = "Absent";
            attMapData["title"] = "Absent";
            attMapData["colorCode"] = "absent";
          } else if ((month === curMonth) && (att.day <= new Date().getDate())) {
            attMapData["extendedProps"]["calendar"] = "Absent";
            attMapData["title"] = "Absent";
            attMapData["colorCode"] = "absent";
          } else {
            attMapData["extendedProps"]["calendar"] = "NA";
            attMapData["title"] = "Future Date";
            attMapData["colorCode"] = "n-a";
          }

        } else if (att.att === "L") {
          attMapData["extendedProps"]["calendar"] = "Leave";
          attMapData["title"] = att["remark"];
          attMapData["colorCode"] = "leave";
        } else if (att.att === "H") {
          attMapData["extendedProps"]["calendar"] = "Holiday";
          attMapData["title"] = att["remark"];
          attMapData["colorCode"] = "holiday";
        } else if (att.att === "M" || att.att === "N") {
          attMapData["extendedProps"]["calendar"] = "Half Day";
          if (att.att === "M") {
            attMapData["title"] = "Half Day - Morning (M)";
          } else {
            attMapData["title"] = "Half Day - Afternoon (N)";
          }
          attMapData["colorCode"] = "halfday";
        } else if (att.att === "W") {
          attMapData["extendedProps"]["calendar"] = "Week Off";
          attMapData["title"] = "Week Off";
          attMapData["colorCode"] = "weekoff";
        } else if (att.att === "LC" || att.att === "EG" || att.att === "SP") {
          if (att.halfDayLeave != 0) {
            attMapData["extendedProps"]["calendar"] = "Half Day";
            attMapData["title"] = "Half Day";
            attMapData["colorCode"] = "halfday";
          } else if (att.exemStatus === "approved") {
            attMapData["extendedProps"]["calendar"] = "Permitted";
            attMapData["title"] = "Permitted";
            attMapData["colorCode"] = "permitted";
          } else {
            attMapData["extendedProps"]["calendar"] = "Exempted";
            attMapData["title"] = "Exempted";
            attMapData["colorCode"] = "exempted";
          }
        }
        if (att["inTime"]) {
          // attMapData['extendedProps']['time'] = `${TimeFormat(att['inTime'])} TO ${TimeFormat(att['outTime'])}`
          attMapData["extendedProps"]["time"] = `${att["inTime"].slice(
            0,
            5
          )} TO ${att["outTime"] ? att["outTime"].slice(0, 5) : "-"}`;
        }
        if (att["remark"]) {
          attMapData["extendedProps"]["remark"] = att["remark"];
        }
        attListData.push(attMapData);

        // if (att.att) {
        //   const attMapData = {}
        //   attMapData['id'] = Math.random()
        //   attMapData['url'] = ''
        //   attMapData['start'] = new Date(`${resData.year}-${resData.month}-${att.day}`)
        //   attMapData['end'] = new Date(`${resData.year}-${resData.month}-${att.day}`)
        //   // attMapData['allDay'] = ((att.att === 'M' || att.att === 'N') ? false : trueBool)
        //   attMapData['allDay'] = true
        //   if (att.att === 'P') {
        //     attMapData['extendedProps'] = { calendar: 'Present' }
        //     attMapData['title'] = 'Present'
        //     attMapData['colorCode'] = 'present'
        //   } else if (att.att === 'D') {
        //     attMapData['extendedProps'] = { calendar: 'On Duty' }
        //     attMapData['title'] = 'On Duty'
        //     attMapData['colorCode'] = 'onduty'
        //   } else if (att.att === 'A') {
        //     attMapData['extendedProps'] = { calendar: 'Absent' }
        //     attMapData['title'] = 'Absent'
        //     attMapData['colorCode'] = 'absent'
        //   } else if (att.att === 'L') {
        //     attMapData['extendedProps'] = { calendar: 'Leave' }
        //     attMapData['title'] = 'Leave'
        //     attMapData['colorCode'] = 'leave'
        //   } else if (att.att === 'H') {
        //     attMapData['extendedProps'] = { calendar: 'Holiday' }
        //     attMapData['title'] = 'Holiday'
        //     attMapData['colorCode'] = 'holiday'
        //   } else if (att.att === 'M' || att.att === 'N') {
        //     attMapData['extendedProps'] = { calendar: 'Half Day' }
        //     if (att.att === 'M') {
        //       attMapData['title'] = 'Half Day - Morning (M)'
        //     } else {
        //       attMapData['title'] = 'Half Day - Afternoon (N)'
        //     }
        //     attMapData['colorCode'] = 'halfday'
        //   } else if (att.att === 'W') {
        //     attMapData['extendedProps'] = { calendar: 'Week Off' }
        //     attMapData['title'] = 'Week Off'
        //     attMapData['colorCode'] = 'weekoff'
        //   } else if (att.att === 'LC' || att.att === 'EG' || att.att === 'SP') {
        //     if (att.exemStatus === 'active') {
        //       attMapData['extendedProps'] = { calendar: 'Permitted' }
        //       attMapData['title'] = 'Permitted'
        //       attMapData['colorCode'] = 'permitted'
        //     } else {
        //       attMapData['extendedProps'] = { calendar: 'Exempted' }
        //       attMapData['title'] = 'Exempted'
        //       attMapData['colorCode'] = 'exempted'
        //     }
        //   }
        //   attListData.push(attMapData)
        // }
        // if (att['inTime']) {
        //   const attMapData = {}
        //   attMapData['id'] = Math.random()
        //   attMapData['url'] = ''
        //   attMapData['start'] = new Date(`${resData.year}-${resData.month}-${att.day}`)
        //   attMapData['end'] = new Date(`${resData.year}-${resData.month}-${att.day}`)
        //   // attMapData['allDay'] = ((att.att === 'M' || att.att === 'N') ? false : trueBool)
        //   attMapData['allDay'] = true
        //   if (att.att === 'P') {
        //     attMapData['extendedProps'] = { calendar: 'Present' }
        //   } else if (att.att === 'D') {
        //     attMapData['extendedProps'] = { calendar: 'On Duty' }
        //   } else if (att.att === 'A') {
        //     attMapData['extendedProps'] = { calendar: 'Absent' }
        //   } else if (att.att === 'L') {
        //     attMapData['extendedProps'] = { calendar: 'Leave' }
        //   } else if (att.att === 'H') {
        //     attMapData['extendedProps'] = { calendar: 'Holiday' }
        //   } else if (att.att === 'M' || att.att === 'N') {
        //     attMapData['extendedProps'] = { calendar: 'Half Day' }
        //   } else if (att.att === 'W') {
        //     attMapData['extendedProps'] = { calendar: 'Week Off' }
        //   } else if (att.att === 'LC' || att.att === 'EG' || att.att === 'SP') {
        //     if (att.exemStatus === 'active') {
        //       attMapData['extendedProps'] = { calendar: 'Permitted' }
        //     } else {
        //       attMapData['extendedProps'] = { calendar: 'Exempted' }
        //     }
        //   }
        //   attMapData['title'] = `${TimeFormat(att['inTime'])} TO ${TimeFormat(att['outTime'])}`
        //   attListData.push(attMapData)
        // }
        // if (att['remark']) {
        //   const attMapData = {}
        //   attMapData['id'] = Math.random()
        //   attMapData['url'] = ''
        //   attMapData['start'] = new Date(`${resData.year}-${resData.month}-${att.day}`)
        //   attMapData['end'] = new Date(`${resData.year}-${resData.month}-${att.day}`)
        //   // attMapData['allDay'] = ((att.att === 'M' || att.att === 'N') ? false : trueBool)
        //   attMapData['allDay'] = true
        //   if (att.att === 'P') {
        //     attMapData['extendedProps'] = { calendar: 'Present' }
        //   } else if (att.att === 'D') {
        //     attMapData['extendedProps'] = { calendar: 'On Duty' }
        //   } else if (att.att === 'A') {
        //     attMapData['extendedProps'] = { calendar: 'Absent' }
        //   } else if (att.att === 'L') {
        //     attMapData['extendedProps'] = { calendar: 'Leave' }
        //   } else if (att.att === 'H') {
        //     attMapData['extendedProps'] = { calendar: 'Holiday' }
        //   } else if (att.att === 'M' || att.att === 'N') {
        //     attMapData['extendedProps'] = { calendar: 'Half Day' }
        //   } else if (att.att === 'W') {
        //     attMapData['extendedProps'] = { calendar: 'Week Off' }
        //   } else if (att.att === 'LC' || att.att === 'EG' || att.att === 'SP') {
        //     if (att.exemStatus === 'active') {
        //       attMapData['extendedProps'] = { calendar: 'Permitted' }
        //     } else {
        //       attMapData['extendedProps'] = { calendar: 'Exempted' }
        //     }
        //   }
        //   attMapData['title'] = att['remark']
        //   attListData.push(attMapData)
        // }
      });
      return attListData;
    } else {
      return [];
    }
  } else {
    return [];
  }
};

const calenderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_EVENTS":
      return { ...state, events: action.events };
    case "ADD_EVENT":
      return { ...state };
    case "REMOVE_EVENT":
      return { ...state };
    case "UPDATE_EVENT":
      return { ...state };
    case "SELECT_EVENT":
      return { ...state, selectedEvent: action.event };
    case "UPDATE_FILTERS":
      // ** Updates Filters based on action filter
      const filterIndex = state.selectedCalendars.findIndex(
        (i) => i === action.filter
      );
      if (state.selectedCalendars.includes(action.filter)) {
        state.selectedCalendars.splice(filterIndex, 1);
      } else {
        state.selectedCalendars.push(action.filter);
      }
      const selectedCalendars = state.selectedCalendars;
      const attDayDetailsList = state.attDayDetailsList;
      const filterData = attDayDetailsList.filter((event) =>
        selectedCalendars.includes(event.extendedProps.calendar)
      );
      return { ...state, events: filterData };
    case "UPDATE_ALL_FILTERS":
      // ** Updates All Filters based on action value
      const value = action.value;
      let selected = [];
      if (value === true) {
        selected = [
          "Present",
          "On Duty",
          "Absent",
          "Leave",
          "Holiday",
          "Half Day",
          "Week Off",
          "Exempted",
          "Permitted",
        ];
      } else {
        selected = [];
      }
      const attDayList = state.attDayDetailsList;
      const filtersData = attDayList.filter((event) =>
        selected.includes(event.extendedProps.calendar)
      );
      return { ...state, selectedCalendars: selected, events: filtersData };
    case "FETCH_EMP_ATTENDANCE":
      return {
        ...state,
        spinner: action.spinner,
        empWorkHoursList: initialItem(action.data),
        attDayDetailsList: attList(action.data),
        cellColors: colorList(action.data),
        events: attList(action.data),
        allData: leaveList(action.allData),
        data: action.datas,
        total: action.totalPages,
        params: action.params,
      };
    case "FETCH_EMP_ATTENDANCE_ISSUE":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
        empWorkHoursList: [],
        attDayDetailsList: [],
        cellColors: [],
        events: [],
        allData: [],
        data: [],
      };
    case "GET_EMP_ATT_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "EMP_ATT_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "FETCH_EMP_ATT_DATA":
      return {
        ...state,
        empExeData: action.data,
        spinner: action.spinner,
      };
    case "FETCH_ATTENDANCE_EXMP":
      return {
        ...state,
        allData: leaveList(action.allData),
        data: getExemptions(action.data),
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "ADD_EXMP":
      return {
        ...state,
        // allData: addExemption(action.allData),
      };
    case "LEAVE_TYPE":
      return {
        ...state,
        leaveTypes: action.leaveTypes,
      };
    case "ATT_LOADING_SPINNER":
      return {
        ...state,
        tokenExp: action.tokenExp,
      };
    case "EMP_WORK_HOURS_DATA":
      return {
        ...state,
        empWorkHoursData: action.data,
        spinner: action.spinner,
      };
    case "EMP_WORK_ HOURS_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    case "GET_ALL_DYNAMIC_DATES_DATA":
      return {
        ...state,
        dynamicdata: action.allData,
        dynamicspinner: action.spinner,
      }
    case "DYNAMIC_DATES_LOADING_SPINNER":
      return {
        ...state,
        dynamicspinner: action.spinner,
      }
    case "GET_EMP_ATT_EXEM_LIMIT_LOADING_SPINNER":
      return {
        ...state,
        elSpinner: action.spinner
      }
    case "GET_EMP_ATT_EXEM_LIMIT":
      return {
        ...state,
        exemLimit: action.exemLimit,
        exemUsed: action.exemUsed,
        elSpinner: action.spinner,
      }
    default:
      return state;
  }
};

export default calenderReducer;
