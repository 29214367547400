import { DefaultConfig } from "../../../../../../settings/config";

const Classes = DefaultConfig().Classes;
const AcademicYear = DefaultConfig().AcademicYear;
const Level = DefaultConfig().Level;
const PaperDisplay = DefaultConfig().PaperDisplay;
const ResultType = DefaultConfig().ResultType;
const QuestionsSkipping = DefaultConfig().QuestionsSkipping;
const TimePattern = DefaultConfig().TimePattern;

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
};

const initialItem = (resData) => {
  if (resData) {
    console.log(Classes, "reducer");
    resData.forEach((resp, index) => {
      resp["clsName"] = Classes.find((item) => item.value == resp.cls)
        ? Classes.find((item) => item.value == resp.cls).label
        : "";
      resp["levelName"] = Level.find((item) => item.value === resp.level)
        ? Level.find((item) => item.value === resp.level).label
        : "";
      resp["paperDispName"] = PaperDisplay.find(
        (item) => item.value === resp.paperDisp
      )
        ? PaperDisplay.find((item) => item.value === resp.paperDisp).label
        : "";
      resp["resultTypeName"] = ResultType.find(
        (item) => item.value === resp.resultType
      )
        ? ResultType.find((item) => item.value === resp.resultType).label
        : "";
      resp["queSkipName"] = QuestionsSkipping.find(
        (item) => item.value === resp.queSkip
      )
        ? QuestionsSkipping.find((item) => item.value === resp.queSkip).label
        : "";
      resp["timePatternName"] = TimePattern.find(
        (item) => item.value === resp.timePattern
      )
        ? TimePattern.find((item) => item.value === resp.timePattern).label
        : "";

      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const OnlineExams = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_ONLINE_EXAM_DATA":
      //  console.log(action, "allData")
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_ONLINE_EXAM_DATA":
      // console.log(action.data, "data")
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_ONLINE_EXAM":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };
    case "ADD_ONLINE_EXAM":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "UPDATE_ONLINE_EXAM":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "DELETE_ONLINE_EXAM":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "ONLINE_EXAM_LOADING_SPINNER":
      // console.log(action.spinner, "ree")
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default OnlineExams;
