// ** React Import
import * as yup from "yup";
import { Fragment, useState, useEffect } from "react";
import Select from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";

// ** Custom Components
import Sidebar from "@components/sidebar";

// ** Utils
import { isObjEmpty, selectThemeColors } from "@utils";

// ** Third Party Components
import classnames from "classnames";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Button, FormGroup, Label, Form, Input, Row, Col } from "reactstrap";

// ** Store & Actions
import { addFeeHeads, updateFeeHeads } from "../store/action";
import { useDispatch } from "react-redux";
import { DefaultConfig } from "../../../../../settings/config";

const SidebarFeeHeads = ({
  open,
  toggleSidebar,
  title,
  selectedData,
  accData,
}) => {
  // ** config vars
  const terms = DefaultConfig().Terms;
  const hidebar = DefaultConfig().HideOption;
  const applicable = DefaultConfig().ApplicableTo;
  const feetypes = DefaultConfig().FeeType;
  const Edit = DefaultConfig().EditStd;
  const Splcourse = DefaultConfig().SpecialCourse;
  const Gen = [
    { value: 1, label: "Boy" },
    { value: 2, label: "Girl" },
    { value: 3, label: "Both" },
  ];
  // ** States
  const [hideoption, setHideOption] = useState(false);
  const [newadmin, setNewAdmin] = useState(false);
  const [feeType, setFeeType] = useState("");
  const [editstd, setEditStd] = useState(false);
  const [gender, setGender] = useState(3);
  const [special, setSpecial] = useState(2);
  const [accountHeadOptions, setAccountList] = useState(accData);
  const [course, setCourse] = useState();

  useEffect(() => {
    if (accData) {
      setAccountList(accData);
    }
  }, [accData]);

  // ** updates
  useEffect(() => {
    if (selectedData) {
      // if (selectedData.hstl === false && selectedData.route === false) {
      //   setFeeType(3);
      // } else if (selectedData.hstl === false && selectedData.route === true) {
      //   setFeeType(1);
      // } else {
      //   setFeeType(2);
      // }
      setNewAdmin(selectedData.newAdm);
      setHideOption(selectedData.hideOption);
      setEditStd(selectedData.editByStu);
      setGender(selectedData.gender);
      if (selectedData.splCourse === 1) {
        setSpecial(1);
        setCourse(selectedData.courseFee);
      } else {
        setSpecial(2);
      }
    }
  }, [selectedData]);

  // ** Store Vars
  const dispatch = useDispatch();

  // ** Vars
  const ValidSchema = yup.object().shape({
    [`feeheader`]: yup.string().required("Fee header is a required field"),

    [`term`]: yup
      .number()
      .typeError("Term is a required field")
      .required("Term is a required field"),
    [`hideoption`]: yup
      .boolean()
      .transform((value) => (value === null ? null : value))
      .notRequired("Hide option is a required field"),
    [`newadmin`]: yup
      .boolean()
      .transform((value) => (value === null ? null : value))
      .notRequired("Applicable to  is a required field"),
    [`feeType`]: yup
      .string()
      .typeError("feetype is a required field")
      .required("feetype is a required field"),
    // [`feetype`]: yup
    //   .string()
    //   .transform((value) => (value === null ? null : value))
    //   .nullable()
    //   .equired("Fee Type is a required field"),
    [`actname`]: yup
      .number()
      .transform((value) => (isNaN(value) ? null : value))
      .nullable()
      .notRequired("Account name is a required field"),
    [`priority`]: yup
      .number()
      .transform((value) => (isNaN(value) ? null : value))
      .nullable()
      .typeError("Priority is a required field and must specify a number")
      .notRequired("Priority is a required field"),
    [`gst`]: yup
      .number()
      .typeError("GST is a required field and must specify a number")
      .required("GST is a required field"),
    [`editstd`]: yup
      .boolean()
      .transform((value) => (value === null ? null : value))
      .notRequired("Edit in student  is a required field"),
    [`special`]: yup
      .number()
      .transform((value) => (value === null ? null : value))
      .nullable()
      .notRequired("Special course  is a required field"),
    [`course`]: yup
      .number()
      .typeError("Course is a required field and must specify a number")
      .notRequired("please")
      .when("special", (val, schema) => {
        if (val === 1)
          return yup
            .number()
            .typeError("Course is a required field and must specify a number")
            .required("Course is a required field and must specify a number");
        else return yup.number().notRequired();
      }),
  });

  const { register, errors, handleSubmit, trigger, control, reset, formState } =
    useForm({
      resolver: yupResolver(ValidSchema),
      mode: "onChange",
    });

  const { isDirty, isValid } = formState;
  // ** Function to handle form submit
  const onSubmit = (values) => {
    trigger();
    if (isObjEmpty(errors)) {
      toggleSidebar();
      if (title === "Add") {
        dispatch(
          addFeeHeads({
            feeheader: values.feeheader,
            term: values.term,
            gender: gender,
            hideoption: values.hideoption,
            newadmin: values.newadmin,
            feeType: values.feeType,
            actname: values.actname,
            priority: values.priority,
            gst: values.gst,
            editstd: values.editstd,
            special: values.special,
            course: values.course,
          })
        );
      } else if (title === "Edit") {
        dispatch(
          updateFeeHeads(
            {
              feeheader: values.feeheader,
              term: values.term,
              gender: gender,
              hideoption: values.hideoption,
              newadmin: values.newadmin,
              feeType: values.feeType,
              actname: values.actname,
              priority: values.priority,
              gst: values.gst,
              editstd: values.editstd,
              special: values.special,
              course: values.course,
            },
            selectedData
          )
        );
      }
    }
  };

  const onFeeChange = (name, value) => {
    setHideOption(value);
  };

  const onNewChange = (name, value) => {
    setNewAdmin(value);
  };

  const onFeeTypeChange = (name, value) => {
    setFeeType(value);
  };

  const onEditChange = (name, value) => {
    setEditStd(value);
  };
  const ongenchange = (name, value) => {
    setGender(value);
  };
  const onsplchange = (name, value) => {
    setSpecial(value);
  };

  return (
    <Sidebar
      size="lg"
      open={open}
      title={`${title} Fee Heads`}
      headerClassName="mb-1"
      contentClassName="pt-0"
      toggleSidebar={toggleSidebar}
      className={"model-popup1"}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col sm="12" md="12" lg="12" xl="12">
            <h5>Add FeeHeads</h5>
          </Col>
          <Col sm="12" md="12" lg="6" xl="6">
            <FormGroup>
              <Label for="feeheader">
                Fee Header <span className="text-danger">*</span>
              </Label>
              <Input
                readonly={title === "View" && "readonly"}
                name="feeheader"
                placeholder="Enter print head"
                innerRef={register({ required: true })}
                defaultValue={selectedData ? selectedData.printHead : ""}
                className={classnames({ "is-invalid": errors["feeheader"] })}
              />
              <ErrorMessage
                errors={errors}
                name="feeheader"
                render={({ message }) => (
                  <span className="text-danger">{message}</span>
                )}
              />
            </FormGroup>
          </Col>
          <Col sm="12" md="12" lg="6" xl="6">
            <FormGroup>
              <Label for="term">
                Term(s) <span className="text-danger">*</span>
              </Label>
              <Controller
                render={({ onChange, value, ref }) => (
                  <Select
                    inputRef={ref}
                    classNamePrefix="select"
                    placeholder="Select"
                    options={terms}
                    value={terms.find((c) => c.value === value)}
                    onChange={(val) => {
                      onChange(val ? val.value : "");
                    }}
                    className={classnames("react-select", {
                      "is-invalid": errors["term"],
                    })}
                    isClearable
                    isDisabled={title === "View" ? true : false}
                  />
                )}
                defaultValue={selectedData ? selectedData.terms : ""}
                control={control}
                name="term"
                theme={selectThemeColors}
              />
              <ErrorMessage
                errors={errors}
                name="term"
                render={({ message }) => (
                  <span className="text-danger">{message}</span>
                )}
              />
            </FormGroup>
          </Col>
          {/* <Col sm="12" md="12" lg="6" xl="6">
            <Label for="hideoption" className="mt-50">
              Hide Option
            </Label>
            <div className="mb-1">
              {hidebar.map((hidevalue, index) => (
                <FormGroup check inline key={index.toString()}>
                  <Label check>
                    <Input
                      disabled={title === "View" ? true : false}
                      type="radio"
                      name="hideoption"
                      value={hidevalue.value}
                      checked={hideoption === hidevalue.value}
                      innerRef={register({ required: false })}
                      onChange={() =>
                        onFeeChange("hideoption", hidevalue.value)
                      }
                    />
                    {hidevalue.label}
                  </Label>
                </FormGroup>
              ))}
              <ErrorMessage
                errors={errors}
                name="hideoption"
                render={({ message }) => (
                  <span className="text-danger">{message}</span>
                )}
              />
            </div>
          </Col> */}
          <Col sm="12" md="12" lg="6" xl="6">
            <Label for="newadmin" className="mt-50">
              Applicable To
            </Label>
            <div className="mb-1">
              {applicable.map((applicablevalue, index) => (
                <FormGroup check inline key={index.toString()}>
                  <Label check>
                    <Input
                      disabled={title === "View" ? true : false}
                      type="radio"
                      name="newadmin"
                      value={applicablevalue.value}
                      checked={newadmin === applicablevalue.value}
                      innerRef={register({ required: false })}
                      onChange={() =>
                        onNewChange("newadmin", applicablevalue.value)
                      }
                    />
                    {applicablevalue.label}
                  </Label>
                </FormGroup>
              ))}
              <ErrorMessage
                errors={errors}
                name="newadmin"
                render={({ message }) => (
                  <span className="text-danger">{message}</span>
                )}
              />
            </div>
          </Col>
          {/* <Col sm="12" md="12" lg="6" xl="6">
            <Label for="feetype" className="mt-50">
              Fee Type
            </Label>
            <div className="mb-1">
              {feetypes.map((feevalue, index) => (
                <FormGroup check inline key={index.toString()}>
                  <Label check>
                    <Input
                      type="radio"
                      disabled={title === "View" ? true : false}
                      name="feetype"
                      value={feevalue.value}
                      checked={feetype === feevalue.value}
                      innerRef={register({ required: false })}
                      onChange={() =>
                        onFeeTypeChange("feetype", feevalue.value)
                      }
                    />
                    {feevalue.label}
                  </Label>
                </FormGroup>
              ))}
              <ErrorMessage
                errors={errors}
                name="feetype"
                render={({ message }) => (
                  <span className="text-danger">{message}</span>
                )}
              />
            </div>
          </Col> */}
          <Col sm="12" md="12" lg="6" xl="6">
            <FormGroup>
              <Label for="feeType">
                Fee Type<span className="text-danger">*</span>
              </Label>
              <Controller
                render={({ onChange, value, ref }) => (
                  <Select
                    inputRef={ref}
                    classNamePrefix="select"
                    placeholder="Select "
                    options={feetypes}
                    value={feetypes.find((c) => c.value === value)}
                    onChange={(val) => {
                      onChange(val ? val.value : "");
                    }}
                    defaultValue={selectedData ? selectedData.feetypes : ""}
                    className={classnames("react-select")}
                    isClearable
                    isDisabled={title === "View" ? true : false}
                  />
                )}
                defaultValue={selectedData ? selectedData.feeType : ""}
                control={control}
                name="feeType"
                theme={selectThemeColors}
              />
              <ErrorMessage
                errors={errors}
                name="feeType"
                render={({ message }) => (
                  <span className="text-danger">{message}</span>
                )}
              />
            </FormGroup>
          </Col>
          <Col sm="12" md="12" lg="6" xl="6">
            <FormGroup>
              <Label for="actname">Account Name</Label>
              <Controller
                render={({ onChange, value, ref }) => (
                  <Select
                    inputRef={ref}
                    classNamePrefix="select"
                    placeholder="Select"
                    options={accountHeadOptions}
                    value={accountHeadOptions.find((c) => c.value === value)}
                    onChange={(val) => {
                      onChange(val ? val.value : "");
                    }}
                    className={classnames("react-select", {
                      "is-invalid": errors["actname"],
                    })}
                    isClearable
                    isDisabled={title === "View" ? true : false}
                  />
                )}
                defaultValue={selectedData ? selectedData.finHead : ""}
                control={control}
                name="actname"
                theme={selectThemeColors}
              />
              <ErrorMessage
                errors={errors}
                name="actname"
                render={({ message }) => (
                  <span className="text-danger">{message}</span>
                )}
              />
            </FormGroup>
          </Col>
          <Col sm="12" md="12" lg="6" xl="6">
            <FormGroup>
              <Label for="priority"> Priority </Label>
              <Input
                readonly={title === "View" && "readonly"}
                name="priority"
                type="number"
                placeholder="Enter free head priority"
                innerRef={register({ required: false })}
                defaultValue={selectedData ? selectedData.priority : ""}
                className={classnames({ "is-invalid": errors["priority"] })}
              />
              <ErrorMessage
                errors={errors}
                name="priority"
                render={({ message }) => (
                  <span className="text-danger">{message}</span>
                )}
              />
            </FormGroup>
          </Col>
          <Col sm="12" md="12" lg="6" xl="6">
            <FormGroup>
              <Label for="gst">
                GST(%) <span className="text-danger">*</span>
              </Label>
              <Input
                readonly={title === "View" && "readonly"}
                name="gst"
                id="numbers"
                type="number"
                step="any"
                pattern="[0-9.]+"
                placeholder="Enter GST percentage"
                innerRef={register({ required: true })}
                defaultValue={selectedData ? selectedData.gst : ""}
                className={classnames({ "is-invalid": errors["gst"] })}
              />
              <ErrorMessage
                errors={errors}
                name="gst"
                render={({ message }) => (
                  <span className="text-danger">{message}</span>
                )}
              />
            </FormGroup>
          </Col>
          <Col sm="12" md="12" lg="6" xl="6">
            <Label for="editstd" className="mt-50">
              Edit In Student Fee Setup
            </Label>
            <div className="mb-1">
              {Edit.map((editstdvalue, index) => (
                <FormGroup check inline key={index.toString()}>
                  <Label check>
                    <Input
                      type="radio"
                      disabled={title === "View" ? true : false}
                      name="editstd"
                      value={editstdvalue.value}
                      checked={editstd === editstdvalue.value}
                      innerRef={register({ required: false })}
                      onChange={() =>
                        onEditChange("editstd", editstdvalue.value)
                      }
                    />
                    {editstdvalue.label}
                  </Label>
                </FormGroup>
              ))}
              <ErrorMessage
                errors={errors}
                name="editstd"
                render={({ message }) => (
                  <span className="text-danger">{message}</span>
                )}
              />
            </div>
          </Col>
          <Col sm="12" md="12" lg="12" xl="12">
            <h5>Special Courses</h5>
          </Col>
          <Col sm="12" md="12" lg="6" xl="6">
            <Label for="special" className="mt-50">
              Special Courses
            </Label>
            <div className="mb-1">
              {Splcourse.map((specialCoursevalue, index) => (
                <FormGroup check inline key={index.toString()}>
                  <Label check>
                    <Input
                      type="radio"
                      disabled={title === "View" ? true : false}
                      name="special"
                      value={specialCoursevalue.value}
                      checked={special === specialCoursevalue.value}
                      innerRef={register({ required: false })}
                      onChange={() =>
                        onsplchange("special", specialCoursevalue.value)
                      }
                    />
                    {specialCoursevalue.label}
                  </Label>
                </FormGroup>
              ))}
              <ErrorMessage
                errors={errors}
                name="special"
                render={({ message }) => (
                  <span className="text-danger">{message}</span>
                )}
              />
            </div>
          </Col>
          
          <Col sm="12" md="12" lg="6" xl="6">
            <Label for="gender" className="mt-50">
              Gender
            </Label>
            <div className="mb-1">
              {Gen.map((genvalue, index) => (
                <FormGroup check inline key={index.toString()}>
                  <Label check>
                    <Input
                      type="radio"
                      disabled={title === "View" ? true : false}
                      name="gender"
                      value={genvalue.value}
                      checked={gender === genvalue.value}
                      innerRef={register({ required: false })}
                      onChange={() =>
                        ongenchange("special", genvalue.value)
                      }
                    />
                    {genvalue.label}
                  </Label>
                </FormGroup>
              ))}
              <ErrorMessage
                errors={errors}
                name="gender"
                render={({ message }) => (
                  <span className="text-danger">{message}</span>
                )}
              />
            </div>
          </Col>
          {special === 1 && (
            <Fragment>
              <Col sm="12" md="12" lg="6" xl="6">
                <FormGroup>
                  <Label for="course">
                    Course Fee <span className="text-danger">*</span>
                  </Label>
                  <Input
                    readonly={title === "View" && "readonly"}
                    name="course"
                    id="numbers"
                    type="number"
                    value={course}
                    step="any"
                    pattern="[0-9.]+"
                    placeholder="Enter Course"
                    innerRef={register({ required: true })}
                    defaultValue={selectedData ? selectedData.course : ""}
                    className={classnames({ "is-invalid": errors["course"] })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="course"
                    render={({ message }) => (
                      <span className="text-danger">{message}</span>
                    )}
                  />
                </FormGroup>
              </Col>
            </Fragment>
          )}
          <Col sm="12" md="12" lg="12" xl="12" style={{ marginTop: "20px" }}>
            {title !== "View" && (
              <Fragment>
                <Button
                  type="submit"
                  className="mr-1"
                  color="primary"
                  //disabled={!isDirty || !isValid}
                >
                  {title === "Add" ? "Submit" : "Update"}
                </Button>
                <Button
                  type="reset"
                  color="secondary"
                  outline
                  onClick={() => reset()}
                >
                  Reset
                </Button>
              </Fragment>
            )}
          </Col>
        </Row>
      </Form>
    </Sidebar>
  );
};

export default SidebarFeeHeads;
