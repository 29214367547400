import { act } from "react";

// ** Initial State
const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    spinner: false,
    tokenExp: false,

    udlAllData: [],
    udlData: [],
    udlTotal: 1,
    udlParams: {},
    udlSpinner: false,

    apiData: [],
    apiSpinner: false,

    udlData: [],
    udlCount: '',
    udlSpinner: false,

    rssData: '',
    rssSpinner: false,
};

const initialItem = (resData) => {
    if (resData) {
        resData.forEach((resp, index) => {
            resp.serial = index + 1;
        });
        return resData;
    } else {
        return [];
    }
};

const SmsApiUdl = (state = initialState, action) => {
    switch (action.type) {
        case "GET_API_BAL_DATA":
            return {
                ...state,
                apiData: initialItem(action.allData),
                apiSpinner: action.spinner,
            };
        case "GET_API_BAL_DATA_LOADING_SPINNER":
            return {
                ...state,
                apiSpinner: action.spinner,
                tokenExp: action.tokenExp,
            };
        case "GET_UDL_SMS_DATA":
            return {
                ...state,
                udlData: initialItem(action.allData),
                udlCount: action.udlCount,
                udlSpinner: action.spinner,
            };
        case "GET_UDL_SMS_DATA_LOADING_SPINNER":
            return {
                ...state,
                udlSpinner: action.spinner,
                tokenExp: action.tokenExp,
            };
        case "GET_SCHEDULED_SMS_REPORT_DATA":
            return {
                ...state,
                udlAllData: action.allData,
                udlData: initialItem(action.data),
                udlTotal: action.totalPages,
                udlParams: action.params,
                udlSpinner: action.spinner,
            };
        case "GET_SCHEDULED_SMS_REPORT_LOADING_SPINNER":
            return {
                ...state,
                udlSpinner: action.spinner,
                tokenExp: action.tokenExp,
            };
        case "GET_SCHEDULED_SMS_FILTER_DATA":
            return {
                ...state,
                udlData: action.data,
                udlTotal: action.totalPages,
                udlParams: action.params,
            };
        case "RESEND_SCHEDULED_SMS_DATA_LOADING_SPINNER":
            return {
                ...state,
                rssSpinner: action.spinner,
                tokenExp: action.tokenExp,
            };
        case "RESEND_SCHEDULED_SMS_DATA":
            return {
                ...state,
                rssData: action.allData,
                rssSpinner: action.spinner,
            };
        default:
            return { ...state };
    }
};
export default SmsApiUdl;
