import * as yup from "yup";
import { Fragment, useState, useEffect } from "react";
import Select from "react-select";
import classnames from "classnames";
import { useForm, Controller } from "react-hook-form";
import { selectThemeColors, isObjEmpty } from "@utils";
import {
  Label,
  FormGroup,
  Row,
  Col,
  Button,
  Form,
  Input,
  Card,
  CardBody,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { DefaultConfig } from "../../../../../settings/config";
import { getAllData } from "../store/action";
import { useDispatch } from "react-redux";
import { getDirRule } from "postcss-rtl/lib/rules";
import { ErrorMessage } from "@hookform/error-message";
import SiblingTable from "./Table";
import { MultiSelect } from "react-multi-select-component";

const siblingForm = ({ }) => {
  // ** Store Vars
  const dispatch = useDispatch();

  // ** States
  // const selectedBranches = DefaultConfig().Branches.map((cus) => ({
  //   value: cus.value,
  //   label: cus.branchName,
  // }));

  const [selectedBranches, setselectedBranches] = useState(DefaultConfig().Branches);
  const [fixedValue, setFixedValue] = useState([]);
  const [submitBool, setSubmit] = useState(false);
  const [getId, setGetId] = useState([]);

  // ** updates
  const ValidSchema = yup.object().shape({
    [`compId`]: yup.number().required("Company ID is a required field"),
  });

  const { register, errors, handleSubmit, trigger, control, formState } =
    useForm({
      resolver: yupResolver(ValidSchema),
      mode: "onChange",
    });
  const { isDirty, isValid } = formState;

  const onGetSubmit = () => {
    setSubmit(true)
  }

  useEffect(() => {
   if (fixedValue.length > 0) {
    const getvalue = []
     for (let i = 0; i < fixedValue.length; i++) {
      const element = fixedValue[i];
      getvalue.push(element.value)
     }
     setGetId(getvalue)
   }
  }, [fixedValue, fixedValue?.length])

  const onChangeBranch = (e) => {
    setSubmit(false)
    setFixedValue(e);
  }

  return (
    <Fragment>
      {!submitBool && (
        <div>
          <Col>
            <Card>
              <CardBody className="">
                <Form>
                  <Row>
                    <Col sm="12" md="4" lg="4" xl="4" />
                    <Col sm="12" md="4" lg="4" xl="4" className='d-none'>
                      <FormGroup>
                        <Label for="compId">Branch</Label>
                        <Controller
                          render={({ onChange, value, ref }) => (
                            <Select
                              inputRef={ref}
                              classNamePrefix="select"
                              placeholder="Select"
                              options={selectedBranches}
                              value={selectedBranches.find(
                                (c) => c.value === value
                              )}
                              onChange={(val) => {
                                onChange(val ? val.value : ""),
                                  setGetId(val ? val.value : "");
                              }}
                              className={classnames("react-select")}
                              isClearable
                            />
                          )}
                          control={control}
                          name="compId"
                          theme={selectThemeColors}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="4" lg="4" xl="4">
                        <FormGroup>
                          <Label className="form-label" for="branch">
                            Branch Selection
                          </Label>
                          <MultiSelect
                            options={selectedBranches}
                            value={fixedValue}
                            onChange={(e) => onChangeBranch(e)}
                            labelledBy={"Select"}
                            isCreatable={true}
                            overrideStrings={{
                              allItemsAreSelected: "All branches are selected",
                            }}
                          />
                        </FormGroup>
                      </Col>
                    <Col sm="12" md="4" lg="4" xl="4" >
                      <Button onClick={() => onGetSubmit()} color="primary" style={{marginTop: '25px'}}>
                        {" "}
                        Submit{" "}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </div>
      )}

      {submitBool && (
        <Card>
          <CardBody>
            <SiblingTable getId={getId} />
          </CardBody>
        </Card>
      )}
    </Fragment>
  );
};

export default siblingForm;
