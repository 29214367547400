import { DefaultConfig } from "../../../../../../settings/config";

// ** Initial State
const initialState = {
  npSubjTopicData: [],
  npSubjTopicSpinner: false,

  nptiData: {},
  nptiSpinner: false,

  swTrackData: [],
  swTrackSpinner: false,
};


const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const NaipunyaStuDB = (state = initialState, action) => {
  switch (action.type) {
    case "NP_SUBJ_TOPIC_LOADING_SPINNER":
        return {
          ...state,
          npSubjTopicSpinner: action.spinner,
          tokenExp: action.tokenExp,
        };
    case "GET_NP_SUBJ_TOPIC":
        return {
            ...state,
            npSubjTopicData: initialItem(action.topicData),
            npSubjTopicSpinner: action.spinner,
        };
    case "SAVE_NP_TOPIC_IMG_LOADING_SPINNER":
        return {
          ...state,
          nptiSpinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      case "SAVE_NP_TOPIC_IMG":
        return {
          ...state,
          nptiData: action.allData,
          npSubjTopicData: initialItem(action.resultData),
          nptiSpinner: action.spinner,
        };
      case "SW_TRACKER_LOADING_SPINNER":
        return {
          ...state,
          swTrackSpinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      case "GET_SW_TRACKER_DATA":
        return {
          ...state,
          swTrackData: action.allData,
          swTrackSpinner: action.spinner,
        };
        
    default:
      return { ...state };
  }
};
export default NaipunyaStuDB;
