// import { array } from "prop-types";
// import { DefaultConfig } from "../../../../../settings/config";

import { DefaultConfig } from "../../../../../settings/config";

// // ** Initial State
// const initialState = {
//   allData: [],
//   data: [],
//   monthData: [],
//   mondata: [],
//   montotal: 1,
//   total: 1,
//   params: {},
//   spinner: false,
//   uspinner: false,
//   tokenExp: false,
//   mspinner: false,
//   columnData: [],
//   holidays: [],
// };

// const initialItem = (resData) => {
//   if (resData) {
//     resData.forEach((resp, index) => {
//       resp.serial = index + 1;
//       for (const key in resp.attMap) {
//         resp["attendance"] = resp.attMap[key]
//           ? resp.attMap[key]
//           : DefaultConfig().stuAtt;
//         resp["day"] = key;
//         if (resp["attendance"] == "P") {
//           resp["color"] = "#4B8641";
//         } else if (resp["attendance"] == "A") {
//           resp["color"] = "#DF0101";
//         } else if (resp["attendance"] == "L") {
//           resp["color"] = "#B18904";
//         } else if (resp["attendance"] == "H") {
//           resp["color"] = "#9178ED";
//         } else if (resp["attendance"] == "M") {
//           resp["color"] = "#FE642E";
//         } else if (resp["attendance"] == "N") {
//           resp["color"] = "#FA8258";
//         } else if (resp["attendance"] == "W") {
//           resp["color"] = "#848484";
//         } else if (resp["attendance"] == "-") {
//           resp["color"] = "#2E9AFE";
//         }
//       }
//     });
//     return resData;
//   } else {
//     return [];
//   }
// };

// const getColumns = (resData) => {
//   var columns = [];
//   columns.push(
//     {
//       name: "S.NO",
//       minWidth: "0px",
//       selector: "serial",
//       sortable: true,
//       showhide: true,
//       cell: (row) => row.serial,
//     },
//     {
//       name: "Name",
//       minWidth: "0px",
//       selector: "name",
//       sortable: true,
//       showhide: true,
//       cell: (row) => row.name,
//     },
//     {
//       name: "Roll No",
//       minWidth: "0px",
//       selector: "regNo",
//       sortable: true,
//       showhide: true,
//       cell: (row) => row.regNo,
//     },

//     {
//       name: "Class",
//       minWidth: "0px",
//       selector: "cls",
//       sortable: true,
//       showhide: true,
//       cell: (row) => row.cls,
//     },
//   );
//   if (resData.length > 0) {
//     for (const key in resData[0].attMap) {
//       columns.push({
//         name: Number(key),
//         minWidth: "0px",
//         selector: "att" + key,
//         sortable: true,
//         showhide: true,
//         cell: (row) => row[resData[0]["attData"]["att" + key]],
//       });
//     }
//   }
//   // columns.push({
//   //   name: "Present Count",
//   //   minWidth: "0px",
//   //   maxWidth: "80px",
//   //   selector: "count",
//   //   sortable: true,
//   //   showhide: true,
//   //   cell: (row) => row.count,
//   // });
//   return columns;
// };

// const HosStuAttReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case "GETALL_HOSTEL_STUATT_DATA":
//       return {
//         ...state,
//         monthData: getItem(action.allData),
//         columnData: getColumns(action.allData),
//         mondata: action.data,
//         montotal: action.totalPages,
//         params: action.params,
//         mspinner: action.spinner,
//       };
//     case "HOSTEL_STUATT_LOADING_SPINNER":
//       return {
//         ...state,
//         spinner: action.spinner,
//         tokenExp: action.tokenExp,
//       };
//     case "UPD_HOSTATT_LOADING_SPINNER":
//       return {
//         ...state,
//         uspinner: action.spinner,
//         tokenExp: action.tokenExp,
//       };
//     case "GET_HOSSTU_UPDATE_DAY_DATA":
//       return {
//         ...state,
//         monthData: getItem(action.allData),
//         mspinner: action.spinner,
//       };
//     case "GET_HOSTEL_STUATT_DATA":
//       return {
//         ...state,
//         mondata: action.data,
//         montotal: action.totalPages,
//       };
//     default:
//       return { ...state };
//   }
// };
// export default HosStuAttReducer;

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  actionTitle: "",
  spinner: false,
  tokenExp: false,
  monthData: [],
  mspinner: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      for (let i = 1; i <= 7; i++) {
        const attKey = `att${i}`;
        resp["day"] = i;
        if (resp[attKey] === null) {
          resp[attKey] = DefaultConfig().stuAtt;
          resp.color = "#2E9AFE"; // Color for null attendance
        } else {
          resp["attendance"] = resp[attKey];
          if (resp["attendance"] === "P") {
            resp.color = "#4B8641"; // Color for Present
          } else if (resp["attendance"] === "A") {
            resp.color = "#DF0101"; // Color for Absent
          }
        }
      }
    });
    return resData;
  } else {
    return [];
  }
};

const HosStuAttReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_HSTL_ATT_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: initialItem(action.data),
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "HOSTEL_STUATT_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_HSTL_ATT_GETDATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "UPD_HOSTATT_LOADING_SPINNER":
      return {
        ...state,
        mspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_HOSSTU_UPDATE_DAY_DATA":
      return {
        ...state,
        monthData: action.allData,
        mspinner: action.spinner,
      };
    default:
      return { ...state };
  }
};
export default HosStuAttReducer;
