// ** Initial State
const initialState = {
    allData: [],
    data: [],
    totalSumData: [],
    total: 1,
    params: {},
    actionTitle: "",
    spinner: false,
  };
  
  const initialItem = (resData) => {
    if (resData) {
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
      });
      return resData;
    } else {
      return [];
    }
  };
  
  const StudentExpReport = (state = initialState, action) => {
    switch (action.type) {
      case "STU_EXP_REPORT_DATA":
        return {
          ...state,
          allData: initialItem(action.allData),
          data: initialItem(action.data),
          totalSumData: initialItem(action.totalSumData),
          total: action.totalPages,
          params: action.params,
          spinner: action.spinner,
        };
      case "STU_EXP_REPORT_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp,
        };
    case "GET_STU_EXP_DATA_GETDATA":
        return {
            ...state,
            data: action.data,
            totalSumData: action.totalSumData,
            total: action.totalPages,
            params: action.params,
        };
      default:
        return { ...state };
    }
  };
  export default StudentExpReport;
  