// ** Initial State
const initialState = {
    allData: [],
    data: [],
    actionTitle: "",
    spinner: false,
    tokenExp: false,
    otpdata: {},
    otpspinner: false,
    deldata: {},
    delspinner: false
  }

  const initialItem = (resData) => {
    if (resData) {
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
      });
      return resData;
    } else {
      return [];
    }
  };
  
  const BulkExemptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_OTP_DATA":
          return { ...state,
            otpdata: action.data,
            otpspinner: action.spinner,
          }
          case "GET_OTP_LOADING_SPINNER":
          return {
            ...state,
            otpspinner: action.spinner,
          }
          case "DEL_OTP_DATA":
          return { ...state,
            deldata: action.data,
            delspinner: action.spinner,
          }
          case "DEL_OTP_LOADING_SPINNER":
          return {
            ...state,
            delspinner: action.spinner,
          }
      default:
        return { ...state }
    }
    
  }
  export default BulkExemptionReducer