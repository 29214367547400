// ** Initial State
const initialState = {
  allData: [],
  columns: [],
  data: [],
  total: 1,
  params: {},
  actionTitle: "",
  spinner: false,
  tokenExp: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const PendDiaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PEND_DIARIES_DATA":
      return {
        ...state,
        allData: action.allData,
        columns: action.columns,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "PEND_DIARIES_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_PEND_DIARY_GETDATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    default:
      return { ...state };
  }
};
export default PendDiaryReducer;
