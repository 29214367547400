// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedSms: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false
}

// const initialItem = resData => {
//   if (resData) {
//     resData.forEach((resp, index) => {
//       resp.serial = index + 1
//     })
//   }
// }

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const idTagReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_IDTAG_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner
      }
    case "GET_IDTAG_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case "GET_IDTAG":
      return {
        ...state,
        selectedSms: action.selectedSms,
        actionTitle: action.actionTitle
      }
    case "ADD_IDTAG":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner
      }
    // case "UPDATE_SMS":
    //   return {
    //     ...state,
    //     allData: initialItem(action.allData),
    //     spinner: action.spinner
    //   }
    //   case "DELETE_SMS":
    //     return { ...state,
    //       allData: initialItem(action.allData),
    //       spinner: action.spinner,
    //     }
    case "IDTAG_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp
      }
    default:
      return { ...state }
  }
}
export default idTagReducer
