// ** Initial State
const initialState = {
    allData: [],
    spinner: false,
    tokenExp: false,
  }

  const initialItem = (resData) => {
    if (resData) {
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
      });
      return resData;
    } else {
      return [];
    }
  };
  
  const ReprintInvReceipt = (state = initialState, action) => {
    switch (action.type) {
      case "GET_ALL_REPRINT_DATA":
        return {
          ...state,
          allData: (action.allData),
          spinner: action.spinner
        }
      case "REPRINT_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp
        }
      default:
        return { ...state }
    }
    
  }
  export default ReprintInvReceipt
