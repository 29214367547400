// ** React Import
import * as yup from "yup";
import { Fragment } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
// ** Custom Components
import Sidebar from "@components/sidebar";

// ** Utils
import { isObjEmpty, selectThemeColors } from "@utils";

// ** Third Party Components
import classnames from "classnames";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Button, FormGroup, Label, Form, Input, Row, Col } from "reactstrap";

// ** Store & Actions
import { addSibling, updateSibling } from "../store/action";
import { useDispatch, useSelector } from "react-redux";
import { DefaultConfig } from "../../../../../settings/config";
//import { updateSibling } from "../store/action";

const subj = DefaultConfig().Subjects;
const SidebarNewSibling = ({ open, toggleSidebar, title, selectedSibling }) => {
  console.log(title, "title");
  // ** Store Vars
  const dispatch = useDispatch();
  const selectedBranches = DefaultConfig().Branches.map((cus) => ({
    value: cus.value,
    label: cus.branchName,
  }));
  console.log(selectedBranches, "branch");
  const stuList = useSelector((state) => state.siblingMapping.stuData);
  console.log(stuList, "Student List");
  console.log(selectedSibling, "selsib");

  // ** Vars
  const ValidSchema = yup.object().shape({
    [`compId`]: yup.string().required("compId is a required field"),
    [`child1`]: yup.string().required("required field"),
    [`child2`]: yup.string().required("required field"),
  });

  const { register, errors, handleSubmit, trigger, control, reset, formState } =
    useForm({
      resolver: yupResolver(ValidSchema),
      mode: "onChange",
    });
  const { isDirty, isValid } = formState;
  // ** Function to handle form submit
  const onSubmit = (values) => {
    console.log(values, "Submit Data");
    console.log(title);
    trigger();
    if (isObjEmpty(errors)) {
      toggleSidebar();
      if (title === "Add") {
        dispatch(
          addSibling({
            compId: values.compId,
            child1: values.child1,
            child2: values.child2,
            child3: values.child3,
            child4: values.child4,
          })
        );
      } else if (title === "Edit") {
        dispatch(
          updateSibling({
            compId: values.compId,
            child1: values.child1,
            child2: values.child2,
            child3: values.child3,
            child4: values.child4,
            ref: selectedSibling.ref,
          })
        );
      }
    }
  };

  return (
    <Sidebar
      size="sm"
      open={open}
      title={`${title} Sibling Mapping`}
      headerClassName="mb-1"
      contentClassName="pt-0"
      toggleSidebar={toggleSidebar}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col sm="12" md="12" lg="12" xl="12">
            <FormGroup>
              <Label className="form-label" for="compId">
                Company Id
              </Label>
              <Controller
                render={({ onChange, value, ref }) => (
                  <Select
                    inputRef={ref}
                    classNamePrefix="select"
                    placeholder="Select"
                    options={selectedBranches ? selectedBranches : []}
                    value={
                      selectedBranches &&
                      selectedBranches.find((c) => c.value === value)
                    }
                    onChange={(val) => {
                      onChange(val ? val.value : "");
                    }}
                    className={classnames("react-select")}
                    isDisabled={title === "View" ? true : false}
                    isClearable
                  />
                )}
                defaultValue={selectedSibling ? selectedSibling.compId : ""}
                control={control}
                name="compId"
                theme={selectThemeColors}
              />
              <ErrorMessage
                errors={errors}
                name="compId"
                render={({ message }) => (
                  <span className="text-danger">{message}</span>
                )}
              />
            </FormGroup>
          </Col>
          <Col sm="12" md="12" lg="12" xl="12">
            <FormGroup>
              <Label className="form-label" for="child1">
                child 1
              </Label>
              <Controller
                render={({ onChange, value, ref }) => (
                  <Select
                    inputRef={ref}
                    classNamePrefix="select"
                    placeholder="Select"
                    options={stuList ? stuList : []}
                    value={stuList && stuList.find((c) => c.value === value)}
                    onChange={(val) => {
                      onChange(val ? val.value : "");
                    }}
                    className={classnames("react-select")}
                    isDisabled={title === "View" ? true : false}
                    isClearable
                  />
                )}
                defaultValue={selectedSibling ? selectedSibling.child1 : ""}
                control={control}
                name="child1"
                theme={selectThemeColors}
              />
              <ErrorMessage
                errors={errors}
                name="child1"
                render={({ message }) => (
                  <span className="text-danger">{message}</span>
                )}
              />
            </FormGroup>
          </Col>

          <Col sm="12" md="12" lg="12" xl="12">
            <FormGroup>
              <Label for="child2">
                child 2 <span className="text-danger">*</span>
              </Label>
              <Controller
                render={({ onChange, value, ref }) => (
                  <Select
                    inputRef={ref}
                    classNamePrefix="select"
                    placeholder="Select Student"
                    options={stuList}
                    value={stuList && stuList.find((c) => c.value === value)}
                    onChange={(val) => {
                      onChange(val ? val.value : "");
                    }}
                    className={classnames("react-select")}
                    isClearable
                    isDisabled={title === "View" ? true : false}
                  />
                )}
                defaultValue={selectedSibling ? selectedSibling.child2 : ""}
                control={control}
                name="child2"
                theme={selectThemeColors}
              />
              <ErrorMessage
                errors={errors}
                name="child2"
                render={({ message }) => (
                  <span className="text-danger">{message}</span>
                )}
              />
            </FormGroup>
          </Col>
          <Col sm="12" md="12" lg="12" xl="12">
            <FormGroup>
              <Label for="child3">child 3</Label>
              <Controller
                render={({ onChange, value, ref }) => (
                  <Select
                    inputRef={ref}
                    classNamePrefix="select"
                    placeholder="Select Student"
                    options={stuList}
                    value={stuList && stuList.find((c) => c.value === value)}
                    onChange={(val) => {
                      onChange(val ? val.value : "");
                    }}
                    className={classnames("react-select")}
                    isClearable
                    isDisabled={title === "View" ? true : false}
                  />
                )}
                defaultValue={selectedSibling ? selectedSibling.child3 : ""}
                control={control}
                name="child3"
                theme={selectThemeColors}
              />
            </FormGroup>
          </Col>
          <Col sm="12" md="12" lg="12" xl="12">
            <FormGroup>
              <Label for="child4">child 4</Label>
              <Controller
                render={({ onChange, value, ref }) => (
                  <Select
                    inputRef={ref}
                    classNamePrefix="select"
                    placeholder="Select Student"
                    options={stuList}
                    value={stuList && stuList.find((c) => c.value === value)}
                    onChange={(val) => {
                      onChange(val ? val.value : "");
                    }}
                    className={classnames("react-select")}
                    isClearable
                    isDisabled={title === "View" ? true : false}
                  />
                )}
                defaultValue={selectedSibling ? selectedSibling.child4 : ""}
                control={control}
                name="child4"
                theme={selectThemeColors}
              />
            </FormGroup>
          </Col>
          <Col sm="12" md="12" lg="12" xl="12" style={{ marginTop: "20px" }}>
            {title !== "View" && (
              <Fragment>
                <Button
                  type="submit"
                  className="mr-1"
                  color="primary"
                  disabled={!isDirty || !isValid}
                >
                  {title === "Add" ? "Submit" : "Update"}
                </Button>
                <Button
                  type="reset"
                  color="secondary"
                  outline
                  // onClick={() => toggleSidebar("Add")}
                  onClick={() => reset()}
                >
                  Reset
                </Button>
              </Fragment>
            )}
          </Col>
        </Row>
      </Form>
    </Sidebar>
  );
};

export default SidebarNewSibling;
