// ** Initial State
const initialState = {
    quantity: [],
    response: [],
    comResponse: [],
    comData: [],
    qspinner: false,
    spinner: false,
    cspinner: false,
    tokenExp: false,
    iSpinner: false,
  };
  
  const InventorySale = (state = initialState, action) => {
    switch (action.type) {
      case "ADD_INVENTORYSALE":
          return {
            ...state,
            response: action.data,
            spinner: action.spinner,
          };
      case "INVENTORY_SALE_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp,
        };
        case "ADD_COMBOSALE":
          return {
            ...state,
            response: action.cData,
            spinner: action.spinner,
          };
      case "COMBO_SALE_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      case "INV_QUAN_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      case "GET_INV_QUAN_DATA":
        return {
          ...state,
        itemRef: action.data.itemRef,
        quantity: action.data,
        qspinner: action.spinner,
        };
      case "INVENTORY_COMBO_LOADING_SPINNER":
        return {
          ...state,
          cspinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      case "GET_ALL_COMBOS_DATA":
          return {
            ...state,
            comData: action.allData,
            cspinner: action.spinner,
            tokenExp: action.tokenExp,
          };
      default:
        return { ...state };
    }
  };
  export default InventorySale;  