// ** Initial State
const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    actionTitle: "",
    spinner: false,
    tokenExp: false,
    columns: []
  };
  
  const initialItem = (resData) => {
    if (resData) {
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
      });
      return resData;
    } else {
      return [];
    }
  };

  const intialColumns = (resData) => {
    const columns = [
      {
        name: "S.NO",
        minWidth: "0px",
        maxWidth: "50px",
        selector: "serial",
        sortable: true,
        showhide: true,
        center: true,
        cell: (row) => row.serial
      },
      {
        name: 'Branch',
        minWidth: '0px',
        selector: 'compName',
        sortable: true,
        showhide: true,
        center: true,
        cell: row => row.compName
      },
      {
        name: 'Department',
        minWidth: '0px',
        selector: 'empDeptName',
        sortable: true,
        showhide: true,
        center: true,
        cell: row => row.empDeptName
      },
      // {
      //   name: 'Emp Id',
      //   minWidth: '0px',
      //   selector: 'empId',
      //   sortable: true,
      //   showhide: true,
      //   center: true,
      //   cell: row => row.empId
      // },
      {
        name: 'Employee',
        minWidth: '0px',
        selector: 'empName',
        sortable: true,
        showhide: true,
        cell: row => row.empName
      },
    ]
     
    if (resData.length > 0) {
      const leaveWiseDetailes = resData[0].leaveWiseDetailes
      for (let i = 0; i < leaveWiseDetailes.length; i++) {
        const element = leaveWiseDetailes[i];
        columns.push({
          name: element.leaveType,
          minWidth: '0px',
          selector: element.ltRef,
          sortable: false,
          showhide: true,
          cell: row => row[element.leaveType]
        },)
      }
    }

    return columns
  }
  
  const StfLeaveReportReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_STAFF_MONTHWISE_DATA":
        return {
          ...state,
          allData: initialItem(action.allData),
          data: initialItem(action.data),
          columns: intialColumns(action.allData),
          total: action.totalPages,
          params: action.params,
          spinner: action.spinner,
        };
      case "STFF_MONTHREPORT_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp,
        };
    case "GET_STFMONTHWISE_GETDATA":
        return {
            ...state,
            data: action.data,
            total: action.totalPages,
            params: action.params,
        };
      default:
        return { ...state };
    }
  };
  export default StfLeaveReportReducer;  