// ** Initial State
const initialState = {
  allData: [],
  spinner: false,
  tokenExp: false,
};

const GetRefReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_REFERENCE_DATA":
      return {
        ...state,
        allData: action.allData,
        spinner: action.spinner,
      };
    case "GET_REFERENCES_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default GetRefReducer;
