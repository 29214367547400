// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
};

const initialItem = (resData, empData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      // if (empData.find(find => find.value === resp.empId)) {
      //   resp.empName = empData.find(find => find.value === resp.empId).label
      // } else {
      //   resp.empName = ''
      // }
    });
    return resData;
  } else {
    return [];
  }
};

const Approval = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_APPSET_DATA":
      return {
        ...state,
        allData: initialItem(action.allData, state.empData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_APPSET_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_APPROVAL":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };
    case "ADD_APPROVAL":
      return {
        ...state,
        allData: initialItem(action.allData, state.empData),
        spinner: action.spinner,
      };
    case "UPDATE_APPROVAL":
      return {
        ...state,
        allData: initialItem(action.allData, state.empData),
        spinner: action.spinner,
      };

    case "GET_DELETE_DATA":
      // console.log(action.spinner, "spp")
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "DELETE_LOADING_SPINNER":
      console.log(action.spinner, "sppp");
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default Approval;
