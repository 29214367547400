import { DefaultConfig } from "../../../../../../settings/config";
const Classes = DefaultConfig().Classes
// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
};

const initialItem = (resData) => {
  if (resData) {
    const Branches = DefaultConfig().Branches;
    resData.forEach((resp, index) => {
   
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const classTeacherMappinG = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_CLASS_TEACHER_DATA":
      return {
        ...state,
        allData: action.allData,
        data: initialItem(action.data),
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_CLASS_TEACHER_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_CLASS_TEACHER":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };
    case "ADD_CLASS_TEACHER":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "UPDATE_CLASS_TEACHER":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "LOADING_CLASS_TEACHER_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "DELETE_CLS_TEACHER":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    default:
      return { ...state };
  }
};
export default classTeacherMappinG;
