import { array } from "prop-types";
import { DefaultConfig } from "../../../../../settings/config";
const instType = DefaultConfig().instType;
// ** Initial State
const initialState = {
  allData: [],
  data: [],
  monthData: [],
  mondata: [],
  montotal: 1,
  total: 1,
  params: {},
  spinner: false,
  tokenExp: false,
  mspinner: false,
  columnData: [],
  holidays: [],
  subjData: [],
  esubjetsData: [],
  sloader: false
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      for (const key in resp.attMap) {
        resp["attendance"] = resp.attMap[key] ? resp.attMap[key] : DefaultConfig().stuAtt;
        resp["day"] = key;
        if (resp["attendance"] == "P") {
          resp["color"] = "#4B8641";
        } else if (resp["attendance"] == "A") {
          resp["color"] = "#DF0101";
        } else if (resp["attendance"] == "L") {
          resp["color"] = "#B18904";
        } else if (resp["attendance"] == "H") {
          resp["color"] = "#9178ED";
        } else if (resp["attendance"] == "M") {
          resp["color"] = "#FE642E";
        } else if (resp["attendance"] == "N") {
          resp["color"] = "#FA8258";
        } else if (resp["attendance"] == "W") {
          resp["color"] = "#848484";
        } else if (resp["attendance"] == "-") {
          resp["color"] = "#2E9AFE";
        }
      }
    });
    return resData;
  } else {
    return [];
  }
};

const getItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      const attDtlsList = resp.attDtlsList
      for (let i = 0; i < attDtlsList.length; i++) {
        const element = attDtlsList[i];
        resp[`attColumn${i + 1}`] = `${element[`attColumn${i + 1}`] ? element[`attColumn${i + 1}`] : ''}`;
      }
      resp["name"] = `${resp.name}`;
      // resp["cls"] = DefaultConfig().Classes.find((cc) => cc.value === resp.cls) ? DefaultConfig().Classes.find((cc) => cc.value === resp.cls).label: "";
      resp["sec"] = DefaultConfig().Sections.find((cc) => cc.value === resp.section) ? DefaultConfig().Sections.find((cc) => cc.value === resp.section).label : "";
      resp["deptName"] = DefaultConfig().Departments.find((cc) => cc.value === resp.dept) ? DefaultConfig().Departments.find((cc) => cc.value === resp.dept).label : "";
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const getColumns = (resData, subjData) => {
  var columns = [];
  columns.push(
    {
      name: "S.NO",
      minWidth: "0px",
      selector: "serial",
      sortable: true,
      showhide: true,
      cell: (row) => row.serial,
    },
    // {
    //   name: "Department",
    //   minWidth: "0px",
    //   selector: "deptName",
    //   sortable: true,
    //   showhide: instType === "school" ? false : true,
    //   cell: (row) => row.deptName,
    // },
    // {
    //   name: "Class",
    //   minWidth: "0px",
    //   selector: "cls",
    //   sortable: true,
    //   showhide: true,
    //   cell: (row) => row.cls,
    // },
    // {
    //   name: "Sec",
    //   minWidth: "0px",
    //   selector: "sec",
    //   sortable: true,
    //   showhide: true,
    //   cell: (row) => row.sec,
    // },
    {
      name: "Roll No",
      minWidth: "0px",
      selector: "rollNo",
      sortable: true,
      showhide: true,
      cell: (row) => row.rollNo,
    },
    {
      name: "Name",
      minWidth: "0px",
      selector: "name",
      sortable: true,
      showhide: true,
      cell: (row) => row.name,
    }
  );
  if (resData.length > 0) {
    const attDtlsList = resData[0].attDtlsList;
    // console.log(attDtlsList)
    for (let i = 0; i < attDtlsList.length; i++) {
      const element = attDtlsList[i];
      const findSubj = subjData.find(find => find.value === element.subjRef)
      columns.push({
        name: findSubj?.label,
        minWidth: "0px",
        selector: element.attColumn,
        sortable: true,
        showhide: true,
        cell: (row) => row[element.attColumn],
      });
    }
  }
  return columns;
};

const getSubjects = (resData, subjData) => {
  // console.log(subjData)
  var subjects = [];
  if (resData.length > 0) {
    const attDtlsList = resData[0].attDtlsList
    for (let i = 0; i < attDtlsList.length; i++) {
      const element = attDtlsList[i];
      if (element.subjRef) {
        subjects.push({ value: element.subjRef, label: element.subjName });
      }
    }
  }
  const uniqueArray = []
  for (let i = 0; i < subjData.length; i++) {
    const element = subjData[i];
    const findSubjects = subjects.find(find => find.value === element.value)
    if (!findSubjects) {
      uniqueArray.push(element)
    }
  }
  // console.log(uniqueArray)
  return uniqueArray;
};

const PeriodWiseAttendance = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_EXAM_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
        actionTitle: action.actionTitle,
      };
    case "GET_EXAM_SUBJ_DATA":
      return {
        ...state,
        subjData: action.data
      };
    case "STUDENT_ATT_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "PGET_STUDENT_ATT_MONTH_DATA":
      return {
        ...state,
        monthData: getItem(action.allData),
        columnData: getColumns(action.allData, action.subjData),
        esubjetsData: getSubjects(action.allData, action.subjData),
        sloader: true,
        mondata: action.data,
        montotal: action.totalPages,
        params: action.params,
        mspinner: action.spinner,
      };
    case "PSTUDENT_ATT_MONTH_LOADING_SPINNER":
      return {
        ...state,
        mspinner: action.spinner,
        tokenExp: action.tokenExp,
        sloader: false,
      };
    case "PGET_STUDENT_UPDATE_MONTH_DATA":
      return {
        ...state,
        monthData: getItem(action.allData),
        columnData: getColumns(action.allData, action.subjData),
        esubjetsData: getSubjects(action.allData, action.subjData),
        sloader: true,
        mspinner: action.spinner,
      };
    case "GET_STUDENT_UPDATE_DAY_DATA":
      return {
        ...state,
        allData: action.allData,
        spinner: action.spinner,
      };
    case "GET_STU_ATT_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
      };
    case "PGET_STU_ATT_MDATA":
      return {
        ...state,
        mondata: action.data,
        montotal: action.totalPages,
      };
    default:
      return { ...state };
  }
};
export default PeriodWiseAttendance;
