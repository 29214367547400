import { DefaultConfig } from "../../../../../../settings/config";

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
};

const initialItem = (resData) => {
  if (resData) {
    const Branches = DefaultConfig().Branches;
    resData.forEach((resp, index) => {
      if (resp.feecategoriesId) {
        resp.companyId = resp.feecategoriesId.compId;
        resp.category = resp.feecategoriesId.catg;
        if (
          Branches.find((find) => find.value === resp.feecategoriesId.compId)
        ) {
          resp.companyName = Branches.find(
            (find) => find.value === resp.feecategoriesId.compId
          ).branchName;
        } else {
          resp.companyName = "";
        }
      } else {
        resp.companyName = "";
      }
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const FeeCategory = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_CAT_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_CAT_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_FEECAT":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };
    case "ADD_FEECAT":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "UPDATE_FEECAT":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "DELETE_FEECAT":
      return { ...state };
    case "LOADING_CAT_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default FeeCategory;
