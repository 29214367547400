import { DefaultConfig } from "../../../../../settings/config";

const DateFormatMonth = DefaultConfig().DateFormatMonth;
// const warenty = DefaultConfig().warenty;

// ** Initial State
const initialState = {
  //routeData: [],
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
};

const initialItem = (resData) => {
  if (resData) {
    // console.log(resData, "reducer")
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const AddVehicles = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_VEHICLES_DATA":
      //  console.log(action, "allData")
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_VEHICLES_DATA":
      // console.log(action.data, "data")
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_VEHICLES":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };
    case "ADD_VEHICLES":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "UPDATE_VEHICLES":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "VEHICLE_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "DELETE_VEHICLE":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    default:
      return { ...state };
  }
};
export default AddVehicles;
