//import { DefaultConfig } from "../../../../../settings/config";

import { DefaultConfig } from "../../../../../../settings/config";

//const DateFormatMonth = DefaultConfig().DateFormatMonth;
// const warenty = DefaultConfig().warenty;

// ** Initial State
const initialState = {
  allData: [],
  invItems: [],
  inv: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
  ispinner: false,
  qspinner: false,
  ospinner: false,
  quantity: "",
  otherquantity: "",

  stuWisePendDistAllData: [],
  stuWisePendDistData: [],
  stuWisePendDistTotal: '',
  stuWisePendDistSpinner: false,

  updPendDistData: {},
  updPendDistSpinner: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp["ddate"] = DefaultConfig().DateFormatMonth(resp.date);
      resp["dueDate"] = DefaultConfig().DateFormatMonth(resp.dueDate);
      resp["remindDate"] = DefaultConfig().DateFormatMonth(resp.remindDate);
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const DistPending = (state = initialState, action) => {
  switch (action.type) {
    case "DIST_ITEM_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_DIST_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_ALL_INVENTORY_ITEM_DATA":
      return {
        ...state,
        invItems: action.allData,
        inv: action.data,
        params: action.params,
        ispinner: action.spinner,
      };
    case "INVENTORY_ITEM_LOADING_SPINNER":
      return {
        ...state,
        ispinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "DIST_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_DIST_ITEM":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };
    case "INV_QUAN_LOADING_SPINNER":
      return {
        ...state,
        qspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_INV_QUAN_DATA":
      return {
        ...state,
        quantity: action.data,
        qspinner: action.spinner,
      };
    case "OTHER_INV_QUAN_LOADING_SPINNER":
      return {
        ...state,
        ospinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_OTHER_INV_QUAN_DATA":
      return {
        ...state,
        otherquantity: action.data,
        ospinner: action.spinner,
      };

    case "UPDATE_DIST_ITEM":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "GET_PEND_DIST_STU_WISE_COUNT":
      return {
        ...state,
        stuWisePendDistAllData: action.allData,
        stuWisePendDistData: action.data,
        stuWisePendDistTotal: action.totalPages,
        params: action.params,
        stuWisePendDistSpinner: action.spinner,
      };
    case "GET_PEND_DIST_STU_WISE_COUNT_LOADING_SPINNER":
      return {
        ...state,
        stuWisePendDistSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_PEND_DIST_STU_WISE_FILTER_DATA":
      return {
        ...state,
        stuWisePendDistData: action.data,
        stuWisePendDistTotal: action.totalPages,
        params: action.params,
      };
    case "UPDATE_PEND_DIST_LIST_LOADING_SPINNER":
      return {
        ...state,
        updPendDistSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "UPDATE_PEND_DIST_LIST":
      return {
        ...state,
        updPendDistData: action.updData,
        updPendDistSpinner: action.spinner,
      };
    default:
      return { ...state };
  }
};
export default DistPending;
