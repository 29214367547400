// ** Initial State
const initialState = {
  empAdvData: [],
  spinner: false,
  tokenExp: false,
};

const empAdvReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_EMP_ADV_DATA":
      return {
        ...state,
        empAdvData: action.empAdvData,
        spinner: action.spinner,
      };
    case "EMP_ADV_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default empAdvReportReducer;
