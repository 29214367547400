// ** Initial State
const initialState = {
  invItems: [],
  trfrResponse: [],
  spinner: false,
  tokenExp: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const InventoryTransfer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_INVENTORY_ITEM_DATA":
      //  console.log(action, "allData")
      return {
        ...state,
        invItems: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "INVENTORY_ITEM_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "SAVE_INVENTORY_TRANSFER":
        return {
          ...state,
          trfrResponse: initialItem(action.resData),
          spinner: action.spinner,
        };
    case "INVENTORY_SAVE_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default InventoryTransfer;
