// ** Initial State
const initialState = {
  noticesData: [],
  spinner: false,
  tokenExp: false,
};

const StuAnns = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ANNOUNCMENTS_DATA":
      return {
        ...state,
        noticesData: action.data,
        spinner: action.spinner,
      };
    case "STU_ANN_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default StuAnns;
