
// ** Initial State
const initialState = {
    
    spinner: false,
    data:[],
    tokenExp: false,
    data: ''

    
  }
  
  const ChangeUsername = (state = initialState, action) => {
    switch (action.type) {
    
      case "CHANGE_USER":
       // console.log(action.data, "red")
        return {
          ...state,
          data: action.data,
          spinner: action.spinner
        }
        case "CHANGE_STU_USER":
          // console.log(action.data, "red")
           return {
             ...state,
             data: action.data,
             spinner: action.spinner
           }
      case "CHANGE_USER_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp
        }
      default:
        return { ...state }
    }
  }
  export default ChangeUsername;
  