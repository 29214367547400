// ** Initial State
const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    spinner: false,
    tokenExp: false,
  };
  
  const initialItem = (resData) => {
    if (resData) {
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
      });
      return resData;
    } else {
      return [];
    }
  };
  
  const SmsUsageReport = (state = initialState, action) => {
    switch (action.type) {
      case "GET_ALL_SMS_USAGE_REPORT_DATA":
        console.log(action.allData, "reducer");
        return {
          ...state,
          allData: action.allData,
          feeData: action.allData,
          data: initialItem(action.data),
          total: action.totalPages,
          params: action.params,
          spinner: action.spinner,
        };
      case "GET_SMS_USAGE_REPORT_DATA":
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params,
        };
      case "SMS_USAGE_REPORT_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      default:
        return { ...state };
    }
  };
  export default SmsUsageReport;
  