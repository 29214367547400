// ** Initial State
const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    actionTitle: "",
    spinner: false,
    tokenExp: false,
    bspinner: false,
    
    bdspinner: false,
    billDetData: [],

    exspinner: false,
    imgData: [],

  };
  
  const initialItem = (resData) => {
    if (resData) {
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
      });
      return resData;
    } else {
      return [];
    }
  };
  
  const VerfBillsReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_VERIFY_BILLS_ALLDATA":
        return {
          ...state,
          allData: initialItem(action.allData),
          data: initialItem(action.data),
          total: action.totalPages,
          params: action.params,
          spinner: action.spinner,
        };
      case "BILLS_VERIFICATION_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp,
        };
    case "GET_VERIFY_BILLS_GETDATA":
        return {
            ...state,
            data: action.data,
            total: action.totalPages,
            params: action.params,
        };
    case "BILLS_VERIFIED_LOADING_SPINNER":
      return {
        ...state,
        bspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
      case "UPD_BILL_VERIFY_DATA":
        return {
          ...state,
          allData: initialItem(action.allData),
          bspinner: action.spinner,
        };
    case "BILLS_DETAILS_LOADING_SPINNER":
      return {
        ...state,
        bdspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_BILLDET_DATA":
      return {
          ...state,
          billDetData: action.allData,
          bdspinner: action.spinner,
      };
    case "EXP_ENTRY_IMG_LOADING_SPINNER":
      return {
        ...state,
        exspinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "ADD_EXPENSES_IMAGE":
      return {
        ...state,
        imgData: action.data,
        filCount: action.filCount,
        exspinner: action.spinner,
      }
      default:
        return { ...state };
    }
  };
  export default VerfBillsReducer;