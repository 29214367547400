// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  actionTitle: "",
  spinner: false,
  vSpinner: false,
  tokenExp: false,
  imSpinner: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const MarksVerfyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_MARKS_VERFY_DTLS":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: initialItem(action.data),
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "MARKS_VERIFICATION_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_MRKS_VERFY_GETDATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "ADD_MARKS_IMAGE":
      return {
        ...state,
        imSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "MARKS_IMG_UPLOAD_LOADING_SPINNER":
      return {
        ...state,
        imSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "VERIFY_MARKS_UPLOAD":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "MARKS_FINAL_UPLOAD_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default MarksVerfyReducer;
