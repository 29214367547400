// ** Initial State
const initialState = {
    advData: [],
    spinner: false,
    tokenExp: false
  };
  
  const EmployeeRemove = (state = initialState, action) => {
    switch (action.type) {
      case "GET_EMP_ADVANCE_DATA":
        return {
          ...state,
          advData: action.data,
          spinner: action.spinner,
        };
      case "EMP_ADVANCE_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      default:
        return { ...state };
    }
  };
  export default EmployeeRemove;  