import mock from "../mock"
import { paginateArray } from "../utils"

const data = {
  sections: [
    {
      id: 1,
      section: "Section - A",
      acronym: "sec - A",
      displayOrder: "1",
      status: "approved"
    },
    {
      id: 2,
      section: "Section - B",
      acronym: "sec - B",
      displayOrder: "2",
      status: "approved"
    }
  ]
}

// GET ALL DATA
mock.onGet("/api/section/all-data").reply(200, data.sections)

//GET UPDATE DATA
mock.onGet("/api/section/data").reply((config) => {
  const { q = "", perPage = 10, page = 1 } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const filteredData = data.sections.filter(
    (sec) =>
      sec.section.toLowerCase().includes(queryLowered) ||
      sec.acronym.toLowerCase().includes(queryLowered)
  )
  /* eslint-enable  */

  return [
    200,
    {
      sections: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

// GET sections
mock.onGet("/api/sections/section").reply((config) => {
  const { id } = config
  const section = data.sections.find((i) => i.id === id)
  return [200, { section }]
})
//Add sections
mock.onPost("/api/sections/add-section").reply((config) => {
  // Get event from post data
  const section = JSON.parse(config.data)

  const { length } = data.sections
  let lastIndex = 0
  if (length) {
    lastIndex = data.sections[length - 1].id
  }
  section.id = lastIndex + 1

  data.sections.unshift(section)

  return [201, { section }]
})

// DELETE: Delete sections
mock.onDelete("/api/sections/delete").reply((config) => {
  // Get bank id from URL
  let sectionId = config.id

  // Convert Id to number
  sectionId = Number(sectionId)

  const sectionIndex = data.sections.findIndex((t) => t.id === sectionId)
  data.sections.splice(sectionIndex, 1)

  return [200]
})
