import axios from "axios";
import { toast } from "react-toastify";
import { paginateArray } from "../../../../../../@fake-db/utils";
import { DefaultConfig } from "../../../../../../settings/config";
import ToastMessage from "../../../../../../@core/components/toast-messages";


// ** Get all Data
export const getAllData = (params) => {
  return async (dispatch, getState) => {
    const finGrp = DefaultConfig().companyId === 'GVCOMP0000' ? "GVCOMP0000" : DefaultConfig().finGrp;
    const Branches = DefaultConfig().Branches;
    const authAxios = DefaultConfig().authAxios;
    const BoardTypes = DefaultConfig().BoardTypes;
    dispatch({ type: "MARKET_SRC_LOADING_SPINNER", spinner: false, tokenExp: false });
    await authAxios
      .get(`students/marketsrcList/${finGrp}`)
      .then((response) => {
        const resData = response.data;
        const { perPage = 25, page = 1 } = params;
        
        if (resData) {
          const emp = getState().navbar.groupEmpBasicInfo;
          const employeeData = emp.map((sub) => ({
            value: sub.empId,
            label: sub.name, 
          }));

          resData.forEach((item, index) => {
            item["addnInchName"] = employeeData.find((cc) => cc.value === item.addnInch)
              ? employeeData.find((cc) => cc.value === item.addnInch).label
              : "";

            item["teleCalName"] = employeeData.find((cc) => cc.value === item.teleCal)
              ? employeeData.find((cc) => cc.value === item.teleCal).label
              : "";

            item["empIdName"] = employeeData.find((cc) => cc.value === item.empId)
            ? employeeData.find((cc) => cc.value === item.empId).label
            : "";

            item["nearBranchName"] = Branches.find((cc) => cc.value === item.nearBranch)
            ? Branches.find((cc) => cc.value === item.nearBranch).label
            : "";

            item["boardName"] = BoardTypes.find((cc) => cc.value === item.board)
            ? BoardTypes.find((cc) => cc.value === item.board).label
            : "";

              item.serial = index + 1;
          });
          

          dispatch({
            type: "GET_ALL_MARKET_SRC_DATA",
            allData: resData,
            data: paginateArray(resData, perPage, page),
            totalPages: resData.length,
            params,
            spinner: true,
          });
        }
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "MARKET_SRC_LOADING_SPINNER",
              spinner: true,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "MARKET_SRC_LOADING_SPINNER",
              spinner: true,
              tokenExp: false,
            });
            toast.error(<ToastMessage message={'students/marketsrcList' + '  ' +error} status={"danger"} />, {
              hideProgressBar: true,
            });
          }
        }
      });
  };
};

// ** Get data on page or row change
export const getData = (params) => {
  return (dispatch, getState) => {
    const {
      q = "",
      perPage = 10,
      page = 1,
      sortname = "",
      sortorder = "",
    } = params;
    const resData = getState().MarketingSources.allData;
    if (resData) {
      let filteredData = [];
      const queryLowered = q.toLowerCase();
      filteredData = resData.filter(
        (crm) =>
          (crm.schRef && crm.schRef.toString().toLowerCase().includes(queryLowered))  ||
          (crm.name && crm.name.toLowerCase().includes(queryLowered)) ||
          (crm.headMob && crm.headMob.toString().toLowerCase().includes(queryLowered)) ||
          (crm.addr && crm.addr.toLowerCase().includes(queryLowered))
      );
      const sorting = DefaultConfig().sorter;
      const sortData = sortorder
        ? sorting(sortname, sortorder, filteredData)
        : filteredData;
      dispatch({
        type: "GET_MARKET_SRC_DATA",
        data: paginateArray(sortData, perPage, page),
        totalPages: sortData.length,
        params,
      });
    }
  };
};

// ** Add new class
export const addMarketsrc = (marketsrcObj) => {
  return async (dispatch, getState) => {
    const authAxios = DefaultConfig().authAxios;
    const ip = DefaultConfig().ip;
    const logId = DefaultConfig().employeeId;
    const compId = DefaultConfig().companyId;
    const empId = DefaultConfig().employeeId;
    dispatch({ type: "MARKET_SRC_LOADING_SPINNER", spinner: false, tokenExp: false });
    const objectData = {};
    objectData["ip"] = ip,
    objectData["logId"] = logId,
    objectData["compId"] = compId,
    objectData["mrktInch"] = empId,
    objectData["name"] = marketsrcObj.name,
    objectData["schRef"] = marketsrcObj.schRef,
    objectData["schCode"] = Number(marketsrcObj.schCode),
    objectData["grp"] = marketsrcObj.grp,
    objectData["stYear"] = Number(marketsrcObj.stYear),
    objectData["board"] = Number(marketsrcObj.board),
    objectData["catg"] = marketsrcObj.catg,
    objectData["addr"] = marketsrcObj.addr,
    objectData["stateRef"] = marketsrcObj.stateRef,
    objectData["headName"] = marketsrcObj.headName,
    objectData["headMob"] = marketsrcObj.headMob,
    objectData["inchName"] = marketsrcObj.inchName,
    objectData["inchMob"] = marketsrcObj.inchMob,
    objectData["addnInch"] = marketsrcObj.addnInch,
    objectData["strength"] = Number(marketsrcObj.strength),
    objectData["fee"] = Number(marketsrcObj.fee),
    objectData["nearBranch"] = marketsrcObj.nearBranch,
    objectData["distance"] = Number(marketsrcObj.distance),
    objectData["teleCal"] = marketsrcObj.teleCal,
    objectData["area"] = marketsrcObj.area,
    objectData["curSoft"] = marketsrcObj.curSoft,
    objectData["status"] = marketsrcObj.status,
    objectData["reason"] = marketsrcObj.reason,
    await authAxios
      .post("students/saveMarketSrc", objectData)
      .then((response) => {
        const resData = response.data.value;
        if (resData) {
          const allData = getState().MarketingSources.allData;
          allData.unshift(resData);
          dispatch({
            type: "ADD_MARKET_SRC",
            allData,
            spinner: true,
          });
          toast.success(
            <ToastMessage message={"Added Successfully"} status={"success"} />,
            { hideProgressBar: true }
          );
        }
      })
      .then(() => {
        dispatch(getData(getState().MarketingSources.params));
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "MARKET_SRC_LOADING_SPINNER",
              spinner: true,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "MARKET_SRC_LOADING_SPINNER",
              spinner: true,
              tokenExp: false,
            });
            toast.error(<ToastMessage message={'students/saveMarketSrc'+ '  ' + error} status={"danger"} />, {
              hideProgressBar: true,
            });
          }
        }
      });
  };
};


// ** Get State list Data
export const getStateListData = () => {
  return async (dispatch, getState) => {
    const authAxios = DefaultConfig().authAxios;
    dispatch({
      type: "MARKET_SRC_LOADING_SPINNER",
      spinner: false,
      tokenExp: false,
    });
    await authAxios
      .get(`finance/indStatesList`)
      .then((response) => {
        const resData = response.data;
        if (resData) {
          const mapData = resData.map((cus) => ({
            value: cus.sno,
            label: cus.state,
          }));
          dispatch({
            type: "GET_STATELIST_DATA",
            data: mapData,
            spinner: true,
          });
        }
      })
      .then(() => {
        dispatch(getAllData(getState().MarketingSources.params));
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "MARKET_SRC_LOADING_SPINNER",
              spinner: true,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "MARKET_SRC_LOADING_SPINNER",
              spinner: true,
              tokenExp: false,
            });
            toast.error(<ToastMessage message={'finance/indStatesList'+ '  ' +error} status={"danger"} />, {
              hideProgressBar: true,
            });
          }
        }
      });
  };
};


// ** Get Marketing Sources
export const getMarketsrc = (id, title) => {
  return (dispatch, getState) => {
    const allData = getState().MarketingSources.allData;
    const findOne = allData.find((find) => find.schRef === id);
    dispatch({
      type: "GET_MARKET_SRC",
      selectedMarketsrc: findOne,
      actionTitle: title,
    });
  };
};

export const getSelectedEmp = (id) => {
  return (dispatch) => {
    dispatch({
      type: "GET_SEL_EMP",
      selectEmpId: id,
    });
  };
};

// ** Update class
export const updateMarketsrc = (marketsrcObj, selectedMarketsrc) => {
  return async (dispatch, getState) => {
    const authAxios = DefaultConfig().authAxios;
    const ip = DefaultConfig().ip;
    const logId = DefaultConfig().employeeId;
    const compId = DefaultConfig().companyId;
    const empId = DefaultConfig().employeeId;
    dispatch({ type: "MARKET_SRC_LOADING_SPINNER", spinner: false, tokenExp: false });
    const objectData = {};
    objectData["ip"] = ip,
    objectData["logId"] = logId,
    objectData["compId"] = compId,
    objectData["mrktInch"] = empId,
    objectData["name"] = marketsrcObj.name,
    objectData["schRef"] = selectedMarketsrc.schRef,
    objectData["schCode"] = Number(marketsrcObj.schCode),
    objectData["grp"] = marketsrcObj.grp,
    objectData["stYear"] = Number(marketsrcObj.stYear),
    objectData["board"] = Number(marketsrcObj.board),
    objectData["catg"] = marketsrcObj.catg,
    objectData["addr"] = marketsrcObj.addr,
    objectData["stateRef"] = marketsrcObj.stateRef,
    objectData["headName"] = marketsrcObj.headName,
    objectData["headMob"] = marketsrcObj.headMob,
    objectData["inchName"] = marketsrcObj.inchName,
    objectData["inchMob"] = marketsrcObj.inchMob,
    objectData["addnInch"] = marketsrcObj.addnInch,
    objectData["strength"] = Number(marketsrcObj.strength),
    objectData["fee"] = Number(marketsrcObj.fee),
    objectData["nearBranch"] = marketsrcObj.nearBranch,
    objectData["distance"] = Number(marketsrcObj.distance),
    objectData["teleCal"] = marketsrcObj.teleCal,
    objectData["area"] = marketsrcObj.area,
    objectData["curSoft"] = marketsrcObj.curSoft,
    objectData["status"] = marketsrcObj.status,
    objectData["reason"] = marketsrcObj.reason,
    await authAxios
      .post(`students/saveMarketSrc/`, objectData)
      .then((response) => {
        const resData = response.data.value;
        if (resData) {
          const allData = getState().MarketingSources.allData;
          const findIndex = allData.findIndex(
            (find) => find.schRef === selectedMarketsrc.schRef
          );
          if (findIndex === -1) {
            allData.push(resData);
          } else {
            allData[findIndex] = resData;
          }
          allData[findIndex] = resData;
          dispatch({
            type: "UPDATE_MARKET_SRC",
            allData,
            spinner: true,
          });
          toast.success(
            <ToastMessage
              message={"Updated Successfully"}
              status={"success"}
            />,
            { hideProgressBar: true }
          );
        }
      })
      .then(() => {
        dispatch(getData(getState().MarketingSources.params));
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "MARKET_SRC_LOADING_SPINNER",
              spinner: true,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "MARKET_SRC_LOADING_SPINNER",
              spinner: true,
              tokenExp: false,
            });
            toast.error(<ToastMessage message={'students/saveMarketSrc'+ '  ' + error} status={"danger"} />, {
              hideProgressBar: true,
            });
          }
        }
      });
  };
};

// ** Delete holidayes
// export const deleteSms = (sno) => {
//   return async (dispatch, getState) => {
//     const authAxios = DefaultConfig().authAxios;
//     await authAxios
//       .delete(`crm/deleteSmsTemplate/${sno}`)
//       .then((response) => {
//         const resData = response.data;
//         if (resData) {
//           const allData = getState().MarketingSources.allData;

//           const totalData = allData.filter((item) => item.sno !== sno);

//           dispatch({
//             type: "DELETE_MARKET_SRC",
//             allData: totalData,
//             spinner: true,
//           });
//           toast.success(
//             <ToastMessage
//               message={"Deleted Successfully"}
//               status={"success"}
//             />,
//             { hideProgressBar: true }
//           );
//         }
//       })
//       .then(() => {
//         dispatch(getData(getState().MarketingSources.params));
//         dispatch(getAllData(params));
//       })
//       .catch((error) => {
//         const errorresponse = error.response;
//         if (errorresponse) {
//           const status = errorresponse.status;
//           if (status === 403) {
//             dispatch({
//               type: "MARKET_SRC_LOADING_SPINNER",
//               spinner: true,
//               tokenExp: true,
//             });
//           } else {
//             const error = errorresponse.data.error;
//             dispatch({
//               type: "MARKET_SRC_LOADING_SPINNER",
//               spinner: true,
//               tokenExp: false,
//             });
//             toast.error(
//               <ToastMessage
//                 message={"crm/deleteSmsTemplate" + "  " + error}
//                 status={"danger"}
//               />,
//               { hideProgressBar: true }
//             );
//           }
//         }
//       });
//   };
// };
