
// ** Initial State
const initialState = {
    
    spinner: false,
    sspinner: false,
    ispinner: false,
    tokenExp: false,
    incFeeSyncSpinner: false,
    data: ''
  }
  
  const FeeSynchronization = (state = initialState, action) => {
    switch (action.type) {
    
      case "ADD_FEE_SYN":
        return {
          ...state,
          data: action.data,
          spinner: action.spinner,
          incFeeSyncSpinner: action.incFeeSyncSpinner,
        }
      case "FEE_SYN_SUB_LOADING_SPINNER":
        return {
          ...state,
          sspinner: action.spinner,
          tokenExp: action.tokenExp
        }
      case "FEE_SYN_LOADING_SPINNER":
        return {
          ...state,
          ispinner: action.spinner,
          incFeeSyncSpinner: action.incFeeSyncSpinner,
          tokenExp: action.tokenExp
        }
      default:
        return { ...state }
    }
  }
  export default FeeSynchronization;
  