
// ** Initial State
const initialState = {
    
  spinner: false,
  // sspinner: false,
  // ispinner: false,
  tokenExp: false,

  data: ''
}

const AttSynchronization = (state = initialState, action) => {
  switch (action.type) {
  
    case "ADD_ATT_SYN":
      return {
        ...state,
        data: action.data,
        spinner: action.spinner
      }
    case "ATT_SYN_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp
      }
    default:
      return { ...state }
  }
}
export default AttSynchronization
