// ** Initial State
const initialState = {
  depData: [],
  empData: [],
  degiData: [],
  // feecateData: [],
  accountTypes: [],
  subaccountTypes: [],
  innersubaccountTypes: [],
  // oldschlData: [],
  // departData: [],
  // degData: [],
  // espinner: false,
  tokenExp: false,
  spinner: false,
  sspinner: false,
  ispinner: false,
  // lspinner: false,
  redirect: false,
  employeeData: {},
  empspinner: true,
}
const EmpDepAdd = (state = initialState, action) => {
  switch (action.type) {
    // case "GET_STUDENT_AR_DEPT_DATA":
    //   return {
    //     ...state,
    //     departData: action.data
    //   }
    // case "GET_STUDENT_AR_DEG_DATA":
    //   return {
    //     ...state,
    //     degData: action.data
    //   }
    // case "GET_STUDENT_AR_FEECATE_DATA":
    //   return {
    //     ...state,
    //     feecateData: action.data,
    //     spinner: action.spinner
    //   }
    case "GET_EMP_DEP_ADD_ACCOUNT_DATA":
      return {
        ...state,
        accountTypes: action.data,
        spinner: action.spinner
      }
    case "GET_EMP_ADD_SUB_ACCOUNT_DATA":
      return {
        ...state,
        subaccountTypes: action.data,
        sspinner: action.spinner
      }
    case "GET_EMP_ADD_INN_SUB_ACCOUNT_DATA":
      return {
        ...state,
        innersubaccountTypes: action.data,
        ispinner: action.spinner
      }
    case "GET_DEP_DATA":
      return {
        ...state,
        depData: action.data,
        spinner: action.spinner
      }
    case "GET_DEG_DATA":
      return {
        ...state,
        degiData: action.data,
        spinner: action.spinner
      }
    case "GET_EMP_DATA":
      return {
        ...state,
        empData: action.data,
        spinner: action.spinner
      }
    // case "GET_STUDENT_AR_PRO_DATA":
    //   return {
    //     ...state,
    //     proData: action.data,
    //     espinner: action.spinner
    //   }
    // case "GET_STUDENT_AR_OLDSCH_DATA":
    //   return {
    //     ...state,
    //     oldschlData: action.data,
    //     spinner: action.spinner
    //   }
    case "ADD_EMPLOYEE":
      return {
        ...state,
        empspinner: action.spinner,
        redirect: action.redirect,
        employeeData: action.data
      }
    // case "STUDENT_AR_LOADING_SPINNER":
    //   return {
    //     ...state,
    //     espinner: action.espinner,
    //     sspinner: action.sspinner,
    //     tokenExp: action.tokenExp
    //   }
    case "EMP_DEP_ADD_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "EMP_ADD_LOADING_SPINNER":
      return {
        ...state,
        empspinner: action.spinner,
        tokenExp: action.tokenExp
      }
    default:
      return { ...state }
  }
}
export default EmpDepAdd
