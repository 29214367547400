import { DefaultConfig } from "../../../../../settings/config";
const authType = DefaultConfig().authTypeOptions;
// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedAuthorizations: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
};

const initialItem = (resData, empData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const AuthorizationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_AUTHORIZATIONS_DATA":
      return {
        ...state,
        allData: initialItem(action.allData, state.empData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_AUTHORIZATIONS_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_AUTHORIZATIONS":
      return {
        ...state,
        selectedAuthorizations: action.selectedAuthorizations,
        actionTitle: action.actionTitle,
      };
    case "ADD_AUTHORIZATIONS":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "UPDATE_AUTHORIZATIONS":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "DELETE_AUTHORIZATIONS":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "AUTHORIZATIONS_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default AuthorizationsReducer;
