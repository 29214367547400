// ** Initial State
const initialState = {
  vasOrderedCount: "",
  vasPendingCount: "",
  vasDispatchCount: "",
  orderedCount: "",
  pendingCount: "",
  dispatchCount: "",
  deliveredCount: "",
  spinner: false,
  tokenExp: false,
  allData: [],
  data: [],
  total: 1,
  totalSumData: [],
  params: {},
  actionTitle: "",
  pspinner: false,

  custAllData: [],
  custData: [],
  custTotal: 1,
  custParams: {},
  vspinner: false,

  cvIsData: [],
  cvisTotal: "",
  cvisSpinner: false,
  cvisParams: {},

  idCountSpinner: false,

  upIdData: "",
  upIdSpinner: false,

  idCardDisData: [],
  idCardDisSpinner: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const VASDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_VAS_PENDING_DATA":
      return {
        ...state,
        vasOrderedCount: action.orderedCount,
        vasPendingCount: action.pendingCount,
        vasDispatchCount: action.dispatchCount,
        spinner: action.spinner,
      };
    case "VAS_COUNTS_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_PENDING_VASDETAILS_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: initialItem(action.data),
        total: action.totalPages,
        params: action.params,
        pspinner: action.spinner,
      };
    case "PENDING_VASDETAILS_LOADING_SPINNER":
      return {
        ...state,
        pspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_PENDING_VASDETAILS_GETDATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_PENDING_CUSTDTLS_DATA":
      return {
        ...state,
        custAllData: initialItem(action.allData),
        custData: initialItem(action.data),
        custTotal: action.totalPages,
        totalSumData: action.totalSumData,
        custParams: action.params,
        vspinner: action.spinner,
      };
    case "PENDING_VAS_CUSTDTLS_LOADING_SPINNER":
      return {
        ...state,
        vspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_PENDING_CUSTDTLS_GETDATA":
      return {
        ...state,
        custData: action.data,
        custTotal: action.totalPages,
        totalSumData: action.totalSumData,
        params: action.params,
      };
    case "CHANGE_VAS_ITEM_STATUS_LOADING_SPINNER":
      return {
        ...state,
        cvisSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "CHANGE_VAS_ITEM_STATUS":
      return {
        ...state,
        custAllData: action.allData,
        cvisSpinner: action.spinner,
      };
    case "CHANGE_ID_CARD_ITEM_STATUS_LOADING_SPINNER":
      return {
        ...state,
        upIdSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "CHANGE_ID_CARD_ITEM_STATUS":
      return {
        ...state,
        upIdData: action.allData,
        upIdSpinner: action.spinner,
      };
    case "GET_ID_CARDS_PENDING_DATA":
      return {
        ...state,
        orderedCount: action.orderedCount,
        pendingCount: action.pendingCount,
        dispatchCount: action.dispatchCount,
        deliveredCount: action.deliveredCount,
        idCountSpinner: action.spinner,
      };
    case "ID_CARDS_COUNTS_LOADING_SPINNER":
      return {
        ...state,
        idCountSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
      case "GET_ID_CARD_DISPATCHED_LIST":
      return {
        ...state,
        idCardDisData: action.allData,
        idCardDisSpinner: action.spinner,
      };
    case "ID_CARDS_COUNTS_LOADING_SPINNER":
      return {
        ...state,
        idCardDisSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default VASDetailsReducer;
