// ** Initial State
const initialState = {
  data: {},
  spinner: false,
  tokenExp: false,

  savedData: '',
  spLoader: true,
  spSpinner: false,
  events: [],
  eventList: [],
  selectedEvent: {},
  selectedCalendars: ['Personal', 'Business', 'Family', 'Holiday', 'ETC'],

  uaEventList: [],
  comEventList: [],

  updLoader: true,
  updSpinner: false,

  statusWiseCount: {},
  statusWiseDtls: {},
  eaSpinner: false,

  statusAllData: [],
  statusData: [],
  total: 1,
  params: {},
  statusSpinner: false,

  eeData: {},
  eeSpinner: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const SocialMediaCalendar = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SM_CALENDAR_DATA":
      return {
        ...state,
        data: action.data,
        uaEventList: action.uaEventList,
        comEventList: action.comEventList,
        spinner: action.spinner,
        updSpinner: false,
      };
    case "GET_SM_CALENDAR_DATA_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "SAVE_CALENDAR_DATA":
      return {
        ...state,
        data: action.data,
        uaEventList: action.uaEventList,
        comEventList: action.comEventList,
        savedData: action.data,
        spLoader: action.loader,
        spSpinner: action.spinner,
      };
    case "SAVE_CALENDAR_DATA_LOADING_SPINNER":
      return {
        ...state,
        spSpinner: action.spinner,
        spLoader: action.loader,
        tokenExp: action.tokenExp,
      };
    case "UPDATE_EVENT_STATUS":
      return {
        ...state,
        data: action.data,
        savedData: action.data,
        updLoader: action.loader,
        updSpinner: action.spinner,
      };
    case "UPDATE_EVENT_STATUS_LOADING_SPINNER":
      return {
        ...state,
        updSpinner: action.spinner,
        updLoader: action.loader,
        tokenExp: action.tokenExp,
      };
    case "UPDATE_EVENT_STATUS":
      return {
        ...state,
        data: action.data,
        savedData: action.data,
        updLoader: action.loader,
        updSpinner: action.spinner,
      };
    case "UPDATE_EVENT_STATUS_LOADING_SPINNER":
      return {
        ...state,
        updSpinner: action.spinner,
        updLoader: action.loader,
        tokenExp: action.tokenExp,
      };
    case "GET_EVENT_ALERTS":
      return {
        ...state,
        statusWiseCount: action.statusWiseCount,
        statusWiseDtls: action.statusWiseDtls,
        eaSpinner: action.spinner,
      };
    case "GET_EVENT_ALERTS_LOADING_SPINNER":
      return {
        ...state,
        eaSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_STATUS_WISE_LIST":
      return {
        ...state,
        statusAllData: initialItem(action.allData),
        statusData: action.data,
        total: action.totalPages,
        params: action.params,
        statusSpinner: action.spinner
      };
    case "GET_STATUS_WISE_LIST_FILTER_DATA":
      return {
        ...state,
        statusData: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "SAVE_EVENT_ENGAGEMENT_DATA":
      return {
        ...state,
        eeData: action.data,
        eeSpinner: action.spinner,
      };
    case "SAVE_EVENT_ENGAGEMENT_LOADING_SPINNER":
      return {
        ...state,
        eeSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case 'FETCH_EVENTS':
      return { ...state, events: action.events }
    case 'ADD_EVENT':
      return { ...state }
    case 'REMOVE_EVENT':
      return { ...state }
    case 'UPDATE_EVENT':
      return { ...state }
    case 'UPDATE_FILTERS':
      // ** Updates Filters based on action filter
      const filterIndex = state.selectedCalendars.findIndex(i => i === action.filter)
      if (state.selectedCalendars.includes(action.filter)) {
        state.selectedCalendars.splice(filterIndex, 1)
      } else {
        state.selectedCalendars.push(action.filter)
      }
      if (state.selectedCalendars.length === 0) {
        state.events.length = 0
      }
      return { ...state }
    case 'UPDATE_ALL_FILTERS':
      // ** Updates All Filters based on action value
      const value = action.value
      let selected = []
      if (value === true) {
        selected = ['Personal', 'Business', 'Family', 'Holiday', 'ETC']
      } else {
        selected = []
      }
      return { ...state, selectedCalendars: selected }
    case 'SELECT_EVENT':
      return { ...state, selectedEvent: action.event }
    default:
      return { ...state };
  }
};
export default SocialMediaCalendar;
