// ** Store & Actions
import { getMarketsrc, deleteSms } from "../store/action"
import { store } from "@store/storeConfig/store"

// ** Third Party Components
import { Button, UncontrolledTooltip } from "reactstrap"
import { Trash2, Eye, Edit } from "react-feather"

export const columns = [
  {
    name: "S.NO",
    minWidth: "0px",
    maxWidth: "50px",
    selector: "serial",
    sortable: true,
    showhide: true,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">{row.serial}</div>
    ),
  },
  {
    name: "School Name",
    minWidth: "0px",
    selector: "name",
    sortable: true,
    showhide: true,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">{row.name}</div>
    ),
  },
  {
    name: "Board",
    minWidth: "0px",
    maxWidth: "120px",
    selector: "boardName",
    sortable: true,
    showhide: false,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">{row.boardName}</div>
    ),
  },
  {
    name: "Category",
    minWidth: "0px",
    maxWidth: "120px",
    selector: "catg",
    sortable: true,
    showhide: false,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">{row.catg}</div>
    ),
  },
  {
    name: "Address",
    minWidth: "0px",
    maxWidth: "120px",
    selector: "addr",
    sortable: true,
    showhide: true,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">{row.addr}</div>
    ),
  },
  {
    name: "Head",
    minWidth: "0px",
    maxWidth: "120px",
    selector: "headName",
    sortable: true,
    showhide: true,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">{row.headName}</div>
    ),
  },
  {
    name: "Head Mob. No.",
    minWidth: "0px",
    maxWidth: "120px",
    selector: "headMob",
    sortable: true,
    showhide: true,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">{row.headMob}</div>
    ),
  },
  {
    name: "Incharge",
    minWidth: "0px",
    maxWidth: "120px",
    selector: "inchName",
    sortable: true,
    showhide: true,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">{row.inchName}, <br /> <span style={{color: "#0787A0"}}>{row.inchMob}</span></div>
    ),
  },
  {
    name: "Strength",
    minWidth: "0px",
    maxWidth: "120px",
    selector: "strength",
    sortable: true,
    showhide: true,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">{row.strength}</div>
    ),
  },
  {
    name: "Fees",
    minWidth: "0px",
    maxWidth: "120px",
    selector: "fee",
    sortable: true,
    showhide: false,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">{row.fee}</div>
    ),
  },
  {
    name: "Branch",
    minWidth: "0px",
    maxWidth: "120px",
    selector: "nearBranchName",
    sortable: true,
    showhide: false,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">{row.nearBranchName}</div>
    ),
  },
  {
    name: "Marketing Incharge",
    minWidth: "0px",
    maxWidth: "120px",
    selector: "empIdName",
    sortable: true,
    showhide: true,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">{row.empIdName}</div>
    ),
  },
  {
    name: "Additional Incharge",
    minWidth: "0px",
    maxWidth: "120px",
    selector: "addnInchName",
    sortable: true,
    showhide: false,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">{row.addnInchName}</div>
    ),
  },
  {
    name: "Telecaller",
    minWidth: "0px",
    maxWidth: "120px",
    selector: "teleCalName",
    sortable: true,
    showhide: true,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">{row.teleCalName}</div>
    ),
  },
  {
    name: "Group",
    minWidth: "0px",
    maxWidth: "120px",
    selector: "grp",
    sortable: true,
    showhide: false,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">{row.grp}</div>
    ),
  },
  {
    name: "College Code",
    minWidth: "0px",
    maxWidth: "120px",
    selector: "schCode",
    sortable: true,
    showhide: false,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">{row.schCode}</div>
    ),
  },
  {
    name: "Start Year",
    minWidth: "0px",
    maxWidth: "120px",
    selector: "stYear",
    sortable: true,
    showhide: false,
    center: true,
    cell: (row) => (
      <div className="text-center w-100">{row.stYear}</div>
    ),
  },
  {
    name: "Actions",
    minWidth: "120px",
    maxWidth: "120px",
    showhide: true,
    center: true,
    allowOverflow: true,
    cell: (row) => (
      <div className='d-flex'>
        <Button.Ripple
          className="btn-icon btn_Action"
          outline
          color="primary"
          id="viewTip"
          onClick={() => store.dispatch(getMarketsrc(row.schRef, "View"))}
        >
          <Eye size={15} />
        </Button.Ripple>
        <UncontrolledTooltip placement="top" target="viewTip">
          View
        </UncontrolledTooltip>
        <Button.Ripple
          className="btn-icon btn_Action"
          outline
          color="primary"
          id="editTip"
          onClick={() => store.dispatch(getMarketsrc(row.schRef, "Edit"))}
        >
          <Edit size={15} />
        </Button.Ripple>
        <UncontrolledTooltip placement="top" target="editTip">
          Edit
        </UncontrolledTooltip>
        {/* <Button.Ripple
          className="btn-icon btn_Action"
          outline
          color="primary"
          id="deleteTip"
          onClick={() => store.dispatch(deleteSms(row.serial))}
        >
          <Trash2 size={15} />
        </Button.Ripple>
        <UncontrolledTooltip placement="top" target="deleteTip">
          Delete
        </UncontrolledTooltip> */}
      </div>
    )
  }
]
