import { DefaultConfig } from "../../../../../../settings/config"
const AcademicYear = DefaultConfig().AcademicYear
const DateFormatMonth = DefaultConfig().DateFormatMonth

// ** Initial State
const initialState = {
  groupData: [],
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false
}

const initialItem = resData => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.startdate = (resp.stDate ? DateFormatMonth(resp.stDate) : '')
      resp.endingDate = (resp.endDate ? DateFormatMonth(resp.endDate) : '')
      resp.currentYear = (resp.curtYear === true ? 'Yes' : (resp.curtYear === false ? 'No' : ''))
      resp.admissionYear = (resp.admnYear === true ? 'Yes' : (resp.admnYear === false ? 'No' : ''))
      resp.serial = index + 1
      if (AcademicYear.find(find => find.value === resp.prevYear)) {
        resp.prevYearName = AcademicYear.find(find => find.value === resp.prevYear).label
      } else {
        resp.prevYearName = ''
      }
    })
    return resData
  } else {
    return []
  }
}

const Academic = (state = initialState, action) => {
  switch (action.type) {
    case "GET_GROUP_DATA":
      return {
        ...state,
        groupData: action.data,
        spinner: action.spinner
      }
    case "GET_ALL_ACADEMIC_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner
      }
    case "GET_ACADEMIC_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case "GET_ACADEMIC":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle
      }
    case "ADD_ACADEMIC":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner
      }
    case "UPDATE_ACADEMIC":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner
      }
    case "DELETE_ACADEMIC":
      return { ...state }
    case "ACADEMIC_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp
      }
    default:
      return { ...state }
  }
}
export default Academic
