// ** Initial State
const initialState = {
    allData: [],
    data: [],
    spinner: false,
    espinner: false,
    tokenExp: false,
    total: 1,
    params: {},
    actionTitle: "",
    chequeData: []
  };
  
  const initialItem = (resData) => {
    if (resData) {
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
      });
      return resData;
    } else {
      return [];
    }
  };
  
  const ExpReconcReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_EXPPENDRECONCILE_DATA":
        return {
          ...state,
          allData: initialItem(action.allData),
          data: action.data,
          total: action.totalPages,
          params: action.params,
          spinner: action.spinner,
          chequeData: action.chequeData,
        };
      case "EXP_RECONC_PENDING_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      case "GET_EXPPENDUPDATE_DATA":
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params,
        };
      case "UPD_EXP_RECONC_DATA":
        return {
          ...state,
          allData: initialItem(action.allData),
          espinner: action.spinner,
        };
      case "EXP_RECONCILE_UPD_LOADING_SPINNER":
      return {
        ...state,
        espinner: action.spinner,
        tokenExp: action.tokenExp,
      };
      default:
        return { ...state };
    }
  };
  export default ExpReconcReducer;  