import { DefaultConfig } from "../../../../../../settings/config";
const AcademicYear = DefaultConfig().AcademicYear;
const DateFormatMonth = DefaultConfig().DateFormatMonth;
const AmountFormat = DefaultConfig().AmountFormat;

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  acadYearList: [],
  expDate: "",
  discount: "",
  total: 1,
  params: {},
  spinner: false,
  espinner: false,
  dspinner: false,
  tokenExp: false,
  aspinner: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp["date"] = resp.date;
      resp["refNo"] = resp.invNo;
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const viewByCustomerId = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_CUSTOMERID_DATA":
      // console.log(action, "Customer ID Data");
      return {
        ...state,
        allData: action.allData,
        data: initialItem(action.data),
        // total: action.totalPages,
        spinner: action.spinner,
      };

    case "VIEW_BY_CUSTOMERID_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    // case "GET_CUST_DATA":
    //   return {
    //     ...state,
    //     data: action.data,
    //     total: action.totalPages,
    //     params: action.params,
    //   };
    case "ACAD_YEAR_LOADING_SPINNER":
      return {
        ...state,
        aspinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    case "GET_ACAD_YEAR_LIST":
      return {
        ...state,
        acadYearList: action.data,
        aspinner: action.spinner,
      };
    case "GET_EXPIRY_DATE_UPD":
      return {
        ...state,
        expDate: action.data,
        espinner: action.spinner,
      };
    case "EXP_DATE_LOADING_SPINNER":
      return {
        ...state,
        espinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_DISCOUNT_UPDATE":
      return {
        ...state,
        discount: action.data,
        dspinner: action.spinner,
      };
    case "DISCOUNT_UPDATE_LOADING_SPINNER":
      return {
        ...state,
        dspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default viewByCustomerId;
