import { DefaultConfig } from "../../../../../../settings/config";
// ** Initial State
const initialState = {
  accData: [],
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
};

const initialItem = (resData, accData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      if (resp) {
        resp.routeName =
          resp.route === true
            ? "Applicable"
            : resp.route === false
              ? "Not Applicable"
              : "";
        resp.newadmName =
          resp.newAdm === true
            ? "Applicable"
            : resp.newAdm === false
              ? "Not Applicable"
              : "";
        resp.hslName =
          resp.hstl === true
            ? "Applicable"
            : resp.hstl === false
              ? "Not Applicable"
              : "";
        resp.splCourseName =
          resp.splCourse === 1
            ? "Applicable"
            : resp.splCourse === 2
              ? "Not Applicable"
              : "";
              resp.genderName =
              resp.gender === 1
                ? "Boy"
                : resp.gender === 2
                  ? "Girl"
                    : resp.gender === 3
                      ? "Both"
                  : "";
        resp.hideOptionName =
          resp.hideOption === true
            ? "Applicable"
            : resp.hideOption === false
              ? "Not Applicable"
              : "";
        resp.editStudent =
          resp.editByStu === true
            ? "true"
            : resp.editByStu === false
              ? "false"
              : "";
        resp.category = resp.feeheadsId.catg;

        if (accData.find((find) => find.value === resp.finHead)) {
          resp.accName = accData.find(
            (find) => find.value === resp.finHead
          ).label;
        } else {
          resp.accName = "";
        }
        resp.serial = index + 1;
      }
    });
    return resData;
  } else {
    return [];
  }
};

const FeeHeads = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ACC_DATA":
      return {
        ...state,
        accData: action.data,
        spinner: action.spinner,
      };
    case "GET_ALL_FEE_HEAD_DATA":
      return {
        ...state,
        allData: initialItem(action.allData, state.accData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_FEE_HEAD_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_FEEHEAD":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };
    case "ADD_FEEHEAD":
      
      return {
        ...state,
        allData: initialItem(action.allData, state.accData),
        spinner: action.spinner,
      };
    case "UPDATE_FEEHEAD":
      return {
        ...state,
        allData: initialItem(action.allData, state.accData),
        spinner: action.spinner,
      };
    case "DELETE_FEEHEAD":
      return { ...state };
    case "LOADING_SPINNER_FEE_HEAD":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default FeeHeads;
