// ** Initial State
const initialState = {
  catgBillsData: [],
  catgBillsTotal: '',
  data: [],
  spinner: false,
  tokenExp: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const CatgPendBillsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CATG_PENDBILLS_DATA":
      return {
        ...state,
        catgBillsData: action.allData,
        catgBillsTotal: action.catgBillsTotal,
        spinner: action.spinner,
      };
    case "CATG_PEND_BILLS_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default CatgPendBillsReducer;
