// ** Initial State
const initialState = {
  allData: [],
  EmpListData: [],
  data: [],
  total: 1,
  params: {},
  selectedSms: null,
  //actionTitle: "",
  spinner: false,
  tokenExp: false,
};

// const initialItem = resData => {
//   if (resData) {
//     console.log(resData, "ResData")
//     resData.forEach((resp, index) => {
//       resp.serial = index + 1
//     })
//   }
// }

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const EmpList = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_EMP_LIST_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        EmpListData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_EMP_LIST_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    //   case "GET_SMS":
    //     return {
    //       ...state,
    //       selectedSms: action.selectedSms,
    //       actionTitle: action.actionTitle
    //     }
    case "EMP_LIST_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default EmpList;
