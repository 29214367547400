
const initialState = {
    data : [],
    tokenExp:false
}
const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_NOTIFICATION_DATA':
      return { ...state,
    data : action.data,
    spinner : action.spinner
    }
    
    default:
      return state
  }
}

export default notificationsReducer
