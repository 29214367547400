// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  actionTitle: "",
  selectedData: null,
  spinner: false,
  tokenExp: false,

  pendingAllData: [],
  pendingData: [],
  pendingTotal: 1,
  pendingParams: {},
  pendingSpinner: false,
  pendingSelectedData: null,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      // resp["itemRef"] = invItems.find((c) => c.value === value);
      //   resp["remDate"] = DefaultConfig().DateFormatMonth(resp.removalDate);
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const InventoryIndentPend = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_INVENTORY_INDENT_PENDING_DATA":
      return {
        ...state,
        pendingAllData: action.allData,
        pendingData: action.data,
        total: action.totalPages,
        pendingParams: action.params,
        pendingSpinner: action.spinner,
      };
    case "GET_INV_PENDING_INDENT_DATA":
      return {
        ...state,
        pendingData: action.data,
        pendingTotal: action.totalPages,
        pendingParams: action.params,
      };
    case "GET_INV_INDENT_PENDING":
      return {
        ...state,
        pendingSelectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };
    case "UPDATE_INVENTORY_INDENT_PENDING":
      return {
        ...state,
        pendingAllData: action.allData,
        pendingSpinner: action.spinner,
      };
    case "INVENTORY_INDENT_PENDING_LOADING_SPINNER":
      return {
        ...state,
        pendingSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default InventoryIndentPend;
