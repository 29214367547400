// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  actionTitle: "",
  spinner: false,
  tokenExp: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const ExpBrachPaidReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_BRANCH_PAID_EXP_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: initialItem(action.data),
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "BRANCH_EXPENSES_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_EXP_BRANCHPAID_GETDATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    default:
      return { ...state };
  }
};
export default ExpBrachPaidReducer;
