// ** Initial State
const initialState = {
  spinner: false,
  tokenExp: false,
  compData: {},
  twdData: []
}

const TermDueReport = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TERM_WISE_DUE_REPORT_DATA":
      return {
        ...state,
        compData: action.compData,
        twdData: action.data,
        spinner: action.spinner
      }
    case "TERM_WISE_DUE_REPORT_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
        twdData: []
      }
    default:
      return { ...state }
  }
}
export default TermDueReport
