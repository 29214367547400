

// ** Initial State
const initialState = {
  accountTypes: [],
  subaccountTypes: [],
  innersubaccountTypes: [],
  billsData: [],
  empData: [],
  bankData: [],
  expData: {},
  spinner: false,
  sspinner: false,
  filCount: 0,
  ispinner: false,
  bspinner: false,
  tokenExp: false,
  uspinner: false,
  utilityData: [],
  imgData: [],
}

const ExpensesEntry = (state = initialState, action) => {
  switch (action.type) {
    case "GET_EXP_ENTY_ACCOUNT_DATA":
      return {
        ...state,
        accountTypes: action.data,
        spinner: action.spinner
      }
    case "GET_EXP_ENTY_SUB_ACCOUNT_DATA":
      return {
        ...state,
        subaccountTypes: action.data,
        sspinner: action.spinner
      }
    case "GET_EXP_ENTY_INN_SUB_ACCOUNT_DATA":
      return {
        ...state,
        innersubaccountTypes: action.data,
        ispinner: action.spinner
      }
      case "GET_ALL_UTILITY_DATA":
      return {
        ...state,
        utilityData: action.data,
        uspinner: action.spinner
      }
    case "GET_EXP_ENTY_EMP_DATA":
      return {
        ...state,
        empData: action.data,
        spinner: action.spinner
      }
    case "GET_EXP_ENTY_BILLS_DATA":
      return {
        ...state,
        billsData: action.data,
        bspinner: action.spinner
      }
    case "EXP_ENTY_LOADING_SPINNER":
      return {
        ...state,
        bspinner: action.spinner
      }
      case "UTILITY_LOADING_SPINNER":
        return {
          ...state,
          uspinner: action.spinner
        }
    case "ADD_EXP_ENTY":
      return {
        ...state,
        expData: action.data,
        bspinner: action.spinner
      }
    case "EXP_ENTY_SUB_LOADING_SPINNER":
      return {
        ...state,
        sspinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "EXP_ENTY_INN_SUB_LOADING_SPINNER":
      return {
        ...state,
        ispinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "EXP_ENTRY_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "EXP_ENTY_BILL_LOADING_SPINNER":
      return {
        ...state,
        bspinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "EXP_ENTY_REMOVE":
      return {
        ...state,
        expData: null
      }
    case "ADD_BILL_IMAGE":
      return {
        ...state,
        imgData: action.data,
        filCount: action.filCount,
        bspinner: action.spinner
      }
    default:
      return { ...state }
  }
}
export default ExpensesEntry
