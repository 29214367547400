// ** Initial State
const initialState = {
  response: [],
  spinner: false,
  tokenExp: false,
  btnSpinner: true,
};

const PurchaseOrderReq = (state = initialState, action) => {
  switch (action.type) {
    case "PURCHASE_ORDER":
      return {
        ...state,

        response: action.data,
        spinner: action.spinner,
        btnSpinner: action.btnSpinner
      };
    case "PURCHASE_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        btnSpinner: action.btnSpinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default PurchaseOrderReq;
