// ** Initial State
const initialState = {
    allData: [],
    tbeData: [],
    tbcData: [],
    data: [],
    total: 1,
    params: {},
    spinner: false,
    tokenExp: false,
  };
  const initialItem = (resData) => {
    if (resData) {
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
      });
      return resData;
    } else {
      return [];
    }
  };
  
  const crmDashboard = (state = initialState, action) => {
    switch (action.type) {
      case "GET_TOKEN_BY_DATE":
        return {
          ...state,
          tbeData: initialItem(action.tbeData),
          tbcData: initialItem(action.tbcData),
          spinner: action.spinner,
        }
      case "GET_TOKENS_BY_DATE_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      default:
        return { ...state };
    }
  };
  export default crmDashboard;
  