import { DefaultConfig } from "../../../../../../settings/config"
const ExamTypes = DefaultConfig().ExamTypes
const AttformatTypes = DefaultConfig().AttformatTypes

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",

  examName: "",
  examRef: null,
  examType: null,
  spinner: false,
  tokenExp: false,
  tspinner: false,
  ettList: [],
  subjData: [],

  aallData: [],
  adata: [],
  atotal: 1,
  aparams: {},
  selectedExam: null,
  actionTitleExam: '',
  selExamData: {},

  coSpinner: false,
  copyExam: [],
}

const initialItem = resData => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1
      if (ExamTypes.find(find => find.value === resp.examType)) {
        resp.examtypename = ExamTypes.find(find => find.value === resp.examType).label
      } else {
        resp.examtypename = ''
      }
      if (AttformatTypes.find(find => find.value === resp.attFrmt)) {
        resp.attFrmtName = AttformatTypes.find(find => find.value === resp.attFrmt).label
        resp.attFrmtName = resp.attFrmtName === "Overall" ? `${resp.stDate} to ${resp.endDate}` : resp.attFrmtName
      } else {
        resp.attFrmtName = ''
      }
    })
    return resData
  } else {
    return []
  }
}

const initialData = (resData, subjData) => {
  const DateFormat = DefaultConfig().DateFormat;
  const TimeFormat = DefaultConfig().TimeFormat;
  resData.forEach((resp, index) => {
    resp.assned = true;
    resp.serial = index + 1
    if (subjData.find((find) => find.value === resp.subRef)) {
      resp.subjname = subjData.find((find) => find.value === resp.subRef) ? subjData.find((find) => find.value === resp.subRef).label : "";
      // resp.subRef === "-1" ? "Elective-1" : resp.subRef === "-2" ? "Elective-2" : resp.subRef === "-3" ? "Elective-3" : 
    } else if (subjData.find((find) => find.value != resp.subRef)) {
      resp.subjname = resp.subRef === -1 ? "Elective-1" : resp.subRef === -2 ? "Elective-2" : resp.subRef === -3 ? "Elective-3" : "";
      // resp.subRef === "-1" ? "Elective-1" : resp.subRef === "-2" ? "Elective-2" : resp.subRef === "-3" ? "Elective-3" : 
    } else {
      resp.subjname = "";
    }
    if (resp.date) {
      resp.dateOnly = DateFormat(resp.date);
      resp.time = TimeFormat(resp.date);
    } else {
      resp.dateOnly = null;
      resp.time = null;
    }
  });
  return resData
}

const Classes = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_EXAM_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
        actionTitle: action.actionTitle
      }
    case "GET_EXAM_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case "GET_EXAM":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle
      }
    case "ADD_EXAM":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner
      }
    case "UPDATE_EXAM":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner
      }
    case "DELETE_EXAM":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      }
    case "GET_EXAM_SUBJ_DATA":
      return {
        ...state,
        subjData: action.data
      }
    case "EXAM_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "GET_EXAM_TIME_TABLE_DATA":
      return {
        ...state,
        ettList: initialData(action.allData, action.subjData),
        aallData: initialData(action.allData, action.subjData),
        adata: initialData(action.data, action.subjData),
        actionTitle: action.actionTitle,
        examName: action.examName,
        examRef: action.examRef,
        examType: action.examType,
        tspinner: action.spinner,
        aparams: action.params,
        atotal: action.totalPages,
        selExamData : action.examData
      }
    case "GET_EXAMTT_DATA":
      return {
        ...state,
        adata: action.data,
        atotal: action.totalPages,
        aparams: action.params
      }
    case "GET_TIMETABLE":
      return {
        ...state,
        selectedExam: action.selectedData,
        actionTitleExam: action.actionTitle
      }
    case "ADD_EXAM_TIME_TABLE_DATA":
      return {
        ...state,
        spinner: action.spinner,
        ettList: initialData(action.data, action.subjData),
        aallData: initialData(action.data, action.subjData),
      }
    case "DELETE_EXAM_TIME_TABLE_DATA":
      return {
        ...state,
        spinner: action.spinner,
        ettList: initialData(action.data, action.subjData),
        aallData: initialData(action.data, action.subjData),
      }
    case "CANCEL_EXAM_TIME_TABLE":
      return {
        ...state,
        actionTitle: action.actionTitle
      }
    case "EXAM_TITBLE_LOADING_SPINNER":
      return {
        ...state,
        tspinner: action.spinner,
        tokenExp: action.tokenExp
      }
    case "COPY_EXAM_DATA":
      return {
        ...state,
        copyExam: action.allData,
        coSpinner: action.spinner
      }
    case "EXAM_COPY_LOADING_SPINNER":
      return {
        ...state,
        coSpinner: action.spinner,
        tokenExp: action.tokenExp
      }
    default:
      return { ...state }
  }
}
export default Classes