// ** Initial State
const initialState = {
  accountTypes: [],
  allData: [],
  data: [],
  total: 1,
  params: {},
  spinner:false,
  //spinner: false,
  tokenExp: false,
  actionTitle: "",
  selectedBudget: null,
  recload: false,
};
const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const Budget = (state = initialState, action) => {
  switch (action.type) {
    case "BUDGET_ACCOUNT_DATA":
      //console.log(action, "acctype")
      return {
        ...state,
        accountTypes: action.data,
        spinner: action.spinner,
        recload: action.recloader
      }
    case "GET_ALL_BUDGET_DATA":

      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_BUDGET_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_BUDGET_TYPE":
      return {
        ...state,
        selectedBudget: action.selectedData,
        actionTitle: action.actionTitle,
      };
      case "ADD_BUDGET": 
      
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "UPDATE_BUDGET": 
    
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "DELETE_BUDGET":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "BUDGET_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default Budget;
