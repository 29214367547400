// ** Initial State
const initialState = {
  suggestions: [],
  bookmarks: [],
  query: '',
  childMenu: [],
  parentMenuTitle: '',
  ttl:'',
  parentId: '',
  parentName: '',
  sel: '',
  parentMenuIcon: '',
  parentMenu: [],
  childMenuLoad: false,
  groupEmpBasicInfo: [],

  insCompMapSpinner: false,
  insCompMap: [],
  insBranchIdList: [],
  insCompCount: 0
}

const navbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'HANDLE_CHILD_MENU_LOAD':
      return { ...state, childMenuLoad: action.val }
    case 'HANDLE_SEARCH_QUERY':
      return { ...state, query: action.val }
    case 'HANDLE_PARENT_MENU_TITLE':
      return { ...state, parentMenuTitle: action.val, ttl: action.ttl }
    case 'HANDLE_PARENT_MENU_ICON':
      return { ...state, parentMenuIcon: action.val }
    case 'HANDLE_CHILD_MENU_QUERY':
      return { ...state, childMenu: action.val, parentMenu: action.parent }
    case 'HANDLE_CHILD_MENU_DATA':
      return { ...state, parentId: action.id, parentName: action.name, sel: action.sel }
    case 'GET_BOOKMARKS':
      return { ...state, suggestions: action.data, bookmarks: action.bookmarks }
    case 'GROUP_EMP_BASIC_INFO':
      return { ...state, groupEmpBasicInfo: action.value }
    case 'UPDATE_BOOKMARKED':
      let objectToUpdate

      // ** find & update object
      state.suggestions.find(item => {
        if (item.id === action.id) {
          item.isBookmarked = !item.isBookmarked
          objectToUpdate = item
        }
      })

      // ** Get index to add or remove bookmark from array
      const bookmarkIndex = state.bookmarks.findIndex(x => x.id === action.id)

      if (bookmarkIndex === -1) {
        state.bookmarks.push(objectToUpdate)
      } else {
        state.bookmarks.splice(bookmarkIndex, 1)
      }

      return { ...state }
      case "GET_INS_COMP_MAP_LOADING_SPINNER":
      return {
        ...state,
        insCompMapSpinner: action.spinner,
        tokenExp: action.tokenExp
      }
      case "GET_INS_COMP_MAP":
      return {
        ...state,
        insCompMapSpinner: action.spinner,
        insCompMap: action.allData,
        insBranchIdList: action.insBranchIdList,
        insCompCount: action.insCompCount,
      }
    default:
      return state
  }
}

export default navbarReducer
