// ** Initial State
const initialState = {
  proData: [],
  feecateData: [],
  feeheads: [],
  oldschlData: [],
  departData: [],
  degData: [],
  stuInfoData: null,
  familyList: [],
  qualiList: [],
  trfrData: [],
  fspinner: false,
  espinner: false,
  tspinner: false,
  spinner: false,
  lspinner: false,
  sspinner: false,
  tokenExp: false,
  rspinner: false,
  routeData: [],
  bspinner: false,
  busData: [],
  stageData: [],
};

const StudentAdmisions = (state = initialState, action) => {
  switch (action.type) {
    case "GET_STUDENT_AR_DEPT_DATA":
      return {
        ...state,
        departData: action.data,
      };
    case "GET_STUDENT_AR_DEG_DATA":
      return {
        ...state,
        degData: action.data,
      };
    case "GET_STUDENT_AR_FEECATE_DATA":
      return {
        ...state,
        feecateData: action.data,
        spinner: action.spinner,
      };
    case "GET_STUDENT_AR_FEEHEAD_DATA":
      return {
        ...state,
        feeheads: action.data,
        spinner: action.spinner,
      };

    case "GET_STUDENT_AR_PRO_DATA":
      return {
        ...state,
        proData: action.data,
        espinner: action.spinner,
      };
    case "GET_STUDENT_AR_OLDSCH_DATA":
      return {
        ...state,
        oldschlData: action.data,
        spinner: action.spinner,
      };
    case "GET_STUDENT_AR_INFO_DATA":
      return {
        ...state,
        stuInfoData: action.data,
        familyList: action.familyList,
        qualiList: action.qualiList,
        lspinner: action.lspinner,
      };
    case "UPDATE_STUDENT_AR":
      return {
        ...state,
        // stuInfoData: action.data,
        // lspinner: action.lspinner,
      };
    case "ADD_STUDENT_AR_FAMILY":
      return {
        ...state,
        familyList: action.data,
        fspinner: action.fspinner,
      };
    case "ADD_STUDENT_AR_CERTIFICATE":
      return {
        ...state,
        qualiList: action.data,
        fspinner: action.fspinner,
      };
    // based on selection loading spinner
    case "STUDENT_AR_LOADING_SPINNER":
      return {
        ...state,
        espinner: action.espinner,
        sspinner: action.sspinner,
        tokenExp: action.tokenExp,
      };
    // initial page loading spinner
    case "STUDENT_AR_GP_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "STUDENT_AR_GET_LOADING_SPINNERS":
      return {
        ...state,
        lspinner: action.lspinner,
        tokenExp: action.tokenExp,
      };
    // get student info loading spinner
    case "STUDENT_AR_GET_LOADING_SPINNER":
      return {
        ...state,
        // lspinner: action.lspinner,
        tokenExp: action.tokenExp,
      };
    // get student info loading spinner
    case "STUDENT_AR_MULTI_LOADING_SPINNER":
      return {
        ...state,
        tspinner: action.fspinner,
        tokenExp: action.tokenExp,
      };
    case "STU_BRANCH_TRFR_GET":
      return {
        ...state,
        trfrData: action.data,
        tspinner: action.tspinner,
      };
    // get student info loading spinner
    case "STUDENT_BRANCH_TRFR_LOADING_SPINNER":
      return {
        ...state,
        fspinner: action.fspinner,
        tokenExp: action.tokenExp,
      };
    case "ROUTE_LOADING_SPINNER":
      return {
        ...state,
        rspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_ROUTE_DATA":
      return {
        ...state,
        routeData: action.data,
        rspinner: action.spinner,
      };
    case "BUS_LOADING_SPINNER":
      return {
        ...state,
        bspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_BUS_DATA":
      return {
        ...state,
        busData: action.data,
        bspinner: action.spinner,
      };
    case "STAGE_LOADING_SPINNER":
      return {
        ...state,
        sspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_STAGE_DATA":
      console.log(action, "red");
      return {
        ...state,
        stageData: action.data,
        sspinner: action.spinner,
      };

    default:
      return { ...state };
  }
};
export default StudentAdmisions;
