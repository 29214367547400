
// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
};

const ParentApp = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_PARENT_APP_DATA":
      return {
        ...state,
        allData: action.allData,
        spinner: action.spinner,
      };

    case "UPDATE_PARENT_APP":
      return {
        ...state,
        allData: action.allData,
        spinner: action.spinner,
      };

    case "PARENT_APP_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    default:
      return { ...state };
  }
};
export default ParentApp;
