import { DefaultConfig } from "../../../../../../settings/config";
const monStart = DefaultConfig().monthOptions;
const compId = DefaultConfig().Branches;

// ** Initial State
const initialState = {
  totalPrintTrayData: [],
  tstuptSpinner: false,
  stuPrintTrayData: [],
  idUpdData: {},
  updData: [],
  walAmount: "",
  updPtSpinner: false,
  stuData: [],
  stuTotal: 1,
  stuParams: {},

  tstaffptSpinner: false,
  staffPrintTrayData: [],
  tptSpinner: false,
  staffData: [],
  staffTotal: 1,
  staffParams: {},

  printTrayAllData: [],
  printTrayData: [],
  ptTotalPages: 1,
  ptParams: {},
  ptSpinner: false,

  allData: [],
  totData: [],
  data: [],
  total: 1,
  params: {},
  actionTitle: "",
  spinner: false,
  tokenExp: false,

  dtPtData: [],
  dtPtSpinner: false,
};

const initialItem = (resData) => {
  if (resData) {
    const Branches = DefaultConfig().Branches;

    resData.forEach((resp, index) => {
      if (Branches.find((find) => find.value === resp.compId)) {
        resp.branchName = Branches.find(
          (find) => find.value === resp.compId
        ).branchName;
      } else {
        resp.branchName = "";
      }

      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const PrintTray = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_PRINT_TRAY_DATA":
      return {
        ...state,
        totalPrintTrayData: action.allData,
        tptSpinner: action.spinner,
      };
    case "GET_STU_PRINT_TRAY_DATA":
      return {
        ...state,
        stuPrintTrayData: action.allData,
        tstuptSpinner: action.spinner,
      };
    case "GET_STU_PRINTTRAY_DATA":
      return {
        ...state,
        stuData: action.data,
        stuTotal: action.totalPages,
        stuParams: action.params,
      };
    case "GET_STAFF_PRINT_TRAY_DATA":
      return {
        ...state,
        staffPrintTrayData: action.allData,
        tstaffptSpinner: action.spinner,
      };
    case "GET_STAFF_PRINTTRAY_DATA":
      return {
        ...state,
        staffData: action.data,
        staffTotal: action.totalPages,
        staffParams: action.params,
      };
    case "PRINT_TRAY_LOADING_SPINNER":
      return {
        ...state,
        updSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    case "GET_PRINT_TRAY_DATA":
      return {
        ...state,
        printTrayAllData: initialItem(action.allData),
        printTrayData: action.data,
        ptTotalPages: action.totalPages,
        ptParams: action.params,
        ptSpinner: action.spinner,
      };
    case "UPDATE_PRINT_TRAY":
      return {
        ...state,
        idUpdData: action.updData,
        walAmount: action.walAmount,
        updData: action.resData,
        updPtSpinner: action.spinner,
      };
    case "PRINT_TRAY_DATEWISE_LOADING_SPINNER":
      return {
        ...state,
        dtPtSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_PTRAY_DTWISE_DATA":
      return {
        ...state,
        dtPtData: action.resData,
        dtPtSpinner: action.spinner,
      };

    default:
      return { ...state };
  }
};
export default PrintTray;
