// ** Initial State
const initialState = {
  allData: [],
  data: [],
  selectedData: null,
  total: 1,
  params: {},
  spinner: false,
  tokenExp: false,

  updData: [],
  uSpinner: false,
};

const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const StuPromoteDemote = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_STUDENT_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };

    case "GET_STUDENT_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "STUDENT_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_STU_PROMOTE_DEMOTE":
      return {
        ...state,
        allData: initialItem(action.data),
        uSpinner: action.spinner,
      };
    case "GET_PROMOTE_DEMOTE_SPINNER":
      return {
        ...state,
        uSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_STU_ITEM":
      return {
        ...state,
        selectedData: action.selectedData,
      };

    default:
      return { ...state };
  }
};
export default StuPromoteDemote;
