import axios from "axios";
import { toast } from "react-toastify";
import ToastMessage from "../../../../../../@core/components/toast-messages";
import { paginateArray } from "../../../../../../@fake-db/utils";
import { DefaultConfig } from "../../../../../../settings/config";

// ** Get all Data
export const getAllData = (params) => {
  console.log(params, "Get Data");
  return async (dispatch) => {
    const academicYear = params.academicData;
    const syllabus = params.syllabus;
    const authAxios = DefaultConfig().authAxios;
    dispatch({ type: "TEN_LOADING_SPINNER", spinner: false, tokenExp: false });
    await authAxios
      .get(`academic/lesson-planning/tentative-exams/${academicYear}/${syllabus}`)
      .then((response) => {
        const resData = response.data;
        const { perPage = 10, page = 1 } = params;
        if (resData) {
          const value = resData.value
          const rows = value.rows
          const columnIds = value.columnIds
          if (rows) {
            dispatch({
              type: "GET_ALL_TEN_DATA",
              columnIds,
              allData: rows,
              data: paginateArray(rows, perPage, page),
              totalPages: rows.length,
              params,
              spinner: true,
            });
          }
        }
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "TEN_LOADING_SPINNER",
              spinner: true,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "TEN_LOADING_SPINNER",
              spinner: true,
              tokenExp: false,
            });
            toast.error(<ToastMessage message={'academic/lesson-planning/tentative-exams' + '  ' + error} status={"danger"} />, {
              hideProgressBar: true,
            });
          }
        }
      });
  };
};

// ** Get data on page or row change
export const getData = (params) => {
  return (dispatch, getState) => {
    const {
      q = "",
      perPage = 10,
      page = 1,
      sortname = "",
      sortorder = "",
    } = params;
    // const resData = getState().classes.allData;
    const resData = getState().tentativeExams.allData
    if (resData) {
      let filteredData = [];
      const queryLowered = q.toLowerCase();
      filteredData = resData.filter(
        (academic) =>
          (academic.cls && academic.cls.toLowerCase().includes(queryLowered)) ||
          (academic.year && academic.year.toLowerCase().includes(queryLowered)) ||
          (academic.dept && academic.dept.toLowerCase().includes(queryLowered))
      );
      const sorting = DefaultConfig().sorter;
      const sortData = sortorder
        ? sorting(sortname, sortorder, filteredData)
        : filteredData;
      dispatch({
        type: "GET_TEN_DATA",
        data: paginateArray(sortData, perPage, page),
        totalPages: sortData.length,
        params,
      });
    }
  };
};

// ** Add new class
export const addTentativeExam = (Obj) => {
  return async (dispatch, getState) => {
    const authAxios = DefaultConfig().authAxios;
    dispatch({ type: "TEN_LOADING_SPINNER", spinner: false, tokenExp: false });
    await authAxios
      .post("academic/lesson-planning/tentative-exams/save", Obj)
      .then((response) => {
        const resData = response.data;
        if (resData) {
          const allData = getState().classes.allData;
          allData.unshift(resData);
          dispatch({
            type: "ADD_TEN",
            allData,
            spinner: true,
          });
          toast.success(
            <ToastMessage message={"Added Successfully"} status={"success"} />,
            { hideProgressBar: true }
          );
        }
      })
      .then(() => {
        dispatch(getAllData(getState().tentativeExams.params));
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "TEN_LOADING_SPINNER",
              spinner: true,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "TEN_LOADING_SPINNER",
              spinner: true,
              tokenExp: false,
            });
            toast.error(<ToastMessage message={'academic/lesson-planning/tentative-exams/save' + '  ' + error} status={"danger"} />, {
              hideProgressBar: true,
            });
          }
        }
      });
  };
};

// ** Get class
export const getTentativeExam = (id, title) => {
  return (dispatch, getState) => {
    const allData = getState().classes.allData;
    const findOne = allData.find((find) => find.sno === id);
    dispatch({
      type: "GET_TEN",
      selectedData: findOne,
      actionTitle: title,
    });
  };
};

// ** Update class
export const updateTentativeExam = (obj, selectedData) => {
  return async (dispatch, getState) => {
    const authAxios = DefaultConfig().authAxios;
    dispatch({ type: "TEN_LOADING_SPINNER", spinner: false, tokenExp: false });
    await authAxios
      .put(`academic/lesson-planning/tentative-exams/${selectedData.sno}`, obj)
      .then((response) => {
        const resData = response.data;
        if (resData) {
          const allData = getState().classes.allData;
          const findIndex = allData.findIndex(
            (find) => find.sno === selectedData.sno
          );
          if (findIndex === -1) {
            allData.push(resData);
          } else {
            allData[findIndex] = resData;
          }
          allData[findIndex] = resData;
          dispatch({
            type: "UPDATE_TEN",
            allData,
            spinner: true,
          });
          toast.success(
            <ToastMessage
              message={"Updated Successfully"}
              status={"success"}
            />,
            { hideProgressBar: true }
          );
        }
      })
      .then(() => {
        dispatch(getData(getState().classes.params));
      })
      .catch((error) => {
        const errorresponse = error.response;
        if (errorresponse) {
          const status = errorresponse.status;
          if (status === 403) {
            dispatch({
              type: "TEN_LOADING_SPINNER",
              spinner: true,
              tokenExp: true,
            });
          } else {
            const error = errorresponse.data.error;
            dispatch({
              type: "TEN_LOADING_SPINNER",
              spinner: true,
              tokenExp: false,
            });
            toast.error(<ToastMessage message={'academic/lesson-planning/tentative-exams' + '  ' + error} status={"danger"} />, {
              hideProgressBar: true,
            });
          }
        }
      });
  };
};

// ** Delete class
export const deleteTeantativeExam = (id) => {
  return async (dispatch, getState) => {
    const authAxios = DefaultConfig().authAxios;
    await authAxios
      .delete(`academic/lesson-planning/tentative-exams-delete/${id}`)
      .then((response) => {
        dispatch({
          type: "DELETE_TEN",
        });
      })
      .then(() => {
        dispatch(getAllData(getState().tentativeExams.params));
      });
  };
};
