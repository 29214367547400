// ** Initial State
const initialState = {
    params: {},
    stuData: [],
    tokenExp: false,
    spinner: false,
    sspinner: false,
    pspinner: false,
  };
  const initialItem = (resData) => {
    if (resData) {
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
      });
      return resData;
    } else {
      return [];
    }
  };
  const StudyCertificate= (state = initialState, action) => {
    switch (action.type) {

      case "GET_STUDY_DATA":
        return {
          ...state,
          stuData: action.allData,
          pspinner: action.spinner,
        };
        case "STUDY_LOADING_SPINNER":
          return {
            ...state,
            pspinner: action.spinner,
            tokenExp: action.tokenExp,
          };
          case "STU_REMOVE":
            return {
              ...state,
              expData: null,
            };
          default:
            return { ...state };
    }
  };
  export default StudyCertificate;
  
  