const initialState = {
  data: [],
  quesData: [],
  loader: false,
  srloader: true,
  saveResData: {}
}
const coursesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_QUES_DATA':
      return {
        ...state,
        quesData: action.data,
        loader: true
      }
    case "QUES_LOADING_SPINNER":
      return {
        ...state,
        tokenExp: action.tokenExp,
        loader: action.loader
      }
    case "SR_SAVE_DATA":
      return {
        ...state,
        saveResData: action.data,
      }
    case "SR_LOADING_SPINNER":
      return {
        ...state,
        tokenExp: action.tokenExp,
        srloader: action.srloader
      }
    default:
      return state
  }
}

export default coursesReducer

