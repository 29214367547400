import mock from "../mock"
import { paginateArray } from "../utils"

const data = {
  subjects: [
    {
      sno: 1,
      subj: "Telugu",
      subjAcr: "Tel",
      elective: "Elective 1",
      credits: 0
    },
    {
      sno: 2,
      subj: "English",
      subjAcr: "ENG",
      elective: "Elective 2",
      credits: 0
    },
    {
      sno: 3,
      subj: "Science",
      subjAcr: "SCI",
      elective: "Elective 3",
      credits: 0
    }
  ]
}

// GET ALL DATA
mock.onGet("/api/subjects/all-data").reply(200, data.subjects)

//GET UPDATE DATA
mock.onGet("/api/subjects/data").reply((config) => {
  const { q = "", perPage = 10, page = 1 } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const filteredData = data.subjects.filter(
    (subject) =>
      subject.subj.toLowerCase().includes(queryLowered) ||
      subject.elective.toLowerCase().includes(queryLowered)
  )
  /* eslint-enable  */

  return [
    200,
    {
      subjects: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

// GET Subject
mock.onGet("/api/subjects/subject").reply((config) => {
  const { id } = config
  const subject = data.subjects.find((i) => i.id === id)
  return [200, { subject }]
})
//Add subject
mock.onPost("/api/subject/add-subject").reply((config) => {
  // Get event from post data
  const subject = JSON.parse(config.data)

  const { length } = data.subjects
  let lastIndex = 0
  if (length) {
    lastIndex = data.subjects[length - 1].id
  }
  subject.id = lastIndex + 1

  data.subjects.unshift(subject)

  return [201, { subject }]
})

// DELETE: Deletes Bank
mock.onDelete("/api/subjects/delete").reply((config) => {
  // Get bank id from URL
  let subjectId = config.id

  // Convert Id to number
  subjectId = Number(subjectId)

  const subjectIndex = data.subjects.findIndex((t) => t.id === subjectId)
  data.subjects.splice(subjectIndex, 1)

  return [200]
})
