import { Fragment } from "react";
import BreadCrumbs from "../../../../breadcrum"; 
import SiblingForm from "./FilterForm";

// ** Styles
import "@styles/react/apps/app-users.scss";


const SiblingMapping = () => {
  return (
    <Fragment>
      <BreadCrumbs
        breadCrumbParent="Students"
        breadCrumbChild="Admission / Remove"
        breadCrumbTitle="Sibling Mapping"
      />
      <div>
        <SiblingForm />
        
      </div>
    </Fragment>
  );
};

export default SiblingMapping;