
// ** Initial State
const initialState = {
  allData: [],
  totData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
  orgType: false,
};

const IMSReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_IMS_DATA":
      return {
        ...state,
        allData: action.allData,
        totData: action.totData,
        spinner: action.spinner,
      };

    case "UPDATE_IMS":
      return {
        ...state,
        totData: action.allData,
        spinner: action.spinner,
        orgType: action.orgType,
      };

    case "IMS_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    default:
      return { ...state };
  }
};
export default IMSReducer;
