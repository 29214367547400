// ** Initial State
const initialState = {
  allData: [],
  billData: [],
  bspinner: false,
  dspinner: false,
  tokenExp: false,
};

const RecExpBillDelReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_BILLSEXP_DATA":
      return {
        ...state,
        billData: action.allData,
        bspinner: action.spinner,
      };
    case "GET_BILLSEXP_LOADING_SPINNER":
      return {
        ...state,
        bspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "DELETE_RECBILL_DATA":
      return {
        ...state,
        allData: action.allData,
        dspinner: action.spinner,
      };
    case "DELETE_RECEXPBILL_LOADING_SPINNER":
      return {
        ...state,
        dspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default RecExpBillDelReducer;
