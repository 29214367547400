import { DefaultConfig } from "../../../../../../settings/config";
const AcademicYear = DefaultConfig().AcademicYear;
const Subjects = DefaultConfig().Subjects;
const NaipunyaBatches = DefaultConfig().NaipunyaBatches;
const SubBranchList = DefaultConfig().SubBranchList

// ** Initial State
const initialState = {
    allData: [],
    attPercntData: [],
    spinner: false,
    tokenExp: false
  }

  const AttPercentage = (state = initialState, action) => {
    switch (action.type) {
      case "GET_ATT_PERCNT_DATA":
        return {
          ...state,
          attPercntData: action.allData,
          spinner: action.spinner,
        };
      case "ATT_PERCNT_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      default:
        return { ...state };
    }
  };
  export default AttPercentage
  