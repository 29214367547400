// ** Initial State
const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    selectedReprint: null,
    actionTitle: "",
    spinner: false,
    tokenExp: false,
  };
  
  // const initialItem = resData => {
  //   if (resData) {
  //     console.log(resData, "ResData")
  //     resData.forEach((resp, index) => {
  //       resp.serial = index + 1
  //     })
  //   }
  // }
  
  const initialItem = (resData) => {
    if (resData) {
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
      });
      return resData;
    } else {
      return [];
    }
  };
  
  const ReprintPocketMoney = (state = initialState, action) => {
    switch (action.type) {
      case "GET_ALL_REPRINT_VCHR_DATA":
        return {
          ...state,
          allData: action.allData,
          // data: action.data,
          //total: action.totalPages,
          // params: action.params,
          spinner: action.spinner,
        };
      case "GET_REPRINT_VCHR_DATA":
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params,
        };
      case "GET_REPRINT_VCHR":
        return {
          ...state,
          selectedReprint: action.selectedReprint,
          actionTitle: action.actionTitle,
        };
      
      case "REPRINT_VCHR_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp,
        };
      case "VOUCHER_ENTY_REMOVE":
        return {
          ...state,
          expData: null,
        };
      default:
        return { ...state };
    }
  };
  export default ReprintPocketMoney;
  