import { DefaultConfig } from "../../../../../settings/config";
const AcademicYear = DefaultConfig().AcademicYear


// ** Initial State
const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    selectedFeedbackcycle: null,
    actionTitle: "",
    spinner: false,
    tokenExp: false
  }

  const initialItem = (resData) => {
    if (resData) {
      console.log(resData, "ResData")
      resData.forEach((resp, index) => {
        resp.serial = index + 1;
        if (AcademicYear.find(find => find.value === resp.acadYear)) {
          resp.acadYear = AcademicYear.find(find => find.value === resp.acadYear).label
        } else {
          resp.acadYear = ''
        }
      });
      return resData;
    } else {
      return [];
    }
  };
  
  const FeedbackCycle = (state = initialState, action) => {
    switch (action.type) {
      case "GET_ALL_FEEDBACK_CYCLE_DATA":
        return {
          ...state,
          allData: initialItem(action.allData),
          data: action.data,
          total: action.totalPages,
          params: action.params,
          spinner: action.spinner
        }
      case "GET_FEEDBACK_CYCLE_DATA":
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      case "GET_FEEDBACK_CYCLE":
        return {
          ...state,
          selectedFeedbackcycle: action.selectedFeedbackcycle,
          actionTitle: action.actionTitle
        }
      case "ADD_FEEDBACK_CYCLE":
        console.log(action.allData, 'ROW Adding')
        return {
          ...state,
          allData: initialItem(action.allData),
          spinner: action.spinner
        }
      case "UPDATE_FEEDBACK_CYCLE":
        return {
          ...state,
          allData: initialItem(action.allData),
          spinner: action.spinner
        }
        case "DELETE_FEEDBACK_CYCLE":
          return { ...state,
            allData: initialItem(action.allData),
            spinner: action.spinner,
          }
      case "FEEDBACK_CYCLE_LOADING_SPINNER":
        return {
          ...state,
          spinner: action.spinner,
          tokenExp: action.tokenExp
        }
      default:
        return { ...state }
    }
  }
  export default FeedbackCycle
  