// ** Initial State
const initialState = {
  data: [],
  acadYearList: [],
  creditdata: [],
  aspinner: false,
  spinner: false,
  cspinner: false,
  tokenExp: false,
  erpdata: [],
  espinner: false,
  walSaveData: "",
  walSpinner: false,
};
const initialItem = (resData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      // if (AcademicYear.find(find => find.value === resp.prevYear)) {
      //   resp.prevYearName = AcademicYear.find(find => find.value === resp.prevYear).label
      // } else {
      //   resp.prevYearName = ''
      // }
    });
    return resData;
  } else {
    return [];
  }
};

const erpincome = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ERP_INCOME_DATA":
      return {
        ...state,
        data: action.data,
        spinner: action.spinner,
      };
    case "ERP_INCOME_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_ACAD_YEAR_LIST":
      return {
        ...state,
        acadYearList: action.data,
        aspinner: action.spinner,
      };
    case "ACAD_YEAR_LOADING_SPINNER":
      return {
        ...state,
        aspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_CREDIT_REPORT_DATA":
      return {
        ...state,
        creditdata: action.data,
        cspinner: action.spinner,
      };
    case "CREDIT_REPORT_LOADING_SPINNER":
      return {
        ...state,
        cspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "ADD_ERP_DATA":
      return {
        ...state,
        erpdata: action.data,
        cspinner: action.spinner,
      };
    case "ADD_ERP_LOADING_SPINNER":
      return {
        ...state,
        espinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "ADD_WALLET_LIST_SAVE":
      return {
        ...state,
        walSaveData: action.data,
        walSpinner: action.spinner,
      };
    case "WALLET_LIST_SAVE_LOADING_SPINNER":
      return {
        ...state,
        walSpinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    default:
      return { ...state };
  }
};
export default erpincome;
