// ** Initial State
const initialState = {
  leaveData: [],

  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
};

const initialItem = (resData, employeData, leaveData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1;
      if (employeData.find((find) => find.value === resp.empId)) {
        resp.empName = employeData.find(
          (find) => find.value === resp.empId
        ).label;
      } else {
        resp.empName = "";
      }
      if (leaveData.find((find) => find.value === resp.ltRef)) {
        resp.leaveName = leaveData.find(
          (find) => find.value === resp.ltRef
        ).label;
      } else {
        resp.leaveName = "";
      }
    });
    return resData;
  } else {
    return [];
  }
};

const ExtraLeaves = (state = initialState, action) => {
  switch (action.type) {
    case "GET_LEV_DATA":
      return {
        ...state,
        leaveData: action.data,
        spinner: action.spinner,
      };
    case "GET_ALL_EXTRA_LEAVE_DATA":
      return {
        ...state,
        allData: initialItem(
          action.allData,
          state.employeData,
          state.leaveData
        ),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_EXTRA_LEAVE_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_EXTRA_LEAVE":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle,
      };
    case "ADD_EXTRA_LEAVE":
      return {
        ...state,
        allData: action.resData,

        spinner: action.spinner,
      };
    case "UPDATE_EXTRA_LEAVE":
      return {
        ...state,
        allData: initialItem(
          action.allData,
          state.employeData,
          state.leaveData
        ),
        spinner: action.spinner,
      };
    case "DELETE_EXTRA_LEAVE":
      return { ...state };
    case "EXTRA_LEAVE_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    default:
      return { ...state };
  }
};
export default ExtraLeaves;
