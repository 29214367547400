// ** React Imports
import { Link, useHistory } from 'react-router-dom'
// ** Third Party Components
import Proptypes from 'prop-types'
import {
  Breadcrumb,
  BreadcrumbItem
} from 'reactstrap'
import { DefaultConfig } from '../../settings/config'

const BreadCrumbs = props => {
  // ** Props
  const { breadCrumbParent, breadCrumbChild, breadCrumbTitle, printDisplay } = props
  const history = useHistory()
  const empFirstName = DefaultConfig().empName
  const empLastName = DefaultConfig().empLastName
  const DateFormatMonth = DefaultConfig().DateFormatMonth
  const branchName = DefaultConfig().branchName

  const rereshPage = () => {
    // console.log(window.location.pathname)
    const pathname = window.location.pathname
    history.push('/pageRefresh')
    setTimeout(() => {
      history.push(pathname)
    }, 2000);
  }

  return (
    <>
    <div className='row mb-2'>
      <div className='col-sm-12 col-md-4 col-lg-4 col-xl-4'>
        {breadCrumbTitle ? <h2 className='content-header-title float-left mb-0 breadcrum-title' style={{ borderRight: 'none' }}>{breadCrumbTitle}</h2> : ''}
      </div>
      <div className='col-sm-12 col-md-8 col-lg-8 col-xl-8'>
        <div className='d-flex justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-start breadcrumb-wrapper'>
          <Breadcrumb>
            <BreadcrumbItem tag='li'> <Link to='/'>Home</Link> </BreadcrumbItem>
            <BreadcrumbItem tag='li'> {breadCrumbParent} </BreadcrumbItem>
            {breadCrumbChild ? <BreadcrumbItem tag='li'> {breadCrumbChild} </BreadcrumbItem> : ""}
            <BreadcrumbItem tag='li'> <Link to='#'
              // onClick={() => window.location.reload(false)}
              onClick={() => rereshPage()}
            >{breadCrumbTitle}</Link> </BreadcrumbItem>
          </Breadcrumb>
        </div>
      </div>
    </div> 
    {printDisplay &&
        <>
          <style>
            {`
              .branch-title{
                text-align: center !important;
                margin: 0 auto;
                font-size: 1.2rem;
                font-weight: bold;
                color: #000000 !important;
                text-decoration: underline;
              }
            `}  
          </style>
          <div className='enable-print-title'>
            <h4 className='text-center branch-title'>{branchName}</h4>
          </div>
          <div className='d-flex m-0 enable-print'>
            <div className='col-md-6 border-right'>
              {breadCrumbTitle ? <h2 className='content-header-title mb-0 breadcrum-title' style={{ borderRight: 'none' }}>{breadCrumbTitle}</h2> : ''}
              <div className='d-flex justify-content-start breadcrumb-wrapper'>
                <Breadcrumb>
                  {/* <BreadcrumbItem tag='li'> <Link to='/'>Home</Link> </BreadcrumbItem> */}
                  <BreadcrumbItem tag='li'> {breadCrumbParent} </BreadcrumbItem>
                  {breadCrumbChild ? <BreadcrumbItem tag='li'> {breadCrumbChild} </BreadcrumbItem> : ""}
                  <BreadcrumbItem tag='li'>{breadCrumbTitle}</BreadcrumbItem>
                </Breadcrumb>
              </div>
            </div>
            <div className='col-md-6 text-right emp-dtl'>
              <h6>Emp. Name: <b>{empFirstName}{empLastName}</b></h6>
              <h6>Date : <b>{DateFormatMonth(new Date())}</b></h6>
            </div>
          </div>
        </>
    }
    </>
  )
}
export default BreadCrumbs

// ** PropTypes
BreadCrumbs.propTypes = {
  breadCrumbTitle: Proptypes.string.isRequired,
  breadCrumbChild: Proptypes.string.isRequired,
  breadCrumbParent: Proptypes.string.isRequired
}
