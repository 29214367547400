// import { DefaultConfig } from "../../../../../../settings/config"
// const Degrees = DefaultConfig().Degrees

// ** Initial State
const initialState = {
  gradeData: [],
  subjData: [],
  groupData: [],
  subjectsData: [],
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedData: null,
  actionTitle: "",
  spinner: false,
  sspinner: false,
  tokenExp: false,
  espinner: false
}

const initialItem = (resData, gradeData, groupData, subjData) => {
  if (resData) {
    resData.forEach((resp, index) => {
      resp.serial = index + 1

      if (gradeData.find(find => find.value === Number(resp.gradeRef))) {
        resp.gradeName = gradeData.find(find => find.value === Number(resp.gradeRef)).label
      } else {
        resp.gradeName = ''
      }

      if (groupData.find(find => find.value === resp.cbseGrpRef)) {
        resp.grpName = groupData.find(find => find.value === resp.cbseGrpRef).label
      } else {
        resp.grpName = ''
      }

      if (subjData.find(find => find.value === resp.cbseSubRef)) {
        resp.subName = subjData.find(find => find.value === resp.cbseSubRef).label
      } else {
        resp.subName = ''
      }
    })
    return resData
  } else {
    return []
  }
}

const DescriptiveIndicator = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CBSE_GRP_DATA":
      return {
        ...state,
        groupData: action.data,
        spinner: action.spinner
      }
    case "GET_CBSE_GRD_DATA":
      return {
        ...state,
        gradeData: action.data,
        spinner: action.spinner
      }
    case "GET_CBSE_SUB_DATA":
      return {
        ...state,
        subjData: action.data,
        spinner: action.spinner,
        espinner: action.espinner
      }
    case "GET_CBSE_SUBJECTS_DATA":
      return {
        ...state,
        subjectsData: action.data,
        sspinner: action.spinner,
      }
    case "GET_ALL_DESCRI_DATA":
      return {
        ...state,
        allData: initialItem(action.allData, state.gradeData, state.groupData, state.subjData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner
      }
    case "GET_DESCRI_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case "GET_DESCRI":
      return {
        ...state,
        selectedData: action.selectedData,
        actionTitle: action.actionTitle
      }
    case "ADD_DESCRI":
      return {
        ...state,
        allData: initialItem(action.allData, state.gradeData, state.groupData, state.subjData),
        spinner: action.spinner
      }
    case "UPDATE_DESCRI":
      return {
        ...state,
        allData: initialItem(action.allData, state.gradeData, state.groupData, state.subjData),
        spinner: action.spinner
      }
    case "DELETE_DESCRI":
      return { ...state }
    case "DESCRI_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
        espinner: action.espinner
      }
    case "CBSE_SUBJECTS_LOADING_SPINNER":
      return {
        ...state,
        sspinner: action.spinner,
        tokenExp: action.tokenExp,
      }
    default:
      return { ...state }
  }
}
export default DescriptiveIndicator
