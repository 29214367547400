// ** Initial State
const initialState = {
  data: [],
  billData: [],
  spinner: false,
  tokenExp: false,
  billInfo: [],
  accountTypes: [],
  subaccountTypes: [],
  sspinner: false,
  innersubaccountTypes: [],
  ispinner: false,
  aspinner: true
};

const BillVendors = (state = initialState, action) => {
  switch (action.type) {
    case "GET_BILL_ENTY_ACCOUNT_DATA":
      return {
        ...state,
        accountTypes: action.data,
        spinner: action.spinner,
      };
    case "GET_BILL_ENTY_SUB_ACCOUNT_DATA":
      return {
        ...state,
        subaccountTypes: action.data,
        sspinner: action.spinner,
      };
    case "GET_BILL_ENTY_INN_SUB_ACCOUNT_DATA":
      return {
        ...state,
        innersubaccountTypes: action.data,
        ispinner: action.spinner,
      };

    case "BILL_ENTY_SUB_LOADING_SPINNER":
      return {
        ...state,
        sspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "BILL_ENTY_INN_SUB_LOADING_SPINNER":
      return {
        ...state,
        ispinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "BILL_ENTY_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "ADD_BILL_ENTRY":
      return {
        ...state,
        billData: action.data,
        aspinner: action.spinner,
      };
    case "BILL_ENTRY_LOADING_SPINNER":
      return {
        ...state,
        aspinner: action.spinner,
        tokenExp: action.tokenExp,
      };
    case "GET_BILL_INFO":
      return {
        ...state,
        billInfo: action.data,
        spinner: action.spinner,
      };

    default:
      return { ...state };
  }
};
export default BillVendors;
