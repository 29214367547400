import { DefaultConfig } from "../../../../../../settings/config";
const monStart = DefaultConfig().monthOptions;
const compId = DefaultConfig().Branches;
// ** Initial State
const initialState = {
  allData: [],
  totData: [],
  data: [],
  total: 1,
  params: {},
  selectedPayroll: null,
  actionTitle: "",
  spinner: false,
  tokenExp: false,
};

const initialItem = (resData) => {
  if (resData) {
    const Branches = DefaultConfig().Branches;

    resData.forEach((resp, index) => {
      if (monStart.find((find) => find.value === resp.monStart)) {
        resp.monStartName = monStart.find(
          (find) => find.value === resp.monStart
        ).label;
      } else {
        resp.monStartName = "";
      }
      // if (Branches.find((find) => find.value === resp.compId)) {
      //   resp.branchName = Branches.find(
      //     (find) => find.value === resp.compId
      //   ).branchName;
      // } else {
      //   resp.branchName = "";
      // }

      resp.serial = index + 1;
    });
    return resData;
  } else {
    return [];
  }
};

const PayRollConditions = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_PAY_CONDITION_DATA":
      return {
        ...state,
        allData: initialItem(action.allData),
        data: action.data,
        total: action.totalPages,
        params: action.params,
        spinner: action.spinner,
      };
    case "GET_PAYROLL_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_PAY_CONDITION":
      return {
        ...state,
        selectedPayroll: action.selectedPayroll,
        actionTitle: action.actionTitle,
      };
    case "ADD_PAY_CONDITION":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "UPDATE_PAYROLL":
      return {
        ...state,
        allData: initialItem(action.allData),
        spinner: action.spinner,
      };
    case "PAY_CONDITION_LOADING_SPINNER":
      return {
        ...state,
        spinner: action.spinner,
        tokenExp: action.tokenExp,
      };

    default:
      return { ...state };
  }
};
export default PayRollConditions;
